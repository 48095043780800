<template>
  <v-expansion-panel>
    <v-expansion-panel-header id="ownersList" class="pl-3">
      <div>
        <v-icon> mdi-security</v-icon>
        <span class="ml-1">IT Security By Design</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-list-item dense :selectable="false">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon>mdi-link</v-icon>
        </v-list-item-icon>
        <v-list-item-content style="text-align: left">
          <v-list-item-title v-if="loading">
            processing request. Please wait.
          </v-list-item-title>
          <v-list-item-title
            v-else-if="
              application.itSecId == undefined || application.itSecId == null
            "
          >
            No IT security demand configured</v-list-item-title
          >
          <v-list-item-title v-else-if="application.itSecId">
            <a class="link" :href="application.itSecURL" target="_blank"
              >{{ application.itSecId }} - {{ application.itSecTitle }}</a
            >
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-blur
                @click="selectSecId"
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                >mdi-plus-circle-outline</v-icon
              >
            </template>
            <span>Select the IT Security Demand of your application</span>
          </v-tooltip>

          <v-icon
            v-blur
            :disabled="
              application.itSecId == undefined ||
              application.itSecId == null ||
              loading
            "
            @click="deleteSecId()"
            >mdi-trash-can-outline</v-icon
          >
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
    <confirm-delete-overlay v-model="confirmDelete" @confirm="onRealDelete">
    </confirm-delete-overlay>
    <i-t-sec-demand-overlay
      v-model="selectDemand"
      @selected="onItDemand"
    ></i-t-sec-demand-overlay>
  </v-expansion-panel>
</template>

<script>
import cass from "../api/cass";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";
import ITSecDemandOverlay from "./ITSecDemandOverlay.vue";

export default {
  name: "ITSecDemand",
  components: { ConfirmDeleteOverlay, ITSecDemandOverlay },
  props: {
    application: Object,
  },
  data() {
    return {
      selectDemand: false,
      confirmDelete: false,
      confirmedDeleteId: null,
      loading: false,
      severError: null,
      showSnackError: false,
      snackErrorMessage: "",
    };
  },
  mounted() {
    this.$emitter.on("data_update", () => {
      //console.log("data_update");
      this.loading = false;
    });
  },
  methods: {
    onItDemand(demand) {
      this.loading = true;
      console.log("Saving demand: " + demand.SecId);
      cass
        .patchApplication(this.application.applicationId, {
          itSecId: demand.SecId,
          //itSecId: "test",
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("application_update");
        })
        .catch((error) => {
          console.log(error);
          this.snackErrorMessage = error.response.data.message;
          this.showSnackError = true;
          this.loading = false;
        });
    },
    selectSecId() {
      this.selectDemand = true;
    },
    deleteSecId() {
      this.confirmDelete = true;
    },
    onRealDelete() {
      this.loading = true;
      console.log("Removing demand: " + this.application.itSecId);
      cass
        .patchApplication(this.application.applicationId, {
          itSecId: "",
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("application_update");
        })
        .catch((error) => {
          console.log(error);
          this.snackErrorMessage = error.response.data.message;
          this.showSnackError = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}
</style>
