var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c("error-overlay", {
        attrs: { retryEnabled: false },
        model: {
          value: _vm.error,
          callback: function ($$v) {
            _vm.error = $$v
          },
          expression: "error",
        },
      }),
      _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
        _c(
          "div",
          [
            _c("v-icon", [_vm._v(" mdi-folder-star-multiple-outline ")]),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v("Subscribed Products"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm.application.subscriptions.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("v-col", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(" No Subscriptions available "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.application.subscriptions.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
          _vm._l(_vm.application.subscriptions, function (product, index) {
            return [
              _c(
                "v-list-item",
                { key: "B" + index, attrs: { dense: "", selectable: false } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [_c("v-icon", [_vm._v("mdi-package-variant-closed")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "flex-nowrap" },
                        [
                          _c("v-col", [
                            _vm._v(" " + _vm._s(product.title) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(product.apiProxies, function (proxy, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: product.name + proxy.name + index,
                      staticClass: "ml-8",
                      attrs: { dense: "", selectable: false },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticStyle: { "margin-right": "10px" } },
                        [_c("v-icon", [_vm._v("mdi-api")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticStyle: { "text-align": "left" } },
                        [
                          _c(
                            "v-row",
                            {},
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "7" } },
                                [
                                  _vm._v(" " + _vm._s(proxy.title) + " "),
                                  proxy.deprecation
                                    ? _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            outlined: "",
                                            color: "red",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDeprecation(
                                                proxy.deprecation
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getDeprecationChipText(
                                                  proxy.deprecation.State
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.permissionModelIconColor(
                                                              proxy.permissionModel
                                                            ),
                                                          small: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.permissionModelIcon(
                                                          proxy.permissionModel
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.permissionModel(
                                                        proxy.permissionModel
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.permissionModelToolTip(
                                              proxy.permissionModel
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-1 mt-1",
                                            attrs: { color: "#006c27" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onAPIPath(
                                                  $event,
                                                  proxy
                                                )
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-help-circle-outline")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Get API URL")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "img",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: _vm.$getImagePath(
                                                "openapi-1.svg"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDownloadSwagger(
                                                  $event,
                                                  proxy.name
                                                )
                                              },
                                            },
                                          },
                                          "img",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Download Swagger")])]
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _c("v-divider", { key: product.name }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c("v-spacer"),
              _c("div", [_vm._v("Manage Subscription")]),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { id: "addSubscriptionsButton" },
                      on: { click: _vm.addSubscription },
                    },
                    [_vm._v("mdi-plus-circle-outline")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("swagger-download-overlay", {
        attrs: { proxyname: _vm.proxyname, environment: _vm.environment },
        model: {
          value: _vm.downloadSwagger,
          callback: function ($$v) {
            _vm.downloadSwagger = $$v
          },
          expression: "downloadSwagger",
        },
      }),
      _c("api-url-overlay", {
        attrs: { proxy: _vm.selectedProxy, environment: _vm.environment },
        model: {
          value: _vm.apiUrl,
          callback: function ($$v) {
            _vm.apiUrl = $$v
          },
          expression: "apiUrl",
        },
      }),
      _c("sub-products-overlay", {
        attrs: { application: _vm.application },
        on: { apply: _vm.subscribeToProducts },
        model: {
          value: _vm.addSubscriptionDialog,
          callback: function ($$v) {
            _vm.addSubscriptionDialog = $$v
          },
          expression: "addSubscriptionDialog",
        },
      }),
      _c("sub-products-progress-overlay", {
        attrs: { messages: _vm.progressMessages },
        model: {
          value: _vm.showSubscriptionProgressDialog,
          callback: function ($$v) {
            _vm.showSubscriptionProgressDialog = $$v
          },
          expression: "showSubscriptionProgressDialog",
        },
      }),
      _c("sub-not-possible-overlay", {
        on: { assistance: _vm.assistance },
        model: {
          value: _vm.subscriptionNotPossibleDialog,
          callback: function ($$v) {
            _vm.subscriptionNotPossibleDialog = $$v
          },
          expression: "subscriptionNotPossibleDialog",
        },
      }),
      _c("sub-assistance-overlay", {
        attrs: { products: _vm.assistanceProducts },
        on: { submit: _vm.assistanceSubmit },
        model: {
          value: _vm.assistanceDialog,
          callback: function ($$v) {
            _vm.assistanceDialog = $$v
          },
          expression: "assistanceDialog",
        },
      }),
      _c("deprecation-detail-overlay", {
        attrs: { deprecation: _vm.selectedDeprecation },
        on: {
          close: function ($event) {
            _vm.showDeprecationDetail = false
          },
        },
        model: {
          value: _vm.showDeprecationDetail,
          callback: function ($$v) {
            _vm.showDeprecationDetail = $$v
          },
          expression: "showDeprecationDetail",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackInfo,
            callback: function ($$v) {
              _vm.showSnackInfo = $$v
            },
            expression: "showSnackInfo",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackInfoMessage) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }