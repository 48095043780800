import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

// https://v2.vuetifyjs.com/en/features/icon-fonts/
// https://www.the-koi.com/projects/setting-up-vue-3-with-vuetify-icons-and-themes/

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});
