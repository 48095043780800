var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "grey--text text--darken-2" },
            [
              _c("div", [_vm._v(" Important Information ")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "text--primary" }, [
            _c("p", [
              _vm._v(
                " You have old (non-CASS) applications. You created them directly in the old Developer Portal and most-likely used them in conjunction with the mTLS Self Service. "
              ),
            ]),
            _c("p", [
              _c("strong", { staticStyle: { color: "red" } }, [
                _vm._v("Old applications are now deprecated."),
              ]),
            ]),
            _c("p", [
              _vm._v(
                " We list the old applications here for your convienence. Please consider to use CASS and delete the old applications here. Use postman to delete your corresponding mTLS accounts! "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }