<template>
  <v-container fluid>
    <v-row justify="center">
      <v-card :loading="loading" :width="1200" class="mt-8 mb-8">
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="grey--text text--darken-2">
          <div>Deleted CASS Applications</div>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            color="#006c27"
            hide-details
            @keyup="applyFilter"
            style="padding-top: 0; margin-top: 0"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text class="text--primary my-3">
          <v-data-table
            sort-by="displayName"
            :custom-sort="customSort"
            must-sort
            :headers="headers"
            :items="filteredApplications"
            class="elevation-1"
            item-key="apiMgmtApplicationId"
            :hide-default-footer="filteredApplications.length <= 10"
            @click:row="rowClicked"
          >
            <template slot="no-data">
              <div v-if="loading">
                Loading deleted applications, please wait ...
              </div>
              <div v-else>No deleted CASS Apps found!</div>
            </template>

            <template v-slot:[`item.environment`]="{ item }">
              <v-chip
                outlined
                small
                :color="getEnvironmentColor(item.environment)"
                dark
              >
                {{ item.environment }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <deleted-application-overlay
      v-model="showInfo"
      :application="selectedApplication"
      @update="loadData"
    >
    </deleted-application-overlay>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import DeletedApplicationOverlay from "../components/DeletedApplicationOverlay.vue";

export default {
  name: "DeletedApps",
  components: { DeletedApplicationOverlay },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      deletedapps: [],
      filteredApplications: [],
      selectedApplication: null,
      search: "",
      loading: false,
      showInfo: false,
      confirmDelete: false,
      confirmedDeleteItem: null,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "displayName",
          width: "35%",
        },
        {
          text: "Owner",
          align: "start",
          value: "firstowner",
          width: "27.2%",
          filterable: false,
        },
        {
          text: "Permission Model",
          value: "permissionModel",
          width: "17.2%",
          filterable: false,
        },

        { text: "Environment", value: "environment", filterable: false },
        { text: "Deleted", value: "SafeDeleteHR", filterable: false },
      ],
    };
  },
  filters: {},
  computed: {},
  created() {},
  methods: {
    rowClicked(row) {
      console.log(row);
      this.selectedApplication = row;
      this.showInfo = true;
    },
    applyFilter() {
      this.filteredApplications = [];
      this.deletedapps.forEach((app) => {
        var search = this.search.toUpperCase();

        var found1 = app.displayName.toUpperCase().indexOf(search) != -1;
        var found2 = app.applicationId.toUpperCase().indexOf(search) != -1;
        var found3 =
          app.apiMgmtApplicationId.toUpperCase().indexOf(search) != -1;
        var found4 = false;
        for (var j = 0; j < app.owners.length; j++) {
          if (app.owners[j].toUpperCase().indexOf(search) != -1) {
            found4 = true;
            break;
          }
        }
        if (found1 || found2 || found3 || found4) {
          this.filteredApplications.push(app);
        }
      });
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index]
              .toLowerCase()
              .localeCompare(b[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          } else {
            return b[index]
              .toLowerCase()
              .localeCompare(a[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          }
        }
      });
      return items;
    },
    loadData() {
      console.log("Loading data ...");
      this.loading = true;
      cass
        .getDeletedAppliations()
        .then((response) => {
          console.log("Getting successful response");
          console.log(response);
          this.deletedapps = response.data;
          this.deletedapps.forEach((app) => {
            app.firstowner = app.owners[0];
            if (app.SafeDelete == false) {
              app.SafeDeleteHR = "yes";
            } else {
              app.SafeDeleteHR = "no";
            }
          });
          this.applyFilter();
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting deleted apps");
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
