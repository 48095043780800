var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c(
                "v-card",
                { attrs: { loading: _vm.loading, "max-width": "900" } },
                [
                  _c(
                    "error-overlay",
                    {
                      on: { retry: _vm.loadData },
                      model: {
                        value: _vm.error,
                        callback: function ($$v) {
                          _vm.error = $$v
                        },
                        expression: "error",
                      },
                    },
                    [
                      _vm.deleteApp
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { color: "#006c27" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteThis($event)
                                },
                              },
                            },
                            [_vm._v(" Delete ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "progress" },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          color: "#006c27",
                          height: "10",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "text--secondary" },
                    [
                      _vm._v(" Application Details "),
                      _c("v-spacer"),
                      _vm.application != null
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                id: "applicationEnvironment",
                                outlined: "",
                                color: _vm.getEnvironmentColor(
                                  _vm.application.environment
                                ),
                                dark: "",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.application.environment) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.application != null
                    ? _c(
                        "v-card-text",
                        { staticClass: "text--primary my-3" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "applicationDisplayName",
                                      value: _vm.application.displayName,
                                      label: "Name",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      color: "#006c27",
                                      "append-icon": "mdi-pencil-outline",
                                    },
                                    on: { "click:append": _vm.editAppName },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      attrs: {
                                        id: "applicationId",
                                        value: _vm.application.applicationId,
                                        label: "CASS Application ID",
                                        outlined: "",
                                        readonly: "",
                                        "hide-details": "",
                                        color: "#006c27",
                                      },
                                    },
                                    [
                                      this.$userData.cass.isUserAdmin
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              staticStyle: {
                                                "margin-top": "2px",
                                              },
                                              attrs: {
                                                slot: "append",
                                                dense: "",
                                              },
                                              on: {
                                                click: _vm.linkAzureAppReg,
                                              },
                                              slot: "append",
                                            },
                                            [_vm._v(" mdi-link ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: { "margin-top": "2px" },
                                          attrs: { slot: "append", dense: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copy2clipboard(
                                                _vm.application.applicationId
                                              )
                                            },
                                          },
                                          slot: "append",
                                        },
                                        [_vm._v(" mdi-content-copy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              this.$userData.cass.isUserAdmin
                                ? _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          attrs: {
                                            id: "applicationApiMgmtApplicationId",
                                            value: _vm._f(
                                              "formatStringToLowerCase"
                                            )(
                                              _vm.application
                                                .apiMgmtApplicationId
                                            ),
                                            label: "SAP Application ID",
                                            outlined: "",
                                            readonly: "",
                                            "hide-details": "",
                                            color: "#006c27",
                                          },
                                        },
                                        [
                                          this.$userData.cass.isUserAdmin
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.linkDeveloperPortal,
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-link ")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "margin-top": "2px",
                                              },
                                              attrs: {
                                                slot: "append",
                                                dense: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copy2clipboard(
                                                    _vm.application
                                                      .apiMgmtApplicationId
                                                  )
                                                },
                                              },
                                              slot: "append",
                                            },
                                            [_vm._v(" mdi-content-copy ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "applicationCreateDate",
                                      value: _vm._f("formatDate")(
                                        _vm.application.createDate
                                      ),
                                      label: "Created At",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      color: "#006c27",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "applicationPermissionModel",
                                      value: _vm._f("formatString")(
                                        _vm.application.permissionModel
                                      ),
                                      label: "Permission Model",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      color: "#006c27",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "applicationState",
                                      value: _vm._f("formatString")(
                                        _vm.application.state
                                      ),
                                      label: "Status",
                                      outlined: "",
                                      readonly: "",
                                      "hide-details": "",
                                      color: "#006c27",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-expansion-panels",
                                {
                                  staticClass: "mx-3 my-3",
                                  attrs: { multiple: true },
                                },
                                [
                                  _vm.isUserSchaeffler()
                                    ? _c("i-t-sec-demand", {
                                        attrs: { application: _vm.application },
                                      })
                                    : _vm._e(),
                                  _c("owners", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("usage-statistics", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("secrets", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("certificates", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("redirect", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("sub-products", {
                                    attrs: {
                                      application: _vm.application,
                                      reload: _vm.loadData,
                                    },
                                  }),
                                  _c("permissions", {
                                    attrs: { application: _vm.application },
                                  }),
                                  _c("o-auth-2", {
                                    attrs: { application: _vm.application },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                "margin-top": "18px",
                                "margin-bottom": "0px",
                                "margin-right": "1px",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-3",
                                  attrs: { color: "#006c27" },
                                  on: { click: _vm.back },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "white" },
                                    },
                                    [_vm._v("mdi-arrow-left")]
                                  ),
                                  _vm._v(" back"),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-3",
                                  attrs: { color: "#006c27" },
                                  on: { click: _vm.showGuide },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "white" },
                                    },
                                    [_vm._v("mdi-help")]
                                  ),
                                  _vm._v(" HELP"),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    id: "deleteApplicationButton",
                                    color: "#006c27",
                                  },
                                  on: { click: _vm.onDeleteApplication },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "white" },
                                    },
                                    [_vm._v("mdi-trash-can-outline")]
                                  ),
                                  _vm._v(" Delete"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("application-guide-overlay", {
        attrs: { application: _vm.application },
        model: {
          value: _vm.guideOverlay,
          callback: function ($$v) {
            _vm.guideOverlay = $$v
          },
          expression: "guideOverlay",
        },
      }),
      _vm.application != undefined
        ? _c("application-delete-overlay", {
            attrs: { safeDelete: _vm.safeDelete, application: _vm.application },
            model: {
              value: _vm.deleteOverlay,
              callback: function ($$v) {
                _vm.deleteOverlay = $$v
              },
              expression: "deleteOverlay",
            },
          })
        : _vm._e(),
      _vm.application != undefined
        ? _c("application-rename-overlay", {
            attrs: { application: _vm.application },
            on: { newname: _vm.updateName },
            model: {
              value: _vm.appNameEdit,
              callback: function ($$v) {
                _vm.appNameEdit = $$v
              },
              expression: "appNameEdit",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }