<template>
  <v-dialog width="100%" v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title>
        <img class="mb-1" :src="$getImagePath('openapi-1.svg')" />
        <div>
          Download Open API Specification
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          Please choose whether to download the Open API Specification in YAML
          or JSON
        </div>
        <v-row class="mt-4">
          <v-col align="center">
            <img
              @click="downloadJSON"
              width="100"
              height="100"
              class="mb-1 mr-9"
              :src="$getImagePath('json.png')"
            />
            <img
              @click="downloadYAML"
              width="100"
              height="100"
              class="mb-1"
              :src="$getImagePath('yaml.png')"
            />
          </v-col>
        </v-row>
        <v-row v-if="errormessage != ''" class="mt-4">
          <v-col align="center">
            <div>Error message: {{ errormessage }}</div>
            <div>Request Id: {{ requestId }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";
import YAML from "json2yaml";
import { Buffer } from "buffer";

export default {
  name: "SwaggerDownloadOverlay",
  components: {},
  props: {
    value: Boolean,
    proxyname: String,
    environment: String,
  },
  data: () => ({
    errormessage: "",
    requestId: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.show = false;
      this.errormessage = "";
      this.requestId = "";
    },
    downloadYAML() {
      cass
        .downloadSwagger(this.proxyname, this.environment)
        .then((response) => {
          console.log(response);
          var buffer = Buffer.from(response.data, "binary");
          var textdata = buffer.toString(); // for string
          var jsondata = JSON.parse(textdata); // for json

          let ymlText = YAML.stringify(jsondata);

          var fileURL = window.URL.createObjectURL(new Blob([ymlText]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.proxyname + "-" + this.environment + ".yaml"
          );
          document.body.appendChild(fileLink);

          fileLink.click();

          //this.close();
        })
        .catch((err) => {
          console.log(err);
          var buffer = Buffer.from(err.response.data, "binary");
          var textdata = buffer.toString(); // for string
          var jsondata = JSON.parse(textdata); // for json
          this.errormessage = jsondata.message;
          this.requestId = jsondata.requestId;
          console.log(jsondata);
        });
    },
    downloadJSON() {
      cass
        .downloadSwagger(this.proxyname, this.environment)
        .then((response) => {
          console.log(response);
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.proxyname + "-" + this.environment + ".json"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
          //this.close();
        })
        .catch((err) => {
          console.log(err);
          var buffer = Buffer.from(err.response.data, "binary");
          var textdata = buffer.toString(); // for string
          var jsondata = JSON.parse(textdata); // for json
          this.errormessage = jsondata.message;
          this.requestId = jsondata.requestId;
          console.log(jsondata);
        });
    },
  },
};
</script>

<style scoped>
img:hover {
  cursor: pointer;
  filter: brightness(10%);
}
</style>
