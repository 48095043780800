var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "mydialog",
      attrs: { persistent: "", width: "700px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text--secondary" }, [
            _c("div", [_vm._v("API Announcement!")]),
          ]),
          _c(
            "v-card-text",
            { staticClass: "text--primary" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("type-writer", {
                        attrs: { text: _vm.code, title: _vm.title },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "2%",
                    right: "1%",
                    "z-index": "202",
                  },
                },
                [
                  _c("henry", {
                    staticStyle: { "justify-content": "center" },
                    attrs: { scale: 1.2 },
                    on: { click: _vm.confirm },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }