<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="grey--text text--darken-2">
        <div>
          Important Information
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="text--primary">
        <p>
          You have old (non-CASS) applications. You created them directly in the
          old Developer Portal and most-likely used them in conjunction with the
          mTLS Self Service.
        </p>
        <p>
          <strong style="color:red"
            >Old applications are now deprecated.</strong
          >
        </p>
        <p>
          We list the old applications here for your convienence. Please
          consider to use CASS and delete the old applications here. Use postman
          to delete your corresponding mTLS accounts!
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  name: "NonCassAppsInfo",
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.show = false;
    },
  },
};
</script>

<style></style>
