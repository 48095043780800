var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", {
      ref: "speech",
      staticClass: "bubblewrap",
      attrs: { id: "speech" },
    }),
    _c(
      "div",
      {
        staticClass: "content",
        style: {
          transform: "scale(" + _vm.scaleC + ")",
          pointerEvents: "none",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "robotBox robotStart",
            staticStyle: { "pointer-events": "none" },
            attrs: { id: "robotBox" },
          },
          [
            _c(
              "div",
              {
                staticClass: "robotInner",
                staticStyle: { "pointer-events": "none" },
                attrs: { id: "robotInner" },
              },
              [
                _c(
                  "div",
                  {
                    ref: "rHeadBox",
                    staticClass: "rHeadBox hovering",
                    staticStyle: { "pointer-events": "none" },
                    attrs: { id: "rHeadBox" },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "rHeadBox2",
                        staticClass: "move2",
                        staticStyle: { "pointer-events": "none" },
                        attrs: { id: "rHeadBox2" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              id: "rHead",
                              viewBox: "0 0 217 212",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              staticClass: "strokeBlue fillSchaeffler",
                              attrs: {
                                d: "M27.7,176.8C13.5,176.8,2,157.1,2,132.6s14.5-44.5,28.7-44.5C22.1,115.1,20.9,145.3,27.7,176.8L27.7,176.8z",
                              },
                            }),
                            _c("path", {
                              staticClass: "strokeBlue fillSchaeffler",
                              attrs: {
                                d: "M189.4,176.8c14.2,0,25.7-19.8,25.7-44.2S200.5,88,186.4,88C194.9,115.1,196.1,145.3,189.4,176.8L189.4,176.8z",
                              },
                            }),
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M193.9,97.2c3.3,6.2,5.1,15.7,6,22.1c1.7,11.7,2.1,23.9,1.5,35.7c-0.5,9.6-1.7,19.5-4.2,28.8 c-0.3,1.2-1,2.1-2,2.8c-25.5,16.9-56.2,25-86.6,25s-61.2-8.1-86.6-25c-1-0.7-1.7-1.6-2-2.8c-2.5-9.3-3.7-19.1-4.2-28.8 c-0.6-11.8-0.2-24,1.5-35.7c0.9-6.4,2.7-15.8,6-22.1c-1.4-4.2-2.4-8.6-3.2-13c-0.3-1.4,0.1-2.7,0.9-3.8 c21-27.3,53.2-43.2,87.6-43.2s66.7,15.9,87.6,43.2c0.9,1.1,1.2,2.4,0.9,3.8C196.3,88.6,195.3,93,193.9,97.2L193.9,97.2z",
                              },
                            }),
                            _c("path", {
                              staticClass: "fillWhite",
                              staticStyle: {
                                cursor: "pointer",
                                "pointer-events": "auto",
                              },
                              attrs: {
                                d: "M192.4,83.4c-19.3-25.1-49.7-41.3-83.8-41.3S44,58.2,24.7,83.4c0.9,5.1,2.2,10,3.8,14.4 c-7.7,10.8-12,54.6-3.9,84.7c22.8,15.1,52.1,24.2,84,24.2s61.2-9.1,84-24.2c8.1-30.1,3.7-73.8-3.9-84.7 C190.1,93.3,191.4,88.4,192.4,83.4L192.4,83.4z",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFunc()
                                },
                              },
                            }),
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M78.5,46.3c-2,0.6-4,1.2-5.9,1.9v53.6h5.9V46.3L78.5,46.3z",
                              },
                            }),
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M144.4,48.3c-1.9-0.7-3.9-1.3-5.9-1.9V102h5.9V48.3L144.4,48.3z",
                              },
                            }),
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M142.9,95.3c18.7,1.5,39.4,7.4,39.4,30v35.3c0,2.6-0.4,5.2-1.3,7.7c-4,11.7-16.5,18.5-27.5,22.4 c-14,4.9-30.2,6.7-44.9,6.7s-30.9-1.9-44.9-6.7c-11-3.8-23.5-10.7-27.5-22.4c-0.9-2.5-1.3-5.1-1.3-7.7v-35.3 c0-22.6,20.7-28.5,39.4-30c2.2-0.2,4.3,1.2,5,3.3c1,2.9,6,5.4,8.6,6.4c6.4,2.5,13.9,3.5,20.8,3.5s14.4-1,20.8-3.5 c2.6-1,7.6-3.5,8.6-6.4C138.6,96.4,140.6,95.1,142.9,95.3L142.9,95.3z",
                              },
                            }),
                            _c("path", {
                              staticClass: "fillWhite",
                              staticStyle: {
                                cursor: "pointer",
                                "pointer-events": "auto",
                              },
                              attrs: {
                                d: "M39.6,160.6v-35.3c0-18.5,16.8-23.7,35-25.2c2.4,7.5,16.7,13.2,33.9,13.2s31.5-5.7,33.9-13.2 c18.2,1.4,35,6.7,35,25.2v35.3C177.4,203.3,39.6,203.3,39.6,160.6L39.6,160.6z",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFunc()
                                },
                              },
                            }),
                            _c("path", {
                              staticClass: "fillWhite",
                              staticStyle: {
                                cursor: "pointer",
                                "pointer-events": "auto",
                              },
                              attrs: {
                                d: "M145.3,105.2c-2.9,4.2-7.9,7-12.4,8.8c-7.5,3-16.2,4.1-24.3,4.1s-16.8-1.2-24.3-4.1 c-4.5-1.8-9.5-4.6-12.4-8.8c-8.2,0.9-18.5,3-23.7,9c-2.7,3.2-3.7,7-3.7,11.1v35.3c0,1.6,0.2,3.1,0.8,4.6 c2.9,8.5,13.8,13.7,21.6,16.4c13,4.5,28.1,6.2,41.7,6.2s28.8-1.7,41.7-6.2c7.8-2.7,18.7-7.9,21.6-16.4c0.5-1.5,0.8-3,0.8-4.6v-35.3 c0-4.1-1-7.9-3.7-11.1C163.6,108.2,153.4,106.1,145.3,105.2L145.3,105.2z",
                                id: "faceMask",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFunc()
                                },
                              },
                            }),
                            _c("polygon", {
                              staticClass: "stBlue",
                              attrs: {
                                points:
                                  "104.8,19.9 112.3,19.9 112.3,39.5 104.8,39.5",
                              },
                            }),
                            _c("path", {
                              staticClass: "strokeBlue fillSchaeffler glow",
                              attrs: {
                                d: "M108.5,2.3c7.5,0,13.5,6.1,13.5,13.5s-6.1,13.5-13.5,13.5C101,29.3,95,23.2,95,15.8S101.1,2.3,108.5,2.3 L108.5,2.3z",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "svg",
                          {
                            attrs: {
                              id: "eyes",
                              viewBox: "0 0 90.2 31.6",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c(
                              "g",
                              { ref: "eyeNormal", attrs: { id: "eyeNormal" } },
                              [
                                _c("path", {
                                  staticClass: "fillSchaeffler",
                                  attrs: {
                                    d: "M11.9,0c6.3,0,11.4,7.1,11.4,15.8s-5.1,15.8-11.4,15.8S0.5,24.5,0.5,15.8C0.5,7.1,5.6,0,11.9,0 L11.9,0z",
                                  },
                                }),
                                _c("path", {
                                  staticClass: "fillSchaeffler",
                                  attrs: {
                                    d: "M78.6,0c6.3,0,11.4,7.1,11.4,15.8s-5.1,15.8-11.4,15.8c-6.3,0-11.4-7.1-11.4-15.8S72.3,0,78.6,0 L78.6,0z",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "g",
                              { ref: "eyeBlink", attrs: { id: "eyeBlink" } },
                              [
                                _c("path", {
                                  staticClass: "fillSchaeffler",
                                  attrs: {
                                    d: "M11.9,3c6.3,0,11.4,7.1,11.4,15.8S18.2,8.6,11.9,8.6S0.5,27.5,0.5,18.8C0.5,10.1,5.6,3,11.9,3 L11.9,3z",
                                  },
                                }),
                                _c("path", {
                                  staticClass: "fillSchaeffler",
                                  attrs: {
                                    d: "M78.6,3c6.3,0,11.4,7.1,11.4,15.8S84.8,8.6,78.6,8.6c-6.3,0-11.4,18.9-11.4,10.2S72.3,3,78.6,3 L78.6,3z",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "g",
                              { ref: "eyeError", attrs: { id: "eyeError" } },
                              [
                                _c("rect", {
                                  staticClass: "fillWhite",
                                  attrs: {
                                    height: "8.1",
                                    width: "41.8",
                                    y: "0.6",
                                  },
                                }),
                                _c("rect", {
                                  staticClass: "fillWhite",
                                  attrs: {
                                    height: "8.1",
                                    width: "41.8",
                                    x: "48.4",
                                    y: "0.6",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "svg",
                          {
                            attrs: {
                              id: "mouth",
                              viewBox: "0 0 28.1 13.5",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              ref: "mouth3",
                              staticClass: "fillSchaeffler",
                              attrs: {
                                d: "M21.4,3.7c0.7,0.4,0.9,1.3,0.5,1.9c-0.8,1.4-2,2.5-3.3,3.3s-2.9,1.2-4.5,1.2s-3.2-0.4-4.5-1.2 C8.2,8.1,7.1,7,6.3,5.6C5.9,4.9,6.1,4.1,6.8,3.7C7.3,3.3,20.7,3.3,21.4,3.7z",
                                id: "mouth3",
                              },
                            }),
                            _c("path", {
                              ref: "mouth2",
                              staticClass: "fillSchaeffler hide",
                              attrs: {
                                d: "M23.6,0.6c0.9,0.8,1.2,2.5,0.7,3.9c-1.1,2.8-2.6,5-4.4,6.6c-1.7,1.5-3.8,2.4-5.9,2.4 s-4.2-0.9-5.9-2.4C6.3,9.5,4.8,7.2,3.7,4.5C3.2,3.1,3.5,1.4,4.4,0.6C5.3-0.2,22.8-0.2,23.6,0.6z",
                                id: "mouth2",
                              },
                            }),
                            _c("path", {
                              ref: "mouth1",
                              staticClass: "fillSchaeffler hide",
                              attrs: {
                                d: "M26.8,0.6c1.2,0.8,1.6,2.5,0.9,3.9c-1.4,2.8-3.4,5-5.8,6.6c-2.3,1.5-5,2.4-7.9,2.4 s-5.5-0.9-7.9-2.4C3.7,9.5,1.7,7.2,0.3,4.5C-0.4,3.1,0,1.4,1.2,0.6C2.4-0.2,25.6-0.2,26.8,0.6z",
                                id: "mouth1",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "rBodyBox",
                    staticClass: "rBodyBox hovering",
                    staticStyle: { "pointer-events": "none" },
                    attrs: { id: "rBodyBox" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "move1",
                        staticStyle: { "pointer-events": "none" },
                        attrs: { id: "rBodyBox2" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            ref: "armRight",
                            attrs: {
                              id: "armRight",
                              viewBox: "0 0 30 42",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M0.5,4.1c-0.7-1-0.6-2.4,0.3-3.2S3.1-0.1,4,0.7c4.5,3.6,8.7,8.5,12.3,12.9c2.6,3.2,5.1,6.5,7.4,9.8 c1.8,2.6,3.6,5.3,5.1,8c0.3,0.6,0.4,1.1,0.2,1.8c-1,4.2-4.4,6.8-8.4,7.9c-2.4,0.7-6.8,1.2-8.8-0.6c-0.9-0.8-2-2.8-2.6-3.9 c-1.2-2-2.3-4.1-3.4-6.1c-1.7-3.2-3.4-6.5-5.1-9.7c-0.4-0.8-0.4-1.6,0.1-2.4c1.5-2.5,1.6-5.8,1.2-8.6C1.9,8.4,1.2,5.2,0.5,4.1 L0.5,4.1z",
                              },
                            }),
                            _c("path", {
                              staticClass: "fillWhite",
                              staticStyle: {
                                cursor: "pointer",
                                "pointer-events": "auto",
                              },
                              attrs: {
                                d: "M2.4,2.6c8.6,7,20.5,23.1,24.2,30.1c-1.7,7-11.7,7.4-13.2,6.1C11.9,37.5,2.9,19.7,2.9,19.7 C6.7,13.4,3.5,4,2.4,2.6z",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFunc()
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "svg",
                          {
                            ref: "armLeft",
                            attrs: {
                              id: "armLeft",
                              viewBox: "0 0 30 42",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              staticClass: "stBlue",
                              attrs: {
                                d: "M29.5,4.1c0.7-1,0.6-2.4-0.3-3.2S26.9-0.1,26,0.7c-4.5,3.6-8.7,8.5-12.3,12.9c-2.6,3.2-5.1,6.5-7.4,9.8 c-1.8,2.6-3.6,5.3-5.1,8c-0.3,0.6-0.4,1.1-0.2,1.8c1,4.2,4.4,6.8,8.4,7.9c2.4,0.7,6.8,1.2,8.8-0.6c0.9-0.8,2-2.8,2.6-3.9 c1.2-2,2.3-4.1,3.4-6.1c1.7-3.2,3.4-6.5,5.1-9.7c0.4-0.8,0.4-1.6-0.1-2.4c-1.5-2.5-1.6-5.8-1.2-8.6C28.1,8.4,28.8,5.2,29.5,4.1 L29.5,4.1z",
                              },
                            }),
                            _c("path", {
                              staticClass: "fillWhite",
                              staticStyle: {
                                cursor: "pointer",
                                "pointer-events": "auto",
                              },
                              attrs: {
                                d: "M27.6,2.6C19,9.6,7.1,25.7,3.4,32.7c1.7,7,11.7,7.4,13.2,6.1c1.5-1.3,10.5-19.1,10.5-19.1 C23.3,13.4,26.5,4,27.6,2.6z",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFunc()
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "svg",
                          {
                            attrs: {
                              id: "body",
                              viewBox: "0 0 122.7 104.5",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("g", { attrs: { id: "body_1_" } }, [
                              _c("path", {
                                staticClass: "stBlue",
                                attrs: {
                                  d: "M114.1,2c4.7,6.6,7.5,14.1,8.3,22.2c1.4,14.9-4.3,29.9-12.1,42.3c-6.3,10-14.7,19.4-24,26.7 c-6.6,5.1-16.3,11.3-25,11.3s-18.4-6.2-25-11.3c-9.3-7.3-17.7-16.8-24-26.7C-0.1,46.8-6.1,22.3,8.5,2c1.3-1.8,3.4-2.4,5.5-1.7 c15,5.3,31.4,7.6,47.3,7.6s32.3-2.3,47.3-7.6C110.7-0.5,112.8,0.2,114.1,2L114.1,2z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fillSchaeffler",
                                attrs: {
                                  d: "M110.2,4.8c27.5,38.5-26.6,95-48.9,95s-76.4-56.6-48.9-95c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fillWhite",
                                staticStyle: {
                                  cursor: "pointer",
                                  "pointer-events": "auto",
                                },
                                attrs: {
                                  d: "M110.2,4.8c13.1,18.3,7.7,40.8-4,59.3c-17.3-4.8-21.1-9.8-22.9-20.8c-2.3-14-15.8-18.4-22-18.1 c-6.2-0.3-19.6,4.1-22,18.1c-1.8,11-5.6,16-22.9,20.8C4.7,45.6-0.7,23.2,12.4,4.8c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickFunc()
                                  },
                                },
                              }),
                              _c("path", {
                                staticClass: "fillWhite",
                                staticStyle: {
                                  cursor: "pointer",
                                  "pointer-events": "auto",
                                },
                                attrs: {
                                  d: "M110.2,4.8c13.1,18.3,7.7,40.8-4,59.3c-17.3-4.8-21.1-9.8-22.9-20.8c-2.3-14-15.8-18.4-22-18.1 c-6.2-0.3-19.6,4.1-22,18.1c-1.8,11-5.6,16-22.9,20.8C4.7,45.6-0.7,23.2,12.4,4.8c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickFunc()
                                  },
                                },
                              }),
                            ]),
                            _c("path", {
                              staticClass: "fillSchaeffler",
                              staticStyle: {
                                transform: "scale(0.7) translate(27px, 35px)",
                                fill: "white",
                              },
                              attrs: {
                                d: "M 57.984 17.018 C 42.779 17.018 37.753 28.893 37.753 34.915 C 37.753 51.738 52.561 53.582 55.261 54.369 C 66.038 57.621 72.769 57.404 72.769 66.236 C 72.769 71.621 68.256 75.767 63.042 75.767 C 54.233 75.767 48.553 69.752 47.479 61.177 H 38.336 V 83.355 H 47.674 V 79.269 C 52.335 83.152 58 84.911 64.015 84.911 C 75.882 84.911 84.441 76.826 84.441 64.68 C 84.441 50.65 75.936 47.444 67.128 44.837 C 58.312 42.246 49.425 40.939 49.425 34.721 C 49.425 29.157 53.277 25.967 58.569 25.967 C 62.172 25.967 65.066 27.328 67.323 29.468 C 69.478 31.538 71.081 34.409 71.797 37.833 H 80.746 V 18.38 H 71.214 V 22.465 C 67.696 19.126 63.019 17.018 57.984 17.018 Z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "rBodyBox hoveringShadow",
                    staticStyle: { "z-index": "-1", "pointer-events": "none" },
                    attrs: { id: "rBodyBox" },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          id: "rShadow",
                          viewBox: "0 0 74.7 9.2",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          staticClass: "rShadow",
                          attrs: {
                            d: "M37.3,0c20.4,0,37,2.1,37,4.6s-16.6,4.6-37,4.6s-37-2.1-37-4.6C0.4,2,16.9,0,37.3,0L37.3,0z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }