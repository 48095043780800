var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { width: "100%", persistent: "", "max-width": "500" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "max-width": "500", loading: _vm.scanning } },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "#006c27",
                      height: "10",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "text--secondary" },
                [
                  _c("v-icon", { staticClass: "mr-3" }, [
                    _vm._v("mdi-account-search-outline"),
                  ]),
                  _vm._v(" User Rescan "),
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", [
                  _vm._v(
                    " Rescanning the user base should ONLY be used if you need to edit a user which has never logged in - and is NOT present in the list. If this is not the case, please do not use this function as it is very resource hungry. "
                  ),
                ]),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(
                    " Please be patient. Scanning can last over 2 minutes. Do not refresh the page. "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-3 d-flex" },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-3",
                        attrs: {
                          disabled: _vm.disabled,
                          dark: !_vm.disabled,
                          color: "#006c27",
                        },
                        on: { click: _vm.close },
                      },
                      [_vm._v("CLOSE")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.disabled,
                          dark: !_vm.disabled,
                          color: "#006c27",
                        },
                        on: { click: _vm.scan },
                      },
                      [_vm._v("SCAN")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }