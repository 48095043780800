var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", "max-width": "1000" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "gradient", attrs: { "max-width": "1000" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-7" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "1.7rem",
                    "line-height": "2.2rem",
                    color: "white",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mb-2 mr-3",
                      attrs: { color: "white", large: "" },
                    },
                    [_vm._v("mdi-binoculars")]
                  ),
                  _vm._v("Our Vision is: "),
                ],
                1
              ),
              _c("v-card", { staticClass: "mt-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "px-7 py-7 text--secondary",
                    staticStyle: {
                      "font-size": "2rem",
                      "line-height": "2.2rem",
                    },
                  },
                  [
                    _vm._v(
                      " to be the leading data product hub that empowers business and IT to easily and securely connect their systems, partners, and customers in a digital world. "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-7" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "1.7rem",
                    "line-height": "2.2rem",
                    color: "white",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mb-1 mr-3",
                      attrs: { color: "white", large: "" },
                    },
                    [_vm._v("mdi-truck-delivery-outline")]
                  ),
                  _vm._v("Our Mission is: "),
                ],
                1
              ),
              _c("v-card", { staticClass: "mt-3 mb-5" }, [
                _c(
                  "div",
                  {
                    staticClass: "px-7 py-7 text--secondary",
                    staticStyle: {
                      "font-size": "2rem",
                      "line-height": "2.2rem",
                    },
                  },
                  [
                    _vm._v(
                      " to empower business to accelerate their digital transformation by providing world-class API Management solutions that simplify integration, enhance security, and improve customer experience. We are committed to delivering innovative, reliable, and scalable solutions that help our customers stay ahead of the competition. "
                    ),
                  ]
                ),
                _c("div", { staticClass: "px-7 pb-3 text--secondary" }, [
                  _vm._v(" #share2empower #data-as-a-product "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }