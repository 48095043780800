export default {
  install: function(vue) {
    // check if we run inside drupal
    var drupal = process.env.VUE_APP_DRUPAL;
    if (drupal == "true") {
      vue.prototype.$drupal = true;
      vue.prototype.$cdnServer = process.env.VUE_APP_CDN_SERVER;
    } else {
      vue.prototype.$drupal = false;
    }

    // function getImagePath
    vue.prototype.$getImagePath = function(filename) {
      if (vue.prototype.$drupal) {
        return vue.prototype.$cdnServer + "/img/" + filename;
      }
      return require("../assets/" + filename);
    };
  },
};
