var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticStyle: { "border-bottom": "1px solid grey !important" },
          attrs: { app: "", elevation: "0" },
        },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { disabled: !_vm.isUserLoggedIn },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "text--secondary text-h6 d-none d-sm-flex",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.goHome },
            },
            [_vm._v(" Consumer Application Self Service ")]
          ),
          _c(
            "span",
            { staticClass: "text--secondary text-h6 d-flex d-sm-none" },
            [_vm._v(" CASS ")]
          ),
          _c("v-spacer"),
          _vm.isUserLoggedIn ? _c("theme-button") : _vm._e(),
          !_vm.isUserLoggedIn
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    icon: "",
                    disabled: !_vm.isUserLoggedIn,
                    color: "#646464",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.SignIn()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-login")])],
                1
              )
            : _vm._e(),
          _vm.isUserLoggedIn && _vm.userInfo
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              [
                                _vm.isUserAdmin
                                  ? _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          color: "red",
                                          icon: "mdi-star",
                                          "offset-x": "25px",
                                          "offset-y": "25px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "mr-3" },
                                              "v-list-item-avatar",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm.userPhoto
                                              ? _c("img", {
                                                  attrs: { src: _vm.userPhoto },
                                                })
                                              : _vm._e(),
                                            !_vm.userPhoto
                                              ? _c(
                                                  "v-avatar",
                                                  {
                                                    attrs: {
                                                      color: "#006C27",
                                                      size: "40",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.nameinitials
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.isUserAdmin
                                  ? _c(
                                      "v-list-item-avatar",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "mr-3" },
                                          "v-list-item-avatar",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.userPhoto
                                          ? _c("img", {
                                              attrs: { src: _vm.userPhoto },
                                            })
                                          : _vm._e(),
                                        !_vm.userPhoto
                                          ? _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  color: "#006C27",
                                                  size: "40",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "white--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.nameinitials)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4019969148
                  ),
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account-outline")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.userInfo.givenName) +
                                        " " +
                                        _vm._s(_vm.userInfo.surname)
                                    ),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.userInfo.jobTitle)),
                                    ]),
                                    _vm.isUserAdmin
                                      ? _c(
                                          "div",
                                          {
                                            style: _vm.isUserAdmin
                                              ? "color: red; cursor: pointer"
                                              : "cursor: pointer",
                                            on: { click: _vm.toogleAdmin },
                                          },
                                          [_vm._v(" CASS Administrator ")]
                                        )
                                      : _vm._e(),
                                    !_vm.isUserAdmin
                                      ? _c("div", [_vm._v("CASS User")])
                                      : _vm._e(),
                                    _vm.isBetaUser
                                      ? _c("div", [_vm._v("Beta User")])
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-star-outline")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "mt-1" },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Active Environments"),
                                  ]),
                                  _c(
                                    "v-list-item-subtitle",
                                    [
                                      _vm._l(
                                        _vm.userEnvironments,
                                        function (env, index) {
                                          return [
                                            _c("div", { key: index }, [
                                              _vm._v(_vm._s(env)),
                                            ]),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-logout")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Logout"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-img", {
                staticClass: "shrink mr-2",
                attrs: {
                  alt: "Schaeffler Logo",
                  contain: "",
                  src: _vm.$getImagePath("schaeffler-logo.svg"),
                  transition: "scale-transition",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", temporary: "", width: 325 },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.home } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-home-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Home")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.apiportal } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-api"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("API Portal")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.developerportal } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-code-tags"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Developer Portal")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.permissionModel } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-key-chain"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Permission Models")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.environments } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-numeric-3-box-multiple-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Environments")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.feedback } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-comment-quote-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Feedback")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.mtlsaccount } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-certificate-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Non-CASS Apps ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.openpermissionrequest } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-account-key"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Open Permission Requests ")]
                      ),
                    ],
                    1
                  ),
                  _vm.isUserAdmin
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.opendeletedapps } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("mdi-delete-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-1 text--secondary" },
                            [_vm._v("Deleted Apps ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.community } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v("mdi-account-group-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "subtitle-1 text--secondary" },
                        [_vm._v("Community")]
                      ),
                    ],
                    1
                  ),
                  _vm.isUserAdmin
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.manage } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#006c27" } }, [
                                _vm._v("mdi-cog-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-1 text--secondary" },
                            [_vm._v("Manage")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }