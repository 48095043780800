<template>
  <v-overlay
    ref="statusoverlay"
    v-model="show"
    z-index="20000"
    absolute
    opacity="1"
    style="display: unset !important"
  >
    <div style="width: 100%; height: 100%; background-color: white;">
      <div
        style="position: absolute;
              z-index: 1;
              top: 100px;
              width: 100%;
              text-transform: uppercase;
              text-align: center;
              font-family: 'Open Sans', sans-serif;"
      >
        <div class="unselectable" style="font-size: 5rem;  color: #4c4c4c;">
          We are sorry!
        </div>
        <div class="unselectable" style=" font-size: 1.1rem; color: #7e7e7e;">
          CASS needs the SAP APIs. These are currently unresponsive.<br />
          Help is on the way. In order to cool down - you can chill the lion<br />
          He will surely appreciate it!
        </div>
      </div>

      <div
        style=" background: white;
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;"
        id="container"
      ></div>
    </div>
  </v-overlay>
</template>

<script>
import * as THREE from "three";

export default {
  name: "StatusOverlay",
  props: {
    value: Boolean,
  },
  mounted() {
    console.log("StatusOverlay mounted");
    if (!this.active) {
      //Activate this for debugging or site enhancements
      //this.stop();
      //this.start();
    }
  },
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      fan: null,
      lion: null,
      isBlowing: false,
      mousePos: { x: 1, y: 0 },
      windowHalfX: null,
      windowHalfY: null,
      active: false,
      resizeObserver: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
    },
  },
  watch: {
    value(value) {
      if (!value) {
        console.log("Status Dialog was closed!");
        this.stop();
      }
    },
  },
  updated() {
    if (!this.active && this.show) {
      console.log("Status Dialog was opened!");
      this.start();
    }
  },

  methods: {
    stop() {
      if (this.resizeObserver != null) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
      this.active = false;
      this.renderer = null;
      this.scene = null;
      this.camera = null;
      this.mesh = null;
      this.fan = null;
      this.lion = null;
      this.isBlowing = false;
      this.getDocument().removeEventListener(
        "mousemove",
        this.handleMouseMove,
        false
      );
      this.getDocument().removeEventListener(
        "mousedown",
        this.handleMouseDown,
        false
      );
      this.getDocument().removeEventListener(
        "mouseup",
        this.handleMouseUp,
        false
      );
      window.removeEventListener("resize", this.onWindowResize, false);
    },
    start() {
      console.log("Starting ThreeJS");
      this.active = true;
      this.init();
      this.createLights();
      this.createFloor();
      this.createFan();
      this.createLion();
      this.animate();
    },
    handleMouseMove(event) {
      this.mousePos = { x: event.clientX, y: event.clientY };
    },
    handleMouseDown() {
      this.isBlowing = true;
    },
    handleMouseUp() {
      this.isBlowing = false;
    },
    getHeight() {
      var HEIGHT;
      if (!this.$drupal) {
        HEIGHT = window.innerHeight;
      } else {
        HEIGHT = this.getDocument().getElementById("app").clientHeight;
        var windowHEIGHT = window.innerHeight;
        if (HEIGHT > windowHEIGHT) {
          HEIGHT = windowHEIGHT;
        }
      }
      return HEIGHT;
    },
    getWidth() {
      var WIDTH;
      if (!this.$drupal) {
        WIDTH = window.innerWidth;
      } else {
        WIDTH = this.getDocument().getElementById("app").clientWidth;
      }
      return WIDTH;
    },
    onWindowResize() {
      console.log("onResize");
      var HEIGHT = this.getHeight();
      var WIDTH = this.getWidth();
      this.windowHalfX = WIDTH / 2;
      this.windowHalfY = HEIGHT / 2;
      this.renderer.setSize(WIDTH, HEIGHT);
      this.camera.aspect = WIDTH / HEIGHT;
      this.camera.updateProjectionMatrix();
    },
    getDocument() {
      return this.$document;
    },
    init() {
      this.scene = new THREE.Scene();
      var HEIGHT = this.getHeight();
      var WIDTH = this.getWidth();
      var aspectRatio = WIDTH / HEIGHT;
      var fieldOfView = 60;
      var nearPlane = 1;
      var farPlane = 2000;
      this.camera = new THREE.PerspectiveCamera(
        fieldOfView,
        aspectRatio,
        nearPlane,
        farPlane
      );
      this.camera.position.z = 800;
      this.camera.position.y = 0;
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(WIDTH, HEIGHT);
      this.renderer.shadowMap.enabled = true;
      var container = this.getDocument().getElementById("container");
      container.appendChild(this.renderer.domElement);
      this.windowHalfX = WIDTH / 2;
      this.windowHalfY = HEIGHT / 2;
      this.getDocument().addEventListener(
        "mousemove",
        this.handleMouseMove,
        false
      );
      this.getDocument().addEventListener(
        "mousedown",
        this.handleMouseDown,
        false
      );
      this.getDocument().addEventListener("mouseup", this.handleMouseUp, false);
      this.getDocument()
        .getElementById("app")
        .addEventListener("resize", this.onWindowResize, false);

      this.resizeObserver = new ResizeObserver(() => {
        console.log("ResizeObserver");
        this.onWindowResize();
      });

      // Observe one or multiple elements
      this.resizeObserver.observe(this.getDocument().getElementById("app"));

      //window.addEventListener("resize", this.onWindowResize, false);
      console.log("init end");
    },
    createLion() {
      let self = this;
      let Lion = function() {
        this.windTime = 0;
        this.bodyInitPositions = [];
        this.maneParts = [];
        this.threegroup = new THREE.Group();
        this.yellowMat = new THREE.MeshPhongMaterial({
          color: 0xfdd276,
          flatShading: true,
          side: THREE.DoubleSide,
        });
        this.redMat = new THREE.MeshPhongMaterial({
          color: 0xad3525,
          flatShading: true,
        });

        this.pinkMat = new THREE.MeshPhongMaterial({
          color: 0xe55d2b,
          flatShading: true,
        });

        this.whiteMat = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          flatShading: true,
        });

        this.purpleMat = new THREE.MeshPhongMaterial({
          color: 0x451954,
          flatShading: true,
        });

        this.greyMat = new THREE.MeshPhongMaterial({
          color: 0x653f4c,
          flatShading: true,
        });

        this.blackMat = new THREE.MeshPhongMaterial({
          color: 0x302925,
          flatShading: true,
        });

        //var bodyGeom = new THREE.CylinderGeometry(30, 80, 140, 4);
        var maneGeom = new THREE.BoxGeometry(40, 40, 15);
        var faceGeom = new THREE.BoxGeometry(80, 80, 80);
        var spotGeom = new THREE.BoxGeometry(4, 4, 4);
        var mustacheGeom = new THREE.BoxGeometry(30, 2, 1);
        mustacheGeom.applyMatrix4(
          new THREE.Matrix4().makeTranslation(15, 0, 0)
        );

        var earGeom = new THREE.BoxGeometry(20, 20, 20);
        var noseGeom = new THREE.BoxGeometry(40, 40, 20);
        var eyeGeom = new THREE.BoxGeometry(5, 30, 30);
        var irisGeom = new THREE.BoxGeometry(4, 10, 10);
        var mouthGeom = new THREE.BoxGeometry(20, 20, 10);
        var smileGeom = new THREE.TorusGeometry(12, 4, 2, 10, Math.PI);
        var lipsGeom = new THREE.BoxGeometry(40, 15, 20);
        var kneeGeom = new THREE.BoxGeometry(25, 80, 80);
        kneeGeom.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 50, 0));
        var footGeom = new THREE.BoxGeometry(40, 20, 20);

        // body
        let bodyGeom = new THREE.BufferGeometry();
        const vertices = [
          // unten rechts
          { pos: [0, 0, 80] }, // 0
          { pos: [0, 0, -80] }, // 1
          { pos: [80, 0, 0] }, // 2
          // unten links
          { pos: [0, 0, 80] }, // 3
          { pos: [0, 0, -80] }, // 4
          { pos: [-80, 0, 0] }, // 5
          // vorne links
          { pos: [0, 0, 80] }, // 6
          { pos: [-40, 140, 80] }, // 7
          { pos: [-80, 0, 0] }, // 8
          // vorne rechts
          { pos: [0, 0, 80] }, //  9
          { pos: [40, 140, 80] }, // 10
          { pos: [80, 0, 0] }, // 11
          // vorne mitte
          { pos: [0, 0, 80] }, // 12
          { pos: [40, 140, 80] }, // 13
          { pos: [-40, 140, 80] }, // 14
          // hinten links
          { pos: [-80, 0, 0] }, // 15
          { pos: [0, 0, -80] }, // 16
          { pos: [-40, 140, 80] }, // 17
          // hinten rechts
          { pos: [80, 0, 0] }, //  18
          { pos: [0, 0, -80] }, // 19
          { pos: [40, 140, 80] }, // 20
          // hinten mitte
          { pos: [0, 0, -80], norm: [0, 1, 0], uv: [0, 1] }, // 21
          { pos: [-40, 140, 80], norm: [0, 1, 0], uv: [0, 1] }, // 22
          { pos: [40, 140, 80], norm: [0, 1, 0], uv: [0, 1] }, // 23
        ];

        const positions = [];
        for (const vertex of vertices) {
          positions.push(...vertex.pos);
        }

        const positionNumComponents = 3;
        bodyGeom.setAttribute(
          "position",
          new THREE.BufferAttribute(
            new Float32Array(positions),
            positionNumComponents
          )
        );

        this.body = new THREE.Mesh(bodyGeom, this.yellowMat);
        this.body.position.y = -100;
        this.body.position.z = -60;
        this.bodyVertices = [7, 10, 13, 14, 17, 20, 22, 23];

        const positionAttribute = this.body.geometry.getAttribute("position");
        const vertex = new THREE.Vector3();

        for (var i = 0; i < this.bodyVertices.length; i++) {
          vertex.fromBufferAttribute(positionAttribute, this.bodyVertices[i]);
          this.bodyInitPositions.push({
            x: vertex.x,
            y: vertex.y,
            z: vertex.z,
          });
        }

        // knee
        this.leftKnee = new THREE.Mesh(kneeGeom, this.yellowMat);
        this.leftKnee.position.x = 65;
        this.leftKnee.position.z = -20;
        this.leftKnee.position.y = -110;
        this.leftKnee.rotation.z = -0.3;

        this.rightKnee = new THREE.Mesh(kneeGeom, this.yellowMat);
        this.rightKnee.position.x = -65;
        this.rightKnee.position.z = -20;
        this.rightKnee.position.y = -110;
        this.rightKnee.rotation.z = 0.3;

        // feet
        this.backLeftFoot = new THREE.Mesh(footGeom, this.yellowMat);
        this.backLeftFoot.position.z = 30;
        this.backLeftFoot.position.x = 75;
        this.backLeftFoot.position.y = -90;

        this.backRightFoot = new THREE.Mesh(footGeom, this.yellowMat);
        this.backRightFoot.position.z = 30;
        this.backRightFoot.position.x = -75;
        this.backRightFoot.position.y = -90;

        this.frontRightFoot = new THREE.Mesh(footGeom, this.yellowMat);
        this.frontRightFoot.position.z = 40;
        this.frontRightFoot.position.x = -22;
        this.frontRightFoot.position.y = -90;

        this.frontLeftFoot = new THREE.Mesh(footGeom, this.yellowMat);
        this.frontLeftFoot.position.z = 40;
        this.frontLeftFoot.position.x = 22;
        this.frontLeftFoot.position.y = -90;

        // mane

        this.mane = new THREE.Group();

        for (var j = 0; j < 4; j++) {
          for (var k = 0; k < 4; k++) {
            var manePart = new THREE.Mesh(maneGeom, this.redMat);
            manePart.position.x = j * 40 - 60;
            manePart.position.y = k * 40 - 60;

            var amp;
            var zOffset;
            var periodOffset = Math.random() * Math.PI * 2;

            if (
              (j == 0 && k == 0) ||
              (j == 0 && k == 3) ||
              (j == 3 && k == 0) ||
              (j == 3 && k == 3)
            ) {
              amp = -10 - Math.floor(Math.random() * 5);
              zOffset = -5;
            } else if (j == 0 || k == 0 || j == 3 || k == 3) {
              amp = -5 - Math.floor(Math.random() * 5);
              zOffset = 0;
            } else {
              amp = 0;
              zOffset = 0;
            }

            this.maneParts.push({
              mesh: manePart,
              amp: amp,
              zOffset: zOffset,
              periodOffset: periodOffset,
              xInit: manePart.position.x,
              yInit: manePart.position.y,
            });
            this.mane.add(manePart);
          }
        }

        this.mane.position.y = -10;
        this.mane.position.z = 80;
        //this.mane.rotation.z = Math.PI/4;

        // face
        this.face = new THREE.Mesh(faceGeom, this.yellowMat);
        this.face.position.z = 135;

        // Mustaches

        this.mustaches = [];

        this.mustache1 = new THREE.Mesh(mustacheGeom, this.greyMat);
        this.mustache1.position.x = 30;
        this.mustache1.position.y = -5;
        this.mustache1.position.z = 175;
        this.mustache2 = this.mustache1.clone();
        this.mustache2.position.x = 35;
        this.mustache2.position.y = -12;
        this.mustache3 = this.mustache1.clone();
        this.mustache3.position.y = -19;
        this.mustache3.position.x = 30;
        this.mustache4 = this.mustache1.clone();
        this.mustache4.rotation.z = Math.PI;
        this.mustache4.position.x = -30;
        this.mustache5 = new THREE.Mesh(mustacheGeom, this.blackMat);
        this.mustache5 = this.mustache2.clone();
        this.mustache5.rotation.z = Math.PI;
        this.mustache5.position.x = -35;
        this.mustache6 = new THREE.Mesh(mustacheGeom, this.blackMat);
        this.mustache6 = this.mustache3.clone();
        this.mustache6.rotation.z = Math.PI;
        this.mustache6.position.x = -30;

        this.mustaches.push(this.mustache1);
        this.mustaches.push(this.mustache2);
        this.mustaches.push(this.mustache3);
        this.mustaches.push(this.mustache4);
        this.mustaches.push(this.mustache5);
        this.mustaches.push(this.mustache6);

        // spots
        this.spot1 = new THREE.Mesh(spotGeom, this.redMat);
        this.spot1.position.x = 39;
        this.spot1.position.z = 150;

        this.spot2 = this.spot1.clone();
        this.spot2.position.z = 160;
        this.spot2.position.y = -10;

        this.spot3 = this.spot1.clone();
        this.spot3.position.z = 140;
        this.spot3.position.y = -15;

        this.spot4 = this.spot1.clone();
        this.spot4.position.z = 150;
        this.spot4.position.y = -20;

        this.spot5 = this.spot1.clone();
        this.spot5.position.x = -39;
        this.spot6 = this.spot2.clone();
        this.spot6.position.x = -39;
        this.spot7 = this.spot3.clone();
        this.spot7.position.x = -39;
        this.spot8 = this.spot4.clone();
        this.spot8.position.x = -39;

        // eyes
        this.leftEye = new THREE.Mesh(eyeGeom, this.whiteMat);
        this.leftEye.position.x = 40;
        this.leftEye.position.z = 120;
        this.leftEye.position.y = 25;

        this.rightEye = new THREE.Mesh(eyeGeom, this.whiteMat);
        this.rightEye.position.x = -40;
        this.rightEye.position.z = 120;
        this.rightEye.position.y = 25;

        // iris
        this.leftIris = new THREE.Mesh(irisGeom, this.purpleMat);
        this.leftIris.position.x = 42;
        this.leftIris.position.z = 120;
        this.leftIris.position.y = 25;

        this.rightIris = new THREE.Mesh(irisGeom, this.purpleMat);
        this.rightIris.position.x = -42;
        this.rightIris.position.z = 120;
        this.rightIris.position.y = 25;

        // mouth
        this.mouth = new THREE.Mesh(mouthGeom, this.blackMat);
        this.mouth.position.z = 171;
        this.mouth.position.y = -30;
        this.mouth.scale.set(0.5, 0.5, 1);

        // smile
        this.smile = new THREE.Mesh(smileGeom, this.greyMat);
        this.smile.position.z = 173;
        this.smile.position.y = -15;
        this.smile.rotation.z = -Math.PI;

        // lips
        this.lips = new THREE.Mesh(lipsGeom, this.yellowMat);
        this.lips.position.z = 165;
        this.lips.position.y = -45;

        // ear
        this.rightEar = new THREE.Mesh(earGeom, this.yellowMat);
        this.rightEar.position.x = -50;
        this.rightEar.position.y = 50;
        this.rightEar.position.z = 105;

        this.leftEar = new THREE.Mesh(earGeom, this.yellowMat);
        this.leftEar.position.x = 50;
        this.leftEar.position.y = 50;
        this.leftEar.position.z = 105;

        // nose
        this.nose = new THREE.Mesh(noseGeom, this.greyMat);
        this.nose.position.z = 170;
        this.nose.position.y = 25;

        // head
        this.head = new THREE.Group();
        this.head.add(this.face);
        this.head.add(this.mane);
        this.head.add(this.rightEar);
        this.head.add(this.leftEar);
        this.head.add(this.nose);
        this.head.add(this.leftEye);
        this.head.add(this.rightEye);
        this.head.add(this.leftIris);
        this.head.add(this.rightIris);
        this.head.add(this.mouth);
        this.head.add(this.smile);
        this.head.add(this.lips);
        this.head.add(this.spot1);
        this.head.add(this.spot2);
        this.head.add(this.spot3);
        this.head.add(this.spot4);
        this.head.add(this.spot5);
        this.head.add(this.spot6);
        this.head.add(this.spot7);
        this.head.add(this.spot8);
        this.head.add(this.mustache1);
        this.head.add(this.mustache2);
        this.head.add(this.mustache3);
        this.head.add(this.mustache4);
        this.head.add(this.mustache5);
        this.head.add(this.mustache6);

        this.head.position.y = 60;

        this.threegroup.add(this.body);
        this.threegroup.add(this.head);
        this.threegroup.add(this.leftKnee);
        this.threegroup.add(this.rightKnee);
        this.threegroup.add(this.backLeftFoot);
        this.threegroup.add(this.backRightFoot);
        this.threegroup.add(this.frontRightFoot);
        this.threegroup.add(this.frontLeftFoot);

        this.threegroup.traverse(function(object) {
          if (object instanceof THREE.Mesh) {
            object.castShadow = true;
            object.receiveShadow = true;
          }
        });
      };

      Lion.prototype.updateBody = function(speed) {
        this.head.rotation.y += (this.tHeagRotY - this.head.rotation.y) / speed;
        this.head.rotation.x += (this.tHeadRotX - this.head.rotation.x) / speed;
        this.head.position.x += (this.tHeadPosX - this.head.position.x) / speed;
        this.head.position.y += (this.tHeadPosY - this.head.position.y) / speed;
        this.head.position.z += (this.tHeadPosZ - this.head.position.z) / speed;

        this.leftEye.scale.y +=
          (this.tEyeScale - this.leftEye.scale.y) / (speed * 2);
        this.rightEye.scale.y = this.leftEye.scale.y;

        this.leftIris.scale.y +=
          (this.tIrisYScale - this.leftIris.scale.y) / (speed * 2);
        this.rightIris.scale.y = this.leftIris.scale.y;

        this.leftIris.scale.z +=
          (this.tIrisZScale - this.leftIris.scale.z) / (speed * 2);
        this.rightIris.scale.z = this.leftIris.scale.z;

        this.leftIris.position.y +=
          (this.tIrisPosY - this.leftIris.position.y) / speed;
        this.rightIris.position.y = this.leftIris.position.y;
        this.leftIris.position.z +=
          (this.tLeftIrisPosZ - this.leftIris.position.z) / speed;
        this.rightIris.position.z +=
          (this.tRightIrisPosZ - this.rightIris.position.z) / speed;

        this.rightKnee.rotation.z +=
          (this.tRightKneeRotZ - this.rightKnee.rotation.z) / speed;
        this.leftKnee.rotation.z +=
          (this.tLeftKneeRotZ - this.leftKnee.rotation.z) / speed;

        this.lips.position.x += (this.tLipsPosX - this.lips.position.x) / speed;
        this.lips.position.y += (this.tLipsPosY - this.lips.position.y) / speed;
        this.smile.position.x +=
          (this.tSmilePosX - this.smile.position.x) / speed;
        this.mouth.position.z +=
          (this.tMouthPosZ - this.mouth.position.z) / speed;
        this.smile.position.z +=
          (this.tSmilePosZ - this.smile.position.z) / speed;
        this.smile.position.y +=
          (this.tSmilePosY - this.smile.position.y) / speed;
        this.smile.rotation.z +=
          (this.tSmileRotZ - this.smile.rotation.z) / speed;
      };

      Lion.prototype.look = function(xTarget, yTarget) {
        this.tHeagRotY = self.rule3(
          xTarget,
          -200,
          200,
          -Math.PI / 4,
          Math.PI / 4
        );
        this.tHeadRotX = self.rule3(
          yTarget,
          -200,
          200,
          -Math.PI / 4,
          Math.PI / 4
        );
        this.tHeadPosX = self.rule3(xTarget, -200, 200, 70, -70);
        this.tHeadPosY = self.rule3(yTarget, -140, 260, 20, 100);
        this.tHeadPosZ = 0;

        this.tEyeScale = 1;
        this.tIrisYScale = 1;
        this.tIrisZScale = 1;
        this.tIrisPosY = self.rule3(yTarget, -200, 200, 35, 15);
        this.tLeftIrisPosZ = self.rule3(xTarget, -200, 200, 130, 110);
        this.tRightIrisPosZ = self.rule3(xTarget, -200, 200, 110, 130);

        this.tLipsPosX = 0;
        this.tLipsPosY = -45;

        this.tSmilePosX = 0;
        this.tMouthPosZ = 174;
        this.tSmilePosZ = 173;
        this.tSmilePosY = -15;
        this.tSmileRotZ = -Math.PI;

        this.tRightKneeRotZ = self.rule3(
          xTarget,
          -200,
          200,
          0.3 - Math.PI / 8,
          0.3 + Math.PI / 8
        );
        this.tLeftKneeRotZ = self.rule3(
          xTarget,
          -200,
          200,
          -0.3 - Math.PI / 8,
          -0.3 + Math.PI / 8
        );

        this.updateBody(10);

        this.mane.rotation.y = 0;
        this.mane.rotation.x = 0;

        for (let i = 0; i < this.maneParts.length; i++) {
          let m = this.maneParts[i].mesh;
          m.position.z = 0;
          m.rotation.y = 0;
        }

        for (let i = 0; i < this.mustaches.length; i++) {
          let m = this.mustaches[i];
          m.rotation.y = 0;
        }

        const positionAttribute = this.body.geometry.getAttribute("position");
        const vertex = new THREE.Vector3();
        for (var i = 0; i < this.bodyVertices.length; i++) {
          var tvInit = this.bodyInitPositions[i];
          vertex.fromBufferAttribute(positionAttribute, this.bodyVertices[i]);
          let x = tvInit.x + this.head.position.x;
          positionAttribute.setXYZ(this.bodyVertices[i], x, vertex.y, vertex.z); // write coordinates back
        }

        this.body.geometry.attributes.position.needsUpdate = true;
      };

      Lion.prototype.cool = function(xTarget, yTarget) {
        this.tHeagRotY = self.rule3(
          xTarget,
          -200,
          200,
          Math.PI / 4,
          -Math.PI / 4
        );
        this.tHeadRotX = self.rule3(
          yTarget,
          -200,
          200,
          Math.PI / 4,
          -Math.PI / 4
        );
        this.tHeadPosX = self.rule3(xTarget, -200, 200, -70, 70);
        this.tHeadPosY = self.rule3(yTarget, -140, 260, 100, 20);
        this.tHeadPosZ = 100;

        this.tEyeScale = 0.1;
        this.tIrisYScale = 0.1;
        this.tIrisZScale = 3;

        this.tIrisPosY = 20;
        this.tLeftIrisPosZ = 120;
        this.tRightIrisPosZ = 120;

        this.tLipsPosX = self.rule3(xTarget, -200, 200, -15, 15);
        this.tLipsPosY = self.rule3(yTarget, -200, 200, -45, -40);

        this.tMouthPosZ = 168;
        this.tSmilePosX = self.rule3(xTarget, -200, 200, -15, 15);
        this.tSmilePosY = self.rule3(yTarget, -200, 200, -20, -8);
        this.tSmilePosZ = 176;
        this.tSmileRotZ = self.rule3(
          xTarget,
          -200,
          200,
          -Math.PI - 0.3,
          -Math.PI + 0.3
        );

        this.tRightKneeRotZ = self.rule3(
          xTarget,
          -200,
          200,
          0.3 + Math.PI / 8,
          0.3 - Math.PI / 8
        );
        this.tLeftKneeRotZ = self.rule3(
          xTarget,
          -200,
          200,
          -0.3 + Math.PI / 8,
          -0.3 - Math.PI / 8
        );

        this.updateBody(10);

        this.mane.rotation.y = -0.8 * this.head.rotation.y;
        this.mane.rotation.x = -0.8 * this.head.rotation.x;

        var dt = 20000 / (xTarget * xTarget + yTarget * yTarget);
        dt = Math.max(Math.min(dt, 1), 0.5);
        this.windTime += dt;

        for (let i = 0; i < this.maneParts.length; i++) {
          let m = this.maneParts[i].mesh;
          let amp = this.maneParts[i].amp;
          let zOffset = this.maneParts[i].zOffset;
          let periodOffset = this.maneParts[i].periodOffset;

          m.position.z =
            zOffset + Math.sin(this.windTime + periodOffset) * amp * dt * 2;
        }

        this.leftEar.rotation.x =
          ((Math.cos(this.windTime) * Math.PI) / 16) * dt;
        this.rightEar.rotation.x =
          ((-Math.cos(this.windTime) * Math.PI) / 16) * dt;

        for (let i = 0; i < this.mustaches.length; i++) {
          let m = this.mustaches[i];
          let amp = i < 3 ? -Math.PI / 8 : Math.PI / 8;
          m.rotation.y = amp + Math.cos(this.windTime + i) * dt * amp;
        }
        const positionAttribute = this.body.geometry.getAttribute("position");
        const vertex = new THREE.Vector3();

        for (var i = 0; i < this.bodyVertices.length; i++) {
          var tvInit = this.bodyInitPositions[i];
          vertex.fromBufferAttribute(positionAttribute, this.bodyVertices[i]);
          let x = tvInit.x + this.head.position.x;
          positionAttribute.setXYZ(this.bodyVertices[i], x, vertex.y, vertex.z); // write coordinates back
        }

        this.body.geometry.attributes.position.needsUpdate = true;
      };

      this.lion = new Lion();
      this.lion.threegroup.position.y = -150;
      this.scene.add(this.lion.threegroup);
    },
    rule3(v, vmin, vmax, tmin, tmax) {
      var nv = Math.max(Math.min(v, vmax), vmin);
      var dv = vmax - vmin;
      var pc = (nv - vmin) / dv;
      var dt = tmax - tmin;
      var tv = tmin + pc * dt;
      return tv;
    },
    createLights() {
      var light = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.5);

      var shadowLight = new THREE.DirectionalLight(0xffffff, 0.8);
      shadowLight.position.set(200, 200, 200);
      shadowLight.castShadow = true;
      //shadowLight.shadowDarkness = 0.2;
      shadowLight.shadow.mapSize.width = 512;
      shadowLight.shadow.mapSize.height = 512;
      shadowLight.shadow.camera.near = 1;
      shadowLight.shadow.camera.far = 2000;
      shadowLight.shadow.camera.bottom = -200;
      shadowLight.shadow.camera.left = -200;
      shadowLight.shadow.camera.right = 200;
      shadowLight.shadow.camera.top = 200;

      shadowLight.shadow.bias = -0.005; // reduces sel
      shadowLight.shadow.radius = 7;

      shadowLight.decay = 0.1;
      shadowLight.distance = 100;

      var backLight = new THREE.DirectionalLight(0xffffff, 0.4);
      backLight.position.set(-100, 200, 50);
      //backLight.shadowDarkness = 0.1;
      backLight.castShadow = true;

      this.scene.add(backLight);
      this.scene.add(light);
      this.scene.add(shadowLight);
      //Create a helper for the shadow camera (optional)
      //const helper = new THREE.CameraHelper(shadowLight.shadow.camera);
      //this.scene.add(helper);
    },
    createFloor() {
      var floor = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(1000, 500),
        new THREE.MeshStandardMaterial({ color: 0xffffff })
      );
      floor.rotation.x = -Math.PI / 2;
      floor.position.y = -250;
      floor.receiveShadow = true;
      this.scene.add(floor);
    },
    createFan() {
      let self = this;
      let Fan = function() {
        this.isBlowing = false;
        this.speed = 0;
        this.acc = 0;
        this.redMat = new THREE.MeshPhongMaterial({
          color: 0xad3525,
          flatShading: true,
        });
        this.greyMat = new THREE.MeshPhongMaterial({
          color: 0x653f4c,
          flatShading: true,
        });

        this.yellowMat = new THREE.MeshPhongMaterial({
          color: 0xfdd276,
          flatShading: true,
        });

        var coreGeom = new THREE.BoxGeometry(10, 10, 20);
        var sphereGeom = new THREE.BoxGeometry(10, 10, 3);
        var propGeom = new THREE.BoxGeometry(10, 30, 2);
        propGeom.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 25, 0));

        this.core = new THREE.Mesh(coreGeom, this.greyMat);

        // propellers
        var prop1 = new THREE.Mesh(propGeom, this.redMat);
        prop1.position.z = 15;
        var prop2 = prop1.clone();
        prop2.rotation.z = Math.PI / 2;
        var prop3 = prop1.clone();
        prop3.rotation.z = Math.PI;
        var prop4 = prop1.clone();
        prop4.rotation.z = -Math.PI / 2;

        this.sphere = new THREE.Mesh(sphereGeom, this.yellowMat);
        this.sphere.position.z = 15;

        this.propeller = new THREE.Group();
        this.propeller.add(prop1);
        this.propeller.add(prop2);
        this.propeller.add(prop3);
        this.propeller.add(prop4);

        this.threegroup = new THREE.Group();
        this.threegroup.add(this.core);
        this.threegroup.add(this.propeller);
        this.threegroup.add(this.sphere);
      };

      Fan.prototype.update = function(xTarget, yTarget) {
        this.threegroup.lookAt(new THREE.Vector3(0, -50, 60));
        this.tPosX = self.rule3(xTarget, -200, 200, -250, 250);
        this.tPosY = self.rule3(yTarget, -200, 200, 250, -250);

        this.threegroup.position.x +=
          (this.tPosX - this.threegroup.position.x) / 10;
        this.threegroup.position.y +=
          (this.tPosY - this.threegroup.position.y) / 10;

        this.targetSpeed = this.isBlowing ? 0.3 : 0.01;
        if (this.isBlowing && this.speed < 0.5) {
          this.acc += 0.001;
          this.speed += this.acc;
        } else if (!this.isBlowing) {
          this.acc = 0;
          this.speed *= 0.98;
        }
        this.propeller.rotation.z += this.speed;
      };

      this.fan = new Fan();
      this.fan.threegroup.position.z = 350;
      this.scene.add(this.fan.threegroup);
    },
    animate() {
      if (this.active) {
        this.renderer.render(this.scene, this.camera);
        var xTarget = this.mousePos.x - this.windowHalfX;
        var yTarget = this.mousePos.y - this.windowHalfY;

        this.fan.isBlowing = this.isBlowing;
        this.fan.update(xTarget, yTarget);
        if (this.isBlowing) {
          this.lion.cool(xTarget, yTarget);
        } else {
          this.lion.look(xTarget, yTarget);
        }
        requestAnimationFrame(this.animate);
      } else {
        this.stop();
      }
    },
  },
};
</script>

<style scoped>
>>> .v-overlay__content {
  position: unset !important;
}
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
</style>
