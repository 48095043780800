var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.userIsAdmin
        ? _c(
            "v-row",
            { staticClass: "mt-2" },
            [
              _c(
                "v-col",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-card",
                    { class: _vm.getAlarmstatus(), attrs: { width: "1200" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text--secondary" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v("CASS Health")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "2px",
                                      "font-size": "0.7rem",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.lastUpdated) + " ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        color:
                                          _vm.getEnvironmentColor(
                                            "DEVELOPMENT"
                                          ),
                                        dark: "",
                                      },
                                    },
                                    [_vm._v(" DEVELOPMENT ")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("status-overwrite", {
                                        attrs: {
                                          status: _vm.getStatus("DEVELOPMENT"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        color:
                                          _vm.getEnvironmentColor("QUALITY"),
                                        dark: "",
                                      },
                                    },
                                    [_vm._v(" QUALITY ")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("status-overwrite", {
                                        attrs: {
                                          status: _vm.getStatus("QUALITY"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        color:
                                          _vm.getEnvironmentColor("PRODUCTION"),
                                        dark: "",
                                      },
                                    },
                                    [_vm._v(" PRODUCTION ")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("status-overwrite", {
                                        attrs: {
                                          status: _vm.getStatus("PRODUCTION"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        small: "",
                                        color: "darkgrey",
                                        dark: "",
                                      },
                                    },
                                    [_vm._v(" DATABASE ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getStatus("PRODUCTION")
                                              .status === "ERROR"
                                              ? "ERROR"
                                              : "ONLINE"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    width: _vm.userIsAdmin ? 1200 : 900,
                  },
                },
                [
                  _c("error-overlay", {
                    on: { retry: _vm.loadData },
                    model: {
                      value: _vm.error,
                      callback: function ($$v) {
                        _vm.error = $$v
                      },
                      expression: "error",
                    },
                  }),
                  _c(
                    "template",
                    { slot: "progress" },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          color: "#006c27",
                          height: "10",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "text--secondary" },
                    [
                      _vm.userIsAdmin
                        ? _c("div", [
                            _vm._v(
                              " All Applications (" +
                                _vm._s(_vm.applications.length) +
                                "/" +
                                _vm._s(_vm.filteredApplications.length) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                      !_vm.userIsAdmin
                        ? _c("div", [_vm._v("Your Applications")])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-text-field",
                        {
                          staticStyle: {
                            "padding-top": "0",
                            "margin-top": "0",
                          },
                          attrs: {
                            label: "Search",
                            "single-line": "",
                            color: "#006c27",
                            "hide-details": "",
                          },
                          on: { keyup: _vm.applyFilter },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-magnify")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "You can wildcard search for name, id or owner."
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.userIsAdmin
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "#006c27",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.onClickQuickFilter,
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getFilterIcon()
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2601819662
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Admin quickfilter! This will filter all applications from API CoE Team"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary my-6" },
                    [
                      !_vm.loading
                        ? _c(
                            "v-data-table",
                            {
                              staticClass: "elevation-1",
                              attrs: {
                                "sort-by": "displayName",
                                "custom-sort": _vm.customSort,
                                "must-sort": "",
                                headers: _vm.headers,
                                items: _vm.filteredApplications,
                                "hide-default-footer":
                                  _vm.filteredApplications.length <= 10,
                              },
                              on: { "click:row": _vm.rowClicked },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: `item.environment`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              color: _vm.getEnvironmentColor(
                                                item.environment
                                              ),
                                              dark: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.environment) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.state`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  _vm.getStatusIconColor(
                                                                    item
                                                                  ),
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getStatusIcon(
                                                                  item
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.getStatusText(item))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "no-data" }, [
                                _vm._v(
                                  " You do not have any applications yet! Just create or import one! "
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      !_vm.loading
                        ? _c(
                            "v-row",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3 mt-9 mb-5",
                                  attrs: { color: "#006c27", dark: "" },
                                  on: { click: _vm.createApplication },
                                },
                                [_vm._v(" CREATE ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("application-create-overlay", {
        model: {
          value: _vm.createOverlay,
          callback: function ($$v) {
            _vm.createOverlay = $$v
          },
          expression: "createOverlay",
        },
      }),
      _c("announcement-dialog", {
        attrs: { announcement: _vm.announcement },
        on: { confirm: _vm.confirmAnnouncement },
        model: {
          value: _vm.announcementDialog,
          callback: function ($$v) {
            _vm.announcementDialog = $$v
          },
          expression: "announcementDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }