var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "text-subtitle-2" }, [
                _vm._v(" Certificate Create "),
              ]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  attrs: { id: "closeCertificateCreateOverlayButton" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _vm.step == 1
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("vue-flip", {
                            attrs: { width: "314", height: "400" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "front",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: {
                                            width: "314",
                                            height: "400",
                                          },
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" Quickstart "),
                                          ]),
                                          _c("v-card-text", [
                                            _vm._v(
                                              " If you want to get started quickly - this option is your friend. Just flip this card, provide the necessary information and press the create button."
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _vm._v(
                                              " The CSR (Certificate Signing Request) and private key is generated automatically on your behalf."
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _vm._v(
                                              " This sound easy? Just give it a try and enjoy this user experience! "
                                            ),
                                          ]),
                                          _c("v-spacer"),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    id: "certificateQuickstartArrow",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.flip()
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-arrow-right")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "back",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "d-flex flex-column",
                                          attrs: {
                                            width: "314",
                                            height: "400",
                                          },
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" Quickstart "),
                                          ]),
                                          _c(
                                            "v-card-text",
                                            {},
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "form",
                                                  on: {
                                                    submit: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.nop.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.quickstartValid,
                                                    callback: function ($$v) {
                                                      _vm.quickstartValid = $$v
                                                    },
                                                    expression:
                                                      "quickstartValid",
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      id: "certificateQuickstartCountry",
                                                      outlined: "",
                                                      label: "Country",
                                                      placeholder:
                                                        "Country (i.e. DE, US)",
                                                      rules: [
                                                        _vm.rules.notEmpty,
                                                      ],
                                                      items: _vm.countries,
                                                      "item-value": "cca2",
                                                      "item-text": "name",
                                                      dense: "",
                                                      counter: "",
                                                      color: "#006c27",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.providedCSR.country,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.providedCSR,
                                                          "country",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "providedCSR.country",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      id: "certificateQuickstartProvince",
                                                      label: "Province",
                                                      placeholder:
                                                        "Province (i.e. NRW, NY)",
                                                      outlined: "",
                                                      rules: [
                                                        _vm.rules.notEmpty,
                                                      ],
                                                      dense: "",
                                                      maxlength: "25",
                                                      counter: "",
                                                      autocomplete:
                                                        "new-password",
                                                      color: "#006c27",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.providedCSR
                                                          .province,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.providedCSR,
                                                          "province",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "providedCSR.province",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      id: "certificateQuickstartCity",
                                                      label: "City",
                                                      placeholder:
                                                        "City (i.e. New York City)",
                                                      outlined: "",
                                                      rules: [
                                                        _vm.rules.notEmpty,
                                                      ],
                                                      dense: "",
                                                      maxlength: "25",
                                                      counter: "",
                                                      autocomplete:
                                                        "new-password",
                                                      color: "#006c27",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.providedCSR.city,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.providedCSR,
                                                          "city",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "providedCSR.city",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      id: "certificateQuickstartCompany",
                                                      label: "Company",
                                                      placeholder:
                                                        "Your company name",
                                                      outlined: "",
                                                      rules: [
                                                        _vm.rules.notEmpty,
                                                      ],
                                                      dense: "",
                                                      maxlength: "25",
                                                      counter: "",
                                                      autocomplete:
                                                        "new-password",
                                                      color: "#006c27",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.providedCSR.company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.providedCSR,
                                                          "company",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "providedCSR.company",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.flip()
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-arrow-left")]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "white--text",
                                                  attrs: {
                                                    id: "certificateQuickstartCreateButton",
                                                    color: "#006c27",
                                                    disabled:
                                                      !_vm.quickstartValid,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.createQuickstart()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Create ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1111980782
                            ),
                            model: {
                              value: _vm.flipCard,
                              callback: function ($$v) {
                                _vm.flipCard = $$v
                              },
                              expression: "flipCard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "d-flex flex-column",
                              attrs: { height: "400" },
                            },
                            [
                              _c("v-card-title", [_vm._v(" With PKI ")]),
                              _c("v-card-text", [
                                _vm._v(
                                  " You have a company internal PKI (Public Key Infrastructure)? You need to create your CSR (Certificate Signing Request) by yourself? Than this option is your friend!"
                                ),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " By clicking the create button we will provide all necessary CSR information for you."
                                ),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " After CSR creation on your side, just upload it! "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text",
                                      attrs: { color: "#006c27" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createWithPKI()
                                        },
                                      },
                                    },
                                    [_vm._v(" Create ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "d-flex flex-column",
                              attrs: { height: "400" },
                            },
                            [
                              _c("v-card-title", [_vm._v(" CSR Details ")]),
                              _c(
                                "v-card-text",
                                [
                                  _c("div", { staticClass: "mb-5" }, [
                                    _vm._v(
                                      " Please use the following attributes for generating your own CSR: "
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value:
                                                  _vm.csrDetails.commonName,
                                                label: "Common Name (CN)",
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                readonly: "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        _vm.csrDetails
                                                          .commonName
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value:
                                                  _vm.csrDetails.emailAddress,
                                                label: "Email Address",
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                readonly: "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        _vm.csrDetails
                                                          .emailAddress
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value:
                                                  _vm.csrDetails
                                                    .organizationUnit,
                                                label:
                                                  "Organizational Unit (OU)",
                                                outlined: "",
                                                dense: "",
                                                readonly: "",
                                                "hide-details": "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        _vm.csrDetails
                                                          .organizationUnit
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value: _vm.csrDetails.keyLength,
                                                label: "Key Length",
                                                outlined: "",
                                                "hide-details": "",
                                                dense: "",
                                                readonly: "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        _vm.csrDetails.keyLength
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value: _vm.csrDetails.keyType,
                                                label: "Key Type",
                                                outlined: "",
                                                "hide-details": "",
                                                dense: "",
                                                readonly: "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        _vm.csrDetails.keyType
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "my-5" }, [
                                    _vm._v(
                                      " An example openssl command would look like this: "
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-text-field",
                                            {
                                              attrs: {
                                                value:
                                                  "openssl req -nodes -new -newkey rsa:4096 -sha256 -out csr.pem -keyout priv.key",
                                                label: "openssl command line",
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                readonly: "",
                                                color: "#006c27",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    slot: "append",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copy2clipboard(
                                                        "openssl req -nodes -new -newkey rsa:4096 -sha256 -out csr.pem -keyout priv.key"
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text mt-4",
                              staticStyle: { float: "right" },
                              attrs: { id: "closedialog", color: "#006c27" },
                              on: {
                                click: function ($event) {
                                  return _vm.close()
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 3
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "d-flex flex-column",
                              attrs: { height: "400" },
                            },
                            [
                              _c("v-card-title", [_vm._v(" Private Key ")]),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      loading: _vm.privKeyLoading,
                                      "loader-height": "5",
                                      outlined: "",
                                      readonly: "",
                                      "no-resize": true,
                                      value: _vm.privKey,
                                      rows: "5",
                                      "hide-details": "true",
                                    },
                                  }),
                                  !_vm.privKeyLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-1" },
                                        [
                                          _vm._v(
                                            " Copy private key to clipboard "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "margin-top": "2px",
                                              },
                                              attrs: {
                                                slot: "append",
                                                small: "",
                                                color: "#006c27",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copy2clipboard(
                                                    _vm.privKey
                                                  )
                                                },
                                              },
                                              slot: "append",
                                            },
                                            [_vm._v(" mdi-content-copy ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.privKeyLoading
                                    ? _c("div", { staticClass: "mt-3" }, [
                                        _vm._v(
                                          " A private key was generated on your behalf."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " It was never saved on the server and can not be recovered."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " This is the only time when the key is visible. Please copy it and store it safely! "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("error-overlay", {
        attrs: { retryEnabled: false },
        model: {
          value: _vm.error,
          callback: function ($$v) {
            _vm.error = $$v
          },
          expression: "error",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }