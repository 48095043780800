var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", [_vm._v(" Create RedirectURI ")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c("v-row", [_c("v-col", [_vm._v(" Select your Platform ")])], 1),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.isTypeDisabled("WEB"),
                            bottom: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.getClass("WEB"),
                                          attrs: {
                                            id: "addWebRedirectButton",
                                            color: _vm.getBtnColor("WEB"),
                                            disabled: _vm.isTypeDisabled("WEB"),
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setType("WEB")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: _vm.getIconColor("WEB"),
                                              },
                                            },
                                            [_vm._v(" mdi-web ")]
                                          ),
                                          _vm._v("WEB"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getDisabledToolTip()),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.isTypeDisabled("SPA"),
                            bottom: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.getClass("SPA"),
                                          attrs: {
                                            block: "",
                                            id: "addSpaRedirectButton",
                                            color: _vm.getBtnColor("SPA"),
                                            disabled: _vm.isTypeDisabled("SPA"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setType("SPA")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: _vm.getIconColor("SPA"),
                                              },
                                            },
                                            [_vm._v(" mdi-newspaper-variant ")]
                                          ),
                                          _vm._v("SPA"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getDisabledToolTip()),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.isTypeDisabled("ANDROID"),
                            bottom: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.getClass("ANDROID"),
                                          attrs: {
                                            block: "",
                                            id: "addAndroidRedirectButton",
                                            color: _vm.getBtnColor("ANDROID"),
                                            disabled:
                                              _vm.isTypeDisabled("ANDROID"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setType("ANDROID")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color:
                                                  _vm.getIconColor("ANDROID"),
                                              },
                                            },
                                            [_vm._v(" mdi-android ")]
                                          ),
                                          _vm._v("ANDROID"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getDisabledToolTip()),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.isTypeDisabled("iOS"),
                            bottom: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.getClass("iOS"),
                                          attrs: {
                                            block: "",
                                            id: "addIosRedirectButton",
                                            color: _vm.getBtnColor("iOS"),
                                            disabled: _vm.isTypeDisabled("iOS"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setType("iOS")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color: _vm.getIconColor("iOS"),
                                              },
                                            },
                                            [_vm._v(" mdi-apple ")]
                                          ),
                                          _vm._v("iOS"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getDisabledToolTip()),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.isTypeDisabled("DESKTOP"),
                            bottom: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.getClass("DESKTOP"),
                                          attrs: {
                                            block: "",
                                            id: "addDesktopRedirectButton",
                                            color: _vm.getBtnColor("DESKTOP"),
                                            disabled:
                                              _vm.isTypeDisabled("DESKTOP"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setType("DESKTOP")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color:
                                                  _vm.getIconColor("DESKTOP"),
                                              },
                                            },
                                            [_vm._v(" mdi-desktop-mac ")]
                                          ),
                                          _vm._v("DESKTOP"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getDisabledToolTip()),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectedType == "WEB" ||
              _vm.selectedType == "SPA" ||
              _vm.selectedType == "DESKTOP"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "web_spa_desktop_form",
                              staticStyle: { width: "100%" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.nop.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.isWebSpaDesktopValid,
                                callback: function ($$v) {
                                  _vm.isWebSpaDesktopValid = $$v
                                },
                                expression: "isWebSpaDesktopValid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c("v-text-field", {
                                        ref: "textfield",
                                        attrs: {
                                          id: "redirectUriInput",
                                          rules: [
                                            _vm.notEmpty,
                                            _vm.rules.validURI,
                                          ],
                                          "error-messages":
                                            _vm.serverErrorWebSpaDesktop,
                                          counter: "",
                                          maxlength: "255",
                                          label: "RedirectURI",
                                          placeholder:
                                            "Enter valid redirect URI",
                                          color: "#006c27",
                                        },
                                        model: {
                                          value: _vm.webSpaDesktopUri,
                                          callback: function ($$v) {
                                            _vm.webSpaDesktopUri = $$v
                                          },
                                          expression: "webSpaDesktopUri",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            id: "addRedirectButton",
                                            color: "#006c27",
                                            disabled: !_vm.isWebSpaDesktopValid,
                                          },
                                          on: {
                                            click: _vm.addSpaWebDesktopUri,
                                          },
                                        },
                                        [_vm._v("mdi-plus-circle-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedType == "iOS"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "ios_form",
                              staticStyle: { width: "100%" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.nop.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.isIosValid,
                                callback: function ($$v) {
                                  _vm.isIosValid = $$v
                                },
                                expression: "isIosValid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c("v-text-field", {
                                        ref: "textfield",
                                        attrs: {
                                          id: "iosBundleIdInput",
                                          rules: [
                                            _vm.notEmpty,
                                            _vm.rules.validBundleId,
                                          ],
                                          "error-messages": _vm.serverErrorIos,
                                          counter: "",
                                          maxlength: "221",
                                          label: "Bundle ID",
                                          placeholder: "Enter valid bundle id",
                                          color: "#006c27",
                                        },
                                        model: {
                                          value: _vm.iOsUri,
                                          callback: function ($$v) {
                                            _vm.iOsUri = $$v
                                          },
                                          expression: "iOsUri",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "#006c27",
                                            id: "plusIosRedirectButton",
                                            disabled: !_vm.isIosValid,
                                          },
                                          on: { click: _vm.addIosUri },
                                        },
                                        [_vm._v("mdi-plus-circle-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _vm._v(
                                      " Your app's Bundle ID can be found in XCode in the Info.plist or `Build Settings`. "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedType == "ANDROID"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "android_form",
                              staticStyle: { width: "100%" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.nop.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.isAndroidValid,
                                callback: function ($$v) {
                                  _vm.isAndroidValid = $$v
                                },
                                expression: "isAndroidValid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c("v-text-field", {
                                        ref: "textfield",
                                        attrs: {
                                          id: "packageNameInput",
                                          rules: [
                                            _vm.notEmpty,
                                            _vm.rules.validPackagename,
                                          ],
                                          "error-messages":
                                            _vm.serverErrorAndroid,
                                          counter: "",
                                          maxlength: "221",
                                          label: "Package Name",
                                          placeholder:
                                            "Enter valid package name",
                                          color: "#006c27",
                                        },
                                        model: {
                                          value: _vm.androidPackagename,
                                          callback: function ($$v) {
                                            _vm.androidPackagename = $$v
                                          },
                                          expression: "androidPackagename",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c("v-text-field", {
                                        ref: "textfield",
                                        attrs: {
                                          id: "hashInput",
                                          rules: [
                                            _vm.notEmpty,
                                            _vm.rules.validBase64,
                                          ],
                                          counter: "",
                                          maxlength: "35",
                                          label: "Signature Hash",
                                          placeholder:
                                            "2pmj9i4rSx0yEb/viWBYkE/ZQrk=",
                                          color: "#006c27",
                                        },
                                        model: {
                                          value: _vm.androidSignaturehash,
                                          callback: function ($$v) {
                                            _vm.androidSignaturehash = $$v
                                          },
                                          expression: "androidSignaturehash",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-5 mt-5",
                                          attrs: {
                                            color: "#006c27",
                                            id: "plusAndroidRedirectButton",
                                            disabled: !_vm.isAndroidValid,
                                          },
                                          on: { click: _vm.addAndroidUri },
                                        },
                                        [_vm._v("mdi-plus-circle-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                { staticClass: "pl-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " mdi-account-question-outline "
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-5" },
                                                        [_vm._v("Show me how!")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("v-expansion-panel-content", [
                                                _vm._v(
                                                  " Your app's package name can be found in the Android Manifest."
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " The signature hash can be generated via command line. "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "Creation could be done like this: "
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  { staticClass: "cli" },
                                                  [
                                                    _vm._v(
                                                      " keytool -exportcert -alias androiddebugkey -keystore %HOMEPATH%\\.android\\debug.keystore | openssl sha1 -binary | openssl base64 "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " For more help Signing your apps, check out "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "https://developer.android.com/studio/publish/app-signing",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Signing your Android app"
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }