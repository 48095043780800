import authStore from "./authStore";
import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import cass from "./api/cass";
import Vue from "vue";
import { Buffer } from "buffer";
class AuthService {
  msalInstance = new PublicClientApplication(authStore.state.msalConfig);
  userData = undefined;
  app = undefined;

  constructor(vue) {
    console.log("AuthService starting");
    this.msalInstance.setActiveAccount(null);
    this.setEmptyUserData();
    this.app = vue;

    // Register Events from MSAL
    this.registerMSALEvents();

    // Register call back handler for Redirect alls
    this.msalInstance
      .handleRedirectPromise()
      .then((handleresponse) => {
        if (handleresponse === null) {
          console.log("No Redirect response");
          this.app.prototype.$emitter.emit("no-redirect");
        } else {
          console.log("Redirect response!");
          const accounts = this.msalInstance.getAllAccounts();
          console.log("Number of accounts: ", accounts.length);
          console.log(accounts);
          if (accounts.length > 0) {
            console.log("Found user: ", accounts[0]);
            this.setActiveAccount(accounts[0]);
          } else {
            this.setActiveAccount(null);
          }
        }
      })
      .catch((error) => {
        console.error(`error during authentication: ${error}`);
        this.setActiveAccount(null);
      });
  }
  setEmptyUserData() {
    this.userData = {
      graphUserInfo: null,
      graphUserPhoto: null,
      account: null,
      cass: {
        isUserAdmin: false,
        isBetaUser: false,
        isUser: false,
        environments: [],
      },
    };
    Vue.prototype.$userData = this.userData;
  }
  isUserLoggedIn() {
    const account = this.msalInstance.getActiveAccount();
    if (account === null) {
      return false;
    }
    return true;
  }
  quitSession() {
    console.log("quit session");
    this.msalInstance.setActiveAccount(null);
    this.setEmptyUserData();
    this.app.prototype.$emitter.emit("quit_session");
  }
  async setActiveAccount(account) {
    if (account === null) {
      console.log("No user logged in, clean up");
      this.msalInstance.setActiveAccount(null);
      this.setEmptyUserData();
      this.app.prototype.$emitter.emit("logout");
      return;
    }

    this.msalInstance.setActiveAccount(account);

    var accessToken;

    try {
      const token = await this.msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
      });
      accessToken = token.accessToken;
      console.log("Got token: ", token);
    } catch (error) {
      console.error("Error getting msgraph access token!");
      console.error(error);
      return;
    }

    let promise_array = [];
    promise_array.push(
      axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
    );
    promise_array.push(
      axios.get("https://graph.microsoft.com/v1.0/me/photos/120x120/$value", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
    );
    promise_array.push(cass.getUser());

    Promise.allSettled(promise_array).then((data) => {
      this.setEmptyUserData();
      // handle graph user call
      var graph_reponse = data[0];
      if (graph_reponse.status == "fulfilled") {
        console.log("Got graph user data");
        console.log(graph_reponse);
        this.userData.graphUserInfo = graph_reponse.value.data;
        this.userData.account = account;
      } else {
        console.error("Call to MS Graph user endpoint failed!");
        console.error(graph_reponse);
      }
      // handle graph photo call
      var graph_photo = data[1];
      if (graph_photo.status == "fulfilled") {
        console.log("Got graph user photo");
        console.log(graph_photo);
        let raw = Buffer.from(graph_photo.value.data).toString("base64");
        this.userData.graphUserPhoto =
          "data:" +
          graph_photo.value.headers["content-type"] +
          ";base64," +
          raw;
      } else {
        console.error("Call to MS Graph photo endpoint failed!");
        console.error(graph_photo);
      }
      // handle CASS me call
      var cass_me = data[2];
      if (cass_me.status == "fulfilled") {
        console.log("Got CASS me data");
        console.log(cass_me);
        // check if beta_user
        if (
          cass_me.value.data != undefined &&
          cass_me.value.data != null &&
          cass_me.value.data.roles.includes("admin")
        ) {
          console.log("found admin");
          cass_me.value.data.isUserAdmin = true;
        } else {
          cass_me.value.data.isUserAdmin = false;
        }
        // check if beta_user
        if (
          cass_me.value.data != undefined &&
          cass_me.value.data != null &&
          cass_me.value.data.roles.includes("beta_user")
        ) {
          console.log("found beta_user");
          cass_me.value.data.isBetaUser = true;
        } else {
          cass_me.value.data.isBetaUser = false;
        }
        // check if user
        if (
          cass_me.value.data != undefined &&
          cass_me.value.data != null &&
          cass_me.value.data.roles.includes("user")
        ) {
          console.log("found user");
          cass_me.value.data.isUser = true;
        } else {
          cass_me.value.data.isUser = false;
        }
        this.userData.cass = cass_me.value.data;
      } else {
        console.error("Call to CASS me endpoint failed!");
        if (
          cass_me.reason &&
          cass_me.reason.response &&
          cass_me.reason.response.data &&
          cass_me.reason.response.data.message
        ) {
          console.error(cass_me.reason.response.data.message);
        } else if (cass_me.reason && cass_me.reason.message) {
          console.error(cass_me.reason.message);
        } else {
          console.error("Unknown connection error: ", cass_me);
        }
      }

      // Check if login was successfull
      if (
        cass_me.status == "fulfilled" &&
        graph_reponse.status == "fulfilled"
      ) {
        console.log("Login successful, sending login event");
        Vue.prototype.$userData = this.userData;
        this.app.prototype.$emitter.emit("login", {});
      } else {
        // Login did not succeed
        // Stop here and show and error
        console.error("SetActiveAccount error");
        console.log("Could not enable user account, unlog user");
        this.msalInstance.setActiveAccount(null);
        this.setEmptyUserData();
        if (cass_me.status !== "fulfilled") {
          if (
            cass_me.reason &&
            cass_me.reason.response &&
            cass_me.reason.response.data &&
            cass_me.reason.response.data.message
          ) {
            this.app.prototype.$emitter.emit(
              "login-error",
              cass_me.reason.response.data.message
            );
          } else if (cass_me.reason && cass_me.reason.message) {
            this.app.prototype.$emitter.emit(
              "login-error",
              cass_me.reason.message
            );
          } else {
            console.error("Unknown connection error: ", cass_me);
            this.app.prototype.$emitter.emit(
              "login-error",
              "Unknown connection error"
            );
          }
        } else if (graph_reponse.status !== "fulfilled") {
          this.app.prototype.$emitter.emit(
            "login-error",
            "MS Graph reported error"
          );
        } else {
          this.app.prototype.$emitter.emit("login-error", "Unkown error");
        }

        // This line produced the login endless loop!
        //this.app.prototype.$emitter.emit("logout");
      }
    });
  }
  signIn() {
    this.msalInstance.loginRedirect({});
  }
  signOut() {
    // I think this function is never used and could be removed
    this.msalInstance
      .logout({})
      .then(() => {
        this.app.prototype.$emitter.emit("logout");
      })
      .catch((error) => {
        console.error(error);
      });
  }
  getAllAccounts() {
    return this.msalInstance.getAllAccounts();
  }
  getAccessToken(scope) {
    return this.msalInstance.acquireTokenSilent(scope);
  }
  /*
  getAccessToken(emitname, scope) {
    this.msalInstance
      .acquireTokenSilent(scope)
      .then((tokenResponse) => {
        console.log(tokenResponse);
        this.app.prototype.$emitter.emit(emitname, tokenResponse);
      })
      .catch((error) => {
        console.log(error);
        this.app.prototype.$emitter.emit(emitname, null);
      });
  }
  */
  encodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }
  registerMSALEvents() {
    this.msalInstance.addEventCallback(function (message) {
      console.debug("MSAL Event: ", message);
    });
  }
}

/**
 * Default export to register the authentication service in the global Vue instance.
 *
 * This allows us to reference it using "this.$auth" whenever we are inside of a Vue context.
 */
export default {
  install: function (Vue) {
    Vue.prototype.$authService = new AuthService(Vue);
  },
};
