var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "800" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "#006c27" } },
                [_vm._v("mdi-car-brake-alert")]
              ),
              _c("div", { staticStyle: { color: "#616161" } }, [
                _vm._v(" We are sorry. Subscription is not possible! "),
              ]),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "mt-3" }, [
            _vm._v(
              " You can only subscribe APIs via this feature if your application is on environment DEVELOPMENT or QUALITY. "
            ),
            _c("br"),
            _vm._v(" Your current application is on environment PRODUCTION. "),
            _c("br"),
            _c("br"),
            _vm._v(" In order to subscribe, please visit the "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "https://developer.schaeffler.com/product-catalog",
                },
              },
              [_vm._v("API Catalog")]
            ),
            _vm._v(
              ", search for the API and use the official Subscribe button. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " In case you can not find the API in the API Catalog please fill out the assistance form using the button below. "
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-1 white--text",
                  attrs: { color: "#006c27" },
                  on: { click: _vm.openAPICatalog },
                },
                [_vm._v("API catalog")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27" },
                  on: { click: _vm.assistance },
                },
                [_vm._v("assistance")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27" },
                  on: { click: _vm.close },
                },
                [_vm._v("CLOSE")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }