var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.application != null
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("div", [
                    _vm._v(" Application Guide for Type"),
                    _c("i", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatString")(
                              _vm.application.permissionModel
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-3" },
                [
                  _vm._v(
                    " This is a basic step-by-step guide to help you through the configuration of your application. This guide is not complete and does not cover all scenarios. If you got stuck - please feel free to contact our support at "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "mailto:api-mgmt-support@schaeffler.com" },
                    },
                    [_vm._v("api-mgmt-support@schaeffler.com")]
                  ),
                  _vm.application.permissionModel === "DELEGATED"
                    ? [
                        _c(
                          "v-row",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-1-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Select your IT Security Demand ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Please make sure you followed the IT Security By Design procedure and configured the IT Security Demand accordingly "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-2-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Check your subscribed products ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Please make sure the API you plan to consume is listed and fits your application type. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-3-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Request Permissions ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " You need to request at least one permission in order to consume an API. In type DELEGATED you most likely also want to login users. If your users use the Schaeffler Azure Tenant please also request the "
                                ),
                                _c("strong", [_vm._v("User.Read")]),
                                _vm._v(
                                  " permission from Microsoft Graph API. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-4-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Register the Redirect URI ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " The OAuth2 flow needs to contact your application in order to interchange authorization codes (refer to OAuth2 section). Therefore you need to register a redirect URI. This is basically an URL of your application where it can process authorization codes provided by the IdP. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-5-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Create secret (optional) ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Only create a secret if your application has a server-side page rendering backend. For all others like Single-Page-Application, Mobile or Desktop you do not need any secret. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-check")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" You are ready to go! ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.application.permissionModel === "APPLICATION"
                    ? [
                        _c(
                          "v-row",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-1-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Select your IT Security Demand ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Please make sure you followed the IT Security By Design procedure and configured the IT Security Demand accordingly "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-2-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Check your subscribed products ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Please make sure the API you plan to consume is listed and fits your application type. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-3-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" Request Permissions ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " You need to request at least one permission in order to consume an API. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-4-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [
                                  _vm._v(
                                    " Request mTLS Certificate (optional) "
                                  ),
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  " Only request an mTLS certificate if the URL of the API you want to consume is at api"
                                ),
                                _c("strong", [_vm._v("mtls")]),
                                _vm._v(
                                  ".schaeffler.com (or environment specific suffix). For all other endpoints you can skip this procedure. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-numeric-5-circle-outline")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v("Create Secret")]
                              ),
                              _c("div", [
                                _vm._v(
                                  " In all cases you need to create a secret for the OAuth2 client credential flow. Use this together with your application ID and permission scope to obtain tokens. "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { color: "#006c27" },
                                  },
                                  [_vm._v("mdi-check")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary",
                                },
                                [_vm._v(" You are ready to go! ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }