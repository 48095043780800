<template>
  <v-dialog width="100%" v-model="show" persistent max-width="700">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
          color="#006c27"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>
        <div style="color: #616161;">
          Processing ...
        </div>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row
          no-gutters
          class="mb-1"
          v-for="(message, index) in messages"
          :key="index"
        >
          <v-col cols="11">
            {{ message.text }} <strong>{{ message.producttitle }}</strong>
          </v-col>
          <v-col cols="1">
            <v-icon v-if="message.state === 'pending'">
              mdi-motion-pause-outline
            </v-icon>
            <v-icon color="#006c27" v-if="message.state === 'inprogress'">
              mdi-cog-outline mdi-spin
            </v-icon>
            <v-icon color="#006c27" v-if="message.state === 'done'">
              mdi-check
            </v-icon>
            <v-icon color="red" v-if="message.state === 'error'">
              mdi-alert-circle
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SubProductsProgressOverlay",
  props: {
    messages: Array,
    value: Boolean,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
