<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card v-if="show && value.loading" height="218" max-width="500">
      <v-container fill-height fluid>
        <v-row>
          <v-col align="center" justify="center">
            <v-progress-circular
              align="center"
              :size="170"
              :width="13"
              color="#006c27"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="show && !value.loading" max-width="500">
      <v-card-title>
        Error getting data
      </v-card-title>
      <v-row>
        <v-col>
          <v-card-text style="text-align: left;"
            ><strong>Message:</strong> {{ value.message }}</v-card-text
          >
          <v-card-text style="text-align: left;"
            ><strong>RequestId:</strong> {{ value.requestId }}</v-card-text
          >
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn color="#006c27" class="white--text" to="/">
          Home
        </v-btn>
        <v-btn
          v-if="retryEnabled"
          color="#006c27"
          class="white--text"
          @click="retry()"
        >
          Retry
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorOverlay",
  props: {
    value: Object,
    retryEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() {
        if (this.value == null) {
          return false;
        }
        return true;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    retry() {
      this.$emit("retry");
    },
  },
};
</script>

<style></style>
