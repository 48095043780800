var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-5" }, [
        _vm._v(
          " Schaeffler API Management provides three environments, meeting modern software development lifecycle requirements. "
        ),
      ]),
      _c("v-row", { staticClass: "mb-6", attrs: { "no-gutters": "" } }, [
        _c("div", { staticClass: "mt-1 subtitle-2" }, [
          _vm._v(" Development - DEV - D "),
        ]),
        _c("div", { staticClass: "mt-1" }, [
          _vm._v(
            " The Development stage provides Schaeffler APIs under development. As an API consumer you most likely won't work here. The exception from this rule: You actively participate in an ongoing development in cooperation with Schaeffler. "
          ),
          _c("br"),
          _vm._v(" For more information click "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "https://developer.schaeffler.com/docs/subscribe-apis-d-or-q-environment",
              },
            },
            [_vm._v("here")]
          ),
          _vm._v(". "),
        ]),
      ]),
      _c("v-row", { staticClass: "mb-6", attrs: { "no-gutters": "" } }, [
        _c("div", { staticClass: "mt-1 subtitle-2" }, [
          _vm._v(" Quality - QAS - Q "),
        ]),
        _c("div", { staticClass: "mt-1" }, [
          _vm._v(
            " The Quality Assurance stage provides Schaeffler APIs under testing. As an API consumer you may use this stage for integration. This mainly makes sense if the API you consume provides write access for you. For integration you can play around here without manipulating production data. Be aware: Some APIs also provide sandbox access at production level for integration. If sandbox is available - you will not need to be on the QA stage. "
          ),
          _c("br"),
          _vm._v("For more information click "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "https://developer.schaeffler.com/docs/subscribe-apis-d-or-q-environment",
              },
            },
            [_vm._v("here")]
          ),
          _vm._v(". "),
        ]),
      ]),
      _c("v-row", { attrs: { "no-gutters": "" } }, [
        _c("div", { staticClass: "mt-1 subtitle-2" }, [
          _vm._v(" Production - PRD - P "),
        ]),
        _c("div", { staticClass: "mt-1" }, [
          _vm._v(
            " The Production stage is the main stage for API consumers. Here you can find stable, quality ensured Schaeffler APIs ready to use. High-Availabilty clusters grantuee stable access and short maintance windows. We always recommend using this stage for your production. "
          ),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _vm._v(" Get more information at our Microsoft Teams channel "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
                target: "_blank",
              },
            },
            [_vm._v("API@Schaeffler (Browser)")]
          ),
          _vm._v(" or "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
                target: "_blank",
              },
            },
            [_vm._v("API@Schaeffler (Teams)")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }