var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "1000" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "1000" } },
        [
          _c("v-card-title", { staticClass: "text--secondary mb-2" }, [
            _vm._v(" Version Details "),
          ]),
          _c(
            "v-card-text",
            { staticClass: "text--primary pb-2" },
            [
              _c("version-content", {
                attrs: {
                  versionCASSAPI: _vm.versionCASSAPI,
                  versionRATEAPI: _vm.versionRATEAPI,
                  versionAALAPI: _vm.versionAALAPI,
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mr-2 mb-1",
                  attrs: { color: "#006c27" },
                  on: {
                    click: function ($event) {
                      return _vm.done()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }