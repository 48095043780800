import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueRouter from "vue-router";
import Emitter from "tiny-emitter";
import authService from "./authService";
import navStore from "./navStore";
import VueExpandableImage from "vue-expandable-image";
import IdleVue from "idle-vue";
import Helper from "./plugins/helper";

navStore.dispatch("init");

Vue.config.productionTip = false;

Vue.prototype.$emitter = new Emitter();

Vue.use(authService);
Vue.use(VueExpandableImage);
Vue.use(VueRouter);
Vue.use(router);
Vue.use(Helper);

Vue.directive("blur", {
  inserted: function(el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 30 * 60 * 1000, // 30 minuntes
  startAtIdle: false,
});

Vue.prototype.$document = document;

new Vue({
  vuetify,
  router: router.get(),
  render: (h) => h(App),
}).$mount("#app");
