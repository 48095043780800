<template>
  <v-dialog width="100%" v-model="show" persistent max-width="700">
    <v-card>
      <v-card-title>
        <div>
          Create RedirectURI
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col>
            Select your Platform
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tooltip :disabled="!isTypeDisabled('WEB')" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    id="addWebRedirectButton"
                    :color="getBtnColor('WEB')"
                    :class="getClass('WEB')"
                    :disabled="isTypeDisabled('WEB')"
                    block
                    @click="setType('WEB')"
                  >
                    <v-icon class="mr-1" :color="getIconColor('WEB')">
                      mdi-web </v-icon
                    >WEB</v-btn
                  >
                </span>
              </template>
              <span v-html="getDisabledToolTip()"></span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip :disabled="!isTypeDisabled('SPA')" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    block
                    id="addSpaRedirectButton"
                    :color="getBtnColor('SPA')"
                    :class="getClass('SPA')"
                    @click="setType('SPA')"
                    :disabled="isTypeDisabled('SPA')"
                    ><v-icon :color="getIconColor('SPA')" class="mr-1">
                      mdi-newspaper-variant </v-icon
                    >SPA</v-btn
                  >
                </span>
              </template>
              <span v-html="getDisabledToolTip()"></span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip :disabled="!isTypeDisabled('ANDROID')" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    block
                    id="addAndroidRedirectButton"
                    :color="getBtnColor('ANDROID')"
                    :class="getClass('ANDROID')"
                    @click="setType('ANDROID')"
                    :disabled="isTypeDisabled('ANDROID')"
                    ><v-icon :color="getIconColor('ANDROID')" class="mr-1">
                      mdi-android </v-icon
                    >ANDROID</v-btn
                  >
                </span>
              </template>
              <span v-html="getDisabledToolTip()"></span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip :disabled="!isTypeDisabled('iOS')" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    block
                    id="addIosRedirectButton"
                    :color="getBtnColor('iOS')"
                    :class="getClass('iOS')"
                    @click="setType('iOS')"
                    :disabled="isTypeDisabled('iOS')"
                    ><v-icon :color="getIconColor('iOS')" class="mr-1">
                      mdi-apple </v-icon
                    >iOS</v-btn
                  >
                </span>
              </template>
              <span v-html="getDisabledToolTip()"></span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip :disabled="!isTypeDisabled('DESKTOP')" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    block
                    id="addDesktopRedirectButton"
                    :color="getBtnColor('DESKTOP')"
                    :class="getClass('DESKTOP')"
                    @click="setType('DESKTOP')"
                    :disabled="isTypeDisabled('DESKTOP')"
                    ><v-icon :color="getIconColor('DESKTOP')" class="mr-1">
                      mdi-desktop-mac </v-icon
                    >DESKTOP</v-btn
                  >
                </span>
              </template>
              <span v-html="getDisabledToolTip()"></span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedType == 'WEB' ||
              selectedType == 'SPA' ||
              selectedType == 'DESKTOP'
          "
        >
          <v-col>
            <v-form
              v-on:submit.prevent="nop"
              ref="web_spa_desktop_form"
              style="width: 100%;"
              v-model="isWebSpaDesktopValid"
            >
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    id="redirectUriInput"
                    ref="textfield"
                    v-model="webSpaDesktopUri"
                    :rules="[notEmpty, rules.validURI]"
                    :error-messages="serverErrorWebSpaDesktop"
                    counter
                    maxlength="255"
                    label="RedirectURI"
                    placeholder="Enter valid redirect URI"
                    color="#006c27"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    id="addRedirectButton"
                    color="#006c27"
                    :disabled="!isWebSpaDesktopValid"
                    @click="addSpaWebDesktopUri"
                    class="mt-5"
                    >mdi-plus-circle-outline</v-icon
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="selectedType == 'iOS'">
          <v-col>
            <v-form
              v-on:submit.prevent="nop"
              ref="ios_form"
              style="width: 100%;"
              v-model="isIosValid"
            >
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    ref="textfield"
                    id="iosBundleIdInput"
                    v-model="iOsUri"
                    :rules="[notEmpty, rules.validBundleId]"
                    :error-messages="serverErrorIos"
                    counter
                    maxlength="221"
                    label="Bundle ID"
                    placeholder="Enter valid bundle id"
                    color="#006c27"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    color="#006c27"
                    id="plusIosRedirectButton"
                    :disabled="!isIosValid"
                    @click="addIosUri"
                    class="mt-5"
                    >mdi-plus-circle-outline</v-icon
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Your app's Bundle ID can be found in XCode in the Info.plist
                  or `Build Settings`.
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="selectedType == 'ANDROID'">
          <v-col>
            <v-form
              v-on:submit.prevent="nop"
              ref="android_form"
              style="width: 100%;"
              v-model="isAndroidValid"
            >
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    ref="textfield"
                    id="packageNameInput"
                    v-model="androidPackagename"
                    :rules="[notEmpty, rules.validPackagename]"
                    :error-messages="serverErrorAndroid"
                    counter
                    maxlength="221"
                    label="Package Name"
                    placeholder="Enter valid package name"
                    color="#006c27"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="11">
                  <v-text-field
                    ref="textfield"
                    id="hashInput"
                    v-model="androidSignaturehash"
                    :rules="[notEmpty, rules.validBase64]"
                    counter
                    maxlength="35"
                    label="Signature Hash"
                    placeholder="2pmj9i4rSx0yEb/viWBYkE/ZQrk="
                    color="#006c27"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    color="#006c27"
                    id="plusAndroidRedirectButton"
                    :disabled="!isAndroidValid"
                    @click="addAndroidUri"
                    class="ml-5 mt-5"
                    >mdi-plus-circle-outline</v-icon
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pl-3">
                        <div>
                          <v-icon>
                            mdi-account-question-outline
                          </v-icon>
                          <span class="ml-5">Show me how!</span>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Your app's package name can be found in the Android
                        Manifest.<br />
                        The signature hash can be generated via command line.
                        <br />Creation could be done like this: <br />
                        <div class="cli">
                          keytool -exportcert -alias androiddebugkey -keystore
                          %HOMEPATH%\.android\debug.keystore | openssl sha1
                          -binary | openssl base64
                        </div>
                        For more help Signing your apps, check out
                        <a
                          href="https://developer.android.com/studio/publish/app-signing"
                          target="_blank"
                          >Signing your Android app</a
                        >
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "RedirectOverlay",
  components: {},
  props: {
    value: Boolean,
    application: Object,
  },
  data: () => ({
    isWebSpaDesktopValid: false,
    webSpaDesktopUri: "",
    serverErrorWebSpaDesktop: "",

    isIosValid: false,
    iOsUri: "",
    serverErrorIos: "",

    isAndroidValid: false,
    androidPackagename: "",
    androidSignaturehash: "",
    serverErrorAndroid: "",

    selectedType: "",
    rules: {
      validPackagename: (value) => {
        let pattern = /^(\w|\.|-)+$/;
        if (!pattern.test(value)) {
          return "given input contains invalid characters";
        }
        return true;
      },
      validBundleId: (value) => {
        let pattern = /^(\w|\.|-)+$/;
        if (!pattern.test(value)) {
          return "given input contains invalid characters";
        }
        return true;
      },
      validBase64: (value) => {
        try {
          window.atob(value);
        } catch (e) {
          return "invalid Signature Hash";
        }
        return true;
      },
      validURI: (value) => {
        if (!value) return "Required";
        // Wildcard and special character check
        // do the test strings contain these terms?
        var conditions = ["?", "*", "&"];

        // run the tests against every element in the array
        if (conditions.some((el) => value.includes(el))) {
          return "Found at least one not allowed character ?*&";
        }

        // Lets see what the standard URI parser replies
        var pattern = new RegExp(
          "(https?:\\/\\/)" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "(127.0.0.1|localhost))" + // 127.0.0.1 or localhost
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator
        if (!pattern.test(value)) {
          return "URL not valid";
        }

        // if localhost or 127.0.0.1 is there .. http is ok
        if (value.startsWith("http://localhost")) return true;

        if (value.startsWith("http://127.0.0.1")) return true;

        // otherwise we always want https
        if (value.startsWith("http:")) {
          return "please use https";
        }

        return true;
      },
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    notEmpty(value) {
      if (this.serverErrorWebSpaDesktop != "") {
        this.serverErrorWebSpaDesktop = "";
      }
      if (this.serverErrorIos != "") {
        this.serverErrorIos = "";
      }
      if (value == null || value == undefined) {
        return "Required";
      }
      if (value.length == "") {
        return "Required";
      }
      return true;
    },
    close() {
      this.selectedType = "";
      this.show = false;
      this.isWebSpaDesktopValid = false;
      this.webSpaDesktopUri = "";
      this.serverErrorWebSpaDesktop = "";
      this.isIosValid = false;
      this.iOsUri = "";
      this.serverErrorIos = "";
      this.isAndroidValid = false;
      this.androidPackagename = "";
      this.androidSignaturehash = "";
      this.serverErrorAndroid = "";
    },
    isTypeDisabled(type) {
      var typeWebPresent = false;
      var othersPresent = false;
      for (let i = 0; i < this.application.redirectURIs.length; i++) {
        if (this.application.redirectURIs[i].type === "WEB") {
          typeWebPresent = true;
        } else {
          othersPresent = true;
        }
      }

      if (typeWebPresent && type !== "WEB") {
        return true;
      }

      if (othersPresent && type === "WEB") {
        return true;
      }

      return false;
    },
    getDisabledToolTip() {
      return "Redirect URI type WEB can not be mixed with other types.<br/>Refer to the OAuth2 section to understand why!";
    },
    getClass(type) {
      if (type == this.selectedType) {
        return "white--text";
      }
      return "";
    },
    getBtnColor(type) {
      if (type == this.selectedType) {
        return "#006c27";
      }
      return "";
    },
    getIconColor(type) {
      if (type == this.selectedType) {
        return "white";
      }
      return "#646464";
    },
    setType(type) {
      this.selectedType = type;
      if (this.$refs.android_form) {
        this.$refs.android_form.reset();
      }
      if (this.$refs.web_spa_desktop_form) {
        this.$refs.web_spa_desktop_form.reset();
      }
      if (this.$refs.ios_form) {
        this.$refs.ios_form.reset();
      }
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
    addSpaWebDesktopUri() {
      cass
        .addRedirectURI(this.application.applicationId, {
          type: this.selectedType,
          redirectURI: this.webSpaDesktopUri,
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
          this.close();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.serverErrorWebSpaDesktop = err.response.data.message;
        });
    },
    addAndroidUri() {
      cass
        .addRedirectURI(this.application.applicationId, {
          type: this.selectedType,
          packageName: this.androidPackagename,
          signatureHash: this.androidSignaturehash,
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
          this.close();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.serverErrorAndroid = err.response.data.message;
        });
    },
    addIosUri() {
      cass
        .addRedirectURI(this.application.applicationId, {
          type: this.selectedType,
          bundleId: this.iOsUri,
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
          this.close();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.serverErrorIos = err.response.data.message;
        });
    },
  },
};
</script>

<style scoped>
.cli {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #006c27;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1em;
  margin-top: 1em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
</style>
