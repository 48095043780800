import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const navStore = new Vuex.Store({
  state() {
    return {
      navTo: "/",
    };
  },
  mutations: {
    navTo(state, to) {
      sessionStorage.setItem("navTo", to);
      state.navTo = to;
      console.log("stored to: " + to);
    },
  },
  actions: {
    init: ({ commit }) => {
      var to = sessionStorage.getItem("navTo");
      if (to == null) {
        to = "/";
      }
      commit("navTo", to);
    },
  },
});

export default navStore;
