var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    { attrs: { multiple: "" } },
    [
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("v-icon", { attrs: { medium: "", color: "#006c27" } }, [
                  _vm._v(" mdi-newspaper-variant-outline "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ml-2",
                    staticStyle: { "padding-top": "2px" },
                  },
                  [
                    _vm._v(
                      "CASS Version: " + _vm._s(_vm.CASSWebAppBuildVersion)
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-expansion-panel-content",
            { staticStyle: { "padding-left": "0px" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Date ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.CASSWebAppBuildDateValue) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Mode ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.CASSWebAppBuildModeValue) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Branch ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.CASSWebAppBuildGitBranch) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Commit ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.CASSWebAppBuildGitCommit) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git URL ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.CASSWebAppBuildGitURL,
                          },
                        },
                        [_vm._v(_vm._s(_vm.CASSWebAppBuildGitURL))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Container Image ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.CASSWebAppBuildContainerImage) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("v-icon", { attrs: { medium: "", color: "#006c27" } }, [
                  _vm._v(" mdi-api "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ml-2",
                    staticStyle: { "padding-top": "2px" },
                  },
                  [
                    _vm._v(
                      "CASS Version: " + _vm._s(_vm.versionCASSAPI.version)
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-expansion-panel-content",
            { staticStyle: { "padding-left": "0px" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Date ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionCASSAPI.buildDate) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Compiler ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionCASSAPI.compiler) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Branch ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionCASSAPI.branch) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Commit ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionCASSAPI.commit) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git URL ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.versionCASSAPI.gitURL,
                          },
                        },
                        [_vm._v(_vm._s(_vm.versionCASSAPI.gitURL))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Container Image ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionCASSAPI.image) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("v-icon", { attrs: { medium: "", color: "#006c27" } }, [
                  _vm._v(" mdi-api "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ml-2",
                    staticStyle: { "padding-top": "2px" },
                  },
                  [
                    _vm._v(
                      "Rating Version: " + _vm._s(_vm.versionRATEAPI.version)
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-expansion-panel-content",
            { staticStyle: { "padding-left": "0px" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Date ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionRATEAPI.buildDate) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Compiler ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionRATEAPI.compiler) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Branch ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionRATEAPI.branch) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Commit ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionRATEAPI.commit) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git URL ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.versionRATEAPI.gitURL,
                          },
                        },
                        [_vm._v(_vm._s(_vm.versionRATEAPI.gitURL))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Container Image ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionRATEAPI.image) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("v-icon", { attrs: { medium: "", color: "#006c27" } }, [
                  _vm._v(" mdi-api "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ml-2",
                    staticStyle: { "padding-top": "2px" },
                  },
                  [_vm._v("AAL Version: " + _vm._s(_vm.versionAALAPI.version))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-expansion-panel-content",
            { staticStyle: { "padding-left": "0px" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Date ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionAALAPI.buildDate) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Build Compiler ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionAALAPI.compiler) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Branch ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionAALAPI.branch) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git Commit ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionAALAPI.commit) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Git URL ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.versionAALAPI.gitURL,
                          },
                        },
                        [_vm._v(_vm._s(_vm.versionAALAPI.gitURL))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_vm._v(" Container Image ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "8" },
                    },
                    [_vm._v(" " + _vm._s(_vm.versionAALAPI.image) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }