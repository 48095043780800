var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    { on: { click: _vm.onExpansionPanelClick } },
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "secretsList" } },
        [
          _c(
            "div",
            [
              _c("v-icon", [_vm._v(" mdi-finance ")]),
              _c("span", { staticClass: "ml-1" }, [_vm._v("Usage Statistics")]),
              _vm.opened
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-2",
                                        attrs: { color: "#006c27" },
                                        on: { click: _vm.update },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("mdi-information-outline")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2745096657
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " You can see here the overall API usage of the last 30 days."
                        ),
                        _c("br"),
                        _vm._v(" You can click the icon to update the data."),
                        _c("br"),
                        _vm._v(" Requests need upto 5 minutes to appear here."),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _c("v-chart", {
            ref: "chart",
            staticClass: "chart",
            attrs: { option: _vm.option },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }