<template>
  <v-container>
    <v-row class="mt-2" v-if="userIsAdmin">
      <v-col align="center">
        <v-card width="1200" :class="getAlarmstatus()">
          <v-card-title class="text--secondary">
            <v-row>
              <v-col cols="4">
                <div style="text-align: left">CASS Health</div>
                <div
                  style="margin-left: 2px; font-size: 0.7rem; text-align: left"
                >
                  {{ lastUpdated }}
                </div>
              </v-col>
              <v-col cols="2">
                <v-chip small :color="getEnvironmentColor('DEVELOPMENT')" dark>
                  DEVELOPMENT
                </v-chip>
                <div>
                  <status-overwrite
                    :status="getStatus('DEVELOPMENT')"
                  ></status-overwrite>
                </div>
              </v-col>
              <v-col cols="2">
                <v-chip small :color="getEnvironmentColor('QUALITY')" dark>
                  QUALITY
                </v-chip>
                <div>
                  <status-overwrite
                    :status="getStatus('QUALITY')"
                  ></status-overwrite>
                </div>
              </v-col>
              <v-col cols="2">
                <v-chip small :color="getEnvironmentColor('PRODUCTION')" dark>
                  PRODUCTION
                </v-chip>
                <div>
                  <status-overwrite
                    :status="getStatus('PRODUCTION')"
                  ></status-overwrite>
                </div>
              </v-col>
              <v-col cols="2">
                <v-chip small color="darkgrey" dark> DATABASE </v-chip>
                <div style="font-size: 1rem">
                  {{
                    getStatus("PRODUCTION").status === "ERROR"
                      ? "ERROR"
                      : "ONLINE"
                  }}
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!--<advent-calendar></advent-calendar>-->
    <v-row class="mt-2">
      <v-col align="center">
        <v-card :loading="loading" :width="userIsAdmin ? 1200 : 900">
          <error-overlay v-model="error" @retry="loadData"></error-overlay>
          <template slot="progress">
            <v-progress-linear
              color="#006c27"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="text--secondary">
            <div v-if="userIsAdmin">
              All Applications ({{ applications.length }}/{{
                filteredApplications.length
              }})
            </div>
            <div v-if="!userIsAdmin">Your Applications</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              color="#006c27"
              hide-details
              @keyup="applyFilter"
              style="padding-top: 0; margin-top: 0"
            >
              <template slot="append">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                  </template>
                  <span>You can wildcard search for name, id or owner.</span>
                </v-tooltip>
                <v-tooltip v-if="userIsAdmin" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="onClickQuickFilter"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="#006c27"
                    >
                      <v-icon>{{ getFilterIcon() }}</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Admin quickfilter! This will filter all applications from
                    API CoE Team</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-title>
          <v-card-text class="text--primary my-6">
            <v-data-table
              sort-by="displayName"
              :custom-sort="customSort"
              v-if="!loading"
              must-sort
              :headers="headers"
              :items="filteredApplications"
              :hide-default-footer="filteredApplications.length <= 10"
              class="elevation-1"
              @click:row="rowClicked"
            >
              <template slot="no-data">
                You do not have any applications yet! Just create or import one!
              </template>
              <template v-slot:[`item.environment`]="{ item }">
                <v-chip
                  outlined
                  small
                  :color="getEnvironmentColor(item.environment)"
                  dark
                >
                  {{ item.environment }}
                </v-chip>
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="getStatusIconColor(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getStatusIcon(item) }}
                    </v-icon>
                  </template>
                  <span>{{ getStatusText(item) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-row v-if="!loading">
              <v-spacer></v-spacer>
              <v-btn
                @click="createApplication"
                color="#006c27"
                dark
                class="mr-3 mt-9 mb-5"
              >
                CREATE
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <application-create-overlay v-model="createOverlay">
    </application-create-overlay>
    <announcement-dialog
      v-model="announcementDialog"
      :announcement="announcement"
      @confirm="confirmAnnouncement"
    ></announcement-dialog>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import ErrorOverlay from "../components/ErrorOverlay.vue";
import ApplicationCreateOverlay from "../components/ApplicationCreateOverlay.vue";
import moment from "moment";
import StatusOverwrite from "../components/StatusOverwrite.vue";
import AnnouncementDialog from "../components/AnnouncementDialog.vue";
moment.relativeTimeThreshold("ss", 0);
import announce from "../api/announce.js";

export default {
  name: "home",

  components: {
    ErrorOverlay,
    ApplicationCreateOverlay,
    StatusOverwrite,
    AnnouncementDialog,
  },

  async mounted() {
    this.loadData();
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    if (this.lastUpdateInterval != null) {
      clearInterval(this.lastUpdateInterval);
      this.lastUpdateInterval = null;
    }
  },
  data() {
    return {
      search: "",
      applications: [],
      filteredApplications: [],
      error: null,
      loading: true,
      createOverlay: false,
      adminQuickFilter: false,
      lastUpdated: null,
      lastUpdateInterval: null,
      userIsAdmin: false,
      announcementDialog: false,
      announcement: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "displayName",
          width: "46%",
        },
        {
          text: "Permission Model",
          value: "permissionModel",
          filterable: false,
        },

        { text: "Environment", value: "environment", filterable: false },
        { text: "Status", value: "state", filterable: false, sortable: false },
      ],
    };
  },
  created() {
    console.log("HOME CREATE");
    this.$emitter.on("home_reload", () => {
      console.log("home_reload");
      this.loadData();
    });
  },
  methods: {
    confirmAnnouncement(annoncement) {
      console.log("Confirm Announcement: ", annoncement);
      announce
        .confirmAnnoncement(annoncement.uuid)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatus(env) {
      if (this.$sap != undefined && this.$sap.status != null) {
        var result = this.$sap.status.filter((element) => {
          return element.environment === env;
        });
        if (result == undefined || result == null || result.length == 0) {
          return { status: "ERROR" };
        }
        return result[0];
      } else {
        return { status: "Loading ..." };
      }
    },
    getAlarmstatus() {
      var rc = "";
      if (this.$sap != null && this.$sap.status != null) {
        this.$sap.status.forEach((element) => {
          if (element.status != "ONLINE") {
            rc = "alarmstatus";
          }
        });
        if (
          this.$sap.timestamp + (process.env.VUE_APP_STATUS_CHECK + 5) * 1000 <
          Date.now()
        ) {
          rc = "alarmstatus";
        }
      }
      return rc;
    },
    addAdminContext() {
      if (this.$userData.cass.isUserAdmin) {
        this.userIsAdmin = true;
        this.headers.splice(1, 0, {
          text: "Owner",
          value: "firstowner",
          filterable: false,
          cellClass: "ownerClass",
        });
        this.headers[0].width = "47.2%";
        var self = this;
        this.lastUpdateInterval = setInterval(() => {
          //console.log("checking");
          self.lastUpdated = "updated " + moment(this.$sap.timestamp).fromNow();
        }, 1000);
      }
    },
    getFilterIcon() {
      if (this.adminQuickFilter) {
        return "mdi-account-filter";
      }
      return "mdi-account-filter-outline";
    },
    onClickQuickFilter() {
      this.adminQuickFilter = !this.adminQuickFilter;
      this.applyFilter();
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index]
              .toLowerCase()
              .localeCompare(b[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          } else {
            return b[index]
              .toLowerCase()
              .localeCompare(a[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          }
        }
      });
      return items;
    },
    loadData() {
      // load announcements
      announce
        .getAnnouncements()
        .then((response) => {
          console.log(response);
          if (response.data.length > 0) {
            this.announcement = response.data[0];
            this.announcementDialog = true;
          } else {
            console.log("no announcements for user");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.addAdminContext();
      this.error = null;
      this.loading = true;
      cass
        .getApplications()
        .then((response) => {
          console.log(response);
          this.applications = response.data;
          this.applications.forEach((app) => {
            app.firstowner = app.owners[0];
          });

          this.applyFilter();
        })
        .catch((error) => {
          console.log("OH NO");
          this.applications = [];
          this.error = cass.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    getStatusIcon(item) {
      if (item.state == "READY_TO_USE") {
        return "mdi-shield-check-outline";
      }
      return "mdi-shield-alert-outline";
    },
    getStatusIconColor(item) {
      if (item.state == "READY_TO_USE") {
        return "#006c27";
      }
      return "red";
    },
    getStatusText(item) {
      if (item.state == "NO_VALID_SECRET") {
        return "Your Application does not have a valid secret";
      } else if (item.state == "MISSING_REDIRECTURI") {
        return "Your Application does not have a valid RedirectURI";
      }
      return "All Good! 🎉";
    },
    applyFilter() {
      //console.log("apply Filter");
      this.filteredApplications = [];
      this.applications.forEach((app) => {
        var search = this.search.toUpperCase();

        var found1 = app.displayName.toUpperCase().indexOf(search) != -1;
        var found2 = app.applicationId.toUpperCase().indexOf(search) != -1;
        var found3 =
          app.apiMgmtApplicationId.toUpperCase().indexOf(search) != -1;
        var found4 = false;
        for (var j = 0; j < app.owners.length; j++) {
          if (app.owners[j].toUpperCase().indexOf(search) != -1) {
            found4 = true;
            break;
          }
        }
        if (this.adminQuickFilter) {
          for (j = 0; j < app.owners.length; j++) {
            if (
              app.owners[j].toUpperCase().indexOf("POLTOKZY") != -1 ||
              app.owners[j].toUpperCase().indexOf("MAZURTMA") != -1 ||
              app.owners[j].toUpperCase().indexOf("KOHNSMRK") != -1 ||
              app.owners[j].toUpperCase().indexOf("ADELHCRI") != -1
            ) {
              return;
            }
          }
        }
        if (found1 || found2 || found3 || found4) {
          this.filteredApplications.push(app);
        }
      });
    },
    rowClicked(item) {
      this.$router
        .push({
          name: "Application",
          params: { applicationid: item.applicationId },
        })
        .catch(() => {
          console.log("Something bad happend .. start over");
          window.location.reload();
        });
    },
    createApplication() {
      this.createOverlay = true;
    },
  },
};
</script>

<style>
.ownerClass {
  min-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.alarmstatus {
  animation: pulsate 1s ease-out infinite;
}

@-webkit-keyframes pulsate {
  0% {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  50% {
    box-shadow: 0 0 24px 2px red;
  }
  100% {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}
</style>
<style type="sass" scoped>
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #ebeef0 !important;
}
</style>
