<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-card>
          <v-card-title class="text--secondary">
            Permission Models
          </v-card-title>
          <v-card-text class="text--primary my-3">
            <permission-content></permission-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PermissionContent from "../components/PermissionContent.vue";
export default {
  name: "PermissionModel",
  components: { PermissionContent },
  mounted() {},
  data() {
    return {};
  },
  filters: {},
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped>
.headline {
  color: #006c27;
  font-size: 1rem !important;
}
</style>
