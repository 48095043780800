<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pl-3">
      <div>
        <v-icon> mdi-account-lock-outline </v-icon>
        <span class="ml-1">OAuth2</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row v-if="application.permissionModel === 'DELEGATED'">
        <v-col>
          <div style="text-align: left">
            This application is in permission model DELEGATED.<br />
            This basically means, this application in meant to be used in user
            context<br />
            With help of this application you can authenticate real users and
            call APIs on behalf of the user<br />
            Keep in mind: Without any real user, this application can do nothing
            on its own!<br />
            This application can only consume APIs which support the permission
            model DELEGATED.<br />
            The supported permission models of an API can be discovered in the
            API's description on the
            <a :href="getDeveloperPortalURL()" target="_blank"
              >Developer Portal</a
            >.<br />
            <br />
            In order to consume an API in delegated permission model, your
            application needs to authenticate real users.<br />
            Schaeffler API Gateway uses for authentication and authorization the
            <a href="https://oauth.net/2/" target="_blank">OAuth2 standard</a
            >.<br />
            Strictly spoken, this application needs to use the
            <a
              href="https://docs.microsoft.com/de-de/azure/active-directory/develop/v2-oauth2-auth-code-flow"
              target="_blank"
              ><strong>OAuth2 authorization code flow (with PKCE)</strong></a
            >
            to obtain a token.
          </div>
          <expandable-image
            class="my-3"
            :src="$getImagePath('AuthCodeFlow.png')"
          />
          <div style="text-align: left">
            Perhaps you mentioned in the picture that
            <strong>secret</strong> was in brackets. It depends on your
            application if you need to use a secret or not. If your application
            is an SPA, Mobile or DesktopApp - you do not need a secret and will
            use the authorization code flow with PKCE. Only if your application
            is a WebApp with dedicated backend (i.e. server side rendering),
            then you need to create a secret and use the normal authorization
            code flow. <br />
            <br />
            It is important to mention that you need one access token (JWT) for
            every individual API you want to consume.<br />

            For the authorization code flow you need five pieces of
            information.<br /><br />
            <strong>Application Id:</strong> {{ this.application.applicationId
            }}<br />
            <strong>Secret:</strong> Get it in the secret section (only if your
            RedirectURI is of type WEB).<br />
            <strong>Scope:</strong> get the scope in the permission section. You
            need to request at least one permission for each API you want to
            consume on behalf of a user.<br />
            <strong>Token URL:</strong>
            https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/token<br />
            <strong>Authorize URL:</strong>
            https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/authorize<br />
            <br />
          </div>

          <div class="pt-2" style="background: #ffcaca; font-weight: 500">
            For a complete step-by-step guide how OAuth2 authentication works,
            visit our
            <a target="_blank" href="https://apicademy.dp.schaeffler/"
              >APIcademy.</a
            >
          </div>
          <div class="pb-2" style="background: #ffcaca; font-weight: 500">
            The content can be found in CONBoarding/Consumption
          </div>
          <div style="text-align: left">
            <br />
            For implementation - we strongly recommend using
            <a
              href="https://docs.microsoft.com/de-de/azure/active-directory/develop/msal-overview"
              target="_blank"
              >Microsoft Authentication Library</a
            >.<br />
            <div>
              Get more information at our Microsoft Teams channel
              <a
                class="link"
                href="https://teams.microsoft.com/_?tenantId=67416604-6509-4014-9859-45e709f53d3f#/tab::04d58b98-f294-4ce8-b803-cc18df972d0f/HowTo%20Consume%20an%20API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&threadId=19:dd5e32026d72441f87004e98a670b13f@thread.tacv2&ctx=channel"
                target="_blank"
                >API@Schaeffler (Browser)</a
              >
              or
              <a
                class="link"
                href="msteam://teams.microsoft.com/_?tenantId=67416604-6509-4014-9859-45e709f53d3f#/tab::04d58b98-f294-4ce8-b803-cc18df972d0f/HowTo%20Consume%20an%20API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&threadId=19:dd5e32026d72441f87004e98a670b13f@thread.tacv2&ctx=channel"
                target="_blank"
                >API@Schaeffler (Teams)</a
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="application.permissionModel === 'APPLICATION'">
        <v-col>
          <div style="text-align: left">
            This application is in permission model APPLICATION.<br />
            This basically means, this application <strong>is</strong> a system
            user.<br />
            This application can only consume APIs which support the permission
            model APPLICATION.<br />
            The supported permission models of an API can be discovered in the
            API's description on the
            <a :href="getDeveloperPortalURL()" target="_blank"
              >Developer Portal</a
            >.<br />
            <br />
            In order to consume an API in application permission model, your
            application needs to authenticate itself.<br />
            Schaeffler API Gateway uses for authentication and authorization the
            <a href="https://oauth.net/2/" target="_blank">OAuth2 standard</a
            >.<br />
            Strictly spoken, this application needs to use the
            <a
              href="https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-client-creds-grant-flow"
              target="_blank"
              ><strong>OAuth2 client credential flow</strong></a
            >
            to obtain a token.
          </div>
          <expandable-image
            class="my-3"
            :src="$getImagePath('ClientCredentialFlow.png')"
          />
          <div style="text-align: left">
            It is important to mention that you need one access token for every
            individual API you want to consume.<br />

            For the client credential flow you need four pieces of
            information.<br /><br />
            <strong>Application Id:</strong> {{ this.application.applicationId
            }}<br />
            <strong>Secret:</strong> Get it in the secret section.<br />
            <strong>Scope:</strong> get the scope in the permission section. You
            need to request one permission for each API you want to consume. The
            application scope always ends with /.default<br />
            <strong>Token URL:</strong>
            https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/token<br />
            <br />
          </div>

          <div class="pt-2" style="background: #ffcaca; font-weight: 500">
            For a complete step-by-step guide how OAuth2 authentication works,
            visit our
            <a target="_blank" href="https://apicademy.dp.schaeffler/"
              >APIcademy.</a
            >
          </div>
          <div class="pb-2" style="background: #ffcaca; font-weight: 500">
            The content can be found in CONBoarding/Consumption
          </div>
          <div style="text-align: left">
            <br />
            For implementation - we strongly recommend using
            <a
              href="https://docs.microsoft.com/de-de/azure/active-directory/develop/msal-overview"
              target="_blank"
              >Microsoft Authentication Library</a
            >.<br />
            <div>
              Get more information at our Microsoft Teams channel
              <a
                class="link"
                href="https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
                target="_blank"
                >API@Schaeffler (Browser)</a
              >
              or
              <a
                class="link"
                href="msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
                target="_blank"
                >API@Schaeffler (Teams)</a
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "OAuth2",
  props: {
    application: Object,
  },
  methods: {
    retry() {
      this.$emit("retry");
    },
    getDeveloperPortalURL() {
      return "https://developer.schaeffler.com";
    },
  },
};
</script>

<style scoped>
a:link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}
</style>
