<template>
  <div
    style="
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div v-if="loading">
      <v-card
        style="margin-left: auto; margin-right: auto"
        width="600px"
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="justify-center">
          Preparing API Subscription
        </v-card-title>
        <v-card-text v-if="!error" class="text-center">
          Please standby
        </v-card-text>
      </v-card>
    </div>
    <div v-if="error">
      <v-card
        style="margin-left: auto; margin-right: auto"
        width="600px"
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="justify-center">
          😞 API Subscription Failed
        </v-card-title>
        <v-card-text class="text-center">
          {{ errorMsg }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="back"
            class="white--text mr-4 mb-3 mt-3"
            color="#006c27"
          >
            OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <div v-if="!loading && !error" style="display: flex">
      <v-card
        style="margin-left: auto; margin-right: auto; z-index: 20"
        width="600px"
      >
        <v-card-title class="ml-3"> Subscribe an API </v-card-title>
        <v-card-text class="ml-3">
          Great! You want to integrate API
          <strong>{{ products[0].title }}</strong> into your application!<br />
          You have two options:<br />
          <ul class="mt-1 mb-1">
            <li>You can register a new application.</li>
            <li>You can add the API to one of your existing applications.</li>
          </ul>
          Just flip the lowest card to choose the right option for you.
        </v-card-text>
        <v-card class="mx-6 mb-4">
          <v-card-title class="text-subtitle-2">
            Select environment
          </v-card-title>
          <v-tooltip
            bottom
            v-for="(item, index) in ['PRODUCTION', 'QUALITY', 'DEVELOPMENT']"
            :key="index"
            :disabled="isEnvironmentValid(item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-chip
                  small
                  color="#006c27"
                  :class="
                    isEnvironmentSelected(item)
                      ? 'ml-4 mb-3 white--text'
                      : 'ml-4 mb-3'
                  "
                  :outlined="!isEnvironmentSelected(item)"
                  :disabled="!isEnvironmentValid(item)"
                  @click="selectEnvironment(item)"
                >
                  {{ item }}
                </v-chip>
              </span>
            </template>
            <span>This environment is not valid for your account</span>
          </v-tooltip>

          <v-btn
            id="environmentHelpMeButtonCreateApplication"
            @click="showEnvironmentHelp = true"
            class="pb-2"
            color="#006c27"
            text
            style="
              display: inline-block;
              float: right;
              text-transform: none;
              letter-spacing: 0;
            "
          >
            Help me!
          </v-btn>
        </v-card>
        <v-card class="mx-6 mb-4">
          <v-card-title class="text-subtitle-2">
            Select permission model
          </v-card-title>
          <v-tooltip
            bottom
            v-for="(item, index) in ['APPLICATION', 'DELEGATED']"
            :key="index"
            :disabled="isPermissionModelValid(item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-chip
                  small
                  color="#006c27"
                  :class="
                    isPermissionModelSelected(item)
                      ? 'ml-4 mb-3 white--text'
                      : 'ml-4 mb-3'
                  "
                  :outlined="!isPermissionModelSelected(item)"
                  :disabled="!isPermissionModelValid(item)"
                  @click="selectPermissionModel(item)"
                >
                  {{ item }}
                </v-chip>
              </span>
            </template>
            <span>This permission model is not support by the API</span>
          </v-tooltip>
          <v-btn
            id="permissionHelpMeButtonCreateApplication"
            @click="showPermissionModelHelp = true"
            class="pb-2"
            color="#006c27"
            text
            style="
              display: inline-block;
              text-transform: none;
              letter-spacing: 0;
              float: right;
            "
          >
            Help me!
          </v-btn>
        </v-card>
        <vue-flip
          v-model="flipCard"
          width="550px"
          height="225px"
          style="margin-left: auto; margin-right: auto"
          class="mb-4"
        >
          <template v-slot:front>
            <v-card class="d-flex flex-column" width="550px" height="225px">
              <v-card-title>
                <div class="text-subtitle-2">Register New Application</div>
              </v-card-title>

              <v-card-text class="">
                <v-form
                  v-if="!flipCard"
                  ref="formCreate"
                  v-model="createValid"
                  v-on:submit.prevent="nop"
                >
                  <v-row no-gutters>
                    <v-col cols="9">
                      <v-text-field
                        v-model="appNameCreate"
                        id="inputApplicationName"
                        label="Application Name"
                        placeholder="give your application a nice name"
                        outlined
                        :rules="[notEmpty, min, rules.validName]"
                        :error-messages="serverErrorCreate"
                        dense
                        maxlength="50"
                        counter
                        autocomplete="new-password"
                        color="#006c27"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn
                        class="white--text"
                        id="createButton"
                        color="#006c27"
                        style="display: inline-block; float: right"
                        :disabled="!createValid"
                        @click="createApplication()"
                      >
                        Create
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span class="subtitle-2 text--secondary">FlipMe!</span>
                <v-icon
                  color="#006c27"
                  id="flipApplicationCreateOverlayToImport"
                  @click="flip()"
                  >mdi-arrow-right
                </v-icon>
              </v-card-actions>
            </v-card>
          </template>
          <template v-slot:back>
            <v-card class="d-flex flex-column" width="550px" height="225px">
              <v-card-title>
                <div class="text-subtitle-2">Add to existing application</div>
              </v-card-title>
              <v-row no-gutters>
                <v-col cols="9">
                  <div
                    class="ml-4"
                    style="
                      border: 1px solid #9e9e9e;
                      border-radius: 5px;
                      overflow: hidden;
                    "
                  >
                    <div style="height: 122px; overflow-y: scroll">
                      <div
                        v-if="filteredApplications.length == 0"
                        style="
                          text-align: center;
                          margin-top: 50px;
                          color: grey;
                          font-size: 0.8rem;
                        "
                      >
                        You have no matching applications
                      </div>
                      <v-list
                        v-if="filteredApplications.length != 0"
                        dense
                        style="padding: 0 !important"
                      >
                        <v-list-item-group
                          v-model="selectedApplication"
                          color="#006c27"
                        >
                          <div
                            v-for="(app, index) in filteredApplications"
                            :key="index"
                          >
                            <v-list-item :disabled="app.alreadySubscribed">
                              <v-list-item-content>
                                <v-list-item-title v-if="!app.alreadySubscribed"
                                  >{{ app.displayName }}
                                </v-list-item-title>
                                <v-list-item-title v-if="app.alreadySubscribed"
                                  >{{ app.displayName }} (Subscription
                                  present)</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    id="importApplicationButton"
                    class="mr-3 white--text"
                    color="#006c27"
                    style="display: inline-block; float: right"
                    :disabled="selectedApplication == null"
                    @click="addToApplication()"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-icon color="#006c27" @click="flip()">mdi-arrow-left </v-icon>

                <span class="subtitle-2 text--secondary">FlipMe!</span>
              </v-card-actions>
            </v-card>
          </template>
        </vue-flip>
      </v-card>
      <div id="pageWrap" style="position: relative">
        <svg
          ref="rocket"
          version="1.1"
          x="0px"
          y="0px"
          width="307px"
          height="283px"
          id="rocket"
          style="position: absolute; top: 454px; left: -80px; z-index: 10"
        >
          <g class="rocket_wrap">
            <g class="rocket_inner">
              <path
                class="fire fire_path"
                id="fireMiddle"
                ref="fireMiddle"
                d="M148.891,179.906c3.928,0,7.111,3.176,7.111,7.094 c0,7.78-7.111,16-7.111,16s-7.111-8.349-7.111-16C141.78,183.082,144.963,179.906,148.891,179.906z"
              />

              <path
                class="fire_path fire"
                id="fireRight"
                ref="fireRight"
                d="M154.063,181.092c3.577-1.624,7.788-0.048,9.408,3.52 c3.216,7.084,0.139,17.508,0.139,17.508s-9.927-4.662-13.09-11.63C148.9,186.923,150.487,182.715,154.063,181.092z"
              />

              <path
                class="fire_path fire"
                id="fireLeft"
                ref="fireLeft"
                d="M143.392,182.519c3.25,2.207,4.098,6.623,1.896,9.864 c-4.372,6.436-14.873,9.238-14.873,9.238s-1.191-10.902,3.108-17.23C135.725,181.149,140.143,180.312,143.392,182.519z"
              />

              <path
                class="fire_path fire"
                id="fireSmallLeft"
                ref="fireSmallLeft"
                d="M143.193 187.531c2.226 0.4 3.7 2.6 3.2 4.8 c-0.875 4.407-5.829 8.264-5.829 8.264s-3.09-5.53-2.229-9.865C138.807 188.5 141 187.1 143.2 187.531z"
              />

              <path
                class="fire_path fire"
                id="fireSmallRight"
                ref="fireSmallRight"
                d="M152.089 188.599c2.043-0.985 4.496-0.132 5.5 1.9 c1.952 4 0.3 10.1 0.3 10.107s-5.795-2.56-7.713-6.541C149.186 192 150 189.6 152.1 188.599z"
              />

              <path
                class="rocket_bottom"
                d="M157.069 171.31h-3.292c-1.562-0.048-3.178-0.076-4.846-0.076 s-3.284 0.028-4.846 0.076h-3.292c-7.277-7.938-12.371-26.182-12.371-47.434c0-28.54 9.182-51.676 20.508-51.676 c11.327 0 20.5 23.1 20.5 51.676C169.44 145.1 164.3 163.4 157.1 171.31z"
              />

              <g id="right_wing_wrap">
                <path
                  class="wing_base"
                  d="M166.678 127.161c0 0 17.7 3.3 12.9 48.099l-18.06-14.05 L166.678 127.161z"
                />
                <path
                  class="wing_shadow"
                  d="M158.225 140.336c10.481-5.584 22.7 22.2 21.4 34.9 l-18.06-14.05C161.542 161.2 156.1 144.3 158.2 140.336z"
                />
              </g>

              <g id="left_wing_wrap">
                <path
                  class="wing_base"
                  d="M135.131 161.21l-18.06 14.1 c-4.805-44.793 12.924-48.099 12.924-48.099L135.131 161.21z"
                />
                <path
                  class="wing_shadow"
                  d="M135.131 161.21l-18.06 14.1 c-1.367-12.746 10.896-40.509 21.377-34.924C140.614 144.3 135.1 161.2 135.1 161.21z"
                />
              </g>

              <g id="rocket_body_wrap">
                <path
                  class="rocket_base"
                  d="M162.728 167.358c-3.778-0.623-8.573-0.996-13.796-0.996 s-10.018 0.373-13.795 0.996c-5.033-10.186-8.257-25.808-8.257-43.338c0-30.688 9.873-55.566 22.052-55.566 s22.053 24.9 22.1 55.566C170.984 141.6 167.8 157.2 162.7 167.358z"
                />
                <path
                  class="rocket_shadow"
                  d="M145.464 166.417c19.578-40.575 7.26-85.229 4.112-98.067 c11.88 0.9 21.4 25.4 21.4 55.525c0 17.529-3.225 33.152-8.257 43.337c0 0-3.786-0.472-8.069-0.697 S145.464 166.4 145.5 166.417z"
                />
              </g>

              <g id="large_window_wrap">
                <radialgradient
                  id="SVGID_2_"
                  cx="148.9"
                  cy="112.5"
                  r="15.2"
                  fx="139.4853"
                  fy="112.5239"
                  gradientunits="userSpaceOnUse"
                >
                  <stop offset="0" class="window_grandient" />
                  <stop offset="0.5868" class="window_grandient" />
                  <stop offset="0.6834" class="window_grandient" />
                  <stop offset="0.6845" class="window_grandient1" />
                  <stop offset="0.6861" class="window_grandient2" />
                  <stop offset="0.6897" class="window_grandient3" />
                </radialgradient>
                <circle
                  class="large_window_path"
                  cx="148.9"
                  cy="111.3"
                  r="10.5"
                />
              </g>

              <circle class="small_window_path" cx="148.9" cy="132.4" r="5.2" />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <environment-dialog v-model="showEnvironmentHelp"></environment-dialog>
    <permission-dialog v-model="showPermissionModelHelp"></permission-dialog>
    <sub-products-progress-overlay
      v-model="showSubscriptionProgressDialog"
      :messages="progressMessages"
    >
    </sub-products-progress-overlay>
  </div>
</template>

<script>
import cass from "../api/cass";
import VueFlip from "vue-flip";
import EnvironmentDialog from "../components/EnvironmentDialog.vue";
import PermissionDialog from "../components/PermissionDialog.vue";
import SubProductsProgressOverlay from "../components/SubProductsProgressOverlay.vue";

export default {
  name: "Subscribe",
  components: {
    VueFlip,
    EnvironmentDialog,
    PermissionDialog,
    SubProductsProgressOverlay,
  },
  data: () => ({
    loading: true,
    error: false,
    errorMsg: "",
    products: [],
    selectedEnvironment: "",
    selectedPermissionModel: "",
    availablePermissionModels: null,
    applications: null,
    filteredApplications: [],
    showSubscriptionProgressDialog: false,
    progressMessages: [],
    selectedApplication: null,
    showEnvironmentHelp: false,
    showPermissionModelHelp: false,
    appNameCreate: "",
    permissionModels: ["DELEGATED", "APPLICATION"],
    createValid: false,
    importValid: false,
    serverErrorCreate: "",
    serverErrorImport: "",
    creating: false,
    flipCard: false,
    show: true,
    rules: {
      validName: (value) => {
        let pattern = /^(\w|\.|-)+$/;
        if (!pattern.test(value)) {
          return "given name contains invalid characters";
        }
        return true;
      },
    },
  }),
  computed: {},
  watch: {
    selectedApplication(value) {
      if (value != null) {
        this.launchFire(true);
      } else {
        this.launchFire(false);
      }
    },
    createValid(value) {
      if (value) {
        this.launchFire(true);
      } else {
        this.launchFire(false);
      }
    },
  },
  mounted() {
    console.log("Subscribe Page mounted");
    this.loadData();
  },
  methods: {
    launchRocket() {
      this.$refs.fireMiddle.classList.remove("prefireon");
      this.$refs.rocket.classList.add("rocket_launch");
      this.$refs.fireLeft.classList.add("fireon");
      this.$refs.fireRight.classList.add("fireon");
      this.$refs.fireMiddle.classList.add("fireon");
      this.$refs.fireSmallLeft.classList.add("fireon");
      this.$refs.fireSmallRight.classList.add("fireon");
    },
    launchFire(state) {
      if (state) {
        // fire on
        this.$refs.fireMiddle.classList.add("prefireon");
      } else {
        // fire off
        this.$refs.fireMiddle.classList.remove("prefireon");
      }
    },
    updateApplicationFilter() {
      this.filteredApplications = [];
      if (this.products.length == 0) {
        return;
      }
      var productname = this.products[0].name;
      this.applications.forEach((app) => {
        if (app.subscriptions == undefined) {
          return;
        }
        var alreadySubscribed = false;
        app.subscriptions.forEach((sub) => {
          if (sub.name === productname) {
            alreadySubscribed = true;
          }
        });
        if (
          app.environment == this.selectedEnvironment &&
          app.permissionModel == this.selectedPermissionModel
        ) {
          console.log("Adding app: " + app.displayName);
          app.alreadySubscribed = alreadySubscribed;
          this.filteredApplications.push(app);
        }
      });
      this.filteredApplications.sort((a, b) => {
        return a.displayName
          .toLowerCase()
          .localeCompare(b.displayName.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: "base",
          });
      });
    },
    isEnvironmentValid(env) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].environment == env) {
          return true;
        }
      }
      return false;
    },
    selectEnvironment(env) {
      this.selectedEnvironment = env;
      this.selectedApplication = null;
      this.updateApplicationFilter();
    },
    isEnvironmentSelected(env) {
      if (env == this.selectedEnvironment) {
        return true;
      }
      return false;
    },
    isPermissionModelValid(pm) {
      if (this.availablePermissionModels.has(pm)) {
        return true;
      }
      return false;
    },
    selectPermissionModel(pm) {
      this.selectedPermissionModel = pm;
      this.selectedApplication = null;
      this.updateApplicationFilter();
    },
    isPermissionModelSelected(pm) {
      if (pm == this.selectedPermissionModel) {
        return true;
      }
      return false;
    },
    getHighestEnvironment() {
      var production = false,
        quality = false,
        development = false;
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].environment == "PRODUCTION") {
          production = true;
        }
        if (this.products[i].environment == "QUALITY") {
          quality = true;
        }
        if (this.products[i].environment == "DEVELOPMENT") {
          development = true;
        }
      }
      if (production) {
        return "PRODUCTION";
      }
      if (quality) {
        return "QUALITY";
      }
      if (development) {
        return "DEVELOPMENT";
      }
      console.error("No environment found");
      return "NO ENVIRONMENT FOUND";
    },
    loadData() {
      console.log("Loading Data");
      console.log(this.$userData.cass.environments);
      console.log(this.$route.params.productid);

      let promise_array = [];
      promise_array = this.$userData.cass.environments.map((e) =>
        cass.getProduct(e.toUpperCase(), this.$route.params.productid)
      );
      promise_array.push(cass.getApplications());

      console.log(promise_array);

      Promise.allSettled(promise_array).then((data) => {
        console.log(data);
        // if all are rejected - something is wrong here
        let all_rejected = true;
        this.availablePermissionModels = new Set();
        data.forEach((data) => {
          if (
            data.status == "fulfilled" &&
            data.value.config.url.startsWith("sapproducts/")
          ) {
            all_rejected = false;
            var product = data.value.data;
            product.environment = data.value.config.params.environment;
            this.products.push(product);
            // look for permission models
            product.apiProxies.forEach((proxy) => {
              proxy.proxyEndPoints[0].properties.forEach((props) => {
                if (props.name == "PERMISSION_MODEL") {
                  if (props.value == "BOTH") {
                    this.availablePermissionModels.add("APPLICATION");
                    this.availablePermissionModels.add("DELEGATED");
                  } else {
                    this.availablePermissionModels.add(props.value);
                  }
                }
              });
            });
          } else if (
            data.value != undefined &&
            data.value.config.url.startsWith("applications")
          ) {
            if (data.status == "fulfilled") {
              console.log("Got applications");
              this.applications = data.value.data;

              // get subscriptions from all applications
              let promise_sub_array = [];
              this.applications.forEach((app) => {
                // subscriptions can only be done if you own it - also for admins
                if (
                  app.owners.includes(this.$userData.cass.email.toLowerCase())
                ) {
                  console.log(
                    "Requestion subscriptions for app: " + app.displayName
                  );
                  promise_sub_array.push(
                    cass.getSubscriptions(app.applicationId)
                  );
                }
              });

              Promise.allSettled(promise_sub_array).then((subscriptions) => {
                console.log("Subscriptions Promise settled");
                console.log(subscriptions);

                subscriptions.forEach((sub) => {
                  console.log(sub);
                  if (sub.status != "fulfilled") {
                    this.loading = false;
                    this.error = true;
                    this.errorMsg = "Could not load current subscriptions!";
                    console.debug(sub);
                    console.error(
                      "Could not load subscription: " + sub.value.config.url
                    );
                  } else {
                    // find out the corresponding application ID
                    var applicationID = sub.value.config.url.replace(
                      "applications/",
                      ""
                    );
                    applicationID = applicationID.replace("/subscriptions", "");
                    this.applications.forEach((app) => {
                      if (app.applicationId == applicationID) {
                        app.subscriptions = sub.value.data;
                      }
                    });
                  }
                });

                this.updateApplicationFilter();
                // This closes the loading windows
                this.loading = false;
              });
            } else {
              this.loading = false;
              this.error = true;
              this.errorMsg = "Could not load your applications!";
              return;
            }
          }
        });

        if (all_rejected) {
          this.loading = false;
          this.error = true;
          this.errorMsg =
            "The product you want to subscribe is not available in any of your onboarded environments or a network error occured.";
          return;
        }
        if (this.availablePermissionModels.length == 0) {
          this.loading = false;
          this.error = true;
          this.errorMsg =
            "The product you want to subscribe is not properly configured. Please contact API CoE.";
          return;
        }

        console.log(this.products);
        console.log(this.availablePermissionModels);
        this.selectedEnvironment = this.getHighestEnvironment();
        if (this.availablePermissionModels.has("APPLICATION")) {
          this.selectedPermissionModel = "APPLICATION";
        } else if (this.availablePermissionModels.has("DELEGATED")) {
          this.selectedPermissionModel = "DELEGATED";
        }

        if (this.selectedPermissionModel == "") {
          this.loading = false;
          this.error = true;
          this.errorMsg =
            "The product you want to subscribe is not properly configured. Please contact API CoE.";
          return;
        }
      });
    },
    notEmpty(value) {
      if (this.serverErrorCreate != null) {
        this.serverErrorCreate = null;
      }
      if (this.serverErrorImport != null) {
        this.serverErrorImport = null;
      }
      if (value == null || value == undefined) {
        return "Required";
      }
      if (value.length == "") {
        return "Required";
      }
      return true;
    },
    nop() {},
    min(v) {
      return (v || "").length >= 5 || `Name must be at least 5 characters long`;
    },
    async createApplication() {
      this.launchRocket();
      var self = this;
      setTimeout(async function () {
        var product = self.getSelectedProduct();
        var newapplicationId = null;

        var message_create = {
          text: "Creating application",
          producttitle: self.appNameCreate,
          state: "pending",
          error: "",
        };
        self.progressMessages.push(message_create);

        var message_subscribe = {
          text: "Subscribing to product",
          producttitle: product.title,
          state: "pending",
          error: "",
        };
        self.progressMessages.push(message_subscribe);
        self.showSubscriptionProgressDialog = true;

        try {
          console.log("Creating application: " + self.appNameCreate);
          message_create.state = "inprogress";
          let response = await cass.createApplication({
            environment: self.selectedEnvironment,
            permissionModel: self.selectedPermissionModel,
            displayName: self.appNameCreate,
          });
          console.log("create application success");
          console.log(response);
          newapplicationId = response.data.applicationId;
          message_create.state = "done";
        } catch (error) {
          console.log("create application failed");
          this.loading = false;
          self.error = true;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            let err = error.response.data.message;
            self.errorMsg = "An error occured: " + err;
            console.log(self.errorMsg);
          } else {
            console.log(error);
            self.errorMsg =
              "An internal error occured. Please try again later.";
          }
          self.showSubscriptionProgressDialog = false;
          return;
        }

        try {
          console.log("Adding subscription to: " + product.title);
          message_subscribe.state = "inprogress";
          let response = await cass.addSubscription(
            newapplicationId,
            product.name
          );
          console.log("add subscriptions success");
          console.log(response);
          message_subscribe.state = "done";
        } catch (error) {
          console.log("add subscriptions failed");
          message_subscribe.state = "error";
          this.loading = false;
          self.error = true;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            let err = error.response.data.message;
            self.errorMsg = "An error occured: " + err;
            console.log(self.errorMsg);
          } else {
            console.log(error);
            self.errorMsg =
              "An internal error occured. Please try again later.";
          }
          self.showSubscriptionProgressDialog = false;
          return;
        }
        // move to application
        self.$router
          .push({
            name: "Application",
            params: { applicationid: newapplicationId },
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }, 1000);
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    back() {
      if (this.$route.name != "Home") {
        this.$router
          .push({
            name: "Home",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    getSelectedProduct() {
      var product = null;
      this.products.forEach((element) => {
        if (element.environment === this.selectedEnvironment) {
          product = element;
        }
      });
      return product;
    },
    async addToApplication() {
      this.launchRocket();
      var self = this;
      setTimeout(async function () {
        var product = self.getSelectedProduct();
        var application = self.filteredApplications[self.selectedApplication];
        var message = {
          text: "Subscribing to product",
          producttitle: product.title,
          state: "pending",
          error: "",
        };
        self.progressMessages.push(message);
        self.showSubscriptionProgressDialog = true;
        try {
          console.log("Adding subscription to: " + product.title);
          message.state = "inprogress";
          let response = await cass.addSubscription(
            application.applicationId,
            product.name
          );
          console.log("add subscriptions success");
          console.log(response);
          message.state = "done";
        } catch (error) {
          console.log("add subscriptions failed");
          console.log(error);
          message.state = "error";
        }
        // move to application
        self.$router
          .push({
            name: "Application",
            params: { applicationid: application.applicationId },
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }, 1000);
    },
    flip() {
      this.launchFire(false);
      this.appNameCreate = null;
      this.selectedApplication = null;
      this.flipCard = !this.flipCard;
    },
  },
};
</script>

<style>
.front,
.back {
  transform-style: unset !important;
  -webkit-transform-style: unset !important;
}

#rocket {
  display: block;
}

/*=============================================
[ Inactive State Styles ]
==============================================*/

.rocket_inner {
  transform: translateY(15px) translateX(-3px);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.icon_circle {
  transition: 0.2s;
  fill: #22303e;
}
.large_window_path {
  transition: 0.2s;
  fill: #22303e;
}
.small_window_path {
  fill: #22303e;
}
.wing_shadow {
  fill: #34495e;
}
.rocket_bottom {
  fill: #34495e;
}
.rocket_base {
  fill: #34495e;
}
.rocket_shadow {
  fill: #34495e;
}
.window_grandient {
  stop-color: #2dcb73;
}
.window_grandient1 {
  stop-color: #2ac16d;
}
.window_grandient2 {
  stop-color: #29b968;
}
.window_grandient3 {
  stop-color: #28b767;
}
.wing_base {
  fill: #34495e;
}
.fire_path {
  fill: #fc0;
}

/*=============================================
[ Hover Styles ]
==============================================*/
.rocket_wrap .rocket_base {
  fill: #e9e9e9 !important;
  stroke-width: 0px !important;
}
.rocket_wrap .rocket_shadow {
  fill: #d3d1d1 !important;
  stroke-width: 0px !important;
}
.rocket_wrap .small_window_path {
  fill: #006c27 !important;
  stroke-width: 0px !important;
}
.rocket_wrap .wing_shadow {
  display: block !important;
  fill: #fc9252 !important;
}
.rocket_wrap .wing_base {
  fill: #e16e36 !important;
  stroke-width: 0px !important;
}
.rocket_wrap .rocket_bottom {
  fill: #006c27 !important;
  stroke-width: 0px !important;
}
.rocket_wrap .large_window_path {
  fill: #006c27 !important;
  stroke-width: 0px !important;
}
.rocket_launch {
  display: block;
  animation-name: rocketMove;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/*=============================================
[ Animation Classes ]
==============================================*/
.fire {
  display: none;
  animation-delay: 0s;
  fill-opacity: 1;
  animation-timing-function: ease-in;
  stroke-width: 0px;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
  animation-direction: normal;
}
.rocket_wrap #fireLeft.fireon {
  display: block;
  animation-delay: 0s;
  animation-name: fireLeft, fillOpacity1;
  animation-duration: 1.2s;
}
.rocket_wrap #fireMiddle.fireon {
  display: block;
  animation-delay: 0s;
  animation-name: fireMiddle, fillOpacity1;
  animation-duration: 1s;
}
.rocket_wrap #fireMiddle.prefireon {
  display: block;
  transform: translateY(-10px);
  animation-delay: 0s;
  animation-name: prefireMiddle;
  animation-duration: 1s;
}
.rocket_wrap #fireRight.fireon {
  display: block;
  animation-delay: 0s;
  animation-name: fireRight, fillOpacity1;
  animation-duration: 1.3s;
}
.rocket_wrap #fireSmallLeft.fireon {
  display: block;
  animation-delay: 0s;
  animation-name: fireSmall, fillOpacity2;
  animation-duration: 1.3s;
  transform-origin: bottom;
}
.rocket_wrap #fireSmallRight.fireon {
  display: block;
  animation-delay: 0.3s;
  animation-name: fireSmall, fillOpacity3;
  animation-duration: 1.6s;
  transform-origin: bottom;
}
/*=============================================
[ KeyFrame Animations ]
==============================================*/
@keyframes fireSmall {
  10% {
    transform: rotate(17deg) translateY(1px);
  }
  20% {
    transform: rotate(-13deg) translateY(2px);
  }
  30% {
    transform: rotate(21deg) translateY(3px);
  }
  40% {
    transform: rotate(-34deg) translateY(4px);
  }
  50% {
    transform: rotate(24deg) translateY(5px);
  }
  60% {
    transform: rotate(-17deg) translateY(6px);
  }
  70% {
    transform: rotate(31deg) translateY(7px);
  }
  80% {
    transform: rotate(-28deg) translateY(8px);
  }
  90% {
    transform: rotate(14deg) translateY(9px);
  }
  99% {
    transform: rotate(0deg) translateY(10px);
  }
}
@keyframes fireLeft {
  6% {
    transform: rotate(25deg);
  }
  15% {
    transform: rotate(-19deg);
  }
  25% {
    transform: rotate(25deg);
  }
  32% {
    transform: rotate(-30deg);
  }
  46% {
    transform: rotate(22deg);
  }
  54% {
    transform: rotate(-29deg);
  }
  61% {
    transform: rotate(32deg);
  }
  74% {
    transform: rotate(-9deg);
  }
  83% {
    transform: rotate(16deg);
  }
  99% {
    transform: rotate(0deg);
  }
}
@keyframes fireMiddle {
  10% {
    transform: rotate(25deg);
  }
  20% {
    transform: rotate(-25deg);
  }
  30% {
    transform: rotate(30deg);
  }
  40% {
    transform: rotate(-22deg);
  }
  50% {
    transform: rotate(29deg);
  }
  60% {
    transform: rotate(-45deg);
  }
  70% {
    transform: rotate(37deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(16deg);
  }
  99% {
    transform: rotate(0deg);
  }
}

@keyframes prefireMiddle {
  10% {
    transform: rotate(5deg) translateY(-10px);
  }
  20% {
    transform: rotate(-5deg) translateY(-10px);
  }
  30% {
    transform: rotate(3deg) translateY(-10px);
  }
  40% {
    transform: rotate(-2deg) translateY(-10px);
  }
  50% {
    transform: rotate(2deg) translateY(-10px);
  }
  60% {
    transform: rotate(-4deg) translateY(-10px);
  }
  70% {
    transform: rotate(3deg) translateY(-10px);
  }
  80% {
    transform: rotate(-1deg) translateY(-10px);
  }
  90% {
    transform: rotate(1deg) translateY(-10px);
  }
  99% {
    transform: rotate(0deg) translateY(-10px);
  }
}

@keyframes fireRight {
  15% {
    transform: rotate(17deg);
  }
  23% {
    transform: rotate(-13deg);
  }
  37% {
    transform: rotate(21deg);
  }
  45% {
    transform: rotate(-34deg);
  }
  54% {
    transform: rotate(24deg);
  }
  67% {
    transform: rotate(-17deg);
  }
  72% {
    transform: rotate(31deg);
  }
  84% {
    transform: rotate(-28deg);
  }
  96% {
    transform: rotate(14deg);
  }
  99% {
    transform: rotate(0deg);
  }
}
@keyframes fillOpacity1 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@keyframes fillOpacity2 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  25% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}
@keyframes fillOpacity3 {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  67% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@keyframes rocketMove {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-1500px);
  }
}
</style>
