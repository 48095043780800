var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { width: "800px", persistent: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text--secondary" }, [
                _vm._v(" Deprecation Details "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text--primary pb-2" },
                [
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.deprecation.proxyName,
                      label: "Proxy Name",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                value: _vm.deprecation.State,
                                label: "Deprecation Status",
                                outlined: "",
                                dense: "",
                                readonly: "",
                                "hide-details": "",
                                color: "#006c27",
                              },
                            },
                            [
                              _vm.deprecation.State == "DEPRECATED"
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { slot: "append", bottom: "" },
                                      slot: "append",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          "margin-top": "2px",
                                                        },
                                                        attrs: { dense: "" },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-help-circle-outline "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2798886857
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Deprecated means, the deprecation time has been reached and "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " it is not possible to subscribe or request permissions "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " anymore, but you can still use the API until the "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " decommissioning time has been reached."
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.deprecation.State == "DECOMMISSONED"
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { slot: "append", bottom: "" },
                                      slot: "append",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          "margin-top": "2px",
                                                        },
                                                        attrs: { dense: "" },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-help-circle-outline "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2798886857
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Decommissioning means, the decommissioning time has been "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " reached and it is not be possible to consume the API anymore"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " and you will not receive data anymore."
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "mt-3",
                            attrs: {
                              value: _vm.formatDate(
                                _vm.deprecation.deprecationDate
                              ),
                              label: "Deprecation Date",
                              outlined: "",
                              dense: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "mt-3",
                            attrs: {
                              value: _vm.formatDate(
                                _vm.deprecation.decommissionDate
                              ),
                              label: "Decomissioning Date",
                              outlined: "",
                              dense: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-textarea", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm._f("decode")(
                        _vm.deprecation.deprecationReason
                      ),
                      label: "Deprecation Reason",
                      outlined: "",
                      dense: "",
                      "auto-grow": "",
                      rows: "1",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _vm.deprecation.successorAPI
                    ? _c("v-text-field", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.deprecation.successorAPI,
                          label: "Successor API",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      })
                    : _vm._e(),
                  _vm.deprecation.successorLink
                    ? _c(
                        "v-text-field",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            value: _vm._f("decode")(
                              _vm.deprecation.successorLink
                            ),
                            label: "Developer Portal Link",
                            outlined: "",
                            dense: "",
                            readonly: "",
                            "hide-details": "",
                            color: "#006c27",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              staticStyle: { "margin-top": "2px" },
                              attrs: { slot: "append", dense: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToLink(
                                    _vm.deprecation.successorLink
                                  )
                                },
                              },
                              slot: "append",
                            },
                            [_vm._v(" mdi-link ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.deprecation.migrationNotes
                    ? _c("v-textarea", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm._f("decode")(
                            _vm.deprecation.migrationNotes
                          ),
                          label: "Migration Recommendation",
                          outlined: "",
                          dense: "",
                          "auto-grow": "",
                          rows: "1",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "#006c27" },
                      on: {
                        click: function ($event) {
                          return _vm.done()
                        },
                      },
                    },
                    [_vm._v(" Done ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }