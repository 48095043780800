var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "500" } },
        [
          _c("v-card-title", [_vm._v(" Secret Details ")]),
          _c(
            "v-card-text",
            { staticClass: "mt-4" },
            [
              _c(
                "v-text-field",
                {
                  ref: "textfield",
                  attrs: {
                    value: _vm.value.secret.secret,
                    label: "Secret",
                    outlined: "",
                    readonly: "",
                    "hide-details": "",
                    color: "#006c27",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "margin-top": "2px" },
                      attrs: { slot: "append", dense: "" },
                      on: {
                        click: function ($event) {
                          return _vm.copy2clipboard(_vm.value.secret.secret)
                        },
                      },
                      slot: "append",
                    },
                    [_vm._v(" mdi-content-copy ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticStyle: { "text-align": "left" } }, [
            _vm._v("This will be the only time you will see your secret."),
            _c("br"),
            _vm._v(" Please copy it and store it safely."),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { id: "doneButton", color: "#006c27" },
                  on: {
                    click: function ($event) {
                      return _vm.done()
                    },
                  },
                },
                [_vm._v(" Done ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }