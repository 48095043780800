<template>
  <v-container fill-height fluid>
    <v-dialog v-model="showInformation" width="800px">
      <v-card>
        <v-card-title>How does it work?</v-card-title>
        <v-card-text
          >The Open Permission Request Page shows you all the permission
          requests which you (or your team) is responsible for.<br />The
          responsibility is derived from your ownership of the Azure App
          Registration the API belongs to.<br />If you are owner of an Azure App
          Registration and this App Registration belongs to an API, you are
          allowed to display the permission requests.</v-card-text
        >
        <v-card-text
          >A Permission request from an API Consumer can have three states
          before it is approved and usable.<br />
          1. The request is open and needs to be granted by the DDM.<br />
          2. The request is granted by the DDM but has not yet a technical
          confirmation.<br />
          3. The request is granted and technically confirmed but the Azure
          Global Admins did not consented yet.</v-card-text
        >
        <v-card-text
          >All three states (which are blocking for the API Consumer) can be
          seen here and revisted.<br />
          If the admin consent needs more 24h, please contact
          api-mgmt-support@schaeffler.com for further
          investigation!</v-card-text
        >
        <v-card-text
          >This page should support you in getting an overview in case your
          permission email is lost.</v-card-text
        >
      </v-card>
    </v-dialog>
    <v-row no-gutters justify="center" align="center">
      <v-card :loading="loading" :width="1200" class="mt-8 mb-8">
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="grey--text text--darken-2">
          Open Permission Requests
          <v-chip
            class="ml-2 white--text"
            color="#006c27"
            small
            @click="showInformation = true"
          >
            What is it?
          </v-chip>
        </v-card-title>
        <v-card-text class="grey--text text--darken-2">
          <div style="display: flex">
            <v-switch
              color="#006c27"
              hide-details
              inset
              :disabled="loading"
              label="Show All APIs"
              v-model="showAll"
              @change="showAllChanged"
            ></v-switch>
          </div>
        </v-card-text>
        <v-card-text class="text--primary my-3">
          <v-data-table
            sort-by="displayName"
            must-sort
            :headers="headers"
            :items="data"
            show-expand
            hide-default-footer
            disable-pagination
            class="elevation-1"
            item-key="Appreg.id"
            @click:row="clickRow"
            :expanded.sync="expanded"
          >
            <template slot="no-data">
              <div v-if="loading">
                Loading permission requests, please wait ...
              </div>
              <div v-else>
                You do not have any open permission requests Great!
              </div>
            </template>

            <template v-slot:[`item.azureURL`]="{ item }">
              <v-chip
                @click="goTo(item.azureURL)"
                outlined
                small
                dark
                color="#006c27"
              >
                Azure Portal
              </v-chip>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :style="{ backgroundColor: getColor }">
                <div class="mt-2 headerline">Requested By</div>
                <template
                  v-for="(permissionrequest, index) in item.PermissionEvents"
                >
                  <div class="my-2" :key="'A' + index">
                    <v-icon class="mr-1" color="#006c27"
                      >mdi-account-circle-outline</v-icon
                    >
                    {{ permissionrequest.events[0].createdBy }}
                  </div>
                </template>
              </td>
              <td :style="{ backgroundColor: getColor }">
                <div class="mt-2 headerline">Requested At</div>
                <template
                  v-for="(permissionrequest, index) in item.PermissionEvents"
                >
                  <div class="my-2" :key="'B' + index">
                    <v-icon class="mr-1" color="#006c27"
                      >mdi-clock-time-eight-outline</v-icon
                    >
                    {{ timeFormat(permissionrequest.events[0].createdAt) }}
                  </div>
                </template>
              </td>
              <td :style="{ backgroundColor: getColor }">
                <div class="mt-2 headerline">Open Permission Request</div>
                <template
                  v-for="(permissionrequest, index) in item.PermissionEvents"
                >
                  <div class="my-2" :key="'C' + index">
                    <v-chip
                      @click="goTo(permissionrequest.permissionURL)"
                      outlined
                      small
                      dark
                      :style="{
                        visibility:
                          permissionrequest.events[0].state != 'REQUESTED'
                            ? 'hidden'
                            : 'unset',
                      }"
                      color="#006c27"
                    >
                      Open
                    </v-chip>
                  </div>
                </template>
              </td>
              <td :style="{ backgroundColor: getColor }">
                <div class="mt-2 headerline">Open Technical Confirm</div>
                <template
                  v-for="(permissionrequest, index) in item.PermissionEvents"
                >
                  <div class="my-2" :key="'C' + index">
                    <v-chip
                      @click="goTo(permissionrequest.permissionURL)"
                      outlined
                      small
                      dark
                      :style="{
                        visibility:
                          permissionrequest.events[0].state !=
                          'APPROVED_BY_API_OWNER'
                            ? 'hidden'
                            : 'unset',
                      }"
                      color="#006c27"
                    >
                      Open
                    </v-chip>
                  </div>
                </template>
              </td>
              <td :style="{ backgroundColor: getColor }">
                <div class="mt-2 headerline">Open Admin Consent</div>
                <template
                  v-for="(permissionrequest, index) in item.PermissionEvents"
                >
                  <div class="my-2" :key="'C' + index">
                    <v-chip
                      @click="goTo(permissionrequest.permissionURL)"
                      outlined
                      small
                      dark
                      :style="{
                        visibility:
                          permissionrequest.events[0].state !=
                          'CREATED_SNOW_TICKET'
                            ? 'hidden'
                            : 'unset',
                      }"
                      color="#006c27"
                    >
                      Open
                    </v-chip>
                    <v-chip
                      class="ml-2"
                      @click="
                        goTo(
                          getSnowTicketLink(
                            permissionrequest.events[0].snowTicketNumber
                          )
                        )
                      "
                      outlined
                      small
                      dark
                      :style="{
                        visibility:
                          permissionrequest.events[0].state !=
                          'CREATED_SNOW_TICKET'
                            ? 'hidden'
                            : 'unset',
                      }"
                      color="#006c27"
                    >
                      {{ permissionrequest.events[0].snowTicketNumber }}
                    </v-chip>
                  </div>
                </template>
              </td>

              <td :style="{ backgroundColor: getColor }"></td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import moment from "moment";

export default {
  name: "OpenPermissionRequests",
  components: {},
  mounted() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      showAll: false,
      showInformation: false,
      data: [],
      rawdata: [],
      expanded: [],
      headers: [
        {
          text: "API Name",
          align: "start",
          value: "displayName",
          width: "30%",
          sortable: true,
        },
        {
          text: "Link to Azure",
          value: "azureURL",
          sortable: true,
        },
        {
          text: "Open Permission Requests",
          value: "openRequests",
          sortable: true,
        },
        {
          text: "Open Technical Confirm",
          value: "openTechnicalConfirm",
          sortable: true,
        },
        {
          text: "Open Admin Consent",
          value: "openAdminConsent",
          sortable: true,
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  filters: {},
  computed: {
    getColor() {
      if (this.$vuetify.theme.dark) {
        return "#2e2e2e";
      } else {
        return "#f3f3f3";
      }
    },
  },
  created() {},
  methods: {
    clickRow(item, event) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(item);
      }
    },
    getSnowTicketLink(ticketId) {
      return (
        process.env.VUE_APP_SNOW_URL +
        "nav_to.do?uri=%2Fincident.do%3Fsys_id%3D" +
        ticketId
      );
    },
    showAllChanged() {
      if (this.data.length != 0) {
        this.processRawData();
      }
    },
    timeFormat(time) {
      return moment(String(time)).format("DD.MM.YYYY HH:mm");
    },
    goTo(url) {
      console.log("Opening URL: " + url);
      window.open(url, "_blank");
    },
    processRawData() {
      this.data = [];
      for (var i = 0; i < this.rawdata.length; i++) {
        let displayname = this.rawdata[i].Appreg.displayName;
        if (
          (displayname.startsWith("odata_") ||
            displayname.startsWith("rest_") ||
            displayname.startsWith("soap_")) &&
          (this.rawdata[i].PermissionEvents.length != 0 || this.showAll)
        ) {
          this.rawdata[i].displayName =
            this.rawdata[i].Appreg.displayName.trim();
          this.rawdata[i].azureURL =
            "https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/" +
            this.rawdata[i].Appreg.appId +
            "/isMSAApp~/false";
          // add the permission URL to the event
          let openRequests = 0;
          let openAdminConsent = 0;
          let openTechnicalConfirm = 0;
          for (let a = 0; a < this.rawdata[i].PermissionEvents.length; a++) {
            this.rawdata[i].PermissionEvents[a].permissionURL =
              window.location.origin +
              "/applications/" +
              this.rawdata[i].PermissionEvents[a].applicationId +
              "/permissions/" +
              this.rawdata[i].PermissionEvents[a].permissionId;

            if (
              this.rawdata[i].PermissionEvents[a].events[0].state == "REQUESTED"
            ) {
              openRequests++;
            } else if (
              this.rawdata[i].PermissionEvents[a].events[0].state ==
              "APPROVED_BY_API_OWNER"
            ) {
              openTechnicalConfirm++;
            } else if (
              this.rawdata[i].PermissionEvents[a].events[0].state ==
              "CREATED_SNOW_TICKET"
            ) {
              openAdminConsent++;
            }
          }
          this.rawdata[i].openRequests = openRequests;
          this.rawdata[i].openAdminConsent = openAdminConsent;
          this.rawdata[i].openTechnicalConfirm = openTechnicalConfirm;
          this.data.push(this.rawdata[i]);
        }
      }
    },
    loadData() {
      console.log("Loading data ...");
      this.loading = true;
      cass
        .getPermissionEvents("REQUESTED")
        .then((response) => {
          console.log("Getting successful response");
          console.log(response);
          this.rawdata = response.data;
          this.processRawData();
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting permission events");
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.headerline {
  font-size: 0.75rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
</style>
