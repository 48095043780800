<template>
  <v-dialog width="100%" v-model="show" persistent max-width="400">
    <v-card>
      <v-card-title>
        <v-icon class="mr-1" color="#006c27">mdi-alarm</v-icon>
        <div class="text--secondary">
          Expiry Notification
        </div>
      </v-card-title>
      <v-card-text class="mt-3">
        <div v-if="mode">
          You are about to <strong>activate</strong> expiry notification!<br />
          This will trigger an calendar event scheduled 1 month before the real
          expiry. This calendar event will be send to
          <strong>all</strong> owners of this application.
        </div>
        <div v-else>
          You are about to <strong>deactivate</strong> expiry notification!<br />
          This will cancel the calendar event scheduled 1 month before the real
          expiry. This calendar event cancelation will be send to
          <strong>all</strong> owners of this application.
        </div>
      </v-card-text>
      <v-card-text>
        <div class="mt-3 d-flex">
          <v-spacer />
          <v-btn class="mr-3" dark color="#006c27" @click="close">
            CLOSE
          </v-btn>
          <v-btn dark color="#006c27" @click="confirm">
            CONFIRM
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ExpiryNotificationConfirm",
  components: {},
  props: {
    value: Boolean,
    mode: Boolean,
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    nop() {},
    close() {
      this.show = false;
    },
    confirm() {
      this.$emit("confirm");
      this.close();
    },
  },
};
</script>

<style scoped></style>
