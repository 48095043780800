<template>
  <v-dialog
    v-if="showPermissionSubmit()"
    width="100%"
    v-model="show"
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-2" color="#006c27" large
          >mdi-information-outline</v-icon
        >
        <div>Permission Request Form</div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text
        class="mt-2"
        v-if="isUserSchaeffler() && application.itSecId"
      >
        <v-row no-gutters>
          <v-col cols="3"> IT Security Demand of Application: </v-col>
          <v-col>
            <a class="link" :href="application.itSecURL" target="_blank"
              >{{ application.itSecId }} - {{ application.itSecTitle }}</a
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3"> Requested Permission: </v-col>
          <v-col>
            <strong>{{ permissionName }}</strong>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        class="mt-2"
        v-if="isUserSchaeffler() && !application.itSecId"
      >
        <v-row justify="center" align="center">
          <v-col cols="1">
            <v-icon class="ml-4" x-large color="red">mdi-alert-outline</v-icon>
          </v-col>
          <v-col>
            <strong>
              <div>
                You did not yet configured the IT Security Demand on this CASS
                application.
              </div>
              <div>This is not recommended!</div>
              <div>
                Please select your IT Security Demand information on the first
                expansion panel.
              </div>
            </strong>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-3">
        This request will be send to the corresponding Data Domain Manager
        (DDM).<br />
        Please provide additional information <strong>why</strong> you need the
        permission: <strong>{{ permissionName }}</strong
        >.
        <v-textarea
          class="mt-4"
          outlined
          v-model="reason"
          :placeholder="permissionRequestText"
          height="200px"
          no-resize
          counter
          :rules="rules"
          color="#006c27"
        >
        </v-textarea>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!submitEnabled"
            color="#006c27"
            class="white--text"
            @click="submit"
            >Submit</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog
    v-else
    v-model="show"
    max-width="450"
    content-class="shadow"
    persistent
  >
    <v-card>
      <v-card-title
        ><v-icon class="mr-1" color="#006c27">mdi-security</v-icon> IT Security
        Warning
      </v-card-title>
      <v-card-text>
        API Permissions on stage production and quality<br />
        can <u>only</u> be requested with a valid IT Security Demand.<br />
        This ensures data protection and data usage transparency.
        <br />
        <br />
        Please step back and use the first expansion panel and select <br />
        the IT Security Demand of <u>your</u> consuming application.
        <br />
        <br />
        If you have trouble getting the point, we are happy <br />
        to help you at
        <a
          class="link"
          target="_blank"
          href="mailto: api-mgmt-support@schaeffler.com"
          >api-mgmt-support@schaeffler.com</a
        >.
        <div class="justify-center d-flex">
          <v-btn
            color="#006c27"
            light
            class="mt-4 white--text"
            @click.stop="close()"
          >
            Got it!
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PermissionSubmit",
  props: {
    value: Boolean,
    permissionName: String,
    application: Object,
    permissionRequestText: String,
  },
  data() {
    return {
      submitEnabled: false,
      reason: null,
      rules: [
        (v) => {
          if (v == null || v == "") {
            this.submitEnabled = false;
            return true;
          }
          if (this.checkIfStringHasSpecialChar(v)) {
            this.submitEnabled = false;
            return "no special characters allowed";
          }
          if (v.length >= 50 && v.length <= 300) {
            this.submitEnabled = true;
            return true;
          }
          this.submitEnabled = false;
          return "please provide min 50 characters, max 300 characters";
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.reason = null;
      } else {
        this.reason = null;
      }
    },
  },
  methods: {
    showPermissionSubmit() {
      if (!this.isUserSchaeffler()) {
        // external users do not have it sec demands - so always show
        return true;
      }
      if (
        this.isUserSchaeffler() &&
        this.application.itSecId &&
        this.application.itSecId != ""
      ) {
        // Schaeffler users having an itSecId are the good ones - always show
        return true;
      }
      if (
        this.isUserSchaeffler() &&
        (!this.application.itSecId || this.application.itSecId == "") &&
        this.application.environment == "DEVELOPMENT"
      ) {
        // DEV is a playground
        return true;
      }
      return false;
    },
    isUserSchaeffler() {
      if (this.$userData.cass.email.includes("@schaeffler.com")) {
        return true;
      }
      return false;
    },
    /**
     * More info about "no-control-regex": https://eslint.org/docs/latest/rules/no-control-regex
     * @param _string
     * @returns {boolean}
     */
    checkIfStringHasSpecialChar(_string) {
      /*eslint-disable-next-line no-control-regex*/
      const asciiOnly = /^[\x00-\x7F]*$/; // allow only ASCII characters
      if (asciiOnly.test(_string)) {
        let spChars = /[#$%^*()_+=[\]{};':"\\|<>]+/
        if (spChars.test(_string)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    close() {
      this.show = false;
    },
    submit() {
      this.$emit("submit", this.reason);
      this.close();
    },
  },
};
</script>

<style>
.link {
  color: #006c27 !important;
  background-color: transparent;
  font-weight: 600;
  text-decoration: none;
}
.shadow1 {
  box-shadow: 0px 0px 50px 0px rgb(255 0 0) !important;
}

.shadow {
  animation: pulsate 3s ease-out infinite;
}

@keyframes pulsate {
  0% {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  50% {
    box-shadow: 0 0 40px 2px red;
  }
  100% {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}
</style>
