<template>
  <v-dialog width="100%" v-model="show" persistent max-width="500">
    <v-card max-width="500">
      <v-card-title>
        Secret Details
      </v-card-title>
      <v-card-text class="mt-4">
        <v-text-field
          ref="textfield"
          :value="value.secret.secret"
          label="Secret"
          outlined
          readonly
          hide-details
          color="#006c27"
        >
          <v-icon
            style="margin-top: 2px;"
            dense
            slot="append"
            @click="copy2clipboard(value.secret.secret)"
          >
            mdi-content-copy
          </v-icon>
        </v-text-field>
      </v-card-text>
      <v-card-text style="text-align: left;"
        >This will be the only time you will see your secret.<br />
        Please copy it and store it safely.</v-card-text
      >
      <v-card-actions>
        <v-spacer />
        <v-btn
          id="doneButton"
          color="#006c27"
          class="white--text"
          @click="done()"
        >
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SecretOverlay",
  props: {
    value: Object,
  },
  computed: {
    show: {
      get() {
        return this.value.show;
      },
    },
  },
  methods: {
    done() {
      this.$emit("close", true);
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style></style>
