var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "ownersList" } },
        [
          _c(
            "div",
            [
              _c("v-icon", [_vm._v(" mdi-account-group-outline ")]),
              _c("span", { staticClass: "ml-1" }, [_vm._v("Owners")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm._l(_vm.application.owners, function (owners, index) {
            return [
              _c(
                "v-list-item",
                { key: index, attrs: { dense: "", selectable: false } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [_c("v-icon", [_vm._v("mdi-account-outline")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c("v-list-item-title", {
                        attrs: { id: "ownerId_" + index },
                        domProps: { textContent: _vm._s(owners) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { id: "deleteOwnerButton_" + index },
                          on: {
                            click: function ($event) {
                              return _vm.deleteOwner($event, owners)
                            },
                          },
                        },
                        [_vm._v("mdi-trash-can-outline")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { key: owners }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c(
                "v-list-item-icon",
                { staticStyle: { "margin-right": "10px" } },
                [_c("v-icon", [_vm._v("mdi-account-outline")])],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticStyle: { width: "100%" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.nop.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.isEmailValid,
                    callback: function ($$v) {
                      _vm.isEmailValid = $$v
                    },
                    expression: "isEmailValid",
                  },
                },
                [
                  _c("v-text-field", {
                    ref: "textfield",
                    attrs: {
                      id: "addOwnerInput",
                      rules: [_vm.rules.emailRules],
                      "error-messages": _vm.severError,
                      counter: "",
                      maxlength: "70",
                      label: "Add Owner",
                      placeholder:
                        "Enter valid email address (please use the short email address for Schaeffler users)",
                      dense: "",
                      color: "#006c27",
                    },
                    model: {
                      value: _vm.addEmail,
                      callback: function ($$v) {
                        _vm.addEmail = $$v
                      },
                      expression: "addEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        id: "addOwnerButton",
                        loading: _vm.loading,
                        disabled: !_vm.isEmailValid,
                      },
                      on: { click: _vm.addOwner },
                      scopedSlots: _vm._u([
                        {
                          key: "loader",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "custom-loader" },
                                [
                                  _c("v-icon", { attrs: { light: "" } }, [
                                    _vm._v("mdi-cached"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("v-icon", [_vm._v("mdi-account-plus-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeleteOwner },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }