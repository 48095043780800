<template>
  <v-app>
    <SchaefflerHeader v-if="!this.$drupal" />

    <v-main>
      <v-banner v-if="env != null" color="red" single-line>
        <div
          @click="env = null"
          class="white--text"
          style="text-align: center;"
        >
          🔥 ATTENTION! YOU ARE ON STAGE {{ env }}. ONLY SELECT ENVIRONMENT
          {{ env }}, OR LOWER, WITHIN THE APPLICATION 🔥
        </div>
      </v-banner>
      <router-view></router-view>
    </v-main>

    <SchaefflerFooter v-if="!this.$drupal" />
    <idle-overlay v-model="idle" v-if="!this.$drupal"></idle-overlay>
    <status-overlay v-model="statuscheck"></status-overlay>
  </v-app>
</template>

<script>
import SchaefflerHeader from "./components/SchaefflerHeader";
import SchaefflerFooter from "./components/SchaefflerFooter";
import IdleOverlay from "./components/IdleOverlay.vue";
import StatusOverlay from "./components/StatusOverlay.vue";
import cass from "./api/cass.js";
import Vue from "vue";

export default {
  name: "App",

  components: {
    SchaefflerHeader,
    SchaefflerFooter,
    IdleOverlay,
    StatusOverlay,
  },
  onIdle() {
    if (this.$route.name !== "Logout") {
      console.log("onIdle");
      this.idle = true;
    }
  },
  onActive() {
    if (this.$route.name !== "Logout") {
      console.log("onActive");
      this.idle = false;
    }
  },
  methods: {
    checkSAPStatus() {
      if (Vue.prototype.$authService.isUserLoggedIn()) {
        //console.debug("Checking Status ...");
        var statusOverall = true;
        cass
          .getSAPStatus()
          .then((response) => {
            console.debug(response);
            Vue.prototype.$sap = {
              status: response.data,
              timestamp: new Date(),
            };
            for (let i = 0; i < response.data.length; i++) {
              if (
                this.$userData.cass.environments.includes(
                  response.data[i].environment.toLowerCase()
                ) &&
                response.data[i].status != "ONLINE"
              ) {
                statusOverall = false;
                break;
              }
            }
          })
          .catch((error) => {
            console.debug(error);
            Vue.prototype.$sap.status = [
              { environment: "DEVELOPMENT", status: "ERROR" },
              { environment: "QUALITY", status: "ERROR" },
              { environment: "PRODUCTION", status: "ERROR" },
            ];
            if (this.$userData.cass.isUserAdmin) {
              return;
            }
            statusOverall = false;
          })
          .finally(() => {
            if (this.$userData.cass.isUserAdmin) {
              return;
            }
            //console.debug("Setting statuscheck to: " + !statusOverall);
            this.statuscheck = !statusOverall;
          });
      }
    },
  },
  mounted() {
    console.log("Main App mounted");
    if (process.env.VUE_APP_CASS_URL.includes("api-qas")) {
      this.env = "QUALITY";
    } else if (process.env.VUE_APP_CASS_URL.includes("api-dev")) {
      this.env = "DEVELOPMENT";
    } else if (process.env.VUE_APP_CASS_URL.includes("localhost")) {
      this.env = "LOCALHOST";
    }

    Vue.prototype.$sap = {
      status: null,
      timestamp: -1,
    };

    this.$emitter.on("login", () => {
      if (this.$userData.cass.environments.length == 0) {
        console.log("Did not find any user environments, disable sap checker");
        return;
      }
      if (!this.statuscheckActive) {
        console.log("starting the cyclic sap status checker");
        this.checkSAPStatus();
        this.statuscheckActive = true;
        let self = this;
        setInterval(function() {
          self.checkSAPStatus();
        }, process.env.VUE_APP_STATUS_CHECK);
      }
    });
  },
  data: () => ({
    idle: false,
    statuscheck: false,
    statuscheckActive: false,
    env: null,
  }),
};
</script>

<style scoped></style>
