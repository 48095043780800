var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-8 mb-8",
              attrs: { loading: _vm.loading, width: 1200 },
            },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "#006c27",
                      height: "10",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "grey--text text--darken-2" },
                [
                  _c("div", [_vm._v("Deleted CASS Applications")]),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    staticStyle: { "padding-top": "0", "margin-top": "0" },
                    attrs: {
                      label: "Search",
                      "single-line": "",
                      color: "#006c27",
                      "hide-details": "",
                    },
                    on: { keyup: _vm.applyFilter },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "text--primary my-3" },
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "elevation-1",
                      attrs: {
                        "sort-by": "displayName",
                        "custom-sort": _vm.customSort,
                        "must-sort": "",
                        headers: _vm.headers,
                        items: _vm.filteredApplications,
                        "item-key": "apiMgmtApplicationId",
                        "hide-default-footer":
                          _vm.filteredApplications.length <= 10,
                      },
                      on: { "click:row": _vm.rowClicked },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: `item.environment`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: _vm.getEnvironmentColor(
                                        item.environment
                                      ),
                                      dark: "",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.environment) + " ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _vm.loading
                          ? _c("div", [
                              _vm._v(
                                " Loading deleted applications, please wait ... "
                              ),
                            ])
                          : _c("div", [_vm._v("No deleted CASS Apps found!")]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("deleted-application-overlay", {
        attrs: { application: _vm.selectedApplication },
        on: { update: _vm.loadData },
        model: {
          value: _vm.showInfo,
          callback: function ($$v) {
            _vm.showInfo = $$v
          },
          expression: "showInfo",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }