var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _vm._v(
          " Permission Models are perhaps the most tricky part to understand in the API world. We will try to keep it simple. APIs can provide their service in Application and/or Delegated Permission Model. Thereby, a permission model is in the first place a feature that an API provides. "
        ),
      ]),
      _c("div", { staticClass: "headline mt-3" }, [
        _vm._v("Application Permission Model"),
      ]),
      _c("div", [
        _vm._v(
          " In the Application Permission Model the API (or an endpoint of the API) is designed to expect a system-user. In comparision - no real user (like you or me) is supported. This is frequently a requirement for backends, daemons or any headless applications where no real users are involved. So - if you want to implement an application with this requirement and want to consume a Schaeffler API inside this application - you definitely need this Permission Model. Need more technical depth? Look at "
        ),
        _c(
          "span",
          {
            staticStyle: { color: "#006c27" },
            on: {
              click: function ($event) {
                _vm.appOverlay1 = true
              },
            },
          },
          [_vm._v("this")]
        ),
        _vm._v(" and "),
        _c(
          "span",
          {
            staticStyle: { color: "#006c27" },
            on: {
              click: function ($event) {
                _vm.appOverlay2 = true
              },
            },
          },
          [_vm._v("this")]
        ),
        _vm._v(". "),
      ]),
      _c("div", { staticClass: "headline mt-3" }, [
        _vm._v("Delegated Permission Model"),
      ]),
      _c("div", [
        _vm._v(
          " In the Delegated Permission Model the API (or an endpoint of the API) is designed to expect real-users. Again, in comparision - no system-users are supported. This is frequently a requirement for web, mobile or desktop applications where real-users need to be logged in. So - if you want to implement an application with this requirement and want to consume a Schaeffler API inside this application - you definitely need this Permission Model. Need more technical depth? Look at "
        ),
        _c(
          "span",
          {
            staticStyle: { color: "#006c27" },
            on: {
              click: function ($event) {
                _vm.appOverlay3 = true
              },
            },
          },
          [_vm._v("this")]
        ),
        _vm._v(". "),
      ]),
      _vm._m(0),
      _c(
        "v-overlay",
        {
          nativeOn: {
            click: function ($event) {
              _vm.appOverlay1 = false
            },
          },
          model: {
            value: _vm.appOverlay1,
            callback: function ($$v) {
              _vm.appOverlay1 = $$v
            },
            expression: "appOverlay1",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-img", {
                        staticClass: "my-3",
                        attrs: {
                          contain: "",
                          src: _vm.$getImagePath(
                            "ApplicationPermissionModel1.png"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          nativeOn: {
            click: function ($event) {
              _vm.appOverlay2 = false
            },
          },
          model: {
            value: _vm.appOverlay2,
            callback: function ($$v) {
              _vm.appOverlay2 = $$v
            },
            expression: "appOverlay2",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-img", {
                        staticClass: "my-3",
                        attrs: {
                          contain: "",
                          src: _vm.$getImagePath(
                            "ApplicationPermissionModel2.png"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          nativeOn: {
            click: function ($event) {
              _vm.appOverlay3 = false
            },
          },
          model: {
            value: _vm.appOverlay3,
            callback: function ($$v) {
              _vm.appOverlay3 = $$v
            },
            expression: "appOverlay3",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-img", {
                        staticClass: "my-3",
                        attrs: {
                          contain: "",
                          src: _vm.$getImagePath(
                            "DelegatedPermissionModel.png"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-3" }, [
      _vm._v(" Get more information at our Microsoft Teams channel "),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            href: "https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
            target: "_blank",
          },
        },
        [_vm._v("API@Schaeffler (Browser)")]
      ),
      _vm._v(" or "),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            href: "msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
            target: "_blank",
          },
        },
        [_vm._v("API@Schaeffler (Teams)")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }