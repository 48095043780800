<template>
  <v-dialog width="100%" v-if="show" v-model="show" persistent max-width="500">
    <v-card max-width="500">
      <v-card-title class="text--secondary">
        <v-icon class="mr-3">mdi-card-account-details-outline</v-icon>
        User Details
      </v-card-title>
      <v-card-text class="text--primary pb-2">
        <v-text-field
          :value="userCopy.email"
          label="eMail"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
        </v-text-field>
        <v-textarea
          :value="userCopy.firstname"
          label="Firstname"
          outlined
          dense
          readonly
          hide-details
          :auto-grow="true"
          rows="1"
          class="mt-3"
          color="#006c27"
        ></v-textarea>
        <v-text-field
          :value="userCopy.lastname"
          label="Lastname"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-text-field>
        <v-text-field
          :value="userCopy.roles || 'never logged in'"
          label="Roles"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-text-field>
        <v-text-field
          :value="userCopy.environments"
          label="Environments"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-text-field>
        <div class="d-flex">
          <v-text-field
            :value="userCopy.applicationLimit"
            label="Application Limit"
            outlined
            dense
            readonly
            hide-details
            class="mt-3"
            color="#006c27"
          ></v-text-field>
          <v-icon
            ref="incAppLimit"
            class="ml-2 mt-3"
            large
            color="#006c27"
            dark
            v-blur
            @click="incAppLimit"
          >
            mdi-plus-circle-outline
          </v-icon>
          <v-icon
            v-blur
            class="mt-3"
            large
            color="#006c27"
            dark
            @click="decAppLimit"
            ref="decAppLimit"
          >
            mdi-minus-circle-outline
          </v-icon>
        </div>
        <div class="d-flex">
          <v-text-field
            :value="userCopy.certificateLimit"
            label="Certificate Limit"
            outlined
            dense
            readonly
            hide-details
            class="mt-3"
            color="#006c27"
          ></v-text-field>
          <v-icon
            class="ml-2 mt-3"
            large
            v-blur
            color="#006c27"
            dark
            @click="incCertLimit"
            ref="incCertLimit"
          >
            mdi-plus-circle-outline
          </v-icon>
          <v-icon
            class="mt-3"
            v-blur
            large
            color="#006c27"
            dark
            @click="decCertLimit"
            ref="decCertLimit"
          >
            mdi-minus-circle-outline
          </v-icon>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="#006c27" class="white--text mr-2 mt-2" @click="done()">
          {{ actionLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "UserDetailOverlay",
  props: {
    value: Boolean,
    user: Object,
  },
  data() {
    return {
      userCopy: null,
      actionLabel: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(show) {
      if (show) {
        console.log("User Dialog opened");
        this.actionLabel = "Close";
        this.userCopy = { ...this.user };
      }
    },
  },
  methods: {
    incAppLimit() {
      if (this.userCopy.applicationLimit == 30) {
        this.$refs.incAppLimit.$el.classList.add("shake");
        setTimeout(() => {
          this.$refs.incAppLimit.$el.classList.remove("shake");
        }, 1000);
        return;
      }
      this.userCopy.applicationLimit++;
      this.updateAction();
    },
    incCertLimit() {
      if (this.userCopy.certificateLimit == 15) {
        this.$refs.incCertLimit.$el.classList.add("shake");
        setTimeout(() => {
          this.$refs.incCertLimit.$el.classList.remove("shake");
        }, 1000);
        return;
      }
      this.userCopy.certificateLimit++;
      this.updateAction();
    },
    decAppLimit() {
      if (this.userCopy.applicationLimit == 0) {
        this.$refs.decAppLimit.$el.classList.add("shake");
        setTimeout(() => {
          this.$refs.decAppLimit.$el.classList.remove("shake");
        }, 1000);
        return;
      }
      this.userCopy.applicationLimit--;
      this.updateAction();
    },
    decCertLimit() {
      if (this.userCopy.certificateLimit == 0) {
        this.$refs.decCertLimit.$el.classList.add("shake");
        setTimeout(() => {
          this.$refs.decCertLimit.$el.classList.remove("shake");
        }, 1000);
        return;
      }
      this.userCopy.certificateLimit--;
      this.updateAction();
    },
    updateAction() {
      console.log(this.userCopy.applicationLimit);
      console.log(this.user.applicationLimit);
      if (
        this.userCopy.applicationLimit != this.user.applicationLimit ||
        this.userCopy.certificateLimit != this.user.certificateLimit
      ) {
        this.actionLabel = "Save";
      } else {
        this.actionLabel = "Close";
      }
    },
    done() {
      if (this.actionLabel == "Save") {
        cass
          .patchUser(this.userCopy)
          .then((response) => {
            console.log(response);
            this.$emit("saved", "User updated!");
          })
          .catch((error) => {
            console.log(error);
            this.$emit("saved", "User update failed!");
          });
      }
      this.show = false;
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  color: red !important;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
