<template>
  <v-menu nudge-bottom="30px" v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :dot="true"
        color="red"
        :value="status.statusOverride != undefined"
        offset-x="-2px"
        offset-y="11px"
      >
        <div v-bind="attrs" v-on="on" :class="getAlarmTextClass()">
          {{ status.status }}
        </div>
      </v-badge>
    </template>
    <v-card>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <div style="font-size: 1rem;">
              Status Overwrite
            </div>
          </v-col>
        </v-row>
        <v-row v-if="status.actualStatus != undefined" no-gutters class="mt-5">
          <v-col>
            <v-text-field
              readonly
              outlined
              color="#006c27"
              dense
              label="Actual Status"
              :value="status.actualStatus"
              hide-details=""
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col>
            <v-select
              v-model="selectedOverwrite"
              :items="overwrites"
              label="Status Overwrite"
              outlined
              color="#006c27"
              hide-details
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="submitDisabled()"
          @click="submit"
          color="#006c27"
          class="white--text mr-2 mb-2"
          >submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "StatusOverwrite",
  props: {
    status: Object,
  },
  data() {
    return {
      menu: false,
      selectedOverwrite: null,
      overwrites: ["no overwrite", "ONLINE", "OFFLINE", "WARNING"],
    };
  },
  watch: {
    menu(menu) {
      if (menu) {
        console.log("menu watcher");
        if (this.status.statusOverride != undefined) {
          this.selectedOverwrite = this.status.statusOverride;
        } else {
          this.selectedOverwrite = "no overwrite";
        }
      }
    },
    status() {
      console.log("status watcher");
      if (this.status.statusOverride != undefined) {
        this.selectedOverwrite = this.status.statusOverride;
      } else {
        this.selectedOverwrite = "no overwrite";
      }
    },
  },
  mounted() {
    console.log("StatusOverwrite mounted");
    if (this.status.statusOverride != undefined) {
      this.selectedOverwrite = this.status.statusOverride;
    } else {
      this.selectedOverwrite = "no overwrite";
    }
  },
  computed: {},
  methods: {
    submitDisabled() {
      if (this.selectedOverwrite == status.statusOverride) {
        return true;
      }
      return false;
    },
    submit() {
      var request_status = [];
      if (this.selectedOverwrite == "no overwrite") {
        request_status.push({
          environment: this.status.environment,
        });
      } else {
        request_status.push({
          environment: this.status.environment,
          statusOverride: this.selectedOverwrite,
        });
      }
      cass
        .patchSAPStatus(request_status)
        .then((response) => {
          console.log(response);
          this.$sap.status = response.data;
          this.$sap.timestamp = new Date();
        })
        .catch((error) => {
          console.error(error);
        });
      this.menu = false;
    },
    getAlarmTextClass() {
      var rc = "statusindicator";
      if (this.status == null) {
        return rc;
      }
      if (this.status == "OFFLINE") {
        rc += " alarmtext";
      }
      if (this.status == "WARNING") {
        rc += " warntext";
      }
      return rc;
    },
  },
};
</script>

<style scoped>
.statusindicator {
  font-size: 1rem;
}

.alarmtext {
  color: red;
}

.warntext {
  color: orange;
}
</style>
