<template>
  <v-dialog v-model="show" width="800">
    <v-card>
      <v-card-title class="text--secondary">
        <div>
          Environments
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="text--primary">
        <environment-content></environment-content>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EnvironmentContent from "./EnvironmentContent.vue";
export default {
  components: { EnvironmentContent },
  name: "EnvironmentDialog",
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.show = false;
    },
  },
};
</script>

<style></style>
