<template>
  <v-dialog width="100%" v-model="show" persistent max-width="800">
    <v-card>
      <v-card-title>
        <v-icon large class="mr-3" color="#006c27">mdi-face-agent</v-icon>
        <div style="color: #616161">Subscription Assistance</div>
      </v-card-title>
      <v-card-text class="mt-3">
        <div class="ml-1 mb-2">
          Please confirm the APIs you can not find in the API catalog:
        </div>
        <template v-for="(product, index) in products">
          <v-checkbox
            color="#006c27"
            v-model="confirmedProducts"
            dense
            hide-details
            :key="index"
            :label="product.title + ' (' + product.name + ')'"
            :value="product.name"
          ></v-checkbox>
        </template>
        <div class="mt-4">Please provide additional information</div>
        <v-textarea
          counter
          :rules="rules"
          color="#006c27"
          :no-resize="true"
          outlined
          dense
          v-model="reasonText"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn
          color="#006c27"
          class="white--text"
          @click="submit"
          :disabled="!submitEnabled"
          >submit</v-btn
        >
        <v-btn color="#006c27" class="white--text" @click="close">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SubAssistanceDialog",
  props: {
    value: Boolean,
    products: Array,
  },
  data() {
    return {
      reasonText: "",
      confirmedProducts: [],
      submitEnabled: false,
      rules: [
        (v) => {
          if (v == null || v == "" || this.confirmedProducts.length == 0) {
            this.submitEnabled = false;
            return true;
          }
          if (this.checkIfStringHasSpecialChar(v)) {
            this.submitEnabled = false;
            return "no special characters allowed";
          }
          if (v.length >= 50 && v.length <= 300) {
            this.submitEnabled = true;
            return true;
          }
          this.submitEnabled = false;
          return "please provide min 50 characters, max 300 characters";
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.reasonText = "";
        this.confirmedProducts = [];
        this.submitEnabled = false;
      }
    },
    confirmedProducts() {
      this.rules[0](this.reasonText);
    },
  },
  methods: {
    /**
     * More info about "no-control-regex": https://eslint.org/docs/latest/rules/no-control-regex
     * @param _string
     * @returns {boolean}
     */
    checkIfStringHasSpecialChar(_string) {
      /*eslint-disable-next-line no-control-regex*/
      const asciiOnly = /^[\x00-\x7F]*$/; // allow only ASCII characters
      if (asciiOnly.test(_string)) {
        let spChars = /[#$%^*()_+=[\]{};':"\\|<>]+/
        if (spChars.test(_string)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    submit() {
      this.close();
      var body = {
        userText: window.btoa(this.reasonText),
        affectedProducts: this.confirmedProducts,
      };
      this.$emit("submit", body);
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
