var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500", height: "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { width: "500", height: "500" } },
        [
          _c("v-card-title", [_vm._v(" Are you still there? ")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v(" You will be logged out in 60 seconds "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-8" },
            [
              _c(
                "v-col",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        align: "center",
                        size: 270,
                        width: 23,
                        color: "#006c27",
                      },
                      model: {
                        value: _vm.timer,
                        callback: function ($$v) {
                          _vm.timer = $$v
                        },
                        expression: "timer",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-h1" }, [
                        _vm._v(_vm._s(_vm.countdown)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }