var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    { attrs: { dark: false, absolute: false, value: _vm.show } },
    [
      _vm.application
        ? _c(
            "v-card",
            { staticClass: "d-flex flex-column", attrs: { width: "1000" } },
            [
              _c(
                "v-card-title",
                [
                  _c("div", {}, [_vm._v("Deleted Application Details")]),
                  _c("v-spacer"),
                  _c("v-spacer"),
                  _vm.application != null
                    ? _c(
                        "v-chip",
                        {
                          attrs: {
                            id: "applicationEnvironment",
                            outlined: "",
                            color: _vm.getEnvironmentColor(
                              _vm.application.environment
                            ),
                            dark: "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.application.environment) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "applicationDisplayName",
                              value: _vm.application.displayName,
                              label: "Name",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                id: "applicationId",
                                value: _vm.application.applicationId,
                                label: "CASS Application ID",
                                outlined: "",
                                readonly: "",
                                "hide-details": "",
                                color: "#006c27",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { "margin-top": "2px" },
                                  attrs: { slot: "append", dense: "" },
                                  on: { click: _vm.linkAzureAppReg },
                                  slot: "append",
                                },
                                [_vm._v(" mdi-link ")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "margin-top": "2px" },
                                  attrs: { slot: "append", dense: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copy2clipboard(
                                        _vm.application.applicationId
                                      )
                                    },
                                  },
                                  slot: "append",
                                },
                                [_vm._v(" mdi-content-copy ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.$userData.cass.isUserAdmin
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    id: "applicationApiMgmtApplicationId",
                                    value: _vm._f("formatStringToLowerCase")(
                                      _vm.application.apiMgmtApplicationId
                                    ),
                                    label: "SAP Application ID",
                                    outlined: "",
                                    readonly: "",
                                    "hide-details": "",
                                    color: "#006c27",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      staticStyle: { "margin-top": "2px" },
                                      attrs: { slot: "append", dense: "" },
                                      on: { click: _vm.linkDeveloperPortal },
                                      slot: "append",
                                    },
                                    [_vm._v(" mdi-link ")]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { "margin-top": "2px" },
                                      attrs: { slot: "append", dense: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy2clipboard(
                                            _vm.application.apiMgmtApplicationId
                                          )
                                        },
                                      },
                                      slot: "append",
                                    },
                                    [_vm._v(" mdi-content-copy ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "applicationCreateDate",
                              value: _vm._f("formatDate")(
                                _vm.application.createDate
                              ),
                              label: "Created At",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "applicationPermissionModel",
                              value: _vm._f("formatString")(
                                _vm.application.permissionModel
                              ),
                              label: "Permission Model",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "applicationState",
                              value: _vm._f("formatString")(
                                _vm.application.state
                              ),
                              label: "Status",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm._f("formatDate")(
                                _vm.application.DeletedAt
                              ),
                              label: "Deleted At",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.application.DeletedBy,
                              label: "Deleted By",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm._f("formatSafeDelete")(
                                _vm.application.SafeDelete
                              ),
                              label: "Safe Delete",
                              outlined: "",
                              readonly: "",
                              "hide-details": "",
                              color: "#006c27",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                value: _vm._f("formatITSec")(_vm.application),
                                label: "IT Security Demand",
                                outlined: "",
                                readonly: "",
                                "hide-details": "",
                                color: "#006c27",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { "margin-top": "2px" },
                                  attrs: { slot: "append", dense: "" },
                                  on: { click: _vm.linkITSec },
                                  slot: "append",
                                },
                                [_vm._v(" mdi-link ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.application.owners,
                              label:
                                "Owners(" + _vm.application.owners.length + ")",
                              outlined: "",
                              "hide-details": "",
                              color: "#006c27",
                              "item-color": "#006c27",
                            },
                            model: {
                              value: _vm.firstowner,
                              callback: function ($$v) {
                                _vm.firstowner = $$v
                              },
                              expression: "firstowner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-1 mb-2",
                      attrs: {
                        disabled: _vm.application.SafeDelete == false,
                        color: "#006c27",
                      },
                      on: { click: _vm.realDelete },
                    },
                    [_vm._v(" Delete ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-2 mb-2",
                      attrs: { color: "#006c27" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-overlay",
        {
          attrs: {
            dark: false,
            absolute: true,
            value: _vm.deleting,
            opacity: "0.1",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          align: "center",
                          size: 220,
                          width: 13,
                          color: "#006c27",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000, bottom: "", color: "red" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.serverErrorDelete) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }