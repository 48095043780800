<template>
  <v-dialog width="100%" v-model="show" persistent max-width="700">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
          color="#006c27"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>
        <div style="color: #616161">Subscribe to API</div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-text-field
          class="pl-1 pr-5 pb-5"
          v-model="search"
          @input="filterData"
          label="Search"
          single-line
          :disabled="showSelected"
          color="#006c27"
          hide-details
        >
          <template slot="append">
            <v-tooltip v-if="search == ''" left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
              </template>
              <span>You can wildcard search for product or API name.</span>
            </v-tooltip>
            <v-icon
              v-if="search != ''"
              @click="
                search = '';
                filterData();
              "
              >mdi-close</v-icon
            >
          </template>
        </v-text-field>
        <div style="overflow: hidden">
          <div style="max-height: 429px; height: 429px; overflow-y: scroll">
            <div v-if="productsRaw.length == 0">
              <v-container fill-height fluid>
                <v-row>
                  <v-col align="center" justify="center">
                    <div class="mt-14 text-h5">Loading Products ...</div>
                    <div class="">Please standby</div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div v-if="products.length == 0 && productsRaw.length != 0">
              <v-container fill-height fluid>
                <v-row>
                  <v-col align="center" justify="center">
                    <div class="mt-14 text-h5">No results</div>
                    <div class="">Please try again</div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-expansion-panels
              v-model="expanded"
              :multiple="false"
              class="pl-1 pr-5 pt-2 pb-2"
            >
              <template v-for="product in products">
                <v-expansion-panel :key="'P' + product.name">
                  <v-expansion-panel-header class="pl-3">
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox
                          v-model="selected"
                          color="#006c27"
                          :value="product.name"
                          :indeterminate="!product.valid"
                          :disabled="!product.valid"
                          hide-details
                          class="shrink mr-2 mt-0"
                          @click.native="calcApplyDisabled($event)"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col class="mt-1">
                        <v-icon>mdi-package-variant-closed</v-icon>
                        {{ product.title }}</v-col
                      >
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="padding-left: 0px">
                    <template v-for="(proxy, index) in product.apiProxies">
                      <v-list-item
                        class="ml-8"
                        dense
                        :key="product.name + proxy.name + index"
                        :selectable="false"
                      >
                        <v-list-item-icon style="margin-right: 10px">
                          <v-icon>mdi-api</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="text-align: left">
                          <v-row class="">
                            <v-col cols="">
                              {{ proxy.name }}
                              <v-chip
                                x-small
                                outlined
                                @click="showDeprecation(proxy.deprecation)"
                                color="red"
                                v-if="proxy.deprecation"
                              >
                                {{
                                  getDeprecationChipText(
                                    proxy.deprecation.State
                                  )
                                }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-checkbox
          class="ml-6"
          v-model="showSelected"
          color="#006c27"
          label="Show selected"
          @change="filterData"
        ></v-checkbox>
        <v-checkbox
          class="ml-6"
          v-model="showAll"
          color="#006c27"
          label="Show All"
          :disabled="showSelected"
          @change="filterData"
        ></v-checkbox>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mb-1" color="#006c27" small v-bind="attrs" v-on="on"
              >mdi-help</v-icon
            >
          </template>
          <span v-html="getHelp()"></span>
        </v-tooltip>
        <v-spacer />
        <v-btn
          id="doneButton"
          color="#006c27"
          class="white--text"
          :disabled="applyDisabled"
          @click="apply()"
        >
          Apply
        </v-btn>
        <v-btn
          id="cancelButton"
          color="#006c27"
          class="white--text mr-7"
          @click="close()"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <deprecation-detail-overlay
      v-model="showDeprecationDetail"
      :deprecation="selectedDeprecation"
      @close="showDeprecationDetail = false"
    >
    </deprecation-detail-overlay>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";
import DeprecationDetailOverlay from "./DeprecationDetailOverlay.vue";

export default {
  name: "SubProductsOverlay",
  components: { DeprecationDetailOverlay },
  props: {
    value: Boolean,
    application: Object,
  },
  data: () => ({
    showDeprecationDetail: false,
    selectedDeprecation: null,
    loading: false,
    error: null,
    products: [],
    productsRaw: [],
    selected: [],
    search: "",
    expanded: [],
    showSelected: false,
    showAll: false,
    applyDisabled: true,
  }),
  watch: {
    value: function (newVal) {
      if (newVal) {
        this.loadData();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getDeprecationChipText(state) {
      if (state == "ACTIVE") {
        return "Scheduled";
      }
      return state.charAt(0) + state.substring(1).toLowerCase();
    },
    showDeprecation(deprecation) {
      console.log(deprecation);
      this.selectedDeprecation = deprecation;
      this.showDeprecationDetail = true;
    },
    filterData() {
      if (this.showSelected) {
        this.showAll = false;
      }

      this.products = this.productsRaw.filter((element) => {
        if (this.showSelected) {
          return this.selected.find(
            (selected_element) => selected_element == element.name
          );
        }
        if (!this.showAll && !element.valid) {
          return false;
        }

        let found = false;
        found = element.title.toLowerCase().includes(this.search.toLowerCase());
        if (found) {
          return true;
        }

        found = undefined;
        found = element.apiProxies.find((proxy) =>
          proxy.name.toLowerCase().includes(this.search.toLowerCase())
        );
        if (found) {
          return true;
        }

        return element.apiProxies.find((proxy) =>
          proxy.title.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    close() {
      console.log("Closing Subscribe Dialog");
      this.show = false;
    },
    calcApplyDisabled(evt) {
      console.log("Evaluate apply disable");
      evt.stopPropagation();

      if (this.selected.length == 0) {
        console.log("nothing selected");
        this.applyDisabled = true;
        return;
      }
      if (this.selected.length == this.application.subscriptions.length) {
        for (var i = 0; i < this.application.subscriptions.length; i++) {
          let element_subscribed = this.application.subscriptions[i];
          for (var j = 0; j < this.selected.length; j++) {
            let element_selected = this.selected[j];
            let found = false;
            if (element_selected == element_subscribed.name) {
              found = true;
            }
            if (!found) {
              console.log("same length, but not equal");
              this.applyDisabled = false;
              return;
            }
          }
        }
        console.log("both equal");
        this.applyDisabled = true;
        return;
      }
      console.log("something changed");
      this.applyDisabled = false;
    },
    apply() {
      this.close();
      // copy over whole product object
      var selectedProducts = [];
      this.selected.forEach((productname) => {
        var product = this.productsRaw.find(
          (product) => product.name === productname
        );
        selectedProducts.push(product);
      });
      this.$emit("apply", selectedProducts);
    },
    getHelp() {
      return "By default, this list only shows active products with </br>the permission model of your application.</br> You can still show all - but you are not able to select them.";
    },
    loadData() {
      this.products = [];
      this.productsRaw = [];
      this.search = "";
      this.selected = [];
      this.expanded = [];
      this.showAll = false;
      this.showSelected = false;
      this.applyDisabled = true;
      console.log("Loading products for env: " + this.application.environment);
      this.loading = true;
      cass
        .getProducts(this.application.environment)
        .then(async (response) => {
          console.log(response);
          response.data.sort((a, b) => {
            return a.title
              .toLowerCase()
              .localeCompare(b.title.toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          });

          this.application.subscriptions.forEach((element) => {
            this.selected.push(element.name);
          });

          for (
            let product_index = 0;
            product_index < response.data.length;
            product_index++
          ) {
            // Check for deprecations
            let foundNotDeprecatedProxy = false;
            let product = response.data[product_index];
            product.valid = false;
            for (
              let proxy_index = 0;
              proxy_index < product.apiProxies.length;
              proxy_index++
            ) {
              let proxy = product.apiProxies[proxy_index];
              if (proxy.deprecation == undefined || proxy.deprecation == null) {
                foundNotDeprecatedProxy = true;
              }
              if (proxy.deprecation && proxy.deprecation.State === "ACTIVE") {
                foundNotDeprecatedProxy = true;
              }

              for (
                let property_index = 0;
                property_index < proxy.proxyEndPoints[0].properties.length;
                property_index++
              ) {
                let property =
                  proxy.proxyEndPoints[0].properties[property_index];
                if (
                  property.name == "PERMISSION_MODEL" &&
                  (property.value == this.application.permissionModel ||
                    property.value == "BOTH")
                ) {
                  console.log("Pushing: " + product.name);
                  product.valid = true;
                  break;
                }
              }
            }
            if (!foundNotDeprecatedProxy) {
              // if it is selected - leave it
              let found = this.selected.find(
                (element) => element === product.name
              );
              if (!found) {
                console.log(
                  "Removing (all proxies deprecated): " + product.name
                );
                product.valid = false;
              }
            }
            this.productsRaw.push(product);
          }

          this.filterData();
        })
        .catch((error) => {
          let casserror = cass.handleError(error);
          console.log(casserror);
          this.error = casserror;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
