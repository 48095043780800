<template>
  <div>
    <div>
      Permission Models are perhaps the most tricky part to understand in the
      API world. We will try to keep it simple. APIs can provide their service
      in Application and/or Delegated Permission Model. Thereby, a permission
      model is in the first place a feature that an API provides.
    </div>
    <div class="headline mt-3">Application Permission Model</div>
    <div>
      In the Application Permission Model the API (or an endpoint of the API) is
      designed to expect a system-user. In comparision - no real user (like you
      or me) is supported. This is frequently a requirement for backends,
      daemons or any headless applications where no real users are involved. So
      - if you want to implement an application with this requirement and want
      to consume a Schaeffler API inside this application - you definitely need
      this Permission Model. Need more technical depth? Look at
      <span @click="appOverlay1 = true" style="color: #006c27;">this</span>
      and
      <span @click="appOverlay2 = true" style="color: #006c27;">this</span>.
    </div>
    <div class="headline mt-3">Delegated Permission Model</div>
    <div>
      In the Delegated Permission Model the API (or an endpoint of the API) is
      designed to expect real-users. Again, in comparision - no system-users are
      supported. This is frequently a requirement for web, mobile or desktop
      applications where real-users need to be logged in. So - if you want to
      implement an application with this requirement and want to consume a
      Schaeffler API inside this application - you definitely need this
      Permission Model. Need more technical depth? Look at
      <span @click="appOverlay3 = true" style="color: #006c27;">this</span>.
    </div>
    <div class="mt-3">
      Get more information at our Microsoft Teams channel
      <a
        class="link"
        href="https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
        target="_blank"
        >API@Schaeffler (Browser)</a
      >
      or
      <a
        class="link"
        href="msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
        target="_blank"
        >API@Schaeffler (Teams)</a
      >
    </div>
    <v-overlay @click.native="appOverlay1 = false" v-model="appOverlay1">
      <v-container fill-height fluid>
        <v-row justify="center" align="center">
          <v-col cols="8">
            <v-img
              contain
              class="my-3"
              :src="$getImagePath('ApplicationPermissionModel1.png')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-overlay @click.native="appOverlay2 = false" v-model="appOverlay2">
      <v-container fill-height fluid>
        <v-row justify="center" align="center">
          <v-col cols="8">
            <v-img
              contain
              class="my-3"
              :src="$getImagePath('ApplicationPermissionModel2.png')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-overlay @click.native="appOverlay3 = false" v-model="appOverlay3">
      <v-container fill-height fluid>
        <v-row justify="center" align="center">
          <v-col cols="8">
            <v-img
              contain
              class="my-3"
              :src="$getImagePath('DelegatedPermissionModel.png')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "PermissionContent",
  props: {},
  computed: {},
  data() {
    return {
      appOverlay1: false,
      appOverlay2: false,
      appOverlay3: false,
    };
  },
  methods: {
    openNewWindows(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.headline {
  color: #006c27;
  font-size: 1rem !important;
}
.link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}
</style>
