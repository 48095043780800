<template>
  <v-dialog width="100%" v-model="show" persistent max-width="900">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
          color="#006c27"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>
        <v-icon>mdi-security</v-icon>
        <div class="ml-2 text--secondary">IT Security By Design</div>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-autocomplete
          outlined
          :items="demands"
          v-model="demand"
          label="Select your IT Security Demand"
          dense
          @change="updateDemand"
          return-object
          item-text="Title"
          hide-details=""
          color="#006c27"
          :filter="filterDemands"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.Title"></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                v-text="item.SecId"
              ></v-list-item-action-text>
            </v-list-item-action>
          </template>
        </v-autocomplete>
        <div class="ml-1" v-if="demands.length != 0">
          Last updated: {{ lastUpdate }}, Total: {{ demands.length }}
        </div>
        <div style="color: red" class="mt-3">
          Please select the IT Security Demand of the application where you plan
          to integrate the API(s). <br />Do <u>not</u> select a platform like
          Mendix or Powerapps. For applications on these platforms you need an
          own IT Security Demand.<br />
          In case you integrate an API into an existing application, make sure
          you also update your IT Security Demand accordingly!
        </div>
        <div v-if="demand" class="mt-6">
          <div class="mb-4">
            <v-icon class="mr-2">mdi-information-outline</v-icon>
            <span class="">Demand details</span>
          </div>
          <v-row>
            <v-col cols="8">
              <v-text-field
                dense
                hide-details
                label="Title"
                outlined
                readonly
                color="#006c27"
                v-model="demand.Title"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                hide-details
                label="ID"
                outlined
                readonly
                color="#006c27"
                v-model="demand.SecId"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                dense
                hide-details
                label="Confidentiality"
                outlined
                readonly
                color="#006c27"
                v-model="demand.Confidentiality"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                dense
                hide-details
                label="Status"
                outlined
                readonly
                color="#006c27"
                v-model="demand.Status"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                hide-details
                label="Region"
                outlined
                readonly
                color="#006c27"
                v-model="demand.Region"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Protection Manager"
                :items="demand.ProtectionManager"
                item-text="Name"
                outlined
                dense
                v-model="demand.firstProtectionManager"
                hide-details=""
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                label="Service Owner"
                :items="demand.ServiceOwner"
                item-text="Name"
                outlined
                dense
                v-model="demand.firstServiceOwner"
                hide-details=""
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                dense
                hide-details
                label="Description"
                outlined
                readonly
                color="#006c27"
                no-resize
                v-model="demand.Description"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </div>
        <div class="mt-5" v-else>
          <div>
            In case you are not familiar with the IT Security By Design process,
            find below some useful links.
          </div>
          <div class="mt-3">
            <a
              href="https://sconnect.schaeffler.com/community/global/compliance/corporate-security/information-security-global/blog/2022/06/01/information-security-security-by-design"
              class="link"
              target="_blank"
              >IT Security at SConnect</a
            >
            <br />
            <a
              href="https://sconnect.schaeffler.com/docs/DOC-333053"
              class="link"
              target="_blank"
              >IT Security at Akademie</a
            >
            <br />
            <a
              href="http://qmh.de.ina.com:1080/SAPIKS2/contentShow.sap?_CLASS=QM_DOC&_LOIO=C6A0E6D5672248E9AB8414694C47C94C&&IWB_INDUSTRY=/KWCUST/I1&TMP_IWB_TASK=DISPLAY&RELEASE=10&LANGUAGE=EN"
              class="link"
              target="_blank"
              >IT Security at Management Handbook</a
            >
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn
          v-if="demand"
          color="#006c27"
          class="ml-2 white--text"
          @click="showDemand"
        >
          Show demand
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!demand"
          color="#006c27"
          class="white--text"
          @click="confirm"
        >
          OK
        </v-btn>
        <v-btn color="#006c27" class="white--text" @click="cancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";
import moment from "moment";

export default {
  name: "ITSecDemandOverlay",
  components: {},
  props: {
    value: Boolean,
  },
  data: () => ({
    demand: null,
    demands: [],
    lastUpdate: null,
    loading: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.demand = null;
        console.log("IT Sec Dialog opened");
        if (this.demands.length == 0) {
          this.loadData();
        }
      }
    },
  },
  methods: {
    updateDemand() {
      console.log("selected");
      if (this.demand.ProtectionManager.length != 0) {
        this.demand.firstProtectionManager = this.demand.ProtectionManager[0];
      }
      if (this.demand.ServiceOwner.length != 0) {
        this.demand.firstServiceOwner = this.demand.ServiceOwner[0];
      }
    },
    showDemand() {
      window.open(this.demand.URL, "_blank").focus();
    },
    filterDemands(item, queryText) {
      return (
        item.Title.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.Description.toLocaleLowerCase().indexOf(
          queryText.toLocaleLowerCase()
        ) > -1 ||
        item.SecId.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    confirm() {
      this.show = false;
      this.$emit("selected", this.demand);
    },
    cancel() {
      this.show = false;
      this.$emit("cancel");
    },
    loadData() {
      this.loading = true;
      cass
        .getITDemands()
        .then((response) => {
          console.log(response);
          this.demands = response.data.List;
          const lu = new Date(response.data.LastUpdate);
          this.lastUpdate = moment(String(lu)).format("DD.MM.YYYY HH:mm");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}
</style>
