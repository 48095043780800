var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", [_vm._v("Delete Application")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.nop.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.deleteValid,
                    callback: function ($$v) {
                      _vm.deleteValid = $$v
                    },
                    expression: "deleteValid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "appNameInput",
                              label: "Application name",
                              placeholder:
                                "Please provide the application name",
                              outlined: "",
                              rules: [_vm.equalsApplicationName],
                              dense: "",
                              autocomplete: "new-password",
                              color: "#006c27",
                              "error-messages": _vm.serverErrorDelete,
                            },
                            model: {
                              value: _vm.application2delete,
                              callback: function ($$v) {
                                _vm.application2delete = $$v
                              },
                              expression: "application2delete",
                            },
                          }),
                          _vm.safeDelete
                            ? _c("v-checkbox", {
                                staticStyle: { "margin-top": "0px" },
                                attrs: {
                                  color: "#006c27",
                                  "hide-details": "",
                                  label: "SafeDelete",
                                },
                                model: {
                                  value: _vm.safeDeleteInternal,
                                  callback: function ($$v) {
                                    _vm.safeDeleteInternal = $$v
                                  },
                                  expression: "safeDeleteInternal",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "#006c27",
                                id: "deleteButton",
                                disabled: !_vm.deleteValid,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteApplication()
                                },
                              },
                            },
                            [_vm._v(" Delete ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            dark: false,
            absolute: true,
            value: _vm.deleting,
            opacity: "0.1",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          align: "center",
                          size: 120,
                          width: 13,
                          color: "#006c27",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }