<template>
  <v-dialog width="100%" v-model="show" persistent max-width="1000">
    <v-card max-width="1000">
      <v-card-title class="text--secondary mb-2">
        Version Details
      </v-card-title>
      <v-card-text class="text--primary pb-2">
        <version-content
          :versionCASSAPI="versionCASSAPI"
          :versionRATEAPI="versionRATEAPI"
          :versionAALAPI="versionAALAPI"
        >
        </version-content>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="#006c27" class="white--text mr-2 mb-1" @click="done()">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";
import rating from "../api/rating";
import announce from "../api/announce";
import VersionContent from "./VersionContent.vue";
export default {
  components: { VersionContent },
  name: "VersionOverlay",
  props: {
    value: Boolean,
  },
  data() {
    return {
      versionCASSAPI: {
        version: "Loading ...",
      },
      versionRATEAPI: {
        version: "Loading ...",
      },
      versionAALAPI: {
        version: "Loading ...",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
    },
  },
  watch: {
    value(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        console.log("Dialog was opened!");
        this.loadData();
      } else {
        console.log("Dialog was closed!");
      }
    },
  },
  methods: {
    done() {
      this.$emit("close", true);
    },
    loadData() {
      cass.getVersion().then((version) => {
        console.log(version);
        this.versionCASSAPI = version.data;
      });
      rating.getVersion().then((version) => {
        console.log(version);
        this.versionRATEAPI = version.data;
      });
      announce.getVersion().then((version) => {
        console.log(version);
        this.versionAALAPI = version.data;
      });
    },
  },
};
</script>

<style scoped>
a:link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}
</style>
