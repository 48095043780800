<template>
  <v-overlay :dark="false" :absolute="false" :value="show">
    <v-card v-if="application" class="d-flex flex-column" width="1000">
      <v-card-title>
        <div class="">Deleted Application Details</div>
        <v-spacer></v-spacer>
        <v-spacer />
        <v-chip
          id="applicationEnvironment"
          outlined
          v-if="application != null"
          :color="getEnvironmentColor(application.environment)"
          dark
        >
          {{ application.environment }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              id="applicationDisplayName"
              :value="application.displayName"
              label="Name"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-text-field
              id="applicationId"
              :value="application.applicationId"
              label="CASS Application ID"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
              <v-icon
                style="margin-top: 2px"
                dense
                slot="append"
                @click="linkAzureAppReg"
                class="mr-2"
              >
                mdi-link
              </v-icon>
              <v-icon
                style="margin-top: 2px"
                dense
                slot="append"
                @click="copy2clipboard(application.applicationId)"
              >
                mdi-content-copy
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col v-if="this.$userData.cass.isUserAdmin">
            <v-text-field
              id="applicationApiMgmtApplicationId"
              :value="
                application.apiMgmtApplicationId | formatStringToLowerCase
              "
              label="SAP Application ID"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
              <v-icon
                style="margin-top: 2px"
                dense
                slot="append"
                @click="linkDeveloperPortal"
                class="mr-2"
              >
                mdi-link
              </v-icon>
              <v-icon
                style="margin-top: 2px"
                dense
                slot="append"
                @click="copy2clipboard(application.apiMgmtApplicationId)"
              >
                mdi-content-copy
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-text-field
              id="applicationCreateDate"
              :value="application.createDate | formatDate"
              label="Created At"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              id="applicationPermissionModel"
              :value="application.permissionModel | formatString"
              label="Permission Model"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              id="applicationState"
              :value="application.state | formatString"
              label="Status"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-text-field
              :value="application.DeletedAt | formatDate"
              label="Deleted At"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="application.DeletedBy"
              label="Deleted By"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="application.SafeDelete | formatSafeDelete"
              label="Safe Delete"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="8">
            <v-text-field
              :value="application | formatITSec"
              label="IT Security Demand"
              outlined
              readonly
              hide-details
              color="#006c27"
            >
              <v-icon
                style="margin-top: 2px"
                dense
                slot="append"
                @click="linkITSec"
                class="mr-2"
              >
                mdi-link
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="application.owners"
              v-model="firstowner"
              :label="'Owners(' + application.owners.length + ')'"
              outlined
              hide-details
              color="#006c27"
              item-color="#006c27"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="application.SafeDelete == false"
          color="#006c27"
          class="white--text mr-1 mb-2"
          @click="realDelete"
        >
          Delete
        </v-btn>
        <v-btn color="#006c27" class="white--text mr-2 mb-2" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :dark="false" :absolute="true" :value="deleting" opacity="0.1">
      <v-container fill-height fluid>
        <v-row>
          <v-col align="center" justify="center">
            <v-progress-circular
              align="center"
              :size="220"
              :width="13"
              color="#006c27"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-snackbar :timeout="6000" v-model="showSnackError" bottom color="red">
      {{ serverErrorDelete }}
    </v-snackbar>
  </v-overlay>
</template>

<script>
import moment from "moment";
import cass from "../api/cass";

export default {
  name: "DeletedApplicationOverlay",
  props: {
    value: Boolean,
    application: Object,
  },
  data: () => ({
    deleting: false,
    serverErrorDelete: null,
    showSnackError: false,
    firstowner: "TEST",
    environmentName: Object.freeze({
      PRODUCTION: "PRODUCTION", //
      QUALITY: "QUALITY", //
      DEVELOPMENT: "DEVELOPMENT",
    }),
    environmentUrl: Object.freeze({
      PRODUCTION:
        "https://prodeu2devportalapimgmtpeu2-xbco389jhy.eu2.hana.ondemand.com/",
      QUALITY:
        "https://prodeu2devportalapimgmtpeu2-u388hr22dq.eu2.hana.ondemand.com/",
      DEVELOPMENT:
        "https://prodeu2devportalapimgmtpeu2-ed119f165.eu2.hana.ondemand.com/",
    }),
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        // Here you would put something to happen when dialog opens up
        console.log("Application Create Dialog was opened!");
        this.firstowner = this.application.firstowner;
      } else {
        console.log("Application Create Dialog was closed!");
      }
    },
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("MMMM Do YYYY, HH:mm:ss");
    },
    formatStringToLowerCase(value) {
      return value.toLowerCase();
    },
    formatString(value) {
      return (
        value.charAt(0).toUpperCase() +
        value.replaceAll("_", " ").slice(1).toLowerCase()
      );
    },
    formatSafeDelete(value) {
      if (value == null || value == true) {
        return "App Twins still available";
      }
      return "App Twins removed";
    },
    formatITSec(value) {
      if (value.itSecId == null || value.itSecId == undefined) {
        return "not configured";
      }
      return value.itSecTitle + " (" + value.itSecId + ")";
    },
  },
  methods: {
    realDelete() {
      console.log("Real Delete");
      this.deleting = true;
      cass
        .realDeleteDeletedAppliations(this.application.applicationId)
        .then((response) => {
          console.log("Getting successful response");
          console.log(response);
          this.$emit("update", true);
          this.close();
        })
        .catch((error) => {
          console.log("Error deleting apps");
          console.log(error);
          var casserror = cass.handleError(error);
          this.serverErrorDelete = casserror.message;
          this.showSnackError = true;
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    close() {
      this.show = false;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.reset();
      }
      if (this.$refs.formImport) {
        this.$refs.formImport.reset();
      }
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    getDevPortalLink(env) {
      if (env == this.environmentName.PRODUCTION) {
        return this.environmentUrl.PRODUCTION;
      } else if (env == this.environmentName.QUALITY) {
        return this.environmentUrl.QUALITY;
      } else if (env == this.environmentName.DEVELOPMENT) {
        return this.environmentUrl.DEVELOPMENT;
      } else {
        console.log("Couldn't detect environment: " + env);
      }
    },
    linkITSec() {
      window.open(this.application.itSecURL, "_blank").focus();
    },
    linkDeveloperPortal() {
      let url = this.getDevPortalLink(this.application.environment);
      url += "application/" + this.application.apiMgmtApplicationId;

      window.open(url, "_blank").focus();
    },
    linkAzureAppReg() {
      let url =
        "https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/" +
        this.application.applicationId;

      window.open(url, "_blank").focus();
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style></style>
