var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { align: "center", justify: "center" } }, [
            _c("div", { staticClass: "mt-14 text-h5" }, [
              _vm._v(" You are logged out. "),
            ]),
            _c("div", {}, [_vm._v(" Please close the browser windows. ")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }