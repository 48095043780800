<template>
  <v-container fill-height fluid>
    <v-row v-if="loggin">
      <v-col align="center" justify="center">
        <v-progress-circular
          v-if="!this.$drupal"
          align="center"
          :size="170"
          :width="13"
          color="#006c27"
          indeterminate
        ></v-progress-circular>
        <div v-if="!this.$drupal" class="mt-14 text-h5">
          We are logging you on.
        </div>
        <div v-if="!this.$drupal" class="">Please stand by.</div>
        <div v-if="this.$drupal" class="text-h5">Loading ...</div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col align="center" justify="center">
        <v-card width="60%">
          <v-card-title
            style="vertical-align: middle; text-align: center; display: block"
          >
            <div>Sorry. We have trouble with SSO to log you in 😢</div>
          </v-card-title>

          <div>Details: {{ errormsg }}</div>
          <v-btn
            v-if="signup"
            @click="signUp"
            color="#006c27"
            class="white--text mt-6 mb-4"
            >SIGN UP</v-btn
          >
          <v-btn
            v-else
            @click="tryagain"
            color="#006c27"
            class="white--text mt-6 mb-4"
            >TRY AGAIN</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import navStore from "../navStore";

export default {
  name: "login",

  components: {},

  methods: {
    tryagain() {
      window.open(window.location.origin, "_self");
    },
    signUp() {
      window.open("https://developer.schaeffler.com/sign-up", "_self");
    },
  },

  mounted() {
    console.log("created");
    console.log("log route");
    console.log(this.$route);
    localStorage.removeItem("auto_import_feature");

    this.$emitter.on("login", () => {
      this.$router.replace(navStore.state.navTo);
      console.log("login: saving navTo");
      navStore.commit("navTo", "/");
    });
    this.$emitter.on("no-redirect", () => {
      console.log("no-redirect-event");
      const self = this;
      setTimeout(
        function () {
          console.log("automatic log on");
          // can we logon the user silently?
          const accounts = self.$authService.getAllAccounts();
          console.log("Number of accounts: ", accounts.length);
          console.log(accounts);
          if (accounts.length > 0) {
            console.log("Found user: ", accounts[0]);
            self.$authService.setActiveAccount(accounts[0]);
          } else {
            self.$authService.signIn();
          }
        },
        this.$drupal ? 0 : 2000
      );
    });
    this.$emitter.on("logout", () => {
      console.log("logout");
      this.$authService.signIn();
    });
    this.$emitter.on("login-error", (msg) => {
      console.log("Show Login error");
      if (msg == "Error validating claims. Not in app user group") {
        // This user is not yet onboarded!
        this.errormsg =
          "You are not onboarded to use CASS. Please sign-up first!";
        this.signup = true;
      } else {
        this.errormsg = msg;
      }
      this.loggin = false;
    });
  },

  /*
  mounted() {
    console.log("mounted");
    const self = this;
    setTimeout(function() {
      console.log("automatic log on");
      self.$authService.signIn();
    }, 3000);
  },
  */

  data: () => ({
    loggin: true,
    signup: false,
    errormsg: "Unknown",
  }),
};
</script>

<style scoped></style>
