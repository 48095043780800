<template>
  <v-dialog width="100%" v-model="show" persistent max-width="700">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-2">
          Certificate Create
        </div>
        <v-spacer></v-spacer>
        <v-icon id="closeCertificateCreateOverlayButton" @click="close()"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row v-if="step == 1">
          <v-col>
            <vue-flip v-model="flipCard" width="314" height="400">
              <template v-slot:front>
                <v-card class="d-flex flex-column" width="314" height="400">
                  <v-card-title>
                    Quickstart
                  </v-card-title>
                  <v-card-text>
                    If you want to get started quickly - this option is your
                    friend. Just flip this card, provide the necessary
                    information and press the create button.<br /><br />
                    The CSR (Certificate Signing Request) and private key is
                    generated automatically on your behalf.<br /><br />
                    This sound easy? Just give it a try and enjoy this user
                    experience!
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-icon id="certificateQuickstartArrow" @click="flip()"
                      >mdi-arrow-right</v-icon
                    >
                  </v-card-actions>
                </v-card>
              </template>
              <template v-slot:back>
                <v-card class="d-flex flex-column" width="314" height="400">
                  <v-card-title>
                    Quickstart
                  </v-card-title>
                  <v-card-text class="">
                    <v-form
                      ref="form"
                      v-model="quickstartValid"
                      v-on:submit.prevent="nop"
                    >
                      <v-select
                          id="certificateQuickstartCountry"
                          outlined
                          label="Country"
                          placeholder="Country (i.e. DE, US)"
                          :rules="[rules.notEmpty]"
                          v-model="providedCSR.country"
                          :items="countries"
                          item-value="cca2"
                          item-text="name"
                          dense
                          counter
                          color="#006c27"
                      ></v-select>
                      <v-text-field
                        id="certificateQuickstartProvince"
                        v-model="providedCSR.province"
                        label="Province"
                        placeholder="Province (i.e. NRW, NY)"
                        outlined
                        :rules="[rules.notEmpty]"
                        dense
                        maxlength="25"
                        counter
                        autocomplete="new-password"
                        color="#006c27"
                      >
                      </v-text-field>
                      <v-text-field
                        id="certificateQuickstartCity"
                        v-model="providedCSR.city"
                        label="City"
                        placeholder="City (i.e. New York City)"
                        outlined
                        :rules="[rules.notEmpty]"
                        dense
                        maxlength="25"
                        counter
                        autocomplete="new-password"
                        color="#006c27"
                      >
                      </v-text-field>
                      <v-text-field
                        id="certificateQuickstartCompany"
                        v-model="providedCSR.company"
                        label="Company"
                        placeholder="Your company name"
                        outlined
                        :rules="[rules.notEmpty]"
                        dense
                        maxlength="25"
                        counter
                        autocomplete="new-password"
                        color="#006c27"
                      >
                      </v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-icon @click="flip()">mdi-arrow-left</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn
                      id="certificateQuickstartCreateButton"
                      color="#006c27"
                      class="white--text"
                      :disabled="!quickstartValid"
                      @click="createQuickstart()"
                    >
                      Create
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </vue-flip>
          </v-col>
          <v-col>
            <v-card class="d-flex flex-column" height="400">
              <v-card-title>
                With PKI
              </v-card-title>
              <v-card-text>
                You have a company internal PKI (Public Key Infrastructure)? You
                need to create your CSR (Certificate Signing Request) by
                yourself? Than this option is your friend!<br /><br />
                By clicking the create button we will provide all necessary CSR
                information for you.<br /><br />
                After CSR creation on your side, just upload it!
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#006c27"
                  class="white--text"
                  @click="createWithPKI()"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="step == 2">
          <v-col>
            <v-card class="d-flex flex-column" height="400">
              <v-card-title>
                CSR Details
              </v-card-title>
              <v-card-text>
                <div class="mb-5">
                  Please use the following attributes for generating your own
                  CSR:
                </div>
                <v-row dense class="mb-2">
                  <v-col>
                    <v-text-field
                      :value="csrDetails.commonName"
                      label="Common Name (CN)"
                      outlined
                      dense
                      hide-details
                      readonly
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="copy2clipboard(csrDetails.commonName)"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="mb-2">
                  <v-col>
                    <v-text-field
                      :value="csrDetails.emailAddress"
                      label="Email Address"
                      outlined
                      dense
                      hide-details
                      readonly
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="copy2clipboard(csrDetails.emailAddress)"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      :value="csrDetails.organizationUnit"
                      label="Organizational Unit (OU)"
                      outlined
                      dense
                      readonly
                      hide-details
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="copy2clipboard(csrDetails.organizationUnit)"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="csrDetails.keyLength"
                      label="Key Length"
                      outlined
                      hide-details
                      dense
                      readonly
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="copy2clipboard(csrDetails.keyLength)"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="csrDetails.keyType"
                      label="Key Type"
                      outlined
                      hide-details
                      dense
                      readonly
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="copy2clipboard(csrDetails.keyType)"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="my-5">
                  An example openssl command would look like this:
                </div>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      value="openssl req -nodes -new -newkey rsa:4096 -sha256 -out csr.pem -keyout priv.key"
                      label="openssl command line"
                      outlined
                      dense
                      hide-details
                      readonly
                      color="#006c27"
                    >
                      <v-icon
                        style="margin-top: 2px;"
                        dense
                        slot="append"
                        @click="
                          copy2clipboard(
                            'openssl req -nodes -new -newkey rsa:4096 -sha256 -out csr.pem -keyout priv.key'
                          )
                        "
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn
              id="closedialog"
              color="#006c27"
              class="white--text mt-4"
              style="float: right"
              @click="close()"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="step == 3">
          <v-col>
            <v-card class="d-flex flex-column" height="400">
              <v-card-title>
                Private Key
              </v-card-title>
              <v-card-text>
                <v-textarea
                  :loading="privKeyLoading"
                  loader-height="5"
                  outlined
                  readonly
                  :no-resize="true"
                  :value="privKey"
                  rows="5"
                  hide-details="true"
                >
                </v-textarea>
                <div class="mt-1" v-if="!privKeyLoading">
                  Copy private key to clipboard
                  <v-icon
                    style="margin-top: 2px;"
                    small
                    color="#006c27"
                    slot="append"
                    @click="copy2clipboard(privKey)"
                  >
                    mdi-content-copy
                  </v-icon>
                </div>
                <div class="mt-3" v-if="!privKeyLoading">
                  A private key was generated on your behalf.<br />
                  It was never saved on the server and can not be recovered.<br />
                  This is the only time when the key is visible. Please copy it
                  and store it safely!
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <error-overlay v-model="error" :retryEnabled="false"></error-overlay>
  </v-dialog>
</template>

<script>
import VueFlip from "vue-flip";
import cass from "../api/cass";
import ErrorOverlay from "../components/ErrorOverlay.vue";
import countries from "../consts/countries.json"

export default {
  name: "CertificateCreateOverlay",
  components: { VueFlip, ErrorOverlay },
  props: {
    value: Boolean,
    appid: String,
  },
  data: () => ({
    error: null,
    step: 1,
    providedCSR: {},
    csrDetails: {},
    privKey: "generating key, please standby - this can last 1 minute ...",
    flipCard: false,
    quickstartValid: false,
    rules: {
      notEmpty: (value) => {
        if (value == null || value == undefined) {
          return "Required";
        }
        if (value.length == "") {
          return "Required";
        }
        return true;
      },
    },
    countries: countries.sort((country1, country2) => country1.name.localeCompare(country2.name))
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
    },
  },
  methods: {
    nop() {},
    close() {
      this.privKeyLoading = false;
      this.step = 1;
      this.providedCSR = {};
      this.flipCard = false;
      this.privKey =
        "generating key, please standby - this can last 1 minute ...";
      this.privKeyLoading = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("close", "true");
    },
    createQuickstart() {
      console.log("create quickstart");
      this.privKeyLoading = "#006c27";
      cass
        .addCertificate(this.appid)
        .then((response) => {
          this.step = 3;
          console.log(response);
          console.log(this.providedCSR);
          let csr = {
            country: this.providedCSR.country,
            province: this.providedCSR.province,
            company: this.providedCSR.company,
            city: this.providedCSR.city,
          };
          return cass.addCSR(this.appid, response.data.certifcateId, csr);
        })
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
          this.privKey = response.data;
          this.privKeyLoading = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.error = cass.handleError(err);
        });
    },
    createWithPKI() {
      console.log("create with pki");
      cass
        .addCertificate(this.appid)
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
          this.csrDetails = response.data.csrdetail;
          this.step = 2;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
    flip() {
      this.flipCard = !this.flipCard;
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
}

::v-deep textarea {
  line-height: 20px;
  font-size: 0.8rem;
  margin-top: 3px !important;
  margin-right: 2px;
  margin-bottom: 3px;
}
</style>

<style>
.front,
.back {
  transform-style: unset !important;
  -webkit-transform-style: unset !important;
}
</style>
