<template>
  <v-expansion-panel>
    <error-overlay v-model="error" :retryEnabled="false"></error-overlay>
    <v-expansion-panel-header class="pl-3">
      <div>
        <v-icon> mdi-folder-star-multiple-outline </v-icon>
        <span class="ml-1">Subscribed Products</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-row
        v-if="application.subscriptions.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col style="text-align: left"> No Subscriptions available </v-col>
      </v-row>
      <v-row
        v-if="application.subscriptions.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-divider></v-divider>
      </v-row>
      <template v-for="(product, index) in application.subscriptions">
        <v-list-item dense :key="'B' + index" :selectable="false">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-content style="text-align: left">
            <v-row class="flex-nowrap">
              <v-col>
                {{ product.title }}
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(proxy, index) in product.apiProxies">
          <v-list-item
            class="ml-8"
            dense
            :key="product.name + proxy.name + index"
            :selectable="false"
          >
            <v-list-item-icon style="margin-right: 10px">
              <v-icon>mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-content style="text-align: left">
              <v-row class="">
                <v-col cols="7">
                  {{ proxy.title }}
                  <v-chip
                    x-small
                    outlined
                    @click="showDeprecation(proxy.deprecation)"
                    color="red"
                    v-if="proxy.deprecation"
                  >
                    {{ getDeprecationChipText(proxy.deprecation.State) }}
                  </v-chip>
                </v-col>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="permissionModelIconColor(proxy.permissionModel)"
                        small
                        >{{
                          permissionModelIcon(proxy.permissionModel)
                        }}</v-icon
                      >
                      {{ permissionModel(proxy.permissionModel) }}
                    </template>
                    <span
                      v-html="permissionModelToolTip(proxy.permissionModel)"
                    >
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="onAPIPath($event, proxy)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1 mt-1"
                  color="#006c27"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>Get API URL</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                  @click="onDownloadSwagger($event, proxy.name)"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-1"
                  :src="$getImagePath('openapi-1.svg')"
                />
              </template>
              <span>Download Swagger</span>
            </v-tooltip>
          </v-list-item>
        </template>
        <v-divider :key="product.name"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-spacer></v-spacer>
        <div>Manage Subscription</div>
        <v-list-item-icon>
          <v-icon id="addSubscriptionsButton" @click="addSubscription"
            >mdi-plus-circle-outline</v-icon
          >
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <swagger-download-overlay
      v-model="downloadSwagger"
      :proxyname="proxyname"
      :environment="environment"
    >
    </swagger-download-overlay>
    <api-url-overlay
      v-model="apiUrl"
      :proxy="selectedProxy"
      :environment="environment"
    ></api-url-overlay>
    <sub-products-overlay
      v-model="addSubscriptionDialog"
      :application="application"
      @apply="subscribeToProducts"
    >
    </sub-products-overlay>
    <sub-products-progress-overlay
      v-model="showSubscriptionProgressDialog"
      :messages="progressMessages"
    >
    </sub-products-progress-overlay>
    <sub-not-possible-overlay
      v-model="subscriptionNotPossibleDialog"
      @assistance="assistance"
    >
    </sub-not-possible-overlay>
    <sub-assistance-overlay
      v-model="assistanceDialog"
      :products="assistanceProducts"
      @submit="assistanceSubmit"
    >
    </sub-assistance-overlay>
    <deprecation-detail-overlay
      v-model="showDeprecationDetail"
      :deprecation="selectedDeprecation"
      @close="showDeprecationDetail = false"
    >
    </deprecation-detail-overlay>
    <v-snackbar :timeout="3000" v-model="showSnackInfo" bottom color="#006c27">
      {{ snackInfoMessage }}
    </v-snackbar>
  </v-expansion-panel>
</template>

<script>
import SwaggerDownloadOverlay from "./SwaggerDownloadOverlay.vue";
import ApiUrlOverlay from "./ApiUrlOverlay.vue";
import SubProductsOverlay from "./SubProductsOverlay.vue";
import SubProductsProgressOverlay from "./SubProductsProgressOverlay.vue";
import ErrorOverlay from "../components/ErrorOverlay.vue";
import cass from "../api/cass";
import SubNotPossibleOverlay from "./SubNotPossibleOverlay.vue";
import SubAssistanceOverlay from "./SubAssistanceOverlay.vue";
import DeprecationDetailOverlay from "./DeprecationDetailOverlay.vue";

export default {
  components: {
    SwaggerDownloadOverlay,
    ApiUrlOverlay,
    SubProductsOverlay,
    SubProductsProgressOverlay,
    ErrorOverlay,
    SubNotPossibleOverlay,
    SubAssistanceOverlay,
    DeprecationDetailOverlay,
  },
  name: "SubProducts",
  props: {
    application: Object,
    reload: Function,
  },
  data() {
    return {
      showDeprecationDetail: false,
      selectedDeprecation: null,
      downloadSwagger: false,
      apiUrl: false,
      addSubscriptionDialog: false,
      subscriptionNotPossibleDialog: false,
      assistanceDialog: false,
      assistanceProducts: [],
      showSubscriptionProgressDialog: false,
      proxyname: "",
      selectedProxy: null,
      environment: "",
      progressMessages: [],
      error: null,
      showSnackInfo: false,
      snackInfoMessage: "",
    };
  },
  methods: {
    getDeprecationChipText(state) {
      if (state == "ACTIVE") {
        return "Scheduled";
      }
      return state.charAt(0) + state.substring(1).toLowerCase();
    },
    showDeprecation(deprecation) {
      console.log(deprecation);
      this.selectedDeprecation = deprecation;
      this.showDeprecationDetail = true;
    },
    assistance() {
      this.assistanceDialog = true;
    },
    assistanceSubmit(info) {
      console.log("submit assistance");
      cass
        .subscriptionAssistance(this.application.applicationId, info)
        .then((response) => {
          console.log(response);
          this.snackInfoMessage =
            "The subscriptions assistance form was sent successfully";
          this.showSnackInfo = true;
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            console.log(err.response);
            if (!err.response.data.message) {
              this.snackInfoMessage = "Network communication error occured";
            } else {
              this.snackInfoMessage = err.response.data.message;
            }
          } else {
            this.snackInfoMessage = "Network communication error occured";
          }
          this.showSnackInfo = true;
        });
    },
    async subscribeToProducts(products) {
      console.log("subscribeToProducts");
      console.log(products);
      // check for undefined products
      // This might happen, if applications have subscriptions on products
      // which in the meanwhile are not valid anymore
      // if there are undefined products, show error and exit here
      var data_invalid = false;
      products.forEach((product) => {
        if (product == undefined) {
          data_invalid = true;
        }
      });
      if (data_invalid) {
        this.error = {};
        this.error.message =
          "One of your subscribed products caused an unexpected error!";
        this.error.requestId = "Please contact API CoE for assistance.";
        return;
      }
      this.progressMessages = [];
      // find out the ones to add
      var newOnes = [];
      var deleteOnes = [];
      products.forEach((product) => {
        if (
          this.application.subscriptions.find(
            (element) => element.name == product.name
          )
        ) {
          // this product is already subscribed .. do nothing
          console.log("no action on product: " + product.name);
        } else {
          // this product is new!
          console.log("add action on product: " + product.name);
          var message = {
            text: "Subscribing to product",
            producttitle: product.title,
            state: "pending",
            error: "",
          };
          this.progressMessages.push(message);
          newOnes.push(product);
        }
      });

      // this is the thumbscrew!
      if (
        newOnes.length != 0 &&
        this.application.environment == "PRODUCTION" &&
        !this.$userData.cass.isUserAdmin
      ) {
        console.log("ThumbScrew detected");
        this.progressMessages = [];
        this.assistanceProducts = newOnes;
        this.subscriptionNotPossibleDialog = true;
        return;
      }

      // find out the ones to delete
      this.application.subscriptions.forEach((product) => {
        if (!products.find((element) => element.name == product.name)) {
          // this product is already subscribed .. do nothing
          console.log("delete action on product: " + product.name);
          var message = {
            text: "Unsubscribing product",
            producttitle: product.title,
            state: "pending",
            error: "",
          };
          this.progressMessages.push(message);
          deleteOnes.push(product);
        }
      });

      var refresh_message = {
        text: "Refreshing application data",
        producttitle: "...",
        state: "pending",
        error: "",
      };
      this.progressMessages.push(refresh_message);

      this.showSubscriptionProgressDialog = true;
      console.log("Adding subscriptions:");
      console.log(newOnes);
      console.log("deleting subscriptions:");
      console.log(deleteOnes);
      var index = 0;
      if (newOnes.length > 0) {
        for (let i = 0; i < newOnes.length; i++) {
          try {
            console.log("Adding subscription to: " + newOnes[i]);
            this.progressMessages[index].state = "inprogress";
            let response = await cass.addSubscription(
              this.application.applicationId,
              newOnes[i].name
            );
            console.log("add subscriptions success");
            console.log(response);
            this.progressMessages[index].state = "done";
          } catch (error) {
            console.log("add subscriptions failed");
            console.log(error);
            this.progressMessages[index].state = "error";
          }
          index++;
        }
      }
      //console.log("index = " + index);
      if (deleteOnes.length > 0) {
        for (let i = 0; i < deleteOnes.length; i++) {
          try {
            console.log("Deleting subscription to: " + deleteOnes[i]);
            this.progressMessages[index].state = "inprogress";
            let response = await cass.deleteSubscription(
              this.application.applicationId,
              deleteOnes[i].name
            );
            console.log("delete subscriptions success");
            console.log(response);
            this.progressMessages[index].state = "done";
          } catch (error) {
            console.log("delete subscriptions failed");
            console.log(error);
            this.progressMessages[index].state = "error";
          }
          index++;
        }
      }

      refresh_message.state = "inprogress";
      // refresh subscriptions
      this.reload();
      await this.Sleep(5000);
      this.showSubscriptionProgressDialog = false;
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    addSubscription() {
      this.addSubscriptionDialog = true;
    },
    onDownloadSwagger(evt, proxyname) {
      evt.stopPropagation();
      //console.log(proxyname);
      this.proxyname = proxyname;
      this.environment = this.application.environment;
      this.downloadSwagger = true;
    },
    onAPIPath(evt, proxy) {
      evt.srcElement.blur();
      evt.stopPropagation();
      this.selectedProxy = proxy;
      this.environment = this.application.environment;
      this.apiUrl = true;
    },
    /*
    goToAPI(product, proxy) {
      let url =
        this.getDeveloperPortalURL() +
        "/product/" +
        product.name +
        "/api/" +
        proxy.name;
      window.open(url, "_blank").focus();
    },
    goToProduct(product) {
      let url = this.getDeveloperPortalURL() + "/product/" + product.name;
      window.open(url, "_blank").focus();
    },
    getDeveloperPortalURL() {
      if (this.application.environment === "PRODUCTION") {
        return "https://developer.schaeffler.com";
      }
      if (this.application.environment === "QUALITY") {
        return "https://developer-qas.schaeffler.com";
      }
      if (this.application.environment === "DEVELOPMENT") {
        return "https://developer-dev.schaeffler.com";
      }
    },
    */
    permissionModel(pm) {
      if (pm === "APPLICATION") {
        return "Application";
      }
      if (pm === "DELEGATED") {
        return "Delegated";
      }
      if (pm === "BOTH") {
        return "Application, Delegated";
      }
      if (pm === "NONE") {
        return "Public";
      }
      if (pm === "MISSING") {
        return "Not configured";
      }
      return "FAILURE";
    },
    permissionModelIcon(pm) {
      if (pm === "MISSING") {
        return "mdi-help-circle-outline";
      }
      if (this.application.permissionModel === "DELEGATED") {
        if (pm === "DELEGATED" || pm === "BOTH") {
          return "mdi-check-circle-outline";
        }
        return "mdi-alert-outline";
      }
      if (this.application.permissionModel === "APPLICATION") {
        if (pm === "APPLICATION" || pm === "BOTH") {
          return "mdi-check-circle-outline";
        }
        return "mdi-alert-outline";
      }
      return "mdi-help-circle-outline";
    },
    permissionModelIconColor(pm) {
      if (pm === "MISSING") {
        return "#646464";
      }
      if (this.application.permissionModel === "DELEGATED") {
        if (pm === "DELEGATED" || pm === "BOTH") {
          return "#006c27";
        }
        return "red";
      }
      if (this.application.permissionModel === "APPLICATION") {
        if (pm === "APPLICATION" || pm === "BOTH") {
          return "#006c27";
        }
        return "red";
      }
      return "#646464";
    },
    permissionModelToolTip(pm) {
      if (pm === "MISSING") {
        return "Please contact API CoE Team";
      }
      if (this.application.permissionModel === "DELEGATED") {
        if (pm === "DELEGATED" || pm === "BOTH") {
          return "API Permission Model fits Application Permissoin Model.<br/>This API can be consumed by the Application.";
        }
        return "API Permission Model does NOT fit Application Permission Model.<br/>This API can NOT be consumed by the Application.<br/>Consider unsubscribing.";
      }
      if (this.application.permissionModel === "APPLICATION") {
        if (pm === "APPLICATION" || pm === "BOTH") {
          return "API Permission Model fits Application Permissoin Model.<br/>This API can be consumed by the Application.";
        }
        return "API Permission Model does NOT fit Application Permission Model.<br/>This API can NOT be consumed by the Application.<br/>Consider unsubscribing.";
      }
      return "Please contact API CoE Team";
    },
  },
};
</script>

<style></style>
