<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="9">
        <v-card>
          <v-card-title class="grey--text text--darken-2">
            Version Information
          </v-card-title>
          <v-card-text class="text--primary my-3">
            <version-content
              :versionCASSAPI="versionCASSAPI"
              :versionRATEAPI="versionRATEAPI"
            >
            </version-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import rating from "../api/rating";
import VersionContent from "../components/VersionContent.vue";
export default {
  name: "Version",
  components: { VersionContent },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      versionCASSAPI: {
        version: "Loading ...",
      },
      versionRATEAPI: {
        version: "Loading ...",
      },
    };
  },
  filters: {},
  computed: {},
  created() {},
  methods: {
    loadData() {
      cass.getVersion().then((version) => {
        console.log(version);
        this.versionCASSAPI = version.data;
      });
      rating.getVersion().then((version) => {
        console.log(version);
        this.versionRATEAPI = version.data;
      });
    },
  },
};
</script>

<style scoped></style>
