<template>
  <div>
    <div class="mb-5">
      Schaeffler API Management provides three environments, meeting modern
      software development lifecycle requirements.
    </div>
    <v-row no-gutters class="mb-6">
      <div class="mt-1 subtitle-2">
        Development - DEV - D
      </div>
      <div class="mt-1">
        The Development stage provides Schaeffler APIs under development. As an
        API consumer you most likely won't work here. The exception from this
        rule: You actively participate in an ongoing development in cooperation
        with Schaeffler. <br />
        For more information click
        <a
          class="link"
          href="https://developer.schaeffler.com/docs/subscribe-apis-d-or-q-environment"
          >here</a
        >.
      </div>
    </v-row>
    <v-row no-gutters class="mb-6">
      <div class="mt-1 subtitle-2">
        Quality - QAS - Q
      </div>
      <div class="mt-1">
        The Quality Assurance stage provides Schaeffler APIs under testing. As
        an API consumer you may use this stage for integration. This mainly
        makes sense if the API you consume provides write access for you. For
        integration you can play around here without manipulating production
        data. Be aware: Some APIs also provide sandbox access at production
        level for integration. If sandbox is available - you will not need to be
        on the QA stage. <br />For more information click
        <a
          class="link"
          href="https://developer.schaeffler.com/docs/subscribe-apis-d-or-q-environment"
          >here</a
        >.
      </div>
    </v-row>
    <v-row no-gutters>
      <div class="mt-1 subtitle-2">
        Production - PRD - P
      </div>
      <div class="mt-1">
        The Production stage is the main stage for API consumers. Here you can
        find stable, quality ensured Schaeffler APIs ready to use.
        High-Availabilty clusters grantuee stable access and short maintance
        windows. We always recommend using this stage for your production.
      </div>
      <div class="mt-3">
        Get more information at our Microsoft Teams channel
        <a
          class="link"
          href="https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
          target="_blank"
          >API@Schaeffler (Browser)</a
        >
        or
        <a
          class="link"
          href="msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
          target="_blank"
          >API@Schaeffler (Teams)</a
        >
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EnvironmentContent",
  props: {},
  computed: {},
  methods: {
    openNewWindows(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}
</style>
