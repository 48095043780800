<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-card :loading="loading" :width="1200" class="mt-8 mb-8">
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="grey--text text--darken-2">
          non-CASS Applications
        </v-card-title>
        <v-card-text class="text--primary my-3">
          <v-data-table
            sort-by="displayName"
            :custom-sort="customSort"
            must-sort
            show-expand
            :headers="headers"
            :items="mssapps"
            hide-default-footer
            disable-pagination
            class="elevation-1"
            item-key="apiMgmtApplicationId"
          >
            <template slot="no-data">
              <div v-if="loading">
                Loading applications, please wait ...
              </div>
              <div v-else>
                You do not have any non-CASS applications! Great!
              </div>
            </template>

            <template v-slot:[`item.environment`]="{ item }">
              <v-chip
                outlined
                small
                :color="getEnvironmentColor(item.environment)"
                dark
              >
                {{ item.environment }}
              </v-chip>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td>
                <template v-for="(product, index) in item.products">
                  <div class="my-2" :key="'A' + index">
                    <v-icon class="mr-3" color="#006c27"
                      >mdi-package-variant-closed</v-icon
                    >
                    {{ product.title }}
                  </div>
                  <template v-for="(proxy, index) in product.apiProxies">
                    <div
                      class="ml-7 my-2"
                      :key="'A' + product.name + proxy.name + index"
                    >
                      <v-icon class="ml-3" color="#006c27">mdi-api</v-icon>
                      {{ proxy.name }}
                    </div>
                  </template>
                </template>
              </td>
              <td>
                <template v-for="(product, index) in item.products">
                  <div class="my-2" style="min-height: 24px" :key="'B' + index">
                    <wbr />
                  </div>
                  <template v-for="(proxy, index) in product.apiProxies">
                    <div
                      class="my-2"
                      style="min-height: 24px"
                      :key="'B' + product.name + proxy.name + index"
                    >
                      <v-icon
                        small
                        :color="proxy.cassOnly ? 'red' : '#006c27'"
                        >{{ permissionModelIcon(proxy) }}</v-icon
                      >
                      {{ permissionModel(proxy.permissionModel) }}
                    </div>
                  </template>
                </template>
              </td>
              <td>
                <template v-for="(product, index) in item.products">
                  <div class="my-2" style="min-height: 24px" :key="'B' + index">
                    <wbr />
                  </div>
                  <template v-for="(proxy, index) in product.apiProxies">
                    <div
                      class="my-2"
                      style="min-height: 24px"
                      :key="'B' + product.name + proxy.name + index"
                    >
                      <div v-if="proxy.cassOnly" style="color: red">
                        Subscription invalid. Please use a CASS application.
                      </div>
                    </div>
                  </template>
                </template>
              </td>
              <td>
                <template v-for="(product, index) in item.products">
                  <div style="min-height: 24px" class="my-0" :key="'C' + index">
                    <wbr />
                  </div>
                  <template v-for="(proxy, index) in product.apiProxies">
                    <div
                      class="my-2"
                      style="max-height: 24px"
                      :key="'C' + product.name + proxy.name + index"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            @click="
                              onDownloadSwagger(
                                $event,
                                proxy.name,
                                item.environment
                              )
                            "
                            v-bind="attrs"
                            v-on="on"
                            class="mb-0"
                            style="max-height: 24px;"
                            :src="$getImagePath('openapi-1.svg')"
                          />
                        </template>
                        <span>Download Swagger</span>
                      </v-tooltip>
                    </div>
                  </template>
                </template>
              </td>
              <td></td>
            </template>

            <template v-slot:[`item.apiMgmtApplicationId`]="{ item }">
              <v-icon color="#006c27" @click="onDeleteApp(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template v-slot:[`item.APIKey`]="{ item }">
              <span class="mr-4" style="font-family: monospace; ">{{
                item.APIKey
              }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    slot="append"
                    @click="copy2clipboard(item.APIKey)"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy APIKey to Clipboard</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <swagger-download-overlay
      v-model="downloadSwagger"
      :proxyname="proxyname"
      :environment="environment"
    >
    </swagger-download-overlay>
    <confirm-delete-overlay v-model="confirmDelete" @confirm="onRealDeleteApp">
    </confirm-delete-overlay>
    <non-cass-apps-info v-model="showInfo"> </non-cass-apps-info>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import SwaggerDownloadOverlay from "../components/SwaggerDownloadOverlay.vue";
import ConfirmDeleteOverlay from "../components/ConfirmDeleteOverlay.vue";
import NonCassAppsInfo from "../components/NonCassAppsInfo.vue";

export default {
  name: "NonCassApplications",
  components: { SwaggerDownloadOverlay, ConfirmDeleteOverlay, NonCassAppsInfo },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      mssapps: [],
      loading: false,
      showInfo: false,
      confirmDelete: false,
      confirmedDeleteItem: null,
      downloadSwagger: false,
      proxyname: "",
      environment: "",
      headers: [
        {
          text: "Application Name",
          align: "start",
          value: "displayName",
          width: "40%",
        },
        {
          text: "Environment",
          value: "environment",
          width: "15%",
          filterable: false,
          sortable: true,
        },
        {
          text: "API Key",
          value: "APIKey",
          filterable: false,
          sortable: false,
        },
        {
          text: "Action",
          value: "apiMgmtApplicationId",
          filterable: false,
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  filters: {},
  computed: {},
  created() {},
  methods: {
    onDownloadSwagger(evt, proxyname, env) {
      evt.stopPropagation();
      console.log(proxyname);
      this.proxyname = proxyname;
      this.environment = env;
      this.downloadSwagger = true;
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    permissionModel(pm) {
      if (pm === "APPLICATION") {
        return "Application";
      }
      if (pm === "DELEGATED") {
        return "Delegated";
      }
      if (pm === "BOTH") {
        return "Both";
      }
      if (pm === "NONE") {
        return "Public";
      }
      if (pm === "MISSING") {
        return "Not configured";
      }
      return "FAILURE";
    },
    permissionModelIcon(pm) {
      if (pm.cassOnly) {
        return "mdi-alert-outline";
      }
      if (pm.permissionModel === "MISSING") {
        return "mdi-help-circle-outline";
      }
      return "mdi-check-circle-outline";
    },
    onDeleteApp(item) {
      this.confirmedDeleteItem = item;
      this.confirmDelete = true;
    },
    onRealDeleteApp() {
      console.log("deleteApp!");
      console.log(this.confirmedDeleteItem);
      cass
        .deleteSAPApplication(
          this.confirmedDeleteItem.apiMgmtApplicationId,
          this.confirmedDeleteItem.environment
        )
        .then((response) => {
          console.log(response);
          this.confirmedDeleteItem = null;
          this.loadData();
        })
        .catch((error) => {
          console.error(error);
          //this.error = cass.handleError(err);
        });
    },
    rowClicked() {
      console.log("row clicked, Yeah!");
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index]
              .toLowerCase()
              .localeCompare(b[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          } else {
            return b[index]
              .toLowerCase()
              .localeCompare(a[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          }
        }
      });
      return items;
    },
    loadData() {
      console.log("Loading data ...");
      this.loading = true;
      cass
        .getSAPApplications()
        .then((response) => {
          console.log("Getting successful response");
          console.log(response);
          let mssapps = [];
          let promise_array = [];

          response.data.forEach(function(sapapplication) {
            if (sapapplication.managedByCASS === false) {
              console.log(
                "Found mss app: " + sapapplication.apiMgmtApplicationId
              );
              mssapps.push(sapapplication);
              promise_array.push(
                cass.getSAPApplication(
                  sapapplication.apiMgmtApplicationId,
                  sapapplication.environment
                )
              );
            }
          });

          Promise.allSettled(promise_array).then((data) => {
            var mssapps_index = 0;
            data.forEach((response) => {
              console.log(response);
              if (response.status === "fulfilled") {
                mssapps[mssapps_index] = response.value.data;
              } else {
                mssapps[mssapps_index].APIKey = "Could not retrieve APIKey";
              }
              mssapps_index++;
            });

            this.mssapps = mssapps;
            this.loading = false;
            if (this.mssapps.length != 0) {
              this.showInfo = true;
            }
          });
        })
        .catch((error) => {
          console.log("Error getting sapapplications");
          console.log(error);
        });
    },
  },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
