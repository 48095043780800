<template>
  <div class="wrapper">
    <div class="bubblewrap" id="speech" ref="speech"></div>
    <div
      class="content"
      :style="{
        transform: 'scale(' + scaleC + ')',
        pointerEvents: 'none',
      }"
    >
      <div
        class="robotBox robotStart"
        id="robotBox"
        style="pointer-events: none"
      >
        <div class="robotInner" id="robotInner" style="pointer-events: none">
          <div
            class="rHeadBox hovering"
            id="rHeadBox"
            ref="rHeadBox"
            style="pointer-events: none"
          >
            <div
              ref="rHeadBox2"
              id="rHeadBox2"
              class="move2"
              style="pointer-events: none"
            >
              <svg
                id="rHead"
                viewBox="0 0 217 212"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="strokeBlue fillSchaeffler"
                  d="M27.7,176.8C13.5,176.8,2,157.1,2,132.6s14.5-44.5,28.7-44.5C22.1,115.1,20.9,145.3,27.7,176.8L27.7,176.8z"
                ></path>
                <path
                  class="strokeBlue fillSchaeffler"
                  d="M189.4,176.8c14.2,0,25.7-19.8,25.7-44.2S200.5,88,186.4,88C194.9,115.1,196.1,145.3,189.4,176.8L189.4,176.8z"
                ></path>
                <path
                  class="stBlue"
                  d="M193.9,97.2c3.3,6.2,5.1,15.7,6,22.1c1.7,11.7,2.1,23.9,1.5,35.7c-0.5,9.6-1.7,19.5-4.2,28.8 c-0.3,1.2-1,2.1-2,2.8c-25.5,16.9-56.2,25-86.6,25s-61.2-8.1-86.6-25c-1-0.7-1.7-1.6-2-2.8c-2.5-9.3-3.7-19.1-4.2-28.8 c-0.6-11.8-0.2-24,1.5-35.7c0.9-6.4,2.7-15.8,6-22.1c-1.4-4.2-2.4-8.6-3.2-13c-0.3-1.4,0.1-2.7,0.9-3.8 c21-27.3,53.2-43.2,87.6-43.2s66.7,15.9,87.6,43.2c0.9,1.1,1.2,2.4,0.9,3.8C196.3,88.6,195.3,93,193.9,97.2L193.9,97.2z"
                ></path>
                <path
                  @click="clickFunc()"
                  style="cursor: pointer; pointer-events: auto"
                  class="fillWhite"
                  d="M192.4,83.4c-19.3-25.1-49.7-41.3-83.8-41.3S44,58.2,24.7,83.4c0.9,5.1,2.2,10,3.8,14.4 c-7.7,10.8-12,54.6-3.9,84.7c22.8,15.1,52.1,24.2,84,24.2s61.2-9.1,84-24.2c8.1-30.1,3.7-73.8-3.9-84.7 C190.1,93.3,191.4,88.4,192.4,83.4L192.4,83.4z"
                ></path>
                <path
                  class="stBlue"
                  d="M78.5,46.3c-2,0.6-4,1.2-5.9,1.9v53.6h5.9V46.3L78.5,46.3z"
                ></path>
                <path
                  class="stBlue"
                  d="M144.4,48.3c-1.9-0.7-3.9-1.3-5.9-1.9V102h5.9V48.3L144.4,48.3z"
                ></path>
                <path
                  class="stBlue"
                  d="M142.9,95.3c18.7,1.5,39.4,7.4,39.4,30v35.3c0,2.6-0.4,5.2-1.3,7.7c-4,11.7-16.5,18.5-27.5,22.4 c-14,4.9-30.2,6.7-44.9,6.7s-30.9-1.9-44.9-6.7c-11-3.8-23.5-10.7-27.5-22.4c-0.9-2.5-1.3-5.1-1.3-7.7v-35.3 c0-22.6,20.7-28.5,39.4-30c2.2-0.2,4.3,1.2,5,3.3c1,2.9,6,5.4,8.6,6.4c6.4,2.5,13.9,3.5,20.8,3.5s14.4-1,20.8-3.5 c2.6-1,7.6-3.5,8.6-6.4C138.6,96.4,140.6,95.1,142.9,95.3L142.9,95.3z"
                ></path>
                <path
                  @click="clickFunc()"
                  style="cursor: pointer; pointer-events: auto"
                  class="fillWhite"
                  d="M39.6,160.6v-35.3c0-18.5,16.8-23.7,35-25.2c2.4,7.5,16.7,13.2,33.9,13.2s31.5-5.7,33.9-13.2 c18.2,1.4,35,6.7,35,25.2v35.3C177.4,203.3,39.6,203.3,39.6,160.6L39.6,160.6z"
                ></path>
                <path
                  @click="clickFunc()"
                  style="cursor: pointer; pointer-events: auto"
                  class="fillWhite"
                  d="M145.3,105.2c-2.9,4.2-7.9,7-12.4,8.8c-7.5,3-16.2,4.1-24.3,4.1s-16.8-1.2-24.3-4.1 c-4.5-1.8-9.5-4.6-12.4-8.8c-8.2,0.9-18.5,3-23.7,9c-2.7,3.2-3.7,7-3.7,11.1v35.3c0,1.6,0.2,3.1,0.8,4.6 c2.9,8.5,13.8,13.7,21.6,16.4c13,4.5,28.1,6.2,41.7,6.2s28.8-1.7,41.7-6.2c7.8-2.7,18.7-7.9,21.6-16.4c0.5-1.5,0.8-3,0.8-4.6v-35.3 c0-4.1-1-7.9-3.7-11.1C163.6,108.2,153.4,106.1,145.3,105.2L145.3,105.2z"
                  id="faceMask"
                ></path>
                <polygon
                  class="stBlue"
                  points="104.8,19.9 112.3,19.9 112.3,39.5 104.8,39.5"
                ></polygon>
                <path
                  class="strokeBlue fillSchaeffler glow"
                  d="M108.5,2.3c7.5,0,13.5,6.1,13.5,13.5s-6.1,13.5-13.5,13.5C101,29.3,95,23.2,95,15.8S101.1,2.3,108.5,2.3 L108.5,2.3z"
                ></path>
                <!--
              <circle cx="50%" cy="7.5%" r="0" fill-opacity="0" stroke="green" stroke-width="2px" stroke-opacity="1">
                <animate attributeName="r" from="0" to="45" dur="3s" repeatCount="indefinite" />
                <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite"></animate>
              </circle>
              
              <circle cx="50%" cy="7.5%" r="0" fill-opacity="0" stroke="green" stroke-width="2px" stroke-opacity="1">
                <animate attributeName="r" from="0" to="45" dur="3s" repeatCount="indefinite" begin="0.75s" />
                <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite" begin="0.75s"></animate>
              </circle>
              
              <circle cx="50%" cy="7.5%" r="0" fill-opacity="0" stroke="green" stroke-width="2px" stroke-opacity="1">
                <animate attributeName="r" from="0" to="45" dur="3s" repeatCount="indefinite" begin="1.5s" />
                <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite" begin="1.5s"></animate>
              </circle>  
              <circle cx="50%" cy="7.5%" r="12" fill="#006c27" stroke="#1f233e"></circle>        
              --></svg>

              <!-- EYES -->
              <svg
                id="eyes"
                viewBox="0 0 90.2 31.6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="eyeNormal" ref="eyeNormal">
                  <path
                    class="fillSchaeffler"
                    d="M11.9,0c6.3,0,11.4,7.1,11.4,15.8s-5.1,15.8-11.4,15.8S0.5,24.5,0.5,15.8C0.5,7.1,5.6,0,11.9,0 L11.9,0z"
                  ></path>
                  <path
                    class="fillSchaeffler"
                    d="M78.6,0c6.3,0,11.4,7.1,11.4,15.8s-5.1,15.8-11.4,15.8c-6.3,0-11.4-7.1-11.4-15.8S72.3,0,78.6,0 L78.6,0z"
                  ></path>
                </g>
                <g id="eyeBlink" ref="eyeBlink">
                  <path
                    class="fillSchaeffler"
                    d="M11.9,3c6.3,0,11.4,7.1,11.4,15.8S18.2,8.6,11.9,8.6S0.5,27.5,0.5,18.8C0.5,10.1,5.6,3,11.9,3 L11.9,3z"
                  ></path>
                  <path
                    class="fillSchaeffler"
                    d="M78.6,3c6.3,0,11.4,7.1,11.4,15.8S84.8,8.6,78.6,8.6c-6.3,0-11.4,18.9-11.4,10.2S72.3,3,78.6,3 L78.6,3z"
                  ></path>
                </g>
                <g id="eyeError" ref="eyeError">
                  <rect
                    class="fillWhite"
                    height="8.1"
                    width="41.8"
                    y="0.6"
                  ></rect>
                  <rect
                    class="fillWhite"
                    height="8.1"
                    width="41.8"
                    x="48.4"
                    y="0.6"
                  ></rect>
                </g>
              </svg>
              <!-- MOUTH -->
              <svg
                id="mouth"
                viewBox="0 0 28.1 13.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="fillSchaeffler"
                  d="M21.4,3.7c0.7,0.4,0.9,1.3,0.5,1.9c-0.8,1.4-2,2.5-3.3,3.3s-2.9,1.2-4.5,1.2s-3.2-0.4-4.5-1.2 C8.2,8.1,7.1,7,6.3,5.6C5.9,4.9,6.1,4.1,6.8,3.7C7.3,3.3,20.7,3.3,21.4,3.7z"
                  id="mouth3"
                  ref="mouth3"
                ></path>
                <path
                  class="fillSchaeffler hide"
                  d="M23.6,0.6c0.9,0.8,1.2,2.5,0.7,3.9c-1.1,2.8-2.6,5-4.4,6.6c-1.7,1.5-3.8,2.4-5.9,2.4 s-4.2-0.9-5.9-2.4C6.3,9.5,4.8,7.2,3.7,4.5C3.2,3.1,3.5,1.4,4.4,0.6C5.3-0.2,22.8-0.2,23.6,0.6z"
                  id="mouth2"
                  ref="mouth2"
                ></path>
                <path
                  class="fillSchaeffler hide"
                  d="M26.8,0.6c1.2,0.8,1.6,2.5,0.9,3.9c-1.4,2.8-3.4,5-5.8,6.6c-2.3,1.5-5,2.4-7.9,2.4 s-5.5-0.9-7.9-2.4C3.7,9.5,1.7,7.2,0.3,4.5C-0.4,3.1,0,1.4,1.2,0.6C2.4-0.2,25.6-0.2,26.8,0.6z"
                  id="mouth1"
                  ref="mouth1"
                ></path>
              </svg>
            </div>
          </div>
          <div
            ref="rBodyBox"
            class="rBodyBox hovering"
            id="rBodyBox"
            style="pointer-events: none"
          >
            <div id="rBodyBox2" class="move1" style="pointer-events: none">
              <!-- ARM RIGHT -->
              <svg
                id="armRight"
                ref="armRight"
                viewBox="0 0 30 42"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="stBlue"
                  d="M0.5,4.1c-0.7-1-0.6-2.4,0.3-3.2S3.1-0.1,4,0.7c4.5,3.6,8.7,8.5,12.3,12.9c2.6,3.2,5.1,6.5,7.4,9.8 c1.8,2.6,3.6,5.3,5.1,8c0.3,0.6,0.4,1.1,0.2,1.8c-1,4.2-4.4,6.8-8.4,7.9c-2.4,0.7-6.8,1.2-8.8-0.6c-0.9-0.8-2-2.8-2.6-3.9 c-1.2-2-2.3-4.1-3.4-6.1c-1.7-3.2-3.4-6.5-5.1-9.7c-0.4-0.8-0.4-1.6,0.1-2.4c1.5-2.5,1.6-5.8,1.2-8.6C1.9,8.4,1.2,5.2,0.5,4.1 L0.5,4.1z"
                ></path>
                <path
                  @click="clickFunc()"
                  style="cursor: pointer; pointer-events: auto"
                  class="fillWhite"
                  d="M2.4,2.6c8.6,7,20.5,23.1,24.2,30.1c-1.7,7-11.7,7.4-13.2,6.1C11.9,37.5,2.9,19.7,2.9,19.7 C6.7,13.4,3.5,4,2.4,2.6z"
                ></path>
              </svg>
              <!-- ARM LEFT -->
              <svg
                id="armLeft"
                ref="armLeft"
                viewBox="0 0 30 42"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="stBlue"
                  d="M29.5,4.1c0.7-1,0.6-2.4-0.3-3.2S26.9-0.1,26,0.7c-4.5,3.6-8.7,8.5-12.3,12.9c-2.6,3.2-5.1,6.5-7.4,9.8 c-1.8,2.6-3.6,5.3-5.1,8c-0.3,0.6-0.4,1.1-0.2,1.8c1,4.2,4.4,6.8,8.4,7.9c2.4,0.7,6.8,1.2,8.8-0.6c0.9-0.8,2-2.8,2.6-3.9 c1.2-2,2.3-4.1,3.4-6.1c1.7-3.2,3.4-6.5,5.1-9.7c0.4-0.8,0.4-1.6-0.1-2.4c-1.5-2.5-1.6-5.8-1.2-8.6C28.1,8.4,28.8,5.2,29.5,4.1 L29.5,4.1z"
                ></path>
                <path
                  @click="clickFunc()"
                  style="cursor: pointer; pointer-events: auto"
                  class="fillWhite"
                  d="M27.6,2.6C19,9.6,7.1,25.7,3.4,32.7c1.7,7,11.7,7.4,13.2,6.1c1.5-1.3,10.5-19.1,10.5-19.1 C23.3,13.4,26.5,4,27.6,2.6z"
                ></path>
              </svg>
              <!-- BODY -->
              <svg
                id="body"
                viewBox="0 0 122.7 104.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="body_1_">
                  <path
                    class="stBlue"
                    d="M114.1,2c4.7,6.6,7.5,14.1,8.3,22.2c1.4,14.9-4.3,29.9-12.1,42.3c-6.3,10-14.7,19.4-24,26.7 c-6.6,5.1-16.3,11.3-25,11.3s-18.4-6.2-25-11.3c-9.3-7.3-17.7-16.8-24-26.7C-0.1,46.8-6.1,22.3,8.5,2c1.3-1.8,3.4-2.4,5.5-1.7 c15,5.3,31.4,7.6,47.3,7.6s32.3-2.3,47.3-7.6C110.7-0.5,112.8,0.2,114.1,2L114.1,2z"
                  ></path>
                  <path
                    class="fillSchaeffler"
                    d="M110.2,4.8c27.5,38.5-26.6,95-48.9,95s-76.4-56.6-48.9-95c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z"
                  ></path>
                  <path
                    @click="clickFunc()"
                    style="cursor: pointer; pointer-events: auto"
                    class="fillWhite"
                    d="M110.2,4.8c13.1,18.3,7.7,40.8-4,59.3c-17.3-4.8-21.1-9.8-22.9-20.8c-2.3-14-15.8-18.4-22-18.1 c-6.2-0.3-19.6,4.1-22,18.1c-1.8,11-5.6,16-22.9,20.8C4.7,45.6-0.7,23.2,12.4,4.8c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z"
                  ></path>
                  <!--
                  <path
                    class="stBlue"
                    d="M114.1,2c14.6,20.3,8.6,44.9-3.9,64.6c-1.1,1.8-3.3,2.6-5.3,2c-8.2-2.3-17.5-5.5-22.3-13 c-2.3-3.5-3.3-7.6-4-11.7c-0.8-4.8-3.3-8.6-7.4-11.2c-2.6-1.6-6.5-3.1-9.6-3c-0.2,0-0.3,0-0.5,0c-3.1-0.2-7.1,1.3-9.6,3 c-4.1,2.6-6.6,6.4-7.4,11.2c-0.7,4.1-1.7,8.1-4,11.7c-4.8,7.5-14.2,10.7-22.3,13c-2.1,0.6-4.2-0.2-5.3-2C0,46.9-5.9,22.3,8.6,2 c1.3-1.8,3.4-2.4,5.5-1.7c15,5.3,31.4,7.6,47.3,7.6s32.3-2.3,47.3-7.6C110.7-0.5,112.8,0.2,114.1,2L114.1,2z"
                  ></path>
                  -->
                  <path
                    @click="clickFunc()"
                    style="cursor: pointer; pointer-events: auto"
                    class="fillWhite"
                    d="M110.2,4.8c13.1,18.3,7.7,40.8-4,59.3c-17.3-4.8-21.1-9.8-22.9-20.8c-2.3-14-15.8-18.4-22-18.1 c-6.2-0.3-19.6,4.1-22,18.1c-1.8,11-5.6,16-22.9,20.8C4.7,45.6-0.7,23.2,12.4,4.8c14.1,5,30.9,7.9,48.9,7.9S96.1,9.8,110.2,4.8 L110.2,4.8z"
                  ></path>
                </g>
                <path
                  style="
                    transform: scale(0.7) translate(27px, 35px);
                    fill: white;
                  "
                  class="fillSchaeffler"
                  d="M 57.984 17.018 C 42.779 17.018 37.753 28.893 37.753 34.915 C 37.753 51.738 52.561 53.582 55.261 54.369 C 66.038 57.621 72.769 57.404 72.769 66.236 C 72.769 71.621 68.256 75.767 63.042 75.767 C 54.233 75.767 48.553 69.752 47.479 61.177 H 38.336 V 83.355 H 47.674 V 79.269 C 52.335 83.152 58 84.911 64.015 84.911 C 75.882 84.911 84.441 76.826 84.441 64.68 C 84.441 50.65 75.936 47.444 67.128 44.837 C 58.312 42.246 49.425 40.939 49.425 34.721 C 49.425 29.157 53.277 25.967 58.569 25.967 C 62.172 25.967 65.066 27.328 67.323 29.468 C 69.478 31.538 71.081 34.409 71.797 37.833 H 80.746 V 18.38 H 71.214 V 22.465 C 67.696 19.126 63.019 17.018 57.984 17.018 Z"
                ></path>
                <!--
                <path
                  class="fillSchaeffler"
                  style="transform: translate(-27px, -55px) scale(0.15)"
                  d="M312.984,527.018c-15.205,0-20.231,11.875-20.231,17.897
                c0,16.823,14.808,18.667,17.508,19.454c10.777,3.252,17.508,3.035,17.508,11.867c0,5.385-4.513,9.531-9.727,9.531
                c-8.809,0-14.489-6.015-15.563-14.59h-9.143v22.178h9.338v-4.086c4.661,3.883,10.326,5.642,16.341,5.642
                c11.867,0,20.426-8.085,20.426-20.231c0-14.03-8.505-17.236-17.313-19.843c-8.816-2.591-17.703-3.898-17.703-10.116
                c0-5.564,3.852-8.754,9.144-8.754c3.603,0,6.497,1.361,8.754,3.501c2.155,2.07,3.758,4.941,4.474,8.365h8.949v-19.453h-9.532v4.085
                C322.696,529.126,318.019,527.018,312.984,527.018z M377.57,527.018c-15.555,0-32.876,10.614-32.876,33.46
                c0,19.406,14.372,34.433,33.071,34.433c14.123,0,25.398-7.61,29.18-23.149h-10.699c-3.603,7.182-8.762,12.256-18.481,12.256
                c-13.757,0-21.399-10.723-21.399-23.15c0-12.497,7.828-23.15,21.399-23.15c3.688,0,7.455,1.253,10.505,3.307
                c3.058,2,5.525,4.7,6.419,8.365h9.338v-21.01h-9.338v4.279C389.468,527.726,379.632,527.018,377.57,527.018z M413.559,528.379
                v8.948h7.003V584.6h-7.003v8.755h25.679V584.6h-7.003v-20.62h23.15v20.62h-6.809v8.755h25.484V584.6h-7.003v-47.272h7.003v-8.948
                h-25.484v8.948h6.809v16.341h-23.15v-16.341h7.003v-8.948H413.559z M495.847,528.379v8.948h7.198L486.898,584.6h-5.447v8.755
                h23.344V584.6h-7.003l4.475-12.645h19.648l4.474,12.645h-7.198v8.755h23.928V584.6h-4.863l-15.757-47.272h7.003v-8.948H495.847z
                M552.651,528.379v8.948h7.003V584.6h-7.003v8.755h49.995V573.9h-10.31V584.6h-21.01v-20.62h18.092v-10.311h-18.092v-16.341h21.01
                v8.365h10.31v-17.313H552.651z M614.708,528.379v8.948h7.003V584.6h-7.003v8.755h26.263V584.6h-7.587v-20.62h19.453v-10.311
                h-19.453v-16.341h21.787v8.365h10.311v-17.313H614.708z M673.263,528.379v8.948h7.004V584.6h-7.004v8.755h26.263V584.6h-7.587
                v-20.62h19.453v-10.311h-19.453v-16.341h21.788v8.365h10.31v-17.313H673.263z M734.152,528.379v8.948h7.393V584.6h-7.393v8.755
                h44.743v-19.26h-10.311V584.6h-15.368v-47.272h7.976v-8.948H734.152z M785.51,528.379v8.948h6.809V584.6h-6.809v8.755h49.995V573.9
                h-10.311V584.6H803.99v-20.62h18.092v-10.311H803.99v-16.341h21.204v8.365h10.311v-17.313H785.51z M845.815,528.379v8.948h7.003
                V584.6h-7.003v8.755h25.873V584.6h-7.197v-20.62h7.003c4.777,0,11.283,0.514,11.283,6.613v22.762h18.092V584.6h-6.42v-14.784
                c0-3.953-0.607-8.521-7.976-10.311v-0.195c6.824-2.871,10.115-7.034,10.115-14.59c0-0.988,0.249-5.183-2.724-8.949
                c-6.101-8.116-15.609-7.392-20.037-7.392H845.815z M864.491,537.328h6.225c6.101,0,14.396-0.016,14.396,7.976
                c0,8.739-6.567,9.727-13.034,9.727h-7.586V537.328z M512.188,541.218h0.195l7.198,21.789h-14.785L512.188,541.218z"
                ></path>
                -->
              </svg>
            </div>
          </div>
          <div
            class="rBodyBox hoveringShadow"
            id="rBodyBox"
            style="z-index: -1; pointer-events: none"
          >
            <svg
              id="rShadow"
              viewBox="0 0 74.7 9.2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="rShadow"
                d="M37.3,0c20.4,0,37,2.1,37,4.6s-16.6,4.6-37,4.6s-37-2.1-37-4.6C0.4,2,16.9,0,37.3,0L37.3,0z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "Henry",
  data() {
    return {
      blinkeyes: true,
      talkingInt: null,
    };
  },
  computed: {
    scaleC: function () {
      if (this.scale) {
        return this.scale;
      }
      return 1;
    },
  },
  props: {
    scale: {
      type: Number,
      required: false,
    },
    talking: {
      type: Boolean,
      required: false,
    },
    leftArm: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    talking: {
      handler(val) {
        if (val) {
          this.startTalking();
        } else {
          this.stopTalking();
        }
      },
    },
    leftArm: {
      handler(val) {
        if (val) {
          this.armLeftUp();
        } else {
          this.armLeftDown();
        }
      },
    },
  },
  mounted() {
    const self = this;
    if (this.blinkeyes) {
      setTimeout(this.blinkEyesFunc, 2000);
    }

    this.$emitter.on("leftArmUp", () => {
      this.armLeftUp();
    });

    this.$emitter.on("leftArmDown", () => {
      this.armLeftDown();
    });

    this.$emitter.on("waveArms", () => {
      self.armLeftUp();
      self.armRightUp();
      setTimeout(function () {
        self.armLeftDown();
        self.armRightDown();
      }, 1000);
    });

    this.$emitter.on("leftArmDown", () => {
      this.armLeftDown();
    });

    this.$emitter.on("say", (message, animation, nobubble) => {
      //console.log("message:" + message)
      //console.log("animation:" + animation)
      //console.log("nobubble:" + nobubble)
      var speech = this.$refs.speech;
      var newDiv = document.createElement("div");
      //var newContent = document.createTextNode(message);
      //newDiv.appendChild(newContent); // füge den Textknoten zum neu erstellten div hinzu.
      newDiv.innerHTML = message.trim();
      if (nobubble) {
        newDiv.classList.add(
          "speechnobubble",
          "animate__fadeInUp",
          "animate__animated"
        );
      } else {
        newDiv.classList.add(
          "speechbubble",
          "animate__fadeInUp",
          "animate__animated"
        );
      }

      speech.insertBefore(newDiv, speech.firstChild);

      //this.logger.log("Henry says: {}", message)();
      this.startTalking();
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      newDiv.addEventListener("transitionend", () => {
        newDiv.parentNode.removeChild(newDiv);
      });

      newDiv.addEventListener("animationend", (anim) => {
        if (anim.animationName === "fadeInUp") {
          newDiv.classList.remove("animate__fadeInUp");
          if (animation != undefined && animation != "") {
            newDiv.classList.add("animate__" + animation);
          } else {
            this.stopTalking();
            newDiv.classList.add("animate__fadeOut");
            newDiv.style.setProperty("--animate-duration", "8s");
            newDiv.style.setProperty("--animate-delay", "4s");
            newDiv.style.transitionDelay = "1s";
            newDiv.style.top = "-" + height / 4 + "px";
          }
        } else if (anim.animationName === animation) {
          this.stopTalking();
          newDiv.classList.remove("animate__" + animation);
          newDiv.classList.add("animate__fadeOut");
          newDiv.style.setProperty("--animate-duration", "5s");
          newDiv.style.setProperty("--animate-delay", "4s");
          newDiv.style.transitionDelay = "1s";
          newDiv.style.top = "-" + height / 4 + "px";
        }
      });
    });
  },
  destroyed() {
    this.blinkeyes = false;
  },
  methods: {
    clickFunc() {
      this.$emit("click");
    },
    blinkEyesFunc() {
      if (this.blinkeyes) {
        var next = Math.floor(Math.random() * (4000 - 1000)) + 1000;
        this.blinkEyes();
        setTimeout(this.blinkEyesFunc, next);
      }
    },
    moveALittleFunc() {
      var next = Math.floor(Math.random() * (7000 - 5000)) + 5000;
      this.moveALittle();
      setTimeout(this.moveALittleFunc, next);
    },
    moveALittle() {
      var body = this.$refs.rBodyBox2;
      var head = this.$refs.rHeadBox2;
      if (head.classList.contains("move1")) {
        body.classList.remove("move2");
        head.classList.remove("move1");
      } else {
        body.classList.add("move2");
        head.classList.add("move1");
      }
    },
    armRightUp() {
      var arm = this.$refs.armRight;
      arm.classList.add("armRightAnim");
    },
    armRightDown() {
      var arm = this.$refs.armRight;
      arm.classList.remove("armRightAnim");
    },
    armLeftUp() {
      var arm = this.$refs.armLeft;
      arm.classList.add("armLeftAnim");
    },
    armLeftDown() {
      var arm = this.$refs.armLeft;
      arm.classList.remove("armLeftAnim");
    },
    blinkEyes() {
      this.closeEyes();
      const self = this;
      setTimeout(function () {
        self.openEyes();
      }, 100);
    },
    closeEyes() {
      var blink = this.$refs.eyeBlink;
      var open = this.$refs.eyeNormal;
      var error = this.$refs.eyeError;
      blink.classList.remove("hide");
      blink.classList.add("showBlock");
      open.classList.remove("showBlock");
      open.classList.add("hide");
      error.classList.remove("showBlock");
      error.classList.add("hide");
    },
    openEyes() {
      var blink = this.$refs.eyeBlink;
      var open = this.$refs.eyeNormal;
      var error = this.$refs.eyeError;
      blink.classList.remove("showBlock");
      blink.classList.add("hide");
      open.classList.remove("hide");
      open.classList.add("showBlock");
      error.classList.remove("showBlock");
      error.classList.add("hide");
    },
    errorEyes() {
      var blink = this.$refs.eyeBlink;
      var open = this.$refs.eyeNormal;
      var error = this.$refs.eyeError;
      blink.classList.remove("showBlock");
      blink.classList.add("hide");
      open.classList.remove("hide");
      open.classList.add("showBlock");
      error.classList.remove("hide");
      error.classList.add("showBlock");
    },
    moveFore() {
      var body = this.$refs.rBodyBox;
      var head = this.$refs.rHeadBox;
      body.classList.add("rAnim");
      head.classList.add("rAnim");
    },
    moveBack() {
      var body = this.$refs.rBodyBox;
      var head = this.$refs.rHeadBox;
      body.classList.remove("rAnim");
      head.classList.remove("rAnim");
    },
    stopTalking() {
      var mouth1 = this.$refs.mouth1;
      var mouth2 = this.$refs.mouth2;
      var mouth3 = this.$refs.mouth3;
      clearInterval(this.talkingInt);
      this.talkingInt = null;
      mouth1.classList.add("hide");
      mouth2.classList.add("hide");
      mouth3.classList.remove("hide");
    },
    startTalking() {
      if (this.talkingInt != null) {
        return;
      }
      var fsm = 0;
      var mouth1 = this.$refs.mouth1;
      var mouth2 = this.$refs.mouth2;
      var mouth3 = this.$refs.mouth3;
      this.talkingInt = setInterval(function () {
        if (fsm === 0) {
          mouth1.classList.add("hide");
          mouth2.classList.remove("hide");
          mouth3.classList.add("hide");
        } else if (fsm === 1) {
          mouth1.classList.add("hide");
          mouth2.classList.add("hide");
          mouth3.classList.remove("hide");
        } else if (fsm === 2) {
          mouth1.classList.add("hide");
          mouth2.classList.remove("hide");
          mouth3.classList.add("hide");
        } else if (fsm === 3) {
          mouth1.classList.remove("hide");
          mouth2.classList.add("hide");
          mouth3.classList.add("hide");
        }
        if (fsm > 3) {
          fsm = 0;
        } else {
          fsm++;
        }
      }, 170);
    },
  },
};
</script>

<style type="css">
.speechbubble {
  white-space: nowrap;
  font-size: 17px;
  border: 1px solid grey;
  box-shadow: 0 0 10px rgb(189, 189, 189);
  right: 50px;
  background: white;
  color: rgb(77, 77, 77);
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  top: -70px;
  transition: all 5s;
}
.speechnobubble {
  font-size: 17px;
  right: 50px;
  background: transparent;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: -30px;
  transition: all 5s;
}
</style>

<style type="css" scoped>
.glow {
  animation-name: glow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes glow {
  from {
    fill: #006c27;
  }
  50% {
    fill: #00aa3e;
  }
  to {
    fill: #006c27;
  }
}

.hoveringShadow {
  animation-name: hoveringShadow;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes hoveringShadow {
  from {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(0.8) translateY(10px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.hovering {
  animation-name: hovering;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes hovering {
  from {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0px, 4px);
  }
  to {
    transform: translate(0, 0px);
  }
}

.move1 {
  animation-name: move1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 1, 0, 0);
}
@keyframes move1 {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(2deg);
  }
  to {
    transform: rotate(-0);
  }
}
.move2 {
  animation-name: move2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 1, 0, 0);
}
@keyframes move2 {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-1deg);
  }
  to {
    transform: rotate(-0);
  }
}

.wrapper {
  /*width: 100%;*/
  /*height: 100%;*/
  font-family: Roboto;
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
}

.robotBox {
  position: relative;
}
.robotInner {
  width: 141px;
  height: 182px;
  position: relative;
}
#rBodyBox {
  width: 100%;
  height: 53px;
  top: 99px;
  left: 0;
  position: absolute;
}
#rBodyBox,
#rHeadBox {
  transition: 1.5s;
}
#rHeadBox.rAnim {
  transform: translateX(-35px) rotate(-5deg);
}
#rBodyBox.rAnim {
  transform: translateX(-33px) rotate(-2deg);
}
#body {
  width: 63px;
  height: 53px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
#armLeft,
#armRight {
  width: 30px;
  height: 42px;
  top: 2px;
  position: absolute;
  transition: 0.5s;
}
#armLeft {
  left: 17px;
  transform-origin: top right;
}
#armRight {
  right: 17px;
  transform-origin: top left;
}
.armRightAnim {
  transform: rotate(-80deg) translateY(-3px) translateX(-8px);
}
.armLeftAnim {
  transform: rotate(80deg) translateY(-3px) translateX(8px);
}
#rShadow {
  width: 38px;
  height: 5px;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#rHead,
#rHeadBox {
  width: 91px;
  height: 110px;
}
#rHeadBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
#rHeadBox.rHeadBoxAnim {
  animation: shake 0.8s;
}
@keyframes shake {
  0% {
    transform: translate(5px, 0);
  }
  10% {
    transform: translate(-5px, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  30% {
    transform: translate(-5px, 0);
  }
  40% {
    transform: translate(5px, 0);
  }
  50% {
    transform: translate(-5px, 0);
  }
  60% {
    transform: translate(5px, 0);
  }
  70% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(5px, 0);
  }
  90% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(5px, 0);
  }
}
#rHead {
  position: absolute;
  left: 0;
  top: 0;
}
#eyes {
  transform: scaleY(0.79);
  width: 38px;
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 59px;
  margin: auto;
}
#eyeBlink {
  display: none;
}
#eyeError {
  display: none;
}
#mouth,
.mouth {
  transform: scale(1.6);
  width: 15px;
  height: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: 81px;
  margin: auto;
}
.muteCircle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  top: -9px;
  z-index: 9000;
  cursor: pointer;
}
.muteHide {
  display: none !important;
}
.muteCircleIcon {
  width: 57%;
  height: 57%;
  fill: #fff;
  display: none;
  opacity: 0.9;
}
.introSub {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 10px 0 0;
  font-weight: 500;
  color: #909090;
}
.speechCorner {
  position: absolute;
  right: 100px;
  bottom: -35px;
}
.st0 {
  fill: #000;
  opacity: 0.3;
}
.strokeBlue {
  stroke: #1f233e;
  stroke-width: 3.4999;
  stroke-miterlimit: 2.6131;
}
.strokeBlue2 {
  stroke-width: 4.2;
}
.st5 {
  opacity: 0;
}
.st8 {
  opacity: 0;
}
#eyesError {
  display: none;
}
.stBlue {
  fill: #1f233e;
}
.fillWhite {
  fill: #fff;
}
.fillPink {
  fill: #ffc3c5;
}
.fillSchaeffler {
  fill: #006c27;
}

.speechWrap {
  width: 100%;
  height: 100%;
  padding: 0;

  justify-content: center;
  z-index: 1000;
}
form {
  position: relative;
  z-index: 0;
}
.speech {
  width: 100%;
  padding: 30px;
  padding-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  background: #fff;
  flex-shrink: 0;
}
.speech.hldy:after,
.speech.hldy:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    #f5624d,
    #cc231e,
    #34a65f,
    #0f8a5f,
    #235e6f,
    #f5624d,
    #cc231e,
    #34a65f,
    #0f8a5f,
    #235e6f
  );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  border-radius: 5px;
}
.speech.hldy2 {
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.9);
  border: 1px solid #cc231e;
}
.speech.vday {
  box-shadow: 0 0 25px 2px rgba(255, 0, 0, 0.6);
  border: 1px solid #cc231e;
}
@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.speech.hldy:after {
  -webkit-filter: blur(15px);
  filter: blur(15px);
}
.formTitle {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  margin: 0;
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}
.showBlock {
  display: block !important;
}
.isAndroid.Chrome .robotBox {
  padding-top: 140px;
  top: 8vh;
}
.isAndroid.Chrome .content {
  padding-bottom: 12%;
}
.isAndroid.Chrome .speechCorner {
  display: none;
}
.isAndroid.Firefox .content {
  padding-top: 30%;
}
.isAndroid.Samsung .content,
.isAndroid.UC .content {
  padding-bottom: 45%;
}
</style>
