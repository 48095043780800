var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "300" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { align: "center" } }, [
                    _vm._v(" Please confirm deletion "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "#006c27" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v(" Delete ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "#006c27" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }