<template>
  <v-dialog width="100%" v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title>
        <div>Delete Application</div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form ref="form" v-model="deleteValid" v-on:submit.prevent="nop">
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="application2delete"
                id="appNameInput"
                label="Application name"
                placeholder="Please provide the application name"
                outlined
                :rules="[equalsApplicationName]"
                dense
                autocomplete="new-password"
                color="#006c27"
                :error-messages="serverErrorDelete"
              >
              </v-text-field>
              <v-checkbox
                style="margin-top: 0px"
                v-if="safeDelete"
                v-model="safeDeleteInternal"
                color="#006c27"
                hide-details
                label="SafeDelete"
              ></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="#006c27"
                id="deleteButton"
                class="white--text"
                :disabled="!deleteValid"
                @click="deleteApplication()"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :dark="false" :absolute="true" :value="deleting" opacity="0.1">
      <v-container fill-height fluid>
        <v-row>
          <v-col align="center" justify="center">
            <v-progress-circular
              align="center"
              :size="120"
              :width="13"
              color="#006c27"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "ApplicationDeleteOverlay",
  components: {},
  props: {
    value: Boolean,
    application: Object,
    safeDelete: Boolean,
  },
  data: () => ({
    application2delete: "",
    deleteValid: false,
    deleting: false,
    serverErrorDelete: null,
    safeDeleteInternal: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    equalsApplicationName(value) {
      this.serverErrorDelete = null;
      if (value != this.application.displayName) {
        return "Value does not match the application name";
      }
      return true;
    },
    nop() {},
    close() {
      this.show = false;
      this.$refs.form.reset();
    },
    deleteApplication() {
      this.deleting = true;
      cass
        .deleteApplication(
          this.application.applicationId,
          this.safeDeleteInternal
        )
        .then((response) => {
          console.log(response);
          this.$router
            .push({
              name: "Home",
            })
            .catch(() => {
              console.log("Something bad happend .. start over");
              window.location.reload();
            });
          this.close();
        })
        .catch((err) => {
          console.log(err);
          var casserror = cass.handleError(err);
          this.serverErrorDelete = casserror.message;
        })
        .finally(() => {
          this.deleting = false;
        });
    },
  },
};
</script>

<style scoped></style>
