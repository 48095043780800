<template>
  <v-dialog width="100%" v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title>
        <div>Rename Application</div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form ref="form" v-model="renameValid" v-on:submit.prevent="nop">
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="newName"
                id="appNameInput"
                label="Application name"
                placeholder="Please provide the application name"
                outlined
                :rules="[notEmpty, min, rules.validName]"
                dense
                autocomplete="new-password"
                color="#006c27"
                :error-messages="serverErrorRename"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="#006c27"
                id="renameButton"
                class="white--text"
                :disabled="!renameValid"
                @click="renameApplication()"
              >
                Rename
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :dark="false" :absolute="true" :value="renaming" opacity="0.1">
      <v-container fill-height fluid>
        <v-row>
          <v-col align="center" justify="center">
            <v-progress-circular
              align="center"
              :size="120"
              :width="13"
              color="#006c27"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "ApplicationRenameOverlay",
  components: {},
  props: {
    value: Boolean,
    application: Object,
  },
  data: () => ({
    newName: "",
    application2rename: "",
    renameValid: false,
    renaming: false,
    serverErrorRename: null,
    rules: {
      validName: (value) => {
        let pattern = /^(\w|\.|-)+$/;
        if (!pattern.test(value)) {
          return "given name contains invalid characters";
        }
        return true;
      },
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    notEmpty(value) {
      if (value == null || value == undefined) {
        return "Name can not be empty";
      }
      if (value.length == "") {
        return "Name can not be empty";
      }
      return true;
    },
    min(v) {
      return (v || "").length >= 5 || `Name must be at least 5 characters long`;
    },
    nop() {},
    close() {
      this.show = false;
      this.$refs.form.reset();
    },
    renameApplication() {
      this.renaming = true;
      cass
        .patchApplication(this.application.applicationId, {
          displayName: this.newName,
        })
        .then((response) => {
          console.log(response);
          // emit new name to parent
          console.log("Emitting newname: ", this.newName);
          this.$emit("newname", this.newName);
          this.close();
        })
        .catch((err) => {
          console.log(err);
          var casserror = cass.handleError(err);
          this.serverErrorRename = casserror.message;
        })
        .finally(() => {
          this.renaming = false;
        });
    },
  },
};
</script>

<style scoped></style>
