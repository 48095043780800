<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="6">
        <v-card>
          <v-card-title class="text--secondary">
            Environments
          </v-card-title>
          <v-card-text class="text--primary my-3">
            <environment-content></environment-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EnvironmentContent from "../components/EnvironmentContent.vue";
export default {
  name: "Environments",
  components: { EnvironmentContent },
  mounted() {},
  data() {
    return {};
  },
  filters: {},
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #006c27;
}
</style>
