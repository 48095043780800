<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-card :loading="loading" :width="1200" class="mt-8 mb-8">
        <template slot="progress">
          <v-progress-linear
            color="#006c27"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="grey--text text--darken-2">
          <div>Manage Users</div>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            color="#006c27"
            hide-details
            @keyup="applyFilter"
            style="padding-top: 0; margin-top: 0;"
          >
            <template slot="append">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                </template>
                <span
                  >You can wildcard search for email, firstname or
                  lastname</span
                >
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text class="text--primary my-3">
          <v-data-table
            sort-by="email"
            :custom-sort="customSort"
            must-sort
            :headers="headers"
            :items="filteredUsers"
            hide-default-footer
            disable-pagination
            class="elevation-1"
            item-key="email"
            @click:row="rowClicked"
          >
            <template slot="no-data">
              <div v-if="loading">
                Loading Users, please wait ...
              </div>
              <div v-else>
                No users found :(
              </div>
            </template>
            <template v-slot:[`item.roles`]="{ item }">
              <div v-if="item.roles == undefined">
                never logged in
              </div>
              <div v-else>
                {{ String(item.roles) }}
              </div>
            </template>
            <template v-slot:[`item.environments`]="{ item }">
              <template v-for="(env, index) in item.environments">
                <v-chip
                  v-if="env == 'development'"
                  small
                  dark
                  class="mr-1"
                  :color="getEnvironmentColor(env.toUpperCase())"
                  :key="index"
                >
                  D
                </v-chip>
                <v-chip
                  v-if="env == 'quality'"
                  small
                  dark
                  class="mr-1"
                  :color="getEnvironmentColor(env.toUpperCase())"
                  :key="index"
                >
                  Q
                </v-chip>
                <v-chip
                  v-if="env == 'production'"
                  small
                  dark
                  :color="getEnvironmentColor(env.toUpperCase())"
                  :key="index"
                >
                  P
                </v-chip>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="#006c27" dark class="mr-3" @click="rescan"
            >rescan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <user-detail-overlay
      v-model="showUserDialog"
      :user="selectedUser"
      @saved="userUpdated"
    >
    </user-detail-overlay>
    <v-snackbar v-model="snackBar" timeout="2000" color="#006c27">
      {{ snackText }}
    </v-snackbar>
    <user-rescan-overlay @scanned="scanned" v-model="showRescanDialog">
    </user-rescan-overlay>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import UserDetailOverlay from "../components/UserDetailOverlay.vue";
import UserRescanOverlay from "../components/UserRescanOverlay.vue";

export default {
  name: "ManageUsers",
  components: { UserDetailOverlay, UserRescanOverlay },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      snackBar: false,
      snackText: "",
      users: [],
      filteredUsers: [],
      selectedUser: null,
      showUserDialog: false,
      showRescanDialog: false,
      search: "",
      loading: false,
      headers: [
        {
          text: "eMail",
          value: "email",
          filterable: false,
          sortable: true,
        },
        {
          text: "Firstname",
          value: "firstname",
          filterable: false,
          sortable: false,
          width: "10%",
        },
        {
          text: "Lastname",
          value: "lastname",
          filterable: false,
          sortable: false,
          width: "10%",
        },
        {
          text: "Environment",
          value: "environments",
          filterable: false,
          sortable: false,
          width: 140,
        },
        {
          text: "Roles",
          value: "roles",
          filterable: false,
          sortable: false,
        },
      ],
    };
  },
  filters: {},
  computed: {},
  created() {},
  methods: {
    scanned(message) {
      this.userUpdated(message);
    },
    userUpdated(message) {
      this.snackBar = true;
      this.snackText = message;
      this.loadData();
    },
    applyFilter() {
      this.filteredUsers = [];
      this.users.forEach((user) => {
        var search = this.search.toUpperCase();

        var found1 = user.email.toUpperCase().indexOf(search) != -1;
        var found2 = user.lastname.toUpperCase().indexOf(search) != -1;
        var found3 = user.firstname.toUpperCase().indexOf(search) != -1;

        if (found1 || found2 || found3) {
          this.filteredUsers.push(user);
        }
      });
    },
    rescan() {
      this.showRescanDialog = true;
    },
    rowClicked(item) {
      console.log(item);
      this.selectedUser = item;
      this.showUserDialog = true;
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index]
              .toLowerCase()
              .localeCompare(b[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          } else {
            return b[index]
              .toLowerCase()
              .localeCompare(a[index].toLowerCase(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          }
        }
      });
      return items;
    },
    loadData() {
      console.log("Loading data ...");
      this.users = [];
      this.filteredUsers = [];
      this.loading = true;
      cass
        .getAllUsers()
        .then((response) => {
          console.log("Getting successful response");
          console.log(response);
          // remove invalid users
          response.data.forEach((user) => {
            if (user.email.trim() != "") {
              this.users.push(user);
            }
          });
          if (this.search != "") {
            this.applyFilter();
          } else {
            this.filteredUsers = this.users;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting sapapplications");
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
