<template>
  <div>
    <v-app-bar
      app
      elevation="0"
      style="border-bottom: 1px solid grey !important"
    >
      <v-app-bar-nav-icon
        :disabled="!isUserLoggedIn"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <span
        style="cursor: pointer"
        @click="goHome"
        class="text--secondary text-h6 d-none d-sm-flex"
      >
        Consumer Application Self Service
      </span>
      <span class="text--secondary text-h6 d-flex d-sm-none"> CASS </span>

      <v-spacer></v-spacer>

      <theme-button v-if="isUserLoggedIn"></theme-button>

      <v-btn
        v-if="!isUserLoggedIn"
        icon
        :disabled="!isUserLoggedIn"
        @click="SignIn()"
        color="#646464"
        class="mr-1"
      >
        <v-icon>mdi-login</v-icon>
      </v-btn>

      <v-menu v-if="isUserLoggedIn && userInfo" v-model="menu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-badge
              v-if="isUserAdmin"
              color="red"
              icon="mdi-star"
              offset-x="25px"
              offset-y="25px"
            >
              <v-list-item-avatar class="mr-3" v-bind="attrs" v-on="on">
                <img v-if="userPhoto" :src="userPhoto" />
                <v-avatar v-if="!userPhoto" color="#006C27" size="40">
                  <span class="white--text">{{ nameinitials }}</span>
                </v-avatar>
              </v-list-item-avatar>
            </v-badge>
            <v-list-item-avatar
              class="mr-3"
              v-bind="attrs"
              v-on="on"
              v-if="!isUserAdmin"
            >
              <img v-if="userPhoto" :src="userPhoto" />
              <v-avatar v-if="!userPhoto" color="#006C27" size="40">
                <span class="white--text">{{ nameinitials }}</span>
              </v-avatar>
            </v-list-item-avatar>
          </div>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  >{{ userInfo.givenName }}
                  {{ userInfo.surname }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <div>{{ userInfo.jobTitle }}</div>
                  <div
                    :style="
                      isUserAdmin
                        ? 'color: red; cursor: pointer'
                        : 'cursor: pointer'
                    "
                    v-if="isUserAdmin"
                    @click="toogleAdmin"
                  >
                    CASS Administrator
                  </div>
                  <div v-if="!isUserAdmin">CASS User</div>
                  <div v-if="isBetaUser">Beta User</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-star-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="mt-1">
                <v-list-item-title>Active Environments</v-list-item-title>
                <v-list-item-subtitle>
                  <template v-for="(env, index) in userEnvironments">
                    <div :key="index">{{ env }}</div>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group>
              <v-list-item @click="logout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <div class="d-flex align-center">
        <v-img
          alt="Schaeffler Logo"
          class="shrink mr-2"
          contain
          :src="$getImagePath('schaeffler-logo.svg')"
          transition="scale-transition"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer fixed v-model="drawer" temporary :width="325">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item @click="home">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Home</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="apiportal">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >API Portal</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="developerportal">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-code-tags</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Developer Portal</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="permissionModel">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-key-chain</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Permission Models</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="environments">
            <v-list-item-icon>
              <v-icon color="#006c27"
                >mdi-numeric-3-box-multiple-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Environments</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="feedback">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-comment-quote-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Feedback</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="mtlsaccount">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-certificate-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Non-CASS Apps
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="openpermissionrequest">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Open Permission Requests
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isUserAdmin" @click="opendeletedapps">
            <v-list-item-icon>
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Deleted Apps
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="community">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Community</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="isUserAdmin" @click="manage">
            <v-list-item-icon>
              <v-icon color="#006c27">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1 text--secondary"
              >Manage</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ThemeButton from "./ThemeButton.vue";

export default {
  name: "SchaefflerHeader",
  components: { ThemeButton },
  data() {
    return {
      drawer: false,
      isUserAdmin: false,
      isBetaUser: false,
      isUserLoggedIn: false,
      userInfo: null,
      userPhoto: null,
      userEnvironments: null,
      menu: false,
    };
  },
  created() {
    console.log("created");
    this.$emitter.on("quit_session", () => {
      console.log("quit_session");
      this.userInfo = null;
      this.isUserLoggedIn = false;
    });
    this.$emitter.on("login", () => {
      console.log("SchaefflerHeader: got login event");
      this.userInfo = this.$userData.graphUserInfo;
      this.userPhoto = this.$userData.graphUserPhoto;
      this.userEnvironments = this.$userData.cass.environments;
      this.isUserAdmin = this.$userData.cass.isUserAdmin;
      this.isBetaUser = this.$userData.cass.isBetaUser;
      this.isUserLoggedIn = true;
    });
  },
  mounted() {
    console.log("mounted");
  },
  computed: {
    nameinitials: function () {
      if (
        this.userInfo !== null &&
        this.userInfo.givenName !== null &&
        this.userInfo.surname !== null
      ) {
        return (
          this.userInfo.givenName.charAt(0) + this.userInfo.surname.charAt(0)
        );
      }
      if (this.userInfo.displayName !== null) {
        return this.userInfo.displayName.charAt(0).toUpperCase();
      }
      return "S";
    },
  },
  methods: {
    toogleAdmin() {
      this.$userData.cass.isUserAdmin = !this.$userData.cass.isUserAdmin;
      if (this.$userData.cass.isUserAdmin) {
        console.log("Admin mode enabled");
      } else {
        console.log("Admin mode disabled");
      }
    },
    goHome() {
      if (!this.isUserLoggedIn) {
        return;
      }
      if (this.$route.name != "Home") {
        this.$router
          .push({
            name: "Home",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    SignIn() {
      //console.log("SignIn");
      //this.$authService.signIn();
    },
    home() {
      this.goHome();
      this.drawer = false;
    },
    developerportal() {
      let url = "https://developer.schaeffler.com";

      window.open(url, "_blank").focus();
      this.drawer = false;
    },
    apiportal() {
      let url = "https://apiportal-dev.schaeffler.com/";

      window.open(url, "_blank").focus();
      this.drawer = false;
    },
    logout() {
      console.log("LOGOUT");
      this.$router
        .push({
          name: "Logout",
        })
        .catch((err) => {
          console.log(err);
          console.log("Something bad happend .. start over");
          window.location.reload();
        });
    },
    permissionModel() {
      if (this.$route.name != "PermissionModel") {
        this.$router
          .push({
            name: "PermissionModel",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    environments() {
      if (this.$route.name != "Environments") {
        this.$router
          .push({
            name: "Environments",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    feedback() {
      if (this.$route.name != "Feedback") {
        this.$router
          .push({
            name: "Feedback",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    openpermissionrequest() {
      if (this.$route.name != "OpenPermissionRequest") {
        this.$router
          .push({
            name: "OpenPermissionRequest",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    opendeletedapps() {
      if (this.$route.name != "DeletedApps") {
        this.$router
          .push({
            name: "DeletedApps",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    mtlsaccount() {
      if (this.$route.name != "NonCassApplications") {
        this.$router
          .push({
            name: "NonCassApplications",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    community() {
      let url =
        "msteams://teams.microsoft.com/l/team/19%3aOGG-WbPKZOPQ66Tloj-o0NaNdDFInZT0QJORGHMlQyg1%40thread.tacv2/conversations?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f";
      window.open(url, "_blank").focus();
      this.drawer = false;
    },
    manage() {
      if (this.$route.name != "ManageUsers") {
        this.$router
          .push({
            name: "ManageUsers",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
  },
};
</script>

<style scoped>
.v-btn.v-btn--has-bg {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}
.v-btn.v-btn--has-bg:hover {
  background-color: #006c27;
}

.v-btn.v-btn--text {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}

.v-btn.v-btn--text:hover {
  background-color: white;
}

.v-btn.v-btn--plain {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}

.v-btn.v-btn--plain:hover {
  background-color: white;
  color: #006c27;
}

.v-btn.v-btn--icon:hover {
  background-color: white !important;
  color: #006c27 !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #646464 !important;
  opacity: 0.4 !important;
}

.text-nospacing {
  letter-spacing: 0 !important;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}

.no-underline {
  text-decoration: none;
}
</style>
