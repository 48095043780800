import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const authStore = new Vuex.Store({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_CLIENTID,
          authority: process.env.VUE_APP_AUTHORITY,
        },
        cache: {
          cacheLocation: "sessionStorage",
        },
        system: {
          allowRedirectInIframe: true,
        },
      },
      accessToken: "",
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
  },
});

export default authStore;
