<template>
  <v-expansion-panels multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-3">
        <div style="display: flex; align-items: center">
          <v-icon medium color="#006c27">
            mdi-newspaper-variant-outline
          </v-icon>
          <span class="ml-2" style="padding-top: 2px"
            >CASS Version: {{ CASSWebAppBuildVersion }}</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="padding-left: 0px">
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Date </v-col>
          <v-col cols="8" style="text-align: left">
            {{ CASSWebAppBuildDateValue }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Mode </v-col>
          <v-col cols="8" style="text-align: left">
            {{ CASSWebAppBuildModeValue }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Branch </v-col>
          <v-col cols="8" style="text-align: left">
            {{ CASSWebAppBuildGitBranch }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Commit </v-col>
          <v-col cols="8" style="text-align: left">
            {{ CASSWebAppBuildGitCommit }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git URL </v-col>
          <v-col cols="8" style="text-align: left">
            <a target="_blank" :href="CASSWebAppBuildGitURL">{{
              CASSWebAppBuildGitURL
            }}</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Container Image </v-col>
          <v-col cols="8" style="text-align: left">
            {{ CASSWebAppBuildContainerImage }}
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- ----------->
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-3">
        <div style="display: flex; align-items: center">
          <v-icon medium color="#006c27"> mdi-api </v-icon>
          <span class="ml-2" style="padding-top: 2px"
            >CASS Version: {{ versionCASSAPI.version }}</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="padding-left: 0px">
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Date </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionCASSAPI.buildDate }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Compiler </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionCASSAPI.compiler }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Branch </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionCASSAPI.branch }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Commit </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionCASSAPI.commit }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git URL </v-col>
          <v-col cols="8" style="text-align: left">
            <a target="_blank" :href="versionCASSAPI.gitURL">{{
              versionCASSAPI.gitURL
            }}</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Container Image </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionCASSAPI.image }}
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- ----------->
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-3">
        <div style="display: flex; align-items: center">
          <v-icon medium color="#006c27"> mdi-api </v-icon>
          <span class="ml-2" style="padding-top: 2px"
            >Rating Version: {{ versionRATEAPI.version }}</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="padding-left: 0px">
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Date </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionRATEAPI.buildDate }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Compiler </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionRATEAPI.compiler }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Branch </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionRATEAPI.branch }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Commit </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionRATEAPI.commit }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git URL </v-col>
          <v-col cols="8" style="text-align: left">
            <a target="_blank" :href="versionRATEAPI.gitURL">{{
              versionRATEAPI.gitURL
            }}</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Container Image </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionRATEAPI.image }}
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- ----------->
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-3">
        <div style="display: flex; align-items: center">
          <v-icon medium color="#006c27"> mdi-api </v-icon>
          <span class="ml-2" style="padding-top: 2px"
            >AAL Version: {{ versionAALAPI.version }}</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="padding-left: 0px">
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Date </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionAALAPI.buildDate }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Build Compiler </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionAALAPI.compiler }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Branch </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionAALAPI.branch }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git Commit </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionAALAPI.commit }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Git URL </v-col>
          <v-col cols="8" style="text-align: left">
            <a target="_blank" :href="versionAALAPI.gitURL">{{
              versionAALAPI.gitURL
            }}</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" style="text-align: left"> Container Image </v-col>
          <v-col cols="8" style="text-align: left">
            {{ versionAALAPI.image }}
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "VersionContent",
  props: {
    versionCASSAPI: Object,
    versionRATEAPI: Object,
    versionAALAPI: Object,
  },
  data() {
    return {
      CASSWebAppBuildVersion: process.env.VUE_APP_BUILD_VERSION,
      CASSWebAppBuildModeValue: process.env.VUE_APP_BUILD_MODE,
      CASSWebAppBuildDateValue: process.env.VUE_APP_BUILD_DATE,
      CASSWebAppBuildGitCommit: process.env.VUE_APP_BUILD_COMMIT,
      CASSWebAppBuildGitBranch: process.env.VUE_APP_BUILD_BRANCH,
      CASSWebAppBuildGitURL: process.env.VUE_APP_BUILD_GIT_URL,
      CASSWebAppBuildContainerImage: process.env.VUE_APP_BUILD_IMAGE,
    };
  },
};
</script>

<style scoped>
a:link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}
</style>
