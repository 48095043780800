<template>
  <div class="terminal-window">
    <header>
      <div class="button green"></div>
      <div class="button yellow"></div>
      <div class="button red"></div>
    </header>
    <section class="terminal">
      <div class="history"></div>
      <span class="prompt" v-html="typeValue"></span>
      <span class="typed-cursor">{{ cursor }}</span>
    </section>
  </div>
</template>

<script>
export default {
  name: "TypeWriter",
  props: {
    text: String,
    title: String,
  },
  data() {
    return {
      typeValue: "$ ",
      currentPos: 0,
      nextDelay: 40,
      cursor: "",
    };
  },
  mounted() {
    this.typeEffect();
  },
  computed: {},
  watch: {},
  methods: {
    typeEffect() {
      var rc = this.processElement();
      var delay = rc.nextDelay;

      while (rc.status) {
        rc = this.processElement();
        if (rc.nextDelay > delay) {
          delay = rc.nextDelay;
        }
      }
      if (delay > 40) {
        setTimeout(this.typeEffect, delay);
        return;
      }

      if (this.currentPos < this.text.length) {
        this.typeValue += this.text.charAt(this.currentPos++);
        setTimeout(this.typeEffect, delay);
      } else {
        this.cursor = "|";
        setTimeout(() => {
          this.$emitter.emit(
            "say",
            "<h4>&nbsp;" + this.title + "&nbsp;</h4>",
            "rubberBand"
          );
        }, 0);
        setTimeout(() => {
          this.$emitter.emit(
            "say",
            "<h2>&nbsp;❤️&nbsp;</h2>",
            "rubberBand",
            true
          );
        }, 2000);

        this.$emitter.emit("waveArms");
      }
    },
    processElement() {
      var rc = {};
      if (this.text.charAt(this.currentPos) == "<") {
        // tag detected
        var temp = "";
        temp += this.text.charAt(this.currentPos++);
        while (this.text.charAt(this.currentPos) != ">") {
          temp += this.text.charAt(this.currentPos);
          this.currentPos++;
        }
        temp += this.text.charAt(this.currentPos++);
        this.typeValue += temp;
        if (temp === "</br>") {
          rc.nextDelay = 300;
        } else {
          rc.nextDelay = 40;
        }
        rc.status = true;
        return rc;
      } else {
        rc.nextDelay = 40;
        rc.status = false;
        return rc;
      }
    },
  },
};
</script>

<style scoped>
.terminal-window {
  text-align: left;

  margin-right: 40px;
  height: 360px;
  border-radius: 10px;

  position: relative;
}

.link {
  color: white;
  background-color: transparent;
}

.terminal-window header {
  background: #e0e8f0;
  height: 30px;
  border-radius: 8px 8px 0 0;
  padding-left: 10px;
}

.terminal-window header .button {
  width: 12px;
  height: 12px;
  margin: 10px 4px 0 0;
  display: inline-block;
  border-radius: 8px;
}

.terminal-window header .button.green {
  background: #3bb662;
}

.terminal-window header .button.yellow {
  background: #e5c30f;
}

.terminal-window header .button.red {
  background: #e75448;
}

.terminal-window section.terminal {
  color: white;
  font-family: Menlo, Monaco, "Consolas", "Courier New", "Courier";
  font-size: 11pt;
  background: #30353a;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 30px;
  bottom: 0;
  overflow: auto;
}

.terminal-window section.terminal .typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.terminal-data {
  display: none;
}
.terminal-window .gray {
  color: gray;
}
.terminal-window .green {
  color: green;
}
</style>
