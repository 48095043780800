var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800px" },
          model: {
            value: _vm.showInformation,
            callback: function ($$v) {
              _vm.showInformation = $$v
            },
            expression: "showInformation",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("How does it work?")]),
              _c("v-card-text", [
                _vm._v(
                  "The Open Permission Request Page shows you all the permission requests which you (or your team) is responsible for."
                ),
                _c("br"),
                _vm._v(
                  "The responsibility is derived from your ownership of the Azure App Registration the API belongs to."
                ),
                _c("br"),
                _vm._v(
                  "If you are owner of an Azure App Registration and this App Registration belongs to an API, you are allowed to display the permission requests."
                ),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "A Permission request from an API Consumer can have three states before it is approved and usable."
                ),
                _c("br"),
                _vm._v(
                  " 1. The request is open and needs to be granted by the DDM."
                ),
                _c("br"),
                _vm._v(
                  " 2. The request is granted by the DDM but has not yet a technical confirmation."
                ),
                _c("br"),
                _vm._v(
                  " 3. The request is granted and technically confirmed but the Azure Global Admins did not consented yet."
                ),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "All three states (which are blocking for the API Consumer) can be seen here and revisted."
                ),
                _c("br"),
                _vm._v(
                  " If the admin consent needs more 24h, please contact api-mgmt-support@schaeffler.com for further investigation!"
                ),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "This page should support you in getting an overview in case your permission email is lost."
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center", align: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-8 mb-8",
              attrs: { loading: _vm.loading, width: 1200 },
            },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "#006c27",
                      height: "10",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "grey--text text--darken-2" },
                [
                  _vm._v(" Open Permission Requests "),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-2 white--text",
                      attrs: { color: "#006c27", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.showInformation = true
                        },
                      },
                    },
                    [_vm._v(" What is it? ")]
                  ),
                ],
                1
              ),
              _c("v-card-text", { staticClass: "grey--text text--darken-2" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("v-switch", {
                      attrs: {
                        color: "#006c27",
                        "hide-details": "",
                        inset: "",
                        disabled: _vm.loading,
                        label: "Show All APIs",
                      },
                      on: { change: _vm.showAllChanged },
                      model: {
                        value: _vm.showAll,
                        callback: function ($$v) {
                          _vm.showAll = $$v
                        },
                        expression: "showAll",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text--primary my-3" },
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "elevation-1",
                      attrs: {
                        "sort-by": "displayName",
                        "must-sort": "",
                        headers: _vm.headers,
                        items: _vm.data,
                        "show-expand": "",
                        "hide-default-footer": "",
                        "disable-pagination": "",
                        "item-key": "Appreg.id",
                        expanded: _vm.expanded,
                      },
                      on: {
                        "click:row": _vm.clickRow,
                        "update:expanded": function ($event) {
                          _vm.expanded = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: `item.azureURL`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      dark: "",
                                      color: "#006c27",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goTo(item.azureURL)
                                      },
                                    },
                                  },
                                  [_vm._v(" Azure Portal ")]
                                ),
                              ]
                            },
                          },
                          {
                            key: "expanded-item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  { style: { backgroundColor: _vm.getColor } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 headerline" },
                                      [_vm._v("Requested By")]
                                    ),
                                    _vm._l(
                                      item.PermissionEvents,
                                      function (permissionrequest, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "A" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { color: "#006c27" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-account-circle-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    permissionrequest.events[0]
                                                      .createdBy
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  { style: { backgroundColor: _vm.getColor } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 headerline" },
                                      [_vm._v("Requested At")]
                                    ),
                                    _vm._l(
                                      item.PermissionEvents,
                                      function (permissionrequest, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "B" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { color: "#006c27" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-clock-time-eight-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.timeFormat(
                                                      permissionrequest
                                                        .events[0].createdAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  { style: { backgroundColor: _vm.getColor } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 headerline" },
                                      [_vm._v("Open Permission Request")]
                                    ),
                                    _vm._l(
                                      item.PermissionEvents,
                                      function (permissionrequest, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "C" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  style: {
                                                    visibility:
                                                      permissionrequest
                                                        .events[0].state !=
                                                      "REQUESTED"
                                                        ? "hidden"
                                                        : "unset",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "#006c27",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goTo(
                                                        permissionrequest.permissionURL
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Open ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  { style: { backgroundColor: _vm.getColor } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 headerline" },
                                      [_vm._v("Open Technical Confirm")]
                                    ),
                                    _vm._l(
                                      item.PermissionEvents,
                                      function (permissionrequest, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "C" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  style: {
                                                    visibility:
                                                      permissionrequest
                                                        .events[0].state !=
                                                      "APPROVED_BY_API_OWNER"
                                                        ? "hidden"
                                                        : "unset",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "#006c27",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goTo(
                                                        permissionrequest.permissionURL
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Open ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  { style: { backgroundColor: _vm.getColor } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-2 headerline" },
                                      [_vm._v("Open Admin Consent")]
                                    ),
                                    _vm._l(
                                      item.PermissionEvents,
                                      function (permissionrequest, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "C" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  style: {
                                                    visibility:
                                                      permissionrequest
                                                        .events[0].state !=
                                                      "CREATED_SNOW_TICKET"
                                                        ? "hidden"
                                                        : "unset",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "#006c27",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goTo(
                                                        permissionrequest.permissionURL
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Open ")]
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ml-2",
                                                  style: {
                                                    visibility:
                                                      permissionrequest
                                                        .events[0].state !=
                                                      "CREATED_SNOW_TICKET"
                                                        ? "hidden"
                                                        : "unset",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    small: "",
                                                    dark: "",
                                                    color: "#006c27",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.goTo(
                                                        _vm.getSnowTicketLink(
                                                          permissionrequest
                                                            .events[0]
                                                            .snowTicketNumber
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        permissionrequest
                                                          .events[0]
                                                          .snowTicketNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("td", {
                                  style: { backgroundColor: _vm.getColor },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _vm.loading
                          ? _c("div", [
                              _vm._v(
                                " Loading permission requests, please wait ... "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                " You do not have any open permission requests Great! "
                              ),
                            ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }