<template>
  <v-dialog width="100%" v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title> Upload Signed Certificate</v-card-title>
      <v-card-text class="pb-0">
        <!--UPLOAD-->
        <div class="dropbox">
          <input
            type="file"
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="filesChange($event)"
            accept="*"
            class="input-file"
          />

          <v-row class="pt-7">
            <v-col align="center">
              <v-icon x-large>mdi-cloud-upload-outline</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <p class="subtitle-2" v-if="isInitial || isFailed">
                Drag signed certificate here<br />
                or click to browse
              </p>
              <p class="subtitle-2" v-if="isSaving">
                Uploading Signed Certificate ...
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text>
        <!--SUCCESS-->
        <div v-if="isSuccess">
          <v-icon color="#006c27">mdi-check-outline</v-icon>
          <span class="subtitle-2">
            Upload successful!
          </span>
        </div>
        <!--FAILED-->
        <div v-if="isFailed">
          <p class="red--text subtitle-2">Upload failed.</p>
          <div><strong>Error message:</strong> {{ uploadError }}</div>
          <div v-if="this.requestId">
            <strong>Request Id:</strong> {{ requestId }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn color="#006c27" light class="white--text" @click.stop="close()">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "SignedCertUploadOverlay",
  data() {
    return {
      uploadError: null,
      requestId: null,
      currentStatus: null,
      uploadFieldName: "file",
    };
  },
  mounted() {
    this.reset();
  },
  props: {
    value: Object,
  },
  computed: {
    show: {
      get() {
        return this.value.show;
      },
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    close() {
      this.$emit("close", true);
      this.reset();
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
      this.response = null;
      //this.$emitter.emit("cert_update");
    },
    save(certdata) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      cass
        .uploadSignedCert(
          this.value.applicationId,
          this.value.certificateId,
          certdata
        )
        .then((x) => {
          console.log(x);
          this.currentStatus = STATUS_SUCCESS;
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.uploadError = err.response.data.message;
          this.requestId = err.response.data.requestId;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(event) {
      this.currentStatus = STATUS_SAVING;
      this.uploadError = "";
      this.requestId = "";
      let file = event.target.files[0];

      if (!file) {
        this.currentStatus = STATUS_FAILED;
        this.uploadError = "Can not find or access given file";
        event.target.value = "";
        return;
      }

      console.log(file.type);

      var reader = new FileReader();
      let self = this;
      reader.onload = function(e) {
        var contents = e.target.result;
        if (contents.startsWith("-----BEGIN CERTIFICATE-----")) {
          console.log("Load file");
          self.save(contents);
          event.target.value = "";
        } else {
          console.log("invalid file");
          self.currentStatus = STATUS_FAILED;
          self.uploadError = "Provided File is no PEM certificate";
          event.target.value = "";
          return;
        }
      };
      reader.onerror = (evt) => {
        console.error(evt);
        self.currentStatus = STATUS_FAILED;
        self.uploadError = "Error loading file";
        event.target.value = "";
        return;
      };

      reader.readAsText(file);
      event.target.value = "";
    },
  },
};
</script>

<style scoped>
.dropbox {
  outline: 2px grey; /* the dash box */
  outline-offset: -10px;
  background: #dde3e6;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #1d9bb2; /* when mouse over to the drop zone, change color */
  color: white;
}
</style>
