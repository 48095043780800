var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    {
      staticStyle: { "border-top": "1px solid grey !important" },
      attrs: { padless: "" },
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "7" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: {
                plain: "",
                href: "https://www.schaeffler.de/content.schaeffler.de/en/meta/imprint/index.jsp",
                target: "_blank",
              },
            },
            [_vm._v("Legal notice")]
          ),
          _c("span", { staticClass: "text-caption" }, [_vm._v("|")]),
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: {
                plain: "",
                href: "https://www.schaeffler.de/content.schaeffler.de/en/meta/privacy-policy/index.jsp",
                target: "_blank",
              },
            },
            [_vm._v("Privacy policy")]
          ),
          _c("span", { staticClass: "text-caption" }, [_vm._v("|")]),
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: {
                plain: "",
                href: "https://www.schaeffler.de/content.schaeffler.de/en/meta/condition-of-use/index.jsp",
                target: "_blank",
              },
            },
            [_vm._v("Conditions of use")]
          ),
          _c("span", { staticClass: "text-caption" }, [_vm._v("|")]),
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: {
                plain: "",
                href: "msteams://teams.microsoft.com/l/channel/19%3ab5675378ef994edcba137b634e342ed5%40thread.tacv2/CASS%2520(Consumer%2520Application%2520Self%2520Service)?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
                target: "_blank",
              },
            },
            [_vm._v("Contact us")]
          ),
          _c("span", { staticClass: "text-caption" }, [_vm._v("|")]),
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: { plain: "" },
              on: { click: _vm.showVersionDialog },
            },
            [_vm._v("Version: " + _vm._s(_vm.version))]
          ),
          _c("span", { staticClass: "text-caption" }, [_vm._v("|")]),
          _c(
            "v-btn",
            {
              staticClass: "text-nospacing",
              attrs: { plain: "" },
              on: { click: _vm.showVisionMissionDialog },
            },
            [_vm._v("Vision & Mission")]
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "text-right pr-6 text-caption", attrs: { cols: "5" } },
        [_vm._v(" © Schaeffler Technologies AG & Co. KG")]
      ),
      _c("version-overlay", {
        on: { click: _vm.showVersionDialog, close: _vm.closeVersionDialog },
        model: {
          value: _vm.versionDialog,
          callback: function ($$v) {
            _vm.versionDialog = $$v
          },
          expression: "versionDialog",
        },
      }),
      _c("vision-overlay", {
        on: {
          click: _vm.showVisionMissionDialog,
          close: _vm.closeVisionMissionDialog,
        },
        model: {
          value: _vm.visionDialog,
          callback: function ($$v) {
            _vm.visionDialog = $$v
          },
          expression: "visionDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }