import axios from "axios";
import Vue from "vue";

class AnnounceClient {
  instance = axios.create({
    baseURL: process.env.VUE_APP_AAL_URL,
    timeout: 10000,
  });

  constructor() {
    console.log("AnnounceClient constructor");

    this.instance.interceptors.request.use(
      async function (config) {
        // Do something before request is sent
        let token = await Vue.prototype.$authService.getAccessToken({
          scopes: [process.env.VUE_APP_AAL_SCOPE],
        });
        config.headers.Authorization = `Bearer ${token.accessToken}`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }
  handleError(error) {
    let err;
    console.log(error);
    if (error.response && error.response.data) {
      err = error.response.data;
      if (err.message === "Error validating claims. Not in app user group") {
        err.message =
          "You do not have all needed permissions to use the Consumer Application Self Service. Please contact api-mgmt-support@schaeffler.com to get needed group membership.";
      }
    } else {
      err = {
        message: "network error occurred. please retry.",
        requestId: "not available",
      };
    }
    // This feature enables slot buttons to do something which needs more time
    err.loading = false;
    return err;
  }
  getAnnouncements() {
    console.log("getAnnouncements");
    return this.instance.get("announcements");
  }
  confirmAnnoncement(uuid) {
    console.log("confirmAnnoncement");
    return this.instance.post("announcements/" + uuid + "/confirm");
  }
  getVersion() {
    console.log("getVersion");
    return this.instance.get("version", {});
  }
}

const announce = new AnnounceClient();

export default announce;
