var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    { attrs: { loading: true } },
    [
      _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
        _c(
          "div",
          [
            _c("v-icon", [_vm._v(" mdi-account-key-outline ")]),
            _c("span", { staticClass: "ml-1" }, [_vm._v("Permissions")]),
          ],
          1
        ),
      ]),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm.application.permissions.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("v-col", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(" No permissions available "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.application.permissions.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
          _vm.application.permissions.length != 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "80px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "9" },
                    },
                    [_c("strong", [_vm._v("Name")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "3" },
                    },
                    [_c("strong", [_vm._v("Status")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.sortedPermissions, function (permission, index) {
            return [
              _c(
                "v-list-item",
                {
                  key: index,
                  attrs: { dense: "", selectable: false },
                  on: {
                    click: function ($event) {
                      return _vm.doIt(permission)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [_c("v-icon", [_vm._v("mdi-shield-key-outline")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "9" },
                            },
                            [
                              _vm._v(" " + _vm._s(permission.name) + " "),
                              permission.deprecation
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        outlined: "",
                                        color: "red",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDeprecation(
                                            $event,
                                            permission.deprecation
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDeprecationChipText(
                                              permission.deprecation.State
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "3" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatString")(permission.status)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _vm.isUserSchaeffler()
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.isAuditLogDisabled(
                                                      permission
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.auditLog(
                                                      $event,
                                                      permission
                                                    )
                                                  },
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "mdi-script-text-key-outline"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Audit Log")])]
                          )
                        : _vm._e(),
                      permission.status !== "NOT_REQUESTED"
                        ? _c(
                            "v-icon",
                            {
                              attrs: { disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.onDeletePermission(
                                    $event,
                                    permission.permissionId
                                  )
                                },
                              },
                            },
                            [_vm._v("mdi-trash-can-outline")]
                          )
                        : _vm._e(),
                      permission.status === "NOT_REQUESTED"
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                disabled:
                                  _vm.loading ||
                                  (permission.deprecation &&
                                    permission.deprecation.State !== "ACTIVE"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onTransferPermission(
                                    $event,
                                    permission
                                  )
                                },
                              },
                            },
                            [_vm._v("mdi-plus-circle-outline")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { key: index + 100 }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c(
                "v-list-item-icon",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    { on: { click: _vm.msgraphpermissions } },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-script-text-key-outline")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Use this field to add API permissions from Azure!"
                        ),
                        _c("br"),
                        _vm._v(" Click the Icon to get more information!"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticStyle: { width: "100%" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.nop.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.isPermissionValid,
                    callback: function ($$v) {
                      _vm.isPermissionValid = $$v
                    },
                    expression: "isPermissionValid",
                  },
                },
                [
                  _c("v-text-field", {
                    ref: "textfield",
                    attrs: {
                      rules: [_vm.rules.permissionRules],
                      "error-messages": _vm.severError,
                      counter: "",
                      maxlength: "120",
                      label: "Add MS Azure Permission",
                      placeholder: "Enter valid azure permission",
                      dense: "",
                      color: "#006c27",
                    },
                    model: {
                      value: _vm.addPermission,
                      callback: function ($$v) {
                        _vm.addPermission = $$v
                      },
                      expression: "addPermission",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        loading: _vm.loading,
                        disabled: !_vm.isPermissionValid,
                      },
                      on: { click: _vm.onAddPermission },
                      scopedSlots: _vm._u([
                        {
                          key: "loader",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "custom-loader" },
                                [
                                  _c("v-icon", { attrs: { light: "" } }, [
                                    _vm._v("mdi-cached"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
      _c("permission-detail-overlay", {
        on: { close: _vm.closePermissionDetail },
        model: {
          value: _vm.detaildialog,
          callback: function ($$v) {
            _vm.detaildialog = $$v
          },
          expression: "detaildialog",
        },
      }),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeletePermission },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c("permission-submit", {
        attrs: {
          permissionName: _vm.permissionName,
          permissionRequestText: _vm.permissionRequestText,
          application: _vm.application,
        },
        on: { submit: _vm.submit },
        model: {
          value: _vm.permissionSubmit,
          callback: function ($$v) {
            _vm.permissionSubmit = $$v
          },
          expression: "permissionSubmit",
        },
      }),
      _c("deprecation-detail-overlay", {
        attrs: { deprecation: _vm.selectedDeprecation },
        on: {
          close: function ($event) {
            _vm.showDeprecationDetail = false
          },
        },
        model: {
          value: _vm.showDeprecationDetail,
          callback: function ($$v) {
            _vm.showDeprecationDetail = $$v
          },
          expression: "showDeprecationDetail",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }