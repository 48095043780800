var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { width: "100%", persistent: "", "max-width": "500" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "max-width": "500" } },
            [
              _c(
                "v-card-title",
                { staticClass: "text--secondary" },
                [
                  _c("v-icon", { staticClass: "mr-3" }, [
                    _vm._v("mdi-card-account-details-outline"),
                  ]),
                  _vm._v(" User Details "),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "text--primary pb-2" },
                [
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.userCopy.email,
                      label: "eMail",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c("v-textarea", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.userCopy.firstname,
                      label: "Firstname",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      "auto-grow": true,
                      rows: "1",
                      color: "#006c27",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.userCopy.lastname,
                      label: "Lastname",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.userCopy.roles || "never logged in",
                      label: "Roles",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.userCopy.environments,
                      label: "Environments",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.userCopy.applicationLimit,
                          label: "Application Limit",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      }),
                      _c(
                        "v-icon",
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          ref: "incAppLimit",
                          staticClass: "ml-2 mt-3",
                          attrs: { large: "", color: "#006c27", dark: "" },
                          on: { click: _vm.incAppLimit },
                        },
                        [_vm._v(" mdi-plus-circle-outline ")]
                      ),
                      _c(
                        "v-icon",
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          ref: "decAppLimit",
                          staticClass: "mt-3",
                          attrs: { large: "", color: "#006c27", dark: "" },
                          on: { click: _vm.decAppLimit },
                        },
                        [_vm._v(" mdi-minus-circle-outline ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.userCopy.certificateLimit,
                          label: "Certificate Limit",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      }),
                      _c(
                        "v-icon",
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          ref: "incCertLimit",
                          staticClass: "ml-2 mt-3",
                          attrs: { large: "", color: "#006c27", dark: "" },
                          on: { click: _vm.incCertLimit },
                        },
                        [_vm._v(" mdi-plus-circle-outline ")]
                      ),
                      _c(
                        "v-icon",
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          ref: "decCertLimit",
                          staticClass: "mt-3",
                          attrs: { large: "", color: "#006c27", dark: "" },
                          on: { click: _vm.decCertLimit },
                        },
                        [_vm._v(" mdi-minus-circle-outline ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-2 mt-2",
                      attrs: { color: "#006c27" },
                      on: {
                        click: function ($event) {
                          return _vm.done()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.actionLabel) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }