var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "template",
            { slot: "progress" },
            [
              _c("v-progress-linear", {
                attrs: { color: "#006c27", height: "10", indeterminate: "" },
              }),
            ],
            1
          ),
          _c("v-card-title", [
            _c("div", { staticStyle: { color: "#616161" } }, [
              _vm._v(" Processing ... "),
            ]),
          ]),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            _vm._l(_vm.messages, function (message, index) {
              return _c(
                "v-row",
                {
                  key: index,
                  staticClass: "mb-1",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-col", { attrs: { cols: "11" } }, [
                    _vm._v(" " + _vm._s(message.text) + " "),
                    _c("strong", [_vm._v(_vm._s(message.producttitle))]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      message.state === "pending"
                        ? _c("v-icon", [_vm._v(" mdi-motion-pause-outline ")])
                        : _vm._e(),
                      message.state === "inprogress"
                        ? _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v(" mdi-cog-outline mdi-spin "),
                          ])
                        : _vm._e(),
                      message.state === "done"
                        ? _c("v-icon", { attrs: { color: "#006c27" } }, [
                            _vm._v(" mdi-check "),
                          ])
                        : _vm._e(),
                      message.state === "error"
                        ? _c("v-icon", { attrs: { color: "red" } }, [
                            _vm._v(" mdi-alert-circle "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }