<template>
  <v-dialog width="100%" v-if="show" v-model="show" persistent max-width="500">
    <v-card max-width="500">
      <v-card-title class="text--secondary"> Permission Details </v-card-title>
      <v-card-text class="text--primary pb-2">
        <v-text-field
          v-if="value.permission?.proxyName"
          :value="value.permission.proxyName"
          label="Proxy Relation"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
        </v-text-field>
        <v-text-field
          :value="value.permission.name"
          label="Permission Name"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
        </v-text-field>
        <v-textarea
          :value="value.permission.description"
          label="Permission Description"
          outlined
          dense
          readonly
          hide-details
          :auto-grow="true"
          rows="1"
          class="mt-3"
          color="#006c27"
        ></v-textarea>
        <v-text-field
          :value="formatString(value.permission.type)"
          label="Permission Model"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-text-field>
        <v-text-field
          :value="value.permission.tokenScope"
          label="Token Scope"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="#006c27" class="white--text" @click="done()">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PermissionDetailOverlay",
  props: {
    value: Object,
    permission: Object,
  },
  computed: {
    show: {
      get() {
        return this.value.show;
      },
    },
  },
  methods: {
    done() {
      this.$emit("close", true);
    },
    formatString(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style></style>
