var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "template",
            { slot: "progress" },
            [
              _c("v-progress-linear", {
                attrs: { color: "#006c27", height: "10", indeterminate: "" },
              }),
            ],
            1
          ),
          _c(
            "v-card-title",
            [
              _c("div", { staticStyle: { color: "#616161" } }, [
                _vm._v("Subscribe to API"),
              ]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c(
                "v-text-field",
                {
                  staticClass: "pl-1 pr-5 pb-5",
                  attrs: {
                    label: "Search",
                    "single-line": "",
                    disabled: _vm.showSelected,
                    color: "#006c27",
                    "hide-details": "",
                  },
                  on: { input: _vm.filterData },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _vm.search == ""
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b({}, "v-icon", attrs, false),
                                            on
                                          ),
                                          [_vm._v("mdi-magnify")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3058243105
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "You can wildcard search for product or API name."
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.search != ""
                        ? _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.search = ""
                                  _vm.filterData()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("div", { staticStyle: { overflow: "hidden" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "429px",
                      height: "429px",
                      "overflow-y": "scroll",
                    },
                  },
                  [
                    _vm.productsRaw.length == 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-container",
                              { attrs: { "fill-height": "", fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-14 text-h5" },
                                          [_vm._v("Loading Products ...")]
                                        ),
                                        _c("div", {}, [
                                          _vm._v("Please standby"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.products.length == 0 && _vm.productsRaw.length != 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-container",
                              { attrs: { "fill-height": "", fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-14 text-h5" },
                                          [_vm._v("No results")]
                                        ),
                                        _c("div", {}, [
                                          _vm._v("Please try again"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-expansion-panels",
                      {
                        staticClass: "pl-1 pr-5 pt-2 pb-2",
                        attrs: { multiple: false },
                        model: {
                          value: _vm.expanded,
                          callback: function ($$v) {
                            _vm.expanded = $$v
                          },
                          expression: "expanded",
                        },
                      },
                      [
                        _vm._l(_vm.products, function (product) {
                          return [
                            _c(
                              "v-expansion-panel",
                              { key: "P" + product.name },
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c("v-checkbox", {
                                              staticClass: "shrink mr-2 mt-0",
                                              attrs: {
                                                color: "#006c27",
                                                value: product.name,
                                                indeterminate: !product.valid,
                                                disabled: !product.valid,
                                                "hide-details": "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.calcApplyDisabled(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.selected,
                                                callback: function ($$v) {
                                                  _vm.selected = $$v
                                                },
                                                expression: "selected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-1" },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "mdi-package-variant-closed"
                                              ),
                                            ]),
                                            _vm._v(" " + _vm._s(product.title)),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-expansion-panel-content",
                                  { staticStyle: { "padding-left": "0px" } },
                                  [
                                    _vm._l(
                                      product.apiProxies,
                                      function (proxy, index) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            {
                                              key:
                                                product.name +
                                                proxy.name +
                                                index,
                                              staticClass: "ml-8",
                                              attrs: {
                                                dense: "",
                                                selectable: false,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-api"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {},
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { attrs: { cols: "" } },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                proxy.name
                                                              ) +
                                                              " "
                                                          ),
                                                          proxy.deprecation
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showDeprecation(
                                                                          proxy.deprecation
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getDeprecationChipText(
                                                                          proxy
                                                                            .deprecation
                                                                            .State
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-checkbox", {
                staticClass: "ml-6",
                attrs: { color: "#006c27", label: "Show selected" },
                on: { change: _vm.filterData },
                model: {
                  value: _vm.showSelected,
                  callback: function ($$v) {
                    _vm.showSelected = $$v
                  },
                  expression: "showSelected",
                },
              }),
              _c("v-checkbox", {
                staticClass: "ml-6",
                attrs: {
                  color: "#006c27",
                  label: "Show All",
                  disabled: _vm.showSelected,
                },
                on: { change: _vm.filterData },
                model: {
                  value: _vm.showAll,
                  callback: function ($$v) {
                    _vm.showAll = $$v
                  },
                  expression: "showAll",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mb-1",
                                  attrs: { color: "#006c27", small: "" },
                                },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("mdi-help")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", { domProps: { innerHTML: _vm._s(_vm.getHelp()) } })]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    id: "doneButton",
                    color: "#006c27",
                    disabled: _vm.applyDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.apply()
                    },
                  },
                },
                [_vm._v(" Apply ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mr-7",
                  attrs: { id: "cancelButton", color: "#006c27" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("deprecation-detail-overlay", {
        attrs: { deprecation: _vm.selectedDeprecation },
        on: {
          close: function ($event) {
            _vm.showDeprecationDetail = false
          },
        },
        model: {
          value: _vm.showDeprecationDetail,
          callback: function ($$v) {
            _vm.showDeprecationDetail = $$v
          },
          expression: "showDeprecationDetail",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }