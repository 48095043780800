var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
        _c(
          "div",
          [
            _c("v-icon", [_vm._v(" mdi-account-lock-outline ")]),
            _c("span", { staticClass: "ml-1" }, [_vm._v("OAuth2")]),
          ],
          1
        ),
      ]),
      _c(
        "v-expansion-panel-content",
        [
          _vm.application.permissionModel === "DELEGATED"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(
                          " This application is in permission model DELEGATED."
                        ),
                        _c("br"),
                        _vm._v(
                          " This basically means, this application in meant to be used in user context"
                        ),
                        _c("br"),
                        _vm._v(
                          " With help of this application you can authenticate real users and call APIs on behalf of the user"
                        ),
                        _c("br"),
                        _vm._v(
                          " Keep in mind: Without any real user, this application can do nothing on its own!"
                        ),
                        _c("br"),
                        _vm._v(
                          " This application can only consume APIs which support the permission model DELEGATED."
                        ),
                        _c("br"),
                        _vm._v(
                          " The supported permission models of an API can be discovered in the API's description on the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getDeveloperPortalURL(),
                              target: "_blank",
                            },
                          },
                          [_vm._v("Developer Portal")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " In order to consume an API in delegated permission model, your application needs to authenticate real users."
                        ),
                        _c("br"),
                        _vm._v(
                          " Schaeffler API Gateway uses for authentication and authorization the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://oauth.net/2/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("OAuth2 standard")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _vm._v(
                          " Strictly spoken, this application needs to use the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.microsoft.com/de-de/azure/active-directory/develop/v2-oauth2-auth-code-flow",
                              target: "_blank",
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                "OAuth2 authorization code flow (with PKCE)"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" to obtain a token. "),
                      ]),
                      _c("expandable-image", {
                        staticClass: "my-3",
                        attrs: { src: _vm.$getImagePath("AuthCodeFlow.png") },
                      }),
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(" Perhaps you mentioned in the picture that "),
                        _c("strong", [_vm._v("secret")]),
                        _vm._v(
                          " was in brackets. It depends on your application if you need to use a secret or not. If your application is an SPA, Mobile or DesktopApp - you do not need a secret and will use the authorization code flow with PKCE. Only if your application is a WebApp with dedicated backend (i.e. server side rendering), then you need to create a secret and use the normal authorization code flow. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " It is important to mention that you need one access token (JWT) for every individual API you want to consume."
                        ),
                        _c("br"),
                        _vm._v(
                          " For the authorization code flow you need five pieces of information."
                        ),
                        _c("br"),
                        _c("br"),
                        _c("strong", [_vm._v("Application Id:")]),
                        _vm._v(" " + _vm._s(this.application.applicationId)),
                        _c("br"),
                        _c("strong", [_vm._v("Secret:")]),
                        _vm._v(
                          " Get it in the secret section (only if your RedirectURI is of type WEB)."
                        ),
                        _c("br"),
                        _c("strong", [_vm._v("Scope:")]),
                        _vm._v(
                          " get the scope in the permission section. You need to request at least one permission for each API you want to consume on behalf of a user."
                        ),
                        _c("br"),
                        _c("strong", [_vm._v("Token URL:")]),
                        _vm._v(
                          " https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/token"
                        ),
                        _c("br"),
                        _c("strong", [_vm._v("Authorize URL:")]),
                        _vm._v(
                          " https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/authorize"
                        ),
                        _c("br"),
                        _c("br"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "pt-2",
                          staticStyle: {
                            background: "#ffcaca",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " For a complete step-by-step guide how OAuth2 authentication works, visit our "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: "https://apicademy.dp.schaeffler/",
                              },
                            },
                            [_vm._v("APIcademy.")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pb-2",
                          staticStyle: {
                            background: "#ffcaca",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " The content can be found in CONBoarding/Consumption "
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _c("br"),
                        _vm._v(
                          " For implementation - we strongly recommend using "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.microsoft.com/de-de/azure/active-directory/develop/msal-overview",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Microsoft Authentication Library")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _c("div", [
                          _vm._v(
                            " Get more information at our Microsoft Teams channel "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href: "https://teams.microsoft.com/_?tenantId=67416604-6509-4014-9859-45e709f53d3f#/tab::04d58b98-f294-4ce8-b803-cc18df972d0f/HowTo%20Consume%20an%20API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&threadId=19:dd5e32026d72441f87004e98a670b13f@thread.tacv2&ctx=channel",
                                target: "_blank",
                              },
                            },
                            [_vm._v("API@Schaeffler (Browser)")]
                          ),
                          _vm._v(" or "),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href: "msteam://teams.microsoft.com/_?tenantId=67416604-6509-4014-9859-45e709f53d3f#/tab::04d58b98-f294-4ce8-b803-cc18df972d0f/HowTo%20Consume%20an%20API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&threadId=19:dd5e32026d72441f87004e98a670b13f@thread.tacv2&ctx=channel",
                                target: "_blank",
                              },
                            },
                            [_vm._v("API@Schaeffler (Teams)")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.application.permissionModel === "APPLICATION"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(
                          " This application is in permission model APPLICATION."
                        ),
                        _c("br"),
                        _vm._v(" This basically means, this application "),
                        _c("strong", [_vm._v("is")]),
                        _vm._v(" a system user."),
                        _c("br"),
                        _vm._v(
                          " This application can only consume APIs which support the permission model APPLICATION."
                        ),
                        _c("br"),
                        _vm._v(
                          " The supported permission models of an API can be discovered in the API's description on the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getDeveloperPortalURL(),
                              target: "_blank",
                            },
                          },
                          [_vm._v("Developer Portal")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " In order to consume an API in application permission model, your application needs to authenticate itself."
                        ),
                        _c("br"),
                        _vm._v(
                          " Schaeffler API Gateway uses for authentication and authorization the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://oauth.net/2/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("OAuth2 standard")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _vm._v(
                          " Strictly spoken, this application needs to use the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-client-creds-grant-flow",
                              target: "_blank",
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v("OAuth2 client credential flow"),
                            ]),
                          ]
                        ),
                        _vm._v(" to obtain a token. "),
                      ]),
                      _c("expandable-image", {
                        staticClass: "my-3",
                        attrs: {
                          src: _vm.$getImagePath("ClientCredentialFlow.png"),
                        },
                      }),
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(
                          " It is important to mention that you need one access token for every individual API you want to consume."
                        ),
                        _c("br"),
                        _vm._v(
                          " For the client credential flow you need four pieces of information."
                        ),
                        _c("br"),
                        _c("br"),
                        _c("strong", [_vm._v("Application Id:")]),
                        _vm._v(" " + _vm._s(this.application.applicationId)),
                        _c("br"),
                        _c("strong", [_vm._v("Secret:")]),
                        _vm._v(" Get it in the secret section."),
                        _c("br"),
                        _c("strong", [_vm._v("Scope:")]),
                        _vm._v(
                          " get the scope in the permission section. You need to request one permission for each API you want to consume. The application scope always ends with /.default"
                        ),
                        _c("br"),
                        _c("strong", [_vm._v("Token URL:")]),
                        _vm._v(
                          " https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f/oauth2/v2.0/token"
                        ),
                        _c("br"),
                        _c("br"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "pt-2",
                          staticStyle: {
                            background: "#ffcaca",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " For a complete step-by-step guide how OAuth2 authentication works, visit our "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: "https://apicademy.dp.schaeffler/",
                              },
                            },
                            [_vm._v("APIcademy.")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pb-2",
                          staticStyle: {
                            background: "#ffcaca",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " The content can be found in CONBoarding/Consumption "
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { "text-align": "left" } }, [
                        _c("br"),
                        _vm._v(
                          " For implementation - we strongly recommend using "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.microsoft.com/de-de/azure/active-directory/develop/msal-overview",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Microsoft Authentication Library")]
                        ),
                        _vm._v("."),
                        _c("br"),
                        _c("div", [
                          _vm._v(
                            " Get more information at our Microsoft Teams channel "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href: "https://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
                                target: "_blank",
                              },
                            },
                            [_vm._v("API@Schaeffler (Browser)")]
                          ),
                          _vm._v(" or "),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href: "msteams://teams.microsoft.com/l/channel/19%3add5e32026d72441f87004e98a670b13f%40thread.tacv2/HowTo%2520Consume%2520an%2520API?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f",
                                target: "_blank",
                              },
                            },
                            [_vm._v("API@Schaeffler (Teams)")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }