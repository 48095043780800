<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col align="center" justify="center">
        <div class="mt-14 text-h5">
          You are logged out.
        </div>
        <div class="">
          Please close the browser windows.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { publicPath } from "../../vue.config.js";

export default {
  name: "logout",

  components: {},

  mounted() {
    console.log("LOGOUT MOUNTED");
    sessionStorage.clear();
    this.$authService.quitSession();
    //history.pushState({}, null, publicPath + "/");
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped></style>
