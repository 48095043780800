var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "400" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-1", attrs: { color: "#006c27" } },
                [_vm._v("mdi-alarm")]
              ),
              _c("div", { staticClass: "text--secondary" }, [
                _vm._v(" Expiry Notification "),
              ]),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "mt-3" }, [
            _vm.mode
              ? _c("div", [
                  _vm._v(" You are about to "),
                  _c("strong", [_vm._v("activate")]),
                  _vm._v(" expiry notification!"),
                  _c("br"),
                  _vm._v(
                    " This will trigger an calendar event scheduled 1 month before the real expiry. This calendar event will be send to "
                  ),
                  _c("strong", [_vm._v("all")]),
                  _vm._v(" owners of this application. "),
                ])
              : _c("div", [
                  _vm._v(" You are about to "),
                  _c("strong", [_vm._v("deactivate")]),
                  _vm._v(" expiry notification!"),
                  _c("br"),
                  _vm._v(
                    " This will cancel the calendar event scheduled 1 month before the real expiry. This calendar event cancelation will be send to "
                  ),
                  _c("strong", [_vm._v("all")]),
                  _vm._v(" owners of this application. "),
                ]),
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-3 d-flex" },
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { dark: "", color: "#006c27" },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" CLOSE ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { dark: "", color: "#006c27" },
                    on: { click: _vm.confirm },
                  },
                  [_vm._v(" CONFIRM ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }