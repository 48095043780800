<template>
  <v-dialog v-model="show" persistent width="700px" class="mydialog">
    <v-card>
      <v-card-title class="text--secondary">
        <div>API Announcement!</div>
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row no-gutters>
          <v-col>
            <type-writer :text="code" :title="title"></type-writer>
          </v-col>
        </v-row>
        <div style="position: absolute; bottom: 2%; right: 1%; z-index: 202">
          <henry
            style="justify-content: center"
            :scale="1.2"
            @click="confirm"
          ></henry>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TypeWriter from "./TypeWriter.vue";
import Henry from "./Henry.vue";

export default {
  components: { Henry, TypeWriter },
  name: "AnnouncementDialog",
  props: {
    value: Boolean,
    announcement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //code: 'curl https://apicoe.schaeffler.com/announcements/api-week</br></br>Hello USERNAME!</br>Did you know? We are celebrating the API Week!</br>You are invited to join! You can learn more about APIs,</br>#proud2share and #data-as-a-product. Let´s talk about what you</br>like and what you are still missing. Let´s get in touch!</br>Get more infos at SConnect <a style="color: white;" target="_blank" href="https://sconnect.schaeffler.com/news">(click here)</a></br></br>Click Henry to confirm reading!',
      code: "",
      title: "",
    };
  },
  mounted() {},
  watch: {
    value(visible) {
      if (visible) {
        console.log("Found announcement: ", this.announcement);
        this.code = this.announcement.description.replaceAll(
          "USERNAME",
          this.$userData.graphUserInfo.givenName
        );
        this.title = this.announcement.title;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      console.log("close");
      this.close();
      this.$emit("confirm", this.announcement);
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  overflow: hidden;
}
</style>
