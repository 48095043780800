<template>
  <v-dialog width="100%" v-model="show" persistent max-width="800">
    <v-card>
      <v-card-title>
        <v-icon class="mr-3" color="#006c27">mdi-car-brake-alert</v-icon>
        <div style="color: #616161">
          We are sorry. Subscription is not possible!
        </div>
      </v-card-title>
      <v-card-text class="mt-3">
        You can only subscribe APIs via this feature if your application is on
        environment DEVELOPMENT or QUALITY.
        <br />
        Your current application is on environment PRODUCTION.
        <br /><br />
        In order to subscribe, please visit the
        <a class="link" href="https://developer.schaeffler.com/product-catalog"
          >API Catalog</a
        >, search for the API and use the official Subscribe button.
        <br /><br />
        In case you can not find the API in the API Catalog please fill out the
        assistance form using the button below.
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-btn color="#006c27" class="ml-1 white--text" @click="openAPICatalog"
          >API catalog</v-btn
        >
        <v-btn color="#006c27" class="white--text" @click="assistance"
          >assistance</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="#006c27" class="white--text" @click="close">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SubNotPossibleDialog",
  props: {
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {
    assistance() {
      this.close();
      this.$emit("assistance");
    },
    close() {
      this.show = false;
    },
    openAPICatalog() {
      window.open("https://developer.schaeffler.com/product-catalog");
    },
  },
};
</script>

<style scoped>
.link {
  color: #006c27;
  font-weight: 700;
  background-color: transparent;
  text-decoration: none;
}
</style>
