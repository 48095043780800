<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-fade-transition mode="out-in">
          <v-icon medium v-if="$vuetify.theme.dark" @click="toggleMode($event)"
            >mdi-weather-night</v-icon
          >
          <v-icon medium v-else @click="toggleMode($event)"
            >mdi-weather-sunny</v-icon
          >
        </v-fade-transition>
      </div>
    </template>
    <span>Toggle Dark Mode</span>
  </v-tooltip>
</template>

<script>
export default {
  data() {
    return {
      isBetaUser: this.$userData.cass.isBetaUser,
    };
  },
  methods: {
    toggleMode(evt) {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      evt.target.blur();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="css" scoped></style>
