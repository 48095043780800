var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      !this.$drupal ? _c("SchaefflerHeader") : _vm._e(),
      _c(
        "v-main",
        [
          _vm.env != null
            ? _c("v-banner", { attrs: { color: "red", "single-line": "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "white--text",
                    staticStyle: { "text-align": "center" },
                    on: {
                      click: function ($event) {
                        _vm.env = null
                      },
                    },
                  },
                  [
                    _vm._v(
                      " 🔥 ATTENTION! YOU ARE ON STAGE " +
                        _vm._s(_vm.env) +
                        ". ONLY SELECT ENVIRONMENT " +
                        _vm._s(_vm.env) +
                        ", OR LOWER, WITHIN THE APPLICATION 🔥 "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
      !this.$drupal ? _c("SchaefflerFooter") : _vm._e(),
      !this.$drupal
        ? _c("idle-overlay", {
            model: {
              value: _vm.idle,
              callback: function ($$v) {
                _vm.idle = $$v
              },
              expression: "idle",
            },
          })
        : _vm._e(),
      _c("status-overlay", {
        model: {
          value: _vm.statuscheck,
          callback: function ($$v) {
            _vm.statuscheck = $$v
          },
          expression: "statuscheck",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }