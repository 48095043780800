var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-8 mb-8",
              attrs: { loading: _vm.loading, width: 1200 },
            },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "#006c27",
                      height: "10",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
              _c("v-card-title", { staticClass: "grey--text text--darken-2" }, [
                _vm._v(" non-CASS Applications "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text--primary my-3" },
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "elevation-1",
                      attrs: {
                        "sort-by": "displayName",
                        "custom-sort": _vm.customSort,
                        "must-sort": "",
                        "show-expand": "",
                        headers: _vm.headers,
                        items: _vm.mssapps,
                        "hide-default-footer": "",
                        "disable-pagination": "",
                        "item-key": "apiMgmtApplicationId",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: `item.environment`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: _vm.getEnvironmentColor(
                                        item.environment
                                      ),
                                      dark: "",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.environment) + " ")]
                                ),
                              ]
                            },
                          },
                          {
                            key: "expanded-item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      item.products,
                                      function (product, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "A" + index,
                                              staticClass: "my-2",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: { color: "#006c27" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-package-variant-closed"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(product.title) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            product.apiProxies,
                                            function (proxy, index) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "A" +
                                                      product.name +
                                                      proxy.name +
                                                      index,
                                                    staticClass: "ml-7 my-2",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          color: "#006c27",
                                                        },
                                                      },
                                                      [_vm._v("mdi-api")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(proxy.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      item.products,
                                      function (product, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "B" + index,
                                              staticClass: "my-2",
                                              staticStyle: {
                                                "min-height": "24px",
                                              },
                                            },
                                            [_c("wbr")]
                                          ),
                                          _vm._l(
                                            product.apiProxies,
                                            function (proxy, index) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "B" +
                                                      product.name +
                                                      proxy.name +
                                                      index,
                                                    staticClass: "my-2",
                                                    staticStyle: {
                                                      "min-height": "24px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: proxy.cassOnly
                                                            ? "red"
                                                            : "#006c27",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.permissionModelIcon(
                                                              proxy
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.permissionModel(
                                                            proxy.permissionModel
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      item.products,
                                      function (product, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "B" + index,
                                              staticClass: "my-2",
                                              staticStyle: {
                                                "min-height": "24px",
                                              },
                                            },
                                            [_c("wbr")]
                                          ),
                                          _vm._l(
                                            product.apiProxies,
                                            function (proxy, index) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "B" +
                                                      product.name +
                                                      proxy.name +
                                                      index,
                                                    staticClass: "my-2",
                                                    staticStyle: {
                                                      "min-height": "24px",
                                                    },
                                                  },
                                                  [
                                                    proxy.cassOnly
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Subscription invalid. Please use a CASS application. "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      item.products,
                                      function (product, index) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "C" + index,
                                              staticClass: "my-0",
                                              staticStyle: {
                                                "min-height": "24px",
                                              },
                                            },
                                            [_c("wbr")]
                                          ),
                                          _vm._l(
                                            product.apiProxies,
                                            function (proxy, index) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "C" +
                                                      product.name +
                                                      proxy.name +
                                                      index,
                                                    staticClass: "my-2",
                                                    staticStyle: {
                                                      "max-height": "24px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "img",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mb-0",
                                                                          staticStyle:
                                                                            {
                                                                              "max-height":
                                                                                "24px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: _vm.$getImagePath(
                                                                                "openapi-1.svg"
                                                                              ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.onDownloadSwagger(
                                                                                  $event,
                                                                                  proxy.name,
                                                                                  item.environment
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "img",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Download Swagger"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("td"),
                              ]
                            },
                          },
                          {
                            key: `item.apiMgmtApplicationId`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#006c27" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDeleteApp(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-trash-can-outline ")]
                                ),
                              ]
                            },
                          },
                          {
                            key: `item.APIKey`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "mr-4",
                                    staticStyle: { "font-family": "monospace" },
                                  },
                                  [_vm._v(_vm._s(item.APIKey))]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "2px",
                                                      },
                                                      attrs: {
                                                        slot: "append",
                                                        dense: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copy2clipboard(
                                                            item.APIKey
                                                          )
                                                        },
                                                      },
                                                      slot: "append",
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" mdi-content-copy ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Copy APIKey to Clipboard"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _vm.loading
                          ? _c("div", [
                              _vm._v(" Loading applications, please wait ... "),
                            ])
                          : _c("div", [
                              _vm._v(
                                " You do not have any non-CASS applications! Great! "
                              ),
                            ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("swagger-download-overlay", {
        attrs: { proxyname: _vm.proxyname, environment: _vm.environment },
        model: {
          value: _vm.downloadSwagger,
          callback: function ($$v) {
            _vm.downloadSwagger = $$v
          },
          expression: "downloadSwagger",
        },
      }),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeleteApp },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c("non-cass-apps-info", {
        model: {
          value: _vm.showInfo,
          callback: function ($$v) {
            _vm.showInfo = $$v
          },
          expression: "showInfo",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }