var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "terminal-window" }, [
    _vm._m(0),
    _c("section", { staticClass: "terminal" }, [
      _c("div", { staticClass: "history" }),
      _c("span", {
        staticClass: "prompt",
        domProps: { innerHTML: _vm._s(_vm.typeValue) },
      }),
      _c("span", { staticClass: "typed-cursor" }, [_vm._v(_vm._s(_vm.cursor))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", { staticClass: "button green" }),
      _c("div", { staticClass: "button yellow" }),
      _c("div", { staticClass: "button red" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }