<template>
  <v-footer padless style="border-top: 1px solid grey !important">
    <v-col class="" cols="7">
      <v-btn
        plain
        class="text-nospacing"
        href="https://www.schaeffler.de/content.schaeffler.de/en/meta/imprint/index.jsp"
        target="_blank"
        >Legal notice</v-btn
      >
      <span class="text-caption">|</span>
      <v-btn
        plain
        class="text-nospacing"
        href="https://www.schaeffler.de/content.schaeffler.de/en/meta/privacy-policy/index.jsp"
        target="_blank"
        >Privacy policy</v-btn
      >
      <span class="text-caption">|</span>
      <v-btn
        plain
        class="text-nospacing"
        href="https://www.schaeffler.de/content.schaeffler.de/en/meta/condition-of-use/index.jsp"
        target="_blank"
        >Conditions of use</v-btn
      >
      <span class="text-caption">|</span>
      <v-btn
        plain
        class="text-nospacing"
        href="msteams://teams.microsoft.com/l/channel/19%3ab5675378ef994edcba137b634e342ed5%40thread.tacv2/CASS%2520(Consumer%2520Application%2520Self%2520Service)?groupId=ac301a8b-1db4-45a3-8dd7-9bdb904de8c8&tenantId=67416604-6509-4014-9859-45e709f53d3f"
        target="_blank"
        >Contact us</v-btn
      >
      <span class="text-caption">|</span>
      <v-btn plain @click="showVersionDialog" class="text-nospacing"
        >Version: {{ version }}</v-btn
      >
      <span class="text-caption">|</span>
      <v-btn plain @click="showVisionMissionDialog" class="text-nospacing"
        >Vision & Mission</v-btn
      >
    </v-col>
    <v-col class="text-right pr-6 text-caption" cols="5">
      © Schaeffler Technologies AG & Co. KG</v-col
    >
    <version-overlay
      @click="showVersionDialog"
      @close="closeVersionDialog"
      v-model="versionDialog"
    ></version-overlay>
    <vision-overlay
      @click="showVisionMissionDialog"
      @close="closeVisionMissionDialog"
      v-model="visionDialog"
    >
    </vision-overlay>
  </v-footer>
</template>

<script>
import VersionOverlay from "./VersionOverlay.vue";
import VisionOverlay from "./VisionOverlay.vue";
export default {
  components: { VersionOverlay, VisionOverlay },
  name: "SchaefflerFooter",
  data: () => ({
    version: "inital",
    versionDialog: false,
    visionDialog: false,
  }),
  created() {
    console.log("Footer Init");
    this.version = process.env.VUE_APP_BUILD_VERSION;
    console.log("Version: " + this.version);
  },
  methods: {
    showVisionMissionDialog() {
      console.log("show it");
      this.visionDialog = true;
    },
    closeVisionMissionDialog() {
      this.visionDialog = false;
    },
    showVersionDialog() {
      this.versionDialog = true;
    },
    closeVersionDialog() {
      this.versionDialog = false;
    },
  },
};
</script>

<style scoped>
.v-btn.v-btn--has-bg {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}
.v-btn.v-btn--has-bg:hover {
  background-color: #006c27;
}

.v-btn.v-btn--text {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}

.v-btn.v-btn--text:hover {
  background-color: transparent;
}

.v-btn.v-btn--plain {
  text-transform: none;
  letter-spacing: 0;
  text-indent: 0;
}

.v-btn.v-btn--plain:hover {
  background-color: transparent;
  color: #006c27;
}

.v-btn.v-btn--icon:hover {
  background-color: transparent !important;
  color: #006c27 !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #646464 !important;
  opacity: 0.4 !important;
}

.text-nospacing {
  letter-spacing: 0 !important;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}

.no-underline {
  text-decoration: none;
}
</style>
