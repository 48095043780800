var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        { staticClass: "pl-3", attrs: { id: "secretsList" } },
        [
          _c(
            "div",
            [
              _vm.application.state == "NO_VALID_SECRET"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { color: "red" } },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-lock-alert-outline")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2465096255
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Your application needs at least one valid secret"
                        ),
                      ]),
                    ]
                  )
                : _c("v-icon", [_vm._v(" mdi-lock-check-outline ")]),
              _c("span", { staticClass: "ml-1" }, [_vm._v("Secrets")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticStyle: { "padding-left": "0px" } },
        [
          _vm.application.secrets.length != 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_c("strong", [_vm._v("Secret Hint")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_c("strong", [_vm._v("Valid from")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "4" },
                    },
                    [_c("strong", [_vm._v("Valid to")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.application.secrets.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("v-col", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(" No secrets available "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.application.secrets.length == 0
            ? _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-left": "50px",
                    "padding-right": "56px",
                    "padding-bottom": "10px",
                  },
                },
                [_c("v-divider")],
                1
              )
            : _vm._e(),
          _vm._l(_vm.application.secrets, function (secret, index) {
            return [
              _c(
                "v-list-item",
                { key: index, attrs: { dense: "", selectable: false } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticStyle: { "margin-right": "10px" } },
                    [_c("v-icon", [_vm._v("mdi-form-textbox-password")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _vm._v(" " + _vm._s(secret.secretHint) + " ... "),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "ml-2", attrs: { cols: "4" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(secret.secretValidFrom)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ml-2", attrs: { cols: "3" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(secret.secretValidTo)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { id: "deleteSecretButton_" + index },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSecret($event, secret)
                            },
                          },
                        },
                        [_vm._v("mdi-trash-can-outline")]
                      ),
                      secret.expiryNotification
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "no-focus",
                                                staticStyle: {
                                                  "max-width": "24px",
                                                  "max-height": "24px",
                                                },
                                                attrs: {
                                                  icon: "",
                                                  id:
                                                    "notificationSecretButton_" +
                                                    index,
                                                  loading: _vm.progessing,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmDialog(
                                                      $event,
                                                      secret,
                                                      false
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "loader",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "custom-loader",
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-cached"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("mdi-alarm")])],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Expiry notification is activated. Click to deactivate."
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !secret.expiryNotification
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "no-focus",
                                                staticStyle: {
                                                  "max-width": "24px",
                                                  "max-height": "24px",
                                                },
                                                attrs: {
                                                  icon: "",
                                                  id:
                                                    "notificationSecretButton_" +
                                                    index,
                                                  loading: _vm.progessing,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmDialog(
                                                      $event,
                                                      secret,
                                                      true
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "loader",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "custom-loader",
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-cached"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-alarm-off"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Expiry notification is deactivated. Click to activate."
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { key: index + 100 }),
            ]
          }),
          _c(
            "v-list-item",
            { staticClass: "mt-5", attrs: { dense: "" } },
            [
              _c("v-spacer"),
              _c("div", [_vm._v("Add Secret")]),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { disabled: !_vm.addSecretDisabled(), bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "span",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        id: "addSecretButton",
                                        disabled: _vm.addSecretDisabled(),
                                      },
                                      on: { click: _vm.addSecret },
                                    },
                                    [_vm._v("mdi-lock-plus-outline")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getDisabledToolTip()),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", color: "#006c27" },
          model: {
            value: _vm.showSnackError,
            callback: function ($$v) {
              _vm.showSnackError = $$v
            },
            expression: "showSnackError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackErrorMessage) + " ")]
      ),
      _c("secret-overlay", {
        on: { close: _vm.closeSecretDialog },
        model: {
          value: _vm.secretdialog,
          callback: function ($$v) {
            _vm.secretdialog = $$v
          },
          expression: "secretdialog",
        },
      }),
      _c("confirm-delete-overlay", {
        on: { confirm: _vm.onRealDeleteSecret },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c("expiry-notification-confirm", {
        ref: "expiryComponent",
        attrs: { mode: _vm.confirmNotificationMode },
        model: {
          value: _vm.confirmNotification,
          callback: function ($$v) {
            _vm.confirmNotification = $$v
          },
          expression: "confirmNotification",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }