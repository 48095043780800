var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
      },
    },
    [
      _vm.loading
        ? _c(
            "div",
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "margin-left": "auto",
                    "margin-right": "auto",
                  },
                  attrs: { width: "600px", loading: _vm.loading },
                },
                [
                  _c(
                    "template",
                    { slot: "progress" },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          color: "#006c27",
                          height: "10",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(" Preparing API Subscription "),
                  ]),
                  !_vm.error
                    ? _c("v-card-text", { staticClass: "text-center" }, [
                        _vm._v(" Please standby "),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "div",
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "margin-left": "auto",
                    "margin-right": "auto",
                  },
                  attrs: { width: "600px", loading: _vm.loading },
                },
                [
                  _c(
                    "template",
                    { slot: "progress" },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          color: "#006c27",
                          height: "10",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(" 😞 API Subscription Failed "),
                  ]),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text mr-4 mb-3 mt-3",
                          attrs: { color: "#006c27" },
                          on: { click: _vm.back },
                        },
                        [_vm._v(" OK")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && !_vm.error
        ? _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "margin-left": "auto",
                    "margin-right": "auto",
                    "z-index": "20",
                  },
                  attrs: { width: "600px" },
                },
                [
                  _c("v-card-title", { staticClass: "ml-3" }, [
                    _vm._v(" Subscribe an API "),
                  ]),
                  _c("v-card-text", { staticClass: "ml-3" }, [
                    _vm._v(" Great! You want to integrate API "),
                    _c("strong", [_vm._v(_vm._s(_vm.products[0].title))]),
                    _vm._v(" into your application!"),
                    _c("br"),
                    _vm._v(" You have two options:"),
                    _c("br"),
                    _c("ul", { staticClass: "mt-1 mb-1" }, [
                      _c("li", [_vm._v("You can register a new application.")]),
                      _c("li", [
                        _vm._v(
                          "You can add the API to one of your existing applications."
                        ),
                      ]),
                    ]),
                    _vm._v(
                      " Just flip the lowest card to choose the right option for you. "
                    ),
                  ]),
                  _c(
                    "v-card",
                    { staticClass: "mx-6 mb-4" },
                    [
                      _c("v-card-title", { staticClass: "text-subtitle-2" }, [
                        _vm._v(" Select environment "),
                      ]),
                      _vm._l(
                        ["PRODUCTION", "QUALITY", "DEVELOPMENT"],
                        function (item, index) {
                          return _c(
                            "v-tooltip",
                            {
                              key: index,
                              attrs: {
                                bottom: "",
                                disabled: _vm.isEnvironmentValid(item),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                class:
                                                  _vm.isEnvironmentSelected(
                                                    item
                                                  )
                                                    ? "ml-4 mb-3 white--text"
                                                    : "ml-4 mb-3",
                                                attrs: {
                                                  small: "",
                                                  color: "#006c27",
                                                  outlined:
                                                    !_vm.isEnvironmentSelected(
                                                      item
                                                    ),
                                                  disabled:
                                                    !_vm.isEnvironmentValid(
                                                      item
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectEnvironment(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(item) + " ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "This environment is not valid for your account"
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pb-2",
                          staticStyle: {
                            display: "inline-block",
                            float: "right",
                            "text-transform": "none",
                            "letter-spacing": "0",
                          },
                          attrs: {
                            id: "environmentHelpMeButtonCreateApplication",
                            color: "#006c27",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showEnvironmentHelp = true
                            },
                          },
                        },
                        [_vm._v(" Help me! ")]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-card",
                    { staticClass: "mx-6 mb-4" },
                    [
                      _c("v-card-title", { staticClass: "text-subtitle-2" }, [
                        _vm._v(" Select permission model "),
                      ]),
                      _vm._l(
                        ["APPLICATION", "DELEGATED"],
                        function (item, index) {
                          return _c(
                            "v-tooltip",
                            {
                              key: index,
                              attrs: {
                                bottom: "",
                                disabled: _vm.isPermissionModelValid(item),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                class:
                                                  _vm.isPermissionModelSelected(
                                                    item
                                                  )
                                                    ? "ml-4 mb-3 white--text"
                                                    : "ml-4 mb-3",
                                                attrs: {
                                                  small: "",
                                                  color: "#006c27",
                                                  outlined:
                                                    !_vm.isPermissionModelSelected(
                                                      item
                                                    ),
                                                  disabled:
                                                    !_vm.isPermissionModelValid(
                                                      item
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectPermissionModel(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(item) + " ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "This permission model is not support by the API"
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pb-2",
                          staticStyle: {
                            display: "inline-block",
                            "text-transform": "none",
                            "letter-spacing": "0",
                            float: "right",
                          },
                          attrs: {
                            id: "permissionHelpMeButtonCreateApplication",
                            color: "#006c27",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showPermissionModelHelp = true
                            },
                          },
                        },
                        [_vm._v(" Help me! ")]
                      ),
                    ],
                    2
                  ),
                  _c("vue-flip", {
                    staticClass: "mb-4",
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-right": "auto",
                    },
                    attrs: { width: "550px", height: "225px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "front",
                          fn: function () {
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { width: "550px", height: "225px" },
                                },
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-2" },
                                      [_vm._v("Register New Application")]
                                    ),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    {},
                                    [
                                      !_vm.flipCard
                                        ? _c(
                                            "v-form",
                                            {
                                              ref: "formCreate",
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.nop.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.createValid,
                                                callback: function ($$v) {
                                                  _vm.createValid = $$v
                                                },
                                                expression: "createValid",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "9" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          id: "inputApplicationName",
                                                          label:
                                                            "Application Name",
                                                          placeholder:
                                                            "give your application a nice name",
                                                          outlined: "",
                                                          rules: [
                                                            _vm.notEmpty,
                                                            _vm.min,
                                                            _vm.rules.validName,
                                                          ],
                                                          "error-messages":
                                                            _vm.serverErrorCreate,
                                                          dense: "",
                                                          maxlength: "50",
                                                          counter: "",
                                                          autocomplete:
                                                            "new-password",
                                                          color: "#006c27",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.appNameCreate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.appNameCreate =
                                                              $$v
                                                          },
                                                          expression:
                                                            "appNameCreate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "white--text",
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                            float: "right",
                                                          },
                                                          attrs: {
                                                            id: "createButton",
                                                            color: "#006c27",
                                                            disabled:
                                                              !_vm.createValid,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.createApplication()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Create ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 text--secondary",
                                        },
                                        [_vm._v("FlipMe!")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#006c27",
                                            id: "flipApplicationCreateOverlayToImport",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.flip()
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-arrow-right ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "back",
                          fn: function () {
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { width: "550px", height: "225px" },
                                },
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-2" },
                                      [_vm._v("Add to existing application")]
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c("v-col", { attrs: { cols: "9" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ml-4",
                                            staticStyle: {
                                              border: "1px solid #9e9e9e",
                                              "border-radius": "5px",
                                              overflow: "hidden",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "122px",
                                                  "overflow-y": "scroll",
                                                },
                                              },
                                              [
                                                _vm.filteredApplications
                                                  .length == 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align":
                                                            "center",
                                                          "margin-top": "50px",
                                                          color: "grey",
                                                          "font-size": "0.8rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " You have no matching applications "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.filteredApplications
                                                  .length != 0
                                                  ? _c(
                                                      "v-list",
                                                      {
                                                        staticStyle: {
                                                          padding:
                                                            "0 !important",
                                                        },
                                                        attrs: { dense: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-group",
                                                          {
                                                            attrs: {
                                                              color: "#006c27",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedApplication,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectedApplication =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectedApplication",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.filteredApplications,
                                                            function (
                                                              app,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          app.alreadySubscribed,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        [
                                                                          !app.alreadySubscribed
                                                                            ? _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      app.displayName
                                                                                    ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          app.alreadySubscribed
                                                                            ? _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      app.displayName
                                                                                    ) +
                                                                                      " (Subscription present)"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-3 white--text",
                                              staticStyle: {
                                                display: "inline-block",
                                                float: "right",
                                              },
                                              attrs: {
                                                id: "importApplicationButton",
                                                color: "#006c27",
                                                disabled:
                                                  _vm.selectedApplication ==
                                                  null,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addToApplication()
                                                },
                                              },
                                            },
                                            [_vm._v(" Add ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "#006c27" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.flip()
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-arrow-left ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 text--secondary",
                                        },
                                        [_vm._v("FlipMe!")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4221853392
                    ),
                    model: {
                      value: _vm.flipCard,
                      callback: function ($$v) {
                        _vm.flipCard = $$v
                      },
                      expression: "flipCard",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { position: "relative" },
                  attrs: { id: "pageWrap" },
                },
                [
                  _c(
                    "svg",
                    {
                      ref: "rocket",
                      staticStyle: {
                        position: "absolute",
                        top: "454px",
                        left: "-80px",
                        "z-index": "10",
                      },
                      attrs: {
                        version: "1.1",
                        x: "0px",
                        y: "0px",
                        width: "307px",
                        height: "283px",
                        id: "rocket",
                      },
                    },
                    [
                      _c("g", { staticClass: "rocket_wrap" }, [
                        _c("g", { staticClass: "rocket_inner" }, [
                          _c("path", {
                            ref: "fireMiddle",
                            staticClass: "fire fire_path",
                            attrs: {
                              id: "fireMiddle",
                              d: "M148.891,179.906c3.928,0,7.111,3.176,7.111,7.094 c0,7.78-7.111,16-7.111,16s-7.111-8.349-7.111-16C141.78,183.082,144.963,179.906,148.891,179.906z",
                            },
                          }),
                          _c("path", {
                            ref: "fireRight",
                            staticClass: "fire_path fire",
                            attrs: {
                              id: "fireRight",
                              d: "M154.063,181.092c3.577-1.624,7.788-0.048,9.408,3.52 c3.216,7.084,0.139,17.508,0.139,17.508s-9.927-4.662-13.09-11.63C148.9,186.923,150.487,182.715,154.063,181.092z",
                            },
                          }),
                          _c("path", {
                            ref: "fireLeft",
                            staticClass: "fire_path fire",
                            attrs: {
                              id: "fireLeft",
                              d: "M143.392,182.519c3.25,2.207,4.098,6.623,1.896,9.864 c-4.372,6.436-14.873,9.238-14.873,9.238s-1.191-10.902,3.108-17.23C135.725,181.149,140.143,180.312,143.392,182.519z",
                            },
                          }),
                          _c("path", {
                            ref: "fireSmallLeft",
                            staticClass: "fire_path fire",
                            attrs: {
                              id: "fireSmallLeft",
                              d: "M143.193 187.531c2.226 0.4 3.7 2.6 3.2 4.8 c-0.875 4.407-5.829 8.264-5.829 8.264s-3.09-5.53-2.229-9.865C138.807 188.5 141 187.1 143.2 187.531z",
                            },
                          }),
                          _c("path", {
                            ref: "fireSmallRight",
                            staticClass: "fire_path fire",
                            attrs: {
                              id: "fireSmallRight",
                              d: "M152.089 188.599c2.043-0.985 4.496-0.132 5.5 1.9 c1.952 4 0.3 10.1 0.3 10.107s-5.795-2.56-7.713-6.541C149.186 192 150 189.6 152.1 188.599z",
                            },
                          }),
                          _c("path", {
                            staticClass: "rocket_bottom",
                            attrs: {
                              d: "M157.069 171.31h-3.292c-1.562-0.048-3.178-0.076-4.846-0.076 s-3.284 0.028-4.846 0.076h-3.292c-7.277-7.938-12.371-26.182-12.371-47.434c0-28.54 9.182-51.676 20.508-51.676 c11.327 0 20.5 23.1 20.5 51.676C169.44 145.1 164.3 163.4 157.1 171.31z",
                            },
                          }),
                          _c("g", { attrs: { id: "right_wing_wrap" } }, [
                            _c("path", {
                              staticClass: "wing_base",
                              attrs: {
                                d: "M166.678 127.161c0 0 17.7 3.3 12.9 48.099l-18.06-14.05 L166.678 127.161z",
                              },
                            }),
                            _c("path", {
                              staticClass: "wing_shadow",
                              attrs: {
                                d: "M158.225 140.336c10.481-5.584 22.7 22.2 21.4 34.9 l-18.06-14.05C161.542 161.2 156.1 144.3 158.2 140.336z",
                              },
                            }),
                          ]),
                          _c("g", { attrs: { id: "left_wing_wrap" } }, [
                            _c("path", {
                              staticClass: "wing_base",
                              attrs: {
                                d: "M135.131 161.21l-18.06 14.1 c-4.805-44.793 12.924-48.099 12.924-48.099L135.131 161.21z",
                              },
                            }),
                            _c("path", {
                              staticClass: "wing_shadow",
                              attrs: {
                                d: "M135.131 161.21l-18.06 14.1 c-1.367-12.746 10.896-40.509 21.377-34.924C140.614 144.3 135.1 161.2 135.1 161.21z",
                              },
                            }),
                          ]),
                          _c("g", { attrs: { id: "rocket_body_wrap" } }, [
                            _c("path", {
                              staticClass: "rocket_base",
                              attrs: {
                                d: "M162.728 167.358c-3.778-0.623-8.573-0.996-13.796-0.996 s-10.018 0.373-13.795 0.996c-5.033-10.186-8.257-25.808-8.257-43.338c0-30.688 9.873-55.566 22.052-55.566 s22.053 24.9 22.1 55.566C170.984 141.6 167.8 157.2 162.7 167.358z",
                              },
                            }),
                            _c("path", {
                              staticClass: "rocket_shadow",
                              attrs: {
                                d: "M145.464 166.417c19.578-40.575 7.26-85.229 4.112-98.067 c11.88 0.9 21.4 25.4 21.4 55.525c0 17.529-3.225 33.152-8.257 43.337c0 0-3.786-0.472-8.069-0.697 S145.464 166.4 145.5 166.417z",
                              },
                            }),
                          ]),
                          _c(
                            "g",
                            { attrs: { id: "large_window_wrap" } },
                            [
                              _c(
                                "radialgradient",
                                {
                                  attrs: {
                                    id: "SVGID_2_",
                                    cx: "148.9",
                                    cy: "112.5",
                                    r: "15.2",
                                    fx: "139.4853",
                                    fy: "112.5239",
                                    gradientunits: "userSpaceOnUse",
                                  },
                                },
                                [
                                  _c("stop", {
                                    staticClass: "window_grandient",
                                    attrs: { offset: "0" },
                                  }),
                                  _c("stop", {
                                    staticClass: "window_grandient",
                                    attrs: { offset: "0.5868" },
                                  }),
                                  _c("stop", {
                                    staticClass: "window_grandient",
                                    attrs: { offset: "0.6834" },
                                  }),
                                  _c("stop", {
                                    staticClass: "window_grandient1",
                                    attrs: { offset: "0.6845" },
                                  }),
                                  _c("stop", {
                                    staticClass: "window_grandient2",
                                    attrs: { offset: "0.6861" },
                                  }),
                                  _c("stop", {
                                    staticClass: "window_grandient3",
                                    attrs: { offset: "0.6897" },
                                  }),
                                ],
                                1
                              ),
                              _c("circle", {
                                staticClass: "large_window_path",
                                attrs: { cx: "148.9", cy: "111.3", r: "10.5" },
                              }),
                            ],
                            1
                          ),
                          _c("circle", {
                            staticClass: "small_window_path",
                            attrs: { cx: "148.9", cy: "132.4", r: "5.2" },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("environment-dialog", {
        model: {
          value: _vm.showEnvironmentHelp,
          callback: function ($$v) {
            _vm.showEnvironmentHelp = $$v
          },
          expression: "showEnvironmentHelp",
        },
      }),
      _c("permission-dialog", {
        model: {
          value: _vm.showPermissionModelHelp,
          callback: function ($$v) {
            _vm.showPermissionModelHelp = $$v
          },
          expression: "showPermissionModelHelp",
        },
      }),
      _c("sub-products-progress-overlay", {
        attrs: { messages: _vm.progressMessages },
        model: {
          value: _vm.showSubscriptionProgressDialog,
          callback: function ($$v) {
            _vm.showSubscriptionProgressDialog = $$v
          },
          expression: "showSubscriptionProgressDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }