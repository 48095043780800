var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPermissionSubmit()
    ? _c(
        "v-dialog",
        {
          attrs: { width: "100%", persistent: "", "max-width": "1000" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "#006c27", large: "" },
                    },
                    [_vm._v("mdi-information-outline")]
                  ),
                  _c("div", [_vm._v("Permission Request Form")]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _vm.isUserSchaeffler() && _vm.application.itSecId
                ? _c(
                    "v-card-text",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _vm._v(" IT Security Demand of Application: "),
                          ]),
                          _c("v-col", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: {
                                  href: _vm.application.itSecURL,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.application.itSecId) +
                                    " - " +
                                    _vm._s(_vm.application.itSecTitle)
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _vm._v(" Requested Permission: "),
                          ]),
                          _c("v-col", [
                            _c("strong", [_vm._v(_vm._s(_vm.permissionName))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUserSchaeffler() && !_vm.application.itSecId
                ? _c(
                    "v-card-text",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-4",
                                  attrs: { "x-large": "", color: "red" },
                                },
                                [_vm._v("mdi-alert-outline")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", [
                            _c("strong", [
                              _c("div", [
                                _vm._v(
                                  " You did not yet configured the IT Security Demand on this CASS application. "
                                ),
                              ]),
                              _c("div", [_vm._v("This is not recommended!")]),
                              _c("div", [
                                _vm._v(
                                  " Please select your IT Security Demand information on the first expansion panel. "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "mt-3" },
                [
                  _vm._v(
                    " This request will be send to the corresponding Data Domain Manager (DDM)."
                  ),
                  _c("br"),
                  _vm._v(" Please provide additional information "),
                  _c("strong", [_vm._v("why")]),
                  _vm._v(" you need the permission: "),
                  _c("strong", [_vm._v(_vm._s(_vm.permissionName))]),
                  _vm._v(". "),
                  _c("v-textarea", {
                    staticClass: "mt-4",
                    attrs: {
                      outlined: "",
                      placeholder: _vm.permissionRequestText,
                      height: "200px",
                      "no-resize": "",
                      counter: "",
                      rules: _vm.rules,
                      color: "#006c27",
                    },
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            disabled: !_vm.submitEnabled,
                            color: "#006c27",
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "450",
            "content-class": "shadow",
            persistent: "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1", attrs: { color: "#006c27" } },
                    [_vm._v("mdi-security")]
                  ),
                  _vm._v(" IT Security Warning "),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(" API Permissions on stage production and quality"),
                _c("br"),
                _vm._v(" can "),
                _c("u", [_vm._v("only")]),
                _vm._v(" be requested with a valid IT Security Demand."),
                _c("br"),
                _vm._v(
                  " This ensures data protection and data usage transparency. "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " Please step back and use the first expansion panel and select "
                ),
                _c("br"),
                _vm._v(" the IT Security Demand of "),
                _c("u", [_vm._v("your")]),
                _vm._v(" consuming application. "),
                _c("br"),
                _c("br"),
                _vm._v(" If you have trouble getting the point, we are happy "),
                _c("br"),
                _vm._v(" to help you at "),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      target: "_blank",
                      href: "mailto: api-mgmt-support@schaeffler.com",
                    },
                  },
                  [_vm._v("api-mgmt-support@schaeffler.com")]
                ),
                _vm._v(". "),
                _c(
                  "div",
                  { staticClass: "justify-center d-flex" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-4 white--text",
                        attrs: { color: "#006c27", light: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v(" Got it! ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }