import axios from "axios";
import Vue from "vue";

class RatingClient {
  instance = axios.create({
    baseURL: process.env.VUE_APP_RATING_URL,
    timeout: 5000,
  });

  constructor() {
    console.log("RatingClient constructor");

    this.instance.interceptors.request.use(
      async function(config) {
        // Do something before request is sent
        let token = await Vue.prototype.$authService.getAccessToken({
          scopes: [process.env.VUE_APP_RATING_SCOPE],
        });
        config.headers.Authorization = `Bearer ${token.accessToken}`;
        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }
  handleError(error) {
    let err;
    console.log(error);
    if (error.response) {
      err = error.response.data;
      if (err.message === "Error validating claims. Not in app user group") {
        err.message =
          "You do not have all needed permissions to use the Consumer Application Self Service. Please contact api-mgmt-support@schaeffler.com to get needed group membership.";
      }
    } else {
      err = {
        message: "Connection Timeout",
        requestId: "not available",
      };
    }
    return err;
  }
  getRatings(assetName) {
    console.log("getRatings for " + assetName);
    return this.instance.get("/assets/" + assetName, {});
  }
  getVersion() {
    console.log("getVersion");
    return this.instance.get("version", {});
  }
  submitRatings(assetName, rating, feedback) {
    var body = {
      rating: rating,
      feedback: feedback,
    };
    console.log("submitRatings for " + assetName);
    return this.instance.post("/assets/" + assetName + "/ratings", body, {});
  }
}

const rating = new RatingClient();

export default rating;
