import VueRouter from "vue-router";
import ManagePermissions from "../pages/ManagePermissions";
import Application from "../pages/Application";
import Subscribe from "../pages/Subscribe";
import Feedback from "../pages/Feedback";
import PermissionModel from "../pages/PermissionModel";
import Environments from "../pages/Environments";
import NonCassApplications from "../pages/NonCassApplications";
import DeletedApps from "../pages/DeletedAps.vue";
import OpenPermissionRequest from "../pages/OpenPermissionRequests.vue";
import ManageUsers from "../pages/ManageUsers";
import Home from "../pages/Home";
import Version from "../pages/Version";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import navStore from "../navStore";
import { publicPath } from "../../vue.config.js";

class Router {
  routes = [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/applications/:applicationid",
      name: "Application",
      component: Application,
    },
    {
      path: "/subscribe/:productid",
      name: "Subscribe",
      component: Subscribe,
    },
    {
      path: "/applications/:applicationid/permissions/:permissionid",
      name: "Permissions",
      component: ManagePermissions,
    },
    {
      path: "/feedback",
      name: "Feedback",
      component: Feedback,
    },
    {
      path: "/version",
      name: "Version",
      component: Version,
    },
    {
      path: "/permissionmodel",
      name: "PermissionModel",
      component: PermissionModel,
    },
    {
      path: "/openpermissionrequest",
      name: "OpenPermissionRequest",
      component: OpenPermissionRequest,
    },
    {
      path: "/environments",
      name: "Environments",
      component: Environments,
    },
    {
      path: "/noncassapplications",
      name: "NonCassApplications",
      component: NonCassApplications,
    },
    {
      path: "/deletedapps",
      name: "DeletedApps",
      component: DeletedApps,
    },
    {
      path: "/manage",
      name: "ManageUsers",
      component: ManageUsers,
    },
    { path: "*", redirect: "/" },
  ];
  router = null;
  constructor(vue) {
    console.log("Router constructor");
    this.router = new VueRouter({
      mode: vue.prototype.$drupal ? "abstract" : "history",
      base: vue.prototype.$drupal ? null : publicPath,
      routes: this.routes,
    });
    this.router.beforeEach((to, from, next) => {
      console.log("Router BeforeEach");
      if (vue.prototype.$drupal) {
        console.log("Router Drupal helper");
        var objTo = {};
        objTo.fullPath = window.location.href;
        to = { ...to, ...objTo };
        window.history.pushState(
          "data",
          document.title,
          window.location.origin + window.location.pathname
        );
      }
      if (to.name !== "Login" && !vue.prototype.$authService.isUserLoggedIn()) {
        console.log(to);
        console.log("router: saving navTo: " + to.fullPath);
        navStore.commit("navTo", to.fullPath);
        next({ name: "Login" });
      } else {
        const targetPage = to.query.page;
        if (targetPage != undefined || targetPage != null) {
          console.log("parameter navigation: " + targetPage);
        }
        if (targetPage === "subscribe") {
          const id = to.query.id;
          console.log("id: ", id);
          if (id != undefined) {
            this.router.push({
              name: "Subscribe",
              params: { productid: id },
            });
            return;
          }
        }
        if (targetPage === "feedback") {
          this.router.push({ name: "Feedback" });
          return;
        }
        if (targetPage === "version") {
          this.router.push({ name: "Version" });
          return;
        }
        next();
      }
    });
    if (vue.prototype.$drupal) {
      this.router
        .push({
          name: "Home",
        })
        .catch(() => {
          console.log("Something bad happend .. ");
        });
    }
  }
}

var router_instance = null;

export default {
  install: function (vue) {
    console.log("Init Router");
    router_instance = new Router(vue);
  },
  get: function () {
    console.log("Retrieving Router");
    console.log(router_instance);
    return router_instance.router;
  },
};
