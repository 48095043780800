var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "nudge-bottom": "30px", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-badge",
                {
                  attrs: {
                    dot: true,
                    color: "red",
                    value: _vm.status.statusOverride != undefined,
                    "offset-x": "-2px",
                    "offset-y": "11px",
                  },
                },
                [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        { class: _vm.getAlarmTextClass() },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.status.status) + " ")]
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    _c("div", { staticStyle: { "font-size": "1rem" } }, [
                      _vm._v(" Status Overwrite "),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.status.actualStatus != undefined
                ? _c(
                    "v-row",
                    { staticClass: "mt-5", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              color: "#006c27",
                              dense: "",
                              label: "Actual Status",
                              value: _vm.status.actualStatus,
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.overwrites,
                          label: "Status Overwrite",
                          outlined: "",
                          color: "#006c27",
                          "hide-details": "",
                          dense: "",
                        },
                        model: {
                          value: _vm.selectedOverwrite,
                          callback: function ($$v) {
                            _vm.selectedOverwrite = $$v
                          },
                          expression: "selectedOverwrite",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mr-2 mb-2",
                  attrs: { disabled: _vm.submitDisabled(), color: "#006c27" },
                  on: { click: _vm.submit },
                },
                [_vm._v("submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }