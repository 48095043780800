<template>
  <v-dialog v-model="show" persistent width="500" height="500">
    <v-card width="500" height="500">
      <v-card-title>
        Are you still there?
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            You will be logged out in 60 seconds
          </v-col>
        </v-row>
      </v-card-text>
      <v-row class="mt-8">
        <v-col align="center" justify="center">
          <v-progress-circular
            v-model="timer"
            align="center"
            :size="270"
            :width="23"
            color="#006c27"
            ><div class="text-h1">{{ countdown }}</div>
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IdleOverlay",
  props: {
    value: Boolean,
  },
  mounted() {
    console.log("mounted");
  },
  data: () => ({
    intervalObj: null,
    timer: 0,
    countdown: 60,
    maxTime: 60,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: function(val) {
      console.log("Watcher: " + val);
      if (val) {
        if (this.intervalObj) {
          clearInterval(this.intervalObj);
          this.intervalObj = null;
          this.timer = 0;
          this.countdown = this.maxTime;
        }
        let self = this;
        this.intervalObj = setInterval(function() {
          if (self.countdown == 0) {
            console.log("LOGOUT");
            self.$router
              .push({
                name: "Logout",
              })
              .catch((err) => {
                console.log(err);
                console.log("Something bad happend .. start over");
                window.location.reload();
              });
            clearInterval(self.intervalObj);
            self.show = false;
            return;
          }
          if (self.value) {
            self.countdown--;
            self.timer = 100 - (self.countdown / self.maxTime) * 100;
            console.log(self.timer);
          }
        }, 1000);
      } else {
        if (this.intervalObj) {
          clearInterval(this.intervalObj);
          this.intervalObj = null;
        }
        this.timer = 0;
        this.countdown = this.maxTime;
      }
    },
  },
  methods: {},
};
</script>

<style></style>
