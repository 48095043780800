<template>
  <v-dialog width="100%" v-model="show" persistent max-width="300">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col align="center">
            Please confirm deletion
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="6" align="center">
            <v-btn color="#006c27" class="white--text" @click="confirm">
              Delete
            </v-btn>
          </v-col>
          <v-col cols="6" align="center">
            <v-btn color="#006c27" class="white--text" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDeleteOverlay",
  components: {},
  props: {
    value: Boolean,
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.show = false;
      this.$emit("confirm");
    },
    cancel() {
      this.show = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
