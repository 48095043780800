module.exports = {
  publicPath: "/",
  transpileDependencies: ["vuetify"],
  configureWebpack: (config) => {
    if (process.env.VUE_APP_DRUPAL === "true") {
      config.entry.app = ["./src/main-drupal.js"];
    }
    config.devtool = "source-map";
  },
  // Disable filename hashing in Drupal.
  filenameHashing: false,
  devServer: {
    host: "localhost",
    port: 4200,
  },
};
