var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "800" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { large: "", color: "#006c27" } },
                [_vm._v("mdi-face-agent")]
              ),
              _c("div", { staticStyle: { color: "#616161" } }, [
                _vm._v("Subscription Assistance"),
              ]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c("div", { staticClass: "ml-1 mb-2" }, [
                _vm._v(
                  " Please confirm the APIs you can not find in the API catalog: "
                ),
              ]),
              _vm._l(_vm.products, function (product, index) {
                return [
                  _c("v-checkbox", {
                    key: index,
                    attrs: {
                      color: "#006c27",
                      dense: "",
                      "hide-details": "",
                      label: product.title + " (" + product.name + ")",
                      value: product.name,
                    },
                    model: {
                      value: _vm.confirmedProducts,
                      callback: function ($$v) {
                        _vm.confirmedProducts = $$v
                      },
                      expression: "confirmedProducts",
                    },
                  }),
                ]
              }),
              _c("div", { staticClass: "mt-4" }, [
                _vm._v("Please provide additional information"),
              ]),
              _c("v-textarea", {
                attrs: {
                  counter: "",
                  rules: _vm.rules,
                  color: "#006c27",
                  "no-resize": true,
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.reasonText,
                  callback: function ($$v) {
                    _vm.reasonText = $$v
                  },
                  expression: "reasonText",
                },
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-6" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27", disabled: !_vm.submitEnabled },
                  on: { click: _vm.submit },
                },
                [_vm._v("submit")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27" },
                  on: { click: _vm.close },
                },
                [_vm._v("CLOSE")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }