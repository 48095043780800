<template>
  <v-expansion-panel @click="onExpansionPanelClick">
    <v-expansion-panel-header id="secretsList" class="pl-3">
      <div>
        <v-icon> mdi-finance </v-icon>
        <span class="ml-1">Usage Statistics</span>
        <v-tooltip v-if="opened" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ml-2"
              color="#006c27"
              @click="update"
              >mdi-information-outline</v-icon
            >
          </template>
          <span>
            You can see here the overall API usage of the last 30 days.<br />
            You can click the icon to update the data.<br />
            Requests need upto 5 minutes to appear here.</span
          >
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-chart ref="chart" class="chart" :option="option" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from "moment";
import cass from "../api/cass";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  GridComponent,
  TooltipComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  name: "UsageStats",
  data() {
    return {
      loading: false,
      opened: false,
      error_series: {},
      request_series: {},
      last30DaysMetrics: [],
      option: {
        color: ["#00893d", "red"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          extraCssText: "float: left",
        },
        grid: {
          left: 10,
          containLabel: true,
          bottom: 10,
          top: 10,
          right: 10,
        },
        title: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [],
      },
    };
  },
  props: {
    application: Object,
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("DD.MM.YYYY HH:mm");
    },
  },
  mounted() {
    if (this.last30DaysMetrics.length == 0) {
      this.request_series = {
        name: "2xx responses",
        type: "bar",
        stack: "Usage",
        data: [],
      };
      this.error_series = {
        name: "4xx/5xx responses",
        type: "bar",
        stack: "Usage",
        data: [],
      };
      this.option.series.push(this.request_series);
      this.option.series.push(this.error_series);
      this.loadData();
    }
  },
  methods: {
    onExpansionPanelClick(event) {
      if (
        event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.opened = false;
      } else {
        this.opened = true;
        setTimeout(() => {
          this.resize();
        }, 50);
      }
    },
    update(evt) {
      this.option.xAxis.data = [];
      this.request_series.data = [];
      this.error_series.data = [];
      this.loadData();
      evt.stopPropagation();
    },
    resize() {
      this.$refs.chart.resize();
    },
    loadData() {
      this.loading = true;
      cass
        .getAppMetrics(this.$route.params.applicationid)
        .then((response) => {
          console.log(response);
          this.last30DaysMetrics = response.data;
          this.prepareGraphData();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prepareGraphData() {
      for (let i = this.last30DaysMetrics.length - 1; i >= 0; i--) {
        let metric = this.last30DaysMetrics[i];
        let date = moment(metric.date, "YYYY-MM-DD");
        this.option.xAxis.data.push(date.format("MMM D"));

        if (metric.calls == -1) {
          this.request_series.data.push(0);
        } else {
          this.request_series.data.push(metric.calls);
        }

        if (metric.errors == -1) {
          this.error_series.data.push(0);
        } else {
          this.error_series.data.push(metric.errors);
        }
      }
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 220px;
}
.chartfooter {
  float: left;
}
</style>
