var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    {
      ref: "statusoverlay",
      staticStyle: { display: "unset !important" },
      attrs: { "z-index": "20000", absolute: "", opacity: "1" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            height: "100%",
            "background-color": "white",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                "z-index": "1",
                top: "100px",
                width: "100%",
                "text-transform": "uppercase",
                "text-align": "center",
                "font-family": "'Open Sans', sans-serif",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "unselectable",
                  staticStyle: { "font-size": "5rem", color: "#4c4c4c" },
                },
                [_vm._v(" We are sorry! ")]
              ),
              _c(
                "div",
                {
                  staticClass: "unselectable",
                  staticStyle: { "font-size": "1.1rem", color: "#7e7e7e" },
                },
                [
                  _vm._v(
                    " CASS needs the SAP APIs. These are currently unresponsive."
                  ),
                  _c("br"),
                  _vm._v(
                    " Help is on the way. In order to cool down - you can chill the lion"
                  ),
                  _c("br"),
                  _vm._v(" He will surely appreciate it! "),
                ]
              ),
            ]
          ),
          _c("div", {
            staticStyle: {
              background: "white",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            },
            attrs: { id: "container" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }