var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    { attrs: { dark: false, absolute: false, value: _vm.show } },
    [
      _c(
        "v-sheet",
        { attrs: { color: "rgb(33,33,33,0)", width: "600" } },
        [
          _c(
            "v-card",
            { staticClass: "d-flex flex-column", attrs: { width: "600" } },
            [
              _c(
                "v-card-title",
                [
                  _c("div", {}, [_vm._v("Create New Application")]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { id: "closeCreateApplicationOverlay" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " You want to use an Schaeffler API from within your application? Great! 🥳"
                ),
                _c("br"),
                _vm._v(" Please register your application! "),
              ]),
              _c(
                "v-card-text",
                {},
                [
                  _c(
                    "v-form",
                    {
                      ref: "formCreate",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.nop.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.createValid,
                        callback: function ($$v) {
                          _vm.createValid = $$v
                        },
                        expression: "createValid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  id: "inputApplicationName",
                                  label: "Application Name",
                                  placeholder:
                                    "give your application a nice name",
                                  outlined: "",
                                  rules: [
                                    _vm.notEmpty,
                                    _vm.min,
                                    _vm.rules.validName,
                                  ],
                                  "error-messages": _vm.serverErrorCreate,
                                  dense: "",
                                  maxlength: "50",
                                  counter: "",
                                  autocomplete: "new-password",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.appNameCreate,
                                  callback: function ($$v) {
                                    _vm.appNameCreate = $$v
                                  },
                                  expression: "appNameCreate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-6 white--text",
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    id: "createButton",
                                    color: "#006c27",
                                    disabled: !_vm.createValid,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createApplication()
                                    },
                                  },
                                },
                                [_vm._v(" Create ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "9", id: "selectedEnvironment" } },
                            [
                              _c("v-select", {
                                staticClass: "mb-3",
                                attrs: {
                                  items: _vm.environments,
                                  label: "Environment",
                                  outlined: "",
                                  "menu-props": { bottom: true, offsetY: true },
                                  color: "#006c27",
                                  "hide-details": "",
                                  rules: [_vm.notEmpty],
                                  dense: "",
                                },
                                model: {
                                  value: _vm.selectedEnvironment,
                                  callback: function ($$v) {
                                    _vm.selectedEnvironment = $$v
                                  },
                                  expression: "selectedEnvironment",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-6",
                                  staticStyle: {
                                    display: "inline-block",
                                    "text-transform": "none",
                                    "letter-spacing": "0",
                                    "text-align": "left",
                                  },
                                  attrs: {
                                    id: "environmentHelpMeButtonCreateApplication",
                                    color: "#006c27",
                                    text: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showEnvironmentHelp = true
                                    },
                                  },
                                },
                                [_vm._v(" Help me! ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                cols: "9",
                                id: "selectedPermissionModel",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.permissionModels,
                                  label: "Permission Model",
                                  outlined: "",
                                  "menu-props": { bottom: true, offsetY: true },
                                  color: "#006c27",
                                  rules: [_vm.notEmpty],
                                  dense: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.selectedPermissionModel,
                                  callback: function ($$v) {
                                    _vm.selectedPermissionModel = $$v
                                  },
                                  expression: "selectedPermissionModel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-6",
                                  staticStyle: {
                                    display: "inline-block",
                                    "text-transform": "none",
                                    "letter-spacing": "0",
                                    "text-align": "left",
                                  },
                                  attrs: {
                                    id: "permissionHelpMeButtonCreateApplication",
                                    color: "#006c27",
                                    text: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showPermissionModelHelp = true
                                    },
                                  },
                                },
                                [_vm._v(" Help me! ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            dark: false,
            absolute: true,
            value: _vm.creating,
            opacity: "0.1",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          align: "center",
                          size: 170,
                          width: 13,
                          color: "#006c27",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("environment-dialog", {
        model: {
          value: _vm.showEnvironmentHelp,
          callback: function ($$v) {
            _vm.showEnvironmentHelp = $$v
          },
          expression: "showEnvironmentHelp",
        },
      }),
      _c("permission-dialog", {
        model: {
          value: _vm.showPermissionModelHelp,
          callback: function ($$v) {
            _vm.showPermissionModelHelp = $$v
          },
          expression: "showPermissionModelHelp",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }