var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text text--darken-2" },
                    [_vm._v(" Version Information ")]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary my-3" },
                    [
                      _c("version-content", {
                        attrs: {
                          versionCASSAPI: _vm.versionCASSAPI,
                          versionRATEAPI: _vm.versionRATEAPI,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }