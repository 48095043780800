var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(_vm._b({}, "div", attrs, false), on),
                [
                  _c(
                    "v-fade-transition",
                    { attrs: { mode: "out-in" } },
                    [
                      _vm.$vuetify.theme.dark
                        ? _c(
                            "v-icon",
                            {
                              attrs: { medium: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleMode($event)
                                },
                              },
                            },
                            [_vm._v("mdi-weather-night")]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: { medium: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleMode($event)
                                },
                              },
                            },
                            [_vm._v("mdi-weather-sunny")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v("Toggle Dark Mode")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }