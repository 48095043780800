<template>
  <v-dialog width="800px" v-if="show" v-model="show" persistent>
    <v-card>
      <v-card-title class="text--secondary"> Deprecation Details </v-card-title>
      <v-card-text class="text--primary pb-2">
        <v-text-field
          :value="deprecation.proxyName"
          label="Proxy Name"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
        </v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              :value="deprecation.State"
              label="Deprecation Status"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              color="#006c27"
            >
              <v-tooltip
                v-if="deprecation.State == 'DEPRECATED'"
                slot="append"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    style="margin-top: 2px"
                    dense
                    class="mr-2"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >Deprecated means, the deprecation time has been reached and
                  <br />
                  it is not possible to subscribe or request permissions <br />
                  anymore, but you can still use the API until the <br />
                  decommissioning time has been reached.</span
                >
              </v-tooltip>
              <v-tooltip
                v-if="deprecation.State == 'DECOMMISSONED'"
                slot="append"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    style="margin-top: 2px"
                    dense
                    class="mr-2"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >Decommissioning means, the decommissioning time has been
                  <br />
                  reached and it is not be possible to consume the API
                  anymore<br />
                  and you will not receive data anymore.</span
                >
              </v-tooltip>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="formatDate(deprecation.deprecationDate)"
              label="Deprecation Date"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              color="#006c27"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="formatDate(deprecation.decommissionDate)"
              label="Decomissioning Date"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              color="#006c27"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-textarea
          :value="deprecation.deprecationReason | decode"
          label="Deprecation Reason"
          outlined
          dense
          auto-grow
          rows="1"
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-textarea>
        <v-text-field
          v-if="deprecation.successorAPI"
          :value="deprecation.successorAPI"
          label="Successor API"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
        </v-text-field>
        <v-text-field
          v-if="deprecation.successorLink"
          :value="deprecation.successorLink | decode"
          label="Developer Portal Link"
          outlined
          dense
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        >
          <v-icon
            style="margin-top: 2px"
            dense
            slot="append"
            @click="goToLink(deprecation.successorLink)"
            class="mr-2"
          >
            mdi-link
          </v-icon>
        </v-text-field>
        <v-textarea
          v-if="deprecation.migrationNotes"
          :value="deprecation.migrationNotes | decode"
          label="Migration Recommendation"
          outlined
          dense
          auto-grow
          rows="1"
          readonly
          hide-details
          class="mt-3"
          color="#006c27"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="#006c27" class="white--text" @click="done()">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "DeprecationDetailOverlay",
  props: {
    value: Boolean,
    deprecation: Object,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
    },
  },
  filters: {
    decode(text) {
      return window.atob(text);
    },
  },
  methods: {
    done() {
      this.$emit("close", true);
    },
    formatDate(date) {
      return moment(String(date)).format("DD.MM.YYYY");
    },
    goToLink(url) {
      window.open(window.atob(url), "_blank").focus();
    },
  },
};
</script>

<style></style>
