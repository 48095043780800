<template>
  <v-dialog width="100%" v-model="show" persistent max-width="700">
    <v-card v-if="application != null">
      <v-card-title>
        <div>
          Application Guide for Type<i>
            {{ application.permissionModel | formatString }}</i
          >
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        This is a basic step-by-step guide to help you through the configuration
        of your application. This guide is not complete and does not cover all
        scenarios. If you got stuck - please feel free to contact our support at
        <a href="mailto:api-mgmt-support@schaeffler.com"
          >api-mgmt-support@schaeffler.com</a
        >
        <!-- DELEGATED ------------------------------------------------------------ -->
        <template v-if="application.permissionModel === 'DELEGATED'">
          <v-row class="mt-5">
            <v-col cols="1">
              <v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-1-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Select your IT Security Demand
              </div>
              <div>
                Please make sure you followed the IT Security By Design
                procedure and configured the IT Security Demand accordingly
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-2-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Check your subscribed products
              </div>
              <div>
                Please make sure the API you plan to consume is listed and fits
                your application type.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-3-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Request Permissions
              </div>
              <div>
                You need to request at least one permission in order to consume
                an API. In type DELEGATED you most likely also want to login
                users. If your users use the Schaeffler Azure Tenant please also
                request the <strong>User.Read</strong> permission from Microsoft
                Graph API.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-4-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Register the Redirect URI
              </div>
              <div>
                The OAuth2 flow needs to contact your application in order to
                interchange authorization codes (refer to OAuth2 section).
                Therefore you need to register a redirect URI. This is basically
                an URL of your application where it can process authorization
                codes provided by the IdP.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-5-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Create secret (optional)
              </div>
              <div>
                Only create a secret if your application has a server-side page
                rendering backend. For all others like Single-Page-Application,
                Mobile or Desktop you do not need any secret.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-check</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                You are ready to go!
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- APPLICATION ------------------------------------------------------------ -->
        <template v-if="application.permissionModel === 'APPLICATION'">
          <v-row class="mt-5">
            <v-col cols="1">
              <v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-1-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Select your IT Security Demand
              </div>
              <div>
                Please make sure you followed the IT Security By Design
                procedure and configured the IT Security Demand accordingly
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-2-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Check your subscribed products
              </div>
              <div>
                Please make sure the API you plan to consume is listed and fits
                your application type.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-3-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Request Permissions
              </div>
              <div>
                You need to request at least one permission in order to consume
                an API.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-4-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                Request mTLS Certificate (optional)
              </div>
              <div>
                Only request an mTLS certificate if the URL of the API you want
                to consume is at api<strong>mtls</strong>.schaeffler.com (or
                environment specific suffix). For all other endpoints you can
                skip this procedure.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-numeric-5-circle-outline</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">Create Secret</div>
              <div>
                In all cases you need to create a secret for the OAuth2 client
                credential flow. Use this together with your application ID and
                permission scope to obtain tokens.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"
              ><v-icon style="margin-top: 3px" color="#006c27"
                >mdi-check</v-icon
              ></v-col
            >
            <v-col>
              <div class="text-subtitle-1 text--primary">
                You are ready to go!
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApplicationGuideOverlay",
  components: {},
  props: {
    value: Boolean,
    application: Object,
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  filters: {
    formatString(value) {
      return (
        value.charAt(0).toUpperCase() +
        value.replaceAll("_", " ").slice(1).toLowerCase()
      );
    },
  },
  methods: {
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
