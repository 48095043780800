import axios from "axios";
import Vue from "vue";

class CassClient {
  instance = axios.create({
    baseURL: process.env.VUE_APP_CASS_URL,
    timeout: 10000,
  });

  constructor() {
    console.log("CassClient constructor");

    this.instance.interceptors.request.use(
      async function (config) {
        // Do something before request is sent
        let token = await Vue.prototype.$authService.getAccessToken({
          scopes: [process.env.VUE_APP_CASS_SCOPE],
        });
        config.headers.Authorization = `Bearer ${token.accessToken}`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }
  handleError(error) {
    let err;
    console.log(error);
    if (error.response && error.response.data) {
      err = error.response.data;
      if (err.message === "Error validating claims. Not in app user group") {
        err.message =
          "You do not have all needed permissions to use the Consumer Application Self Service. Please contact api-mgmt-support@schaeffler.com to get needed group membership.";
      }
    } else {
      err = {
        message: "network error occurred. please retry.",
        requestId: "not available",
      };
    }
    // This feature enables slot buttons to do something which needs more time
    err.loading = false;
    return err;
  }
  async getApplications() {
    console.log("getApplications");
    return this.instance.get("applications", { timeout: 35000 });
  }
  getDeletedAppliations() {
    console.log("getDeletedAppliations");
    return this.instance.get("deletedapplications", { timeout: 35000 });
  }
  realDeleteDeletedAppliations(id) {
    console.log("realDeleteDeletedAppliations");
    return this.instance.delete("deletedapplications/" + id, {
      timeout: 35000,
    });
  }
  getApplication(id) {
    console.log("getApplications");
    return this.instance.get("applications/" + id, { timeout: 35000 });
  }
  patchApplication(id, body) {
    console.log("patchApplication");
    return this.instance.patch("applications/" + id, body, { timeout: 35000 });
  }
  getProducts(env) {
    console.log("getProducts");
    return this.instance.get("sapproducts", {
      params: { environment: env },
    });
  }
  getProduct(env, productid) {
    console.log("getProduct " + productid);
    return this.instance.get("sapproducts/" + productid, {
      params: { environment: env },
    });
  }
  patchSAPStatus(status) {
    console.debug("patchSAPStatus");
    return this.instance.patch("status", status, {});
  }
  sendData(data) {
    console.debug("sendData");
    return this.instance.post("data", data, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
  }
  getSAPStatus() {
    console.debug("getSAPStatus");
    return this.instance.get("status", {});
  }
  createApplication(obj) {
    console.log("createApplication");
    return this.instance.post("applications", obj, { timeout: 35000 });
  }
  getPermissions(id) {
    console.log("getPermissions");
    return this.instance.get("applications/" + id + "/permissions", {
      timeout: 35000,
    });
  }
  getPermission(appid, permid) {
    console.log("getPermissions");
    return this.instance.get(
      "applications/" + appid + "/permissions/" + permid,
      { timeout: 35000 }
    );
  }
  grantPermission(appid, permid, grant) {
    console.log("grantPermission");
    return this.instance.patch(
      "applications/" + appid + "/permissions/" + permid,
      grant,
      {}
    );
  }
  denyPermission(appid, permid, deny) {
    console.log("grantPermission");
    return this.instance.patch(
      "applications/" + appid + "/permissions/" + permid,
      deny,
      {}
    );
  }
  getITDemands() {
    console.log("getITDemands");
    return this.instance.get("itsecs", { timeout: 35000 });
  }
  addPermission(appid, perm, reason) {
    console.log("addPermission");
    let permission = { name: perm, reason: reason };
    return this.instance.post(
      "applications/" + appid + "/permissions",
      permission,
      {}
    );
  }
  deletePermission(appid, permid) {
    console.log("deletePermission");
    return this.instance.delete(
      "applications/" + appid + "/permissions/" + permid,
      {}
    );
  }
  getAppMetrics(applicationid) {
    console.log("getAppMetrics: ", applicationid);
    //applicationid = "df31849d-8aa3-432d-acac-d4fae60b90b6";
    return this.instance.get("applications/" + applicationid + "/metrics", {});
  }
  getUser() {
    console.log("getUser");
    return this.instance.get("users/me", {});
  }
  patchUser(user) {
    console.log("PatchUser");
    return this.instance.patch("users", user, {});
  }
  getAllUsers() {
    console.log("getAllUsers");
    return this.instance.get("users", { timeout: 55000 });
  }
  getPermissionEvents(state) {
    console.log("getPermissionEvents");
    return this.instance.get("/permissionevent/" + state, { timeout: 55000 });
  }
  getRefreshUsers() {
    console.log("getRefreshUsers");
    return this.instance.get("users/refresh", { timeout: 55000 });
  }

  getVersion() {
    console.log("getVersion");
    return this.instance.get("version", {});
  }
  getCSR(appid, certid) {
    console.log("getCSR");
    return this.instance.get(
      "applications/" + appid + "/certificates/" + certid + "/csr",
      { responseType: "blob" }
    );
  }
  uploadCSR(appid, certid, csrData) {
    console.log("uploadCSR");
    return this.instance.post(
      "applications/" + appid + "/certificates/" + certid + "/csr",
      csrData,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
  }
  getSignedCert(appid, certid) {
    console.log("getSignedCert");
    return this.instance.get(
      "applications/" + appid + "/certificates/" + certid + "/signedCert",
      { responseType: "blob" }
    );
  }
  uploadSignedCert(appid, certid, certdata) {
    console.log("uploadSignedCert");
    return this.instance.post(
      "applications/" + appid + "/certificates/" + certid + "/signedCert",
      certdata,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
  }
  addOwner(appid, owner) {
    console.log("addOwner");
    let ownersarray = [];
    ownersarray.push(owner);
    return this.instance.post(
      "applications/" + appid + "/owners",
      ownersarray,
      {}
    );
  }
  deleteOwner(appid, owner) {
    console.log(owner);
    return this.instance.delete(
      "applications/" + appid + "/owners/" + owner,
      {}
    );
  }
  getSecrets(appid) {
    console.log("getSecrets");
    return this.instance.get("applications/" + appid + "/secrets", {});
  }
  addSecret(appid) {
    console.log("addSecret");
    return this.instance.post("applications/" + appid + "/secrets", {});
  }
  deleteSecret(appid, secid) {
    console.log("deleteSecret");
    return this.instance.delete(
      "applications/" + appid + "/secrets/" + secid,
      {}
    );
  }
  patchSecret(appid, secid, body) {
    console.log("patchSecret");
    return this.instance.patch(
      "applications/" + appid + "/secrets/" + secid,
      body,
      {}
    );
  }
  addCertificate(appid) {
    console.log("addCertificate");
    return this.instance.post("applications/" + appid + "/certificates", {});
  }
  patchCertificate(appid, certid, body) {
    console.log("patchCertificate");
    return this.instance.patch(
      "applications/" + appid + "/certificates/" + certid,
      body,
      {}
    );
  }
  addCSR(appid, certid, csr) {
    console.log("addCSR");
    return this.instance.post(
      "applications/" + appid + "/certificates/" + certid + "/csr",
      csr,
      { timeout: 55000 }
    );
  }
  deleteCertificate(appid, certid) {
    console.log("deleteCertificate");
    return this.instance.delete(
      "applications/" + appid + "/certificates/" + certid,
      {}
    );
  }
  addRedirectURI(appid, redirect) {
    console.log("addRedirectURI");
    return this.instance.post(
      "applications/" + appid + "/redirectURIs",
      redirect,
      {}
    );
  }
  deleteRedirectURI(appid, uriid) {
    console.log("deleteRedirectURI");
    return this.instance.delete(
      "applications/" + appid + "/redirectURIs/" + uriid,
      {}
    );
  }
  deleteApplication(appid, safeDelete) {
    console.log("deleteApplication");
    return this.instance.delete("applications/" + appid, {
      timeout: 35000,
      params: { safeDelete: safeDelete },
    });
  }
  getSubscriptions(appid) {
    console.log("getSubscriptions");
    return this.instance.get("applications/" + appid + "/subscriptions", {
      timeout: 35000,
    });
  }
  addSubscription(appid, productname) {
    console.log("addSubscription");
    return this.instance.post(
      "applications/" + appid + "/subscriptions/" + productname,
      null,
      {
        timeout: 35000,
      }
    );
  }
  subscriptionAssistance(appid, body) {
    console.log("subscriptionAssistance");
    return this.instance.post(
      "applications/" + appid + "/subscriptions/assistance",
      body,
      {
        timeout: 35000,
      }
    );
  }
  deleteSubscription(appid, productname) {
    console.log("deleteSubscription");
    return this.instance.delete(
      "applications/" + appid + "/subscriptions/" + productname,
      null,
      {
        timeout: 35000,
      }
    );
  }
  downloadSwagger(proxyname, environment) {
    const params = new URLSearchParams([["environment", environment]]);
    console.log("downloadSwagger");
    return this.instance.get("api/" + proxyname + "/documentation", {
      params,
      responseType: "arraybuffer",
    });
  }
  getSAPApplications() {
    console.log("getSAPApplications");
    return this.instance.get("sapapplications", { timeout: 35000 });
  }
  getSAPApplication(id, environment) {
    console.log("getSAPApplication for id: " + id + " and env: " + environment);
    const params = new URLSearchParams([["environment", environment]]);
    return this.instance.get("sapapplications/" + id, {
      params,
      timeout: 35000,
    });
  }
  deleteSAPApplication(id, environment) {
    console.log(
      "deleteSAPApplication with id: " + id + " and env: " + environment
    );
    const params = new URLSearchParams([["environment", environment]]);
    return this.instance.delete("sapapplications/" + id, {
      params,
      timeout: 35000,
    });
  }
}

const cass = new CassClient();

export default cass;
