var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _vm.loggin
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { align: "center", justify: "center" } },
                [
                  !this.$drupal
                    ? _c("v-progress-circular", {
                        attrs: {
                          align: "center",
                          size: 170,
                          width: 13,
                          color: "#006c27",
                          indeterminate: "",
                        },
                      })
                    : _vm._e(),
                  !this.$drupal
                    ? _c("div", { staticClass: "mt-14 text-h5" }, [
                        _vm._v(" We are logging you on. "),
                      ])
                    : _vm._e(),
                  !this.$drupal
                    ? _c("div", {}, [_vm._v("Please stand by.")])
                    : _vm._e(),
                  this.$drupal
                    ? _c("div", { staticClass: "text-h5" }, [
                        _vm._v("Loading ..."),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-card",
                    { attrs: { width: "60%" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticStyle: {
                            "vertical-align": "middle",
                            "text-align": "center",
                            display: "block",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "Sorry. We have trouble with SSO to log you in 😢"
                            ),
                          ]),
                        ]
                      ),
                      _c("div", [_vm._v("Details: " + _vm._s(_vm.errormsg))]),
                      _vm.signup
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text mt-6 mb-4",
                              attrs: { color: "#006c27" },
                              on: { click: _vm.signUp },
                            },
                            [_vm._v("SIGN UP")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "white--text mt-6 mb-4",
                              attrs: { color: "#006c27" },
                              on: { click: _vm.tryagain },
                            },
                            [_vm._v("TRY AGAIN")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }