<template>
  <v-dialog width="100%" v-model="show" max-width="1000">
    <v-card class="gradient" max-width="1000">
      <v-card-text class="pt-7">
        <div
          style="font-size: 1.7rem; line-height: 2.2rem; color: white"
          class=""
        >
          <v-icon class="mb-2 mr-3" color="white" large>mdi-binoculars</v-icon
          >Our Vision is:
        </div>
        <v-card class="mt-3">
          <div
            class="px-7 py-7 text--secondary"
            style="font-size: 2rem; line-height: 2.2rem"
          >
            to be the leading data product hub that empowers business and IT to
            easily and securely connect their systems, partners, and customers
            in a digital world.
          </div>
        </v-card>
      </v-card-text>
      <v-card-text class="pt-7">
        <div style="font-size: 1.7rem; line-height: 2.2rem; color: white">
          <v-icon class="mb-1 mr-3" color="white" large
            >mdi-truck-delivery-outline</v-icon
          >Our Mission is:
        </div>
        <v-card class="mt-3 mb-5">
          <div
            class="px-7 py-7 text--secondary"
            style="font-size: 2rem; line-height: 2.2rem"
          >
            to empower business to accelerate their digital transformation by
            providing world-class API Management solutions that simplify
            integration, enhance security, and improve customer experience. We
            are committed to delivering innovative, reliable, and scalable
            solutions that help our customers stay ahead of the competition.
          </div>
          <div class="px-7 pb-3 text--secondary">
            #share2empower #data-as-a-product
          </div>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VisionOverlay",
  props: {
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.done();
      },
    },
  },
  watch: {
    value(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        console.log("Dialog was opened!");
      } else {
        console.log("Dialog was closed!");
      }
    },
  },
  methods: {
    done() {
      this.$emit("close", true);
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(63, 148, 0, 1) 0%,
    rgba(0, 108, 39, 1) 100%
  );
}
</style>
