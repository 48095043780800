<template>
  <v-dialog width="100%" v-if="show" v-model="show" persistent max-width="500">
    <v-card max-width="500" :loading="scanning">
      <template slot="progress">
        <v-progress-linear
          color="#006c27"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title class="text--secondary">
        <v-icon class="mr-3">mdi-account-search-outline</v-icon>
        User Rescan
      </v-card-title>
      <v-card-text>
        <div>
          Rescanning the user base should ONLY be used if you need to edit a
          user which has never logged in - and is NOT present in the list. If
          this is not the case, please do not use this function as it is very
          resource hungry.
        </div>
        <div class="mt-3">
          Please be patient. Scanning can last over 2 minutes. Do not refresh
          the page.
        </div>
        <div class="mt-3 d-flex">
          <v-spacer />
          <v-btn
            :disabled="disabled"
            @click="close"
            :dark="!disabled"
            color="#006c27"
            class="mr-3"
            >CLOSE</v-btn
          >
          <v-btn
            :disabled="disabled"
            @click="scan"
            :dark="!disabled"
            color="#006c27"
            >SCAN</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import cass from "../api/cass";

export default {
  name: "UserRescanOverlay",
  props: {
    value: Boolean,
  },
  data() {
    return {
      scanning: false,
      disabled: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    scan() {
      this.scanning = true;
      this.disabled = true;
      cass
        .getRefreshUsers()
        .then((response) => {
          console.log(response);
          this.$emit("scanned", "Scanning successful!");
        })
        .catch((error) => {
          console.error(error);
          this.$emit("scanned", "Scanning failed!");
        })
        .finally(() => {
          this.scanning = false;
          this.disabled = false;
          this.close();
        });
    },
  },
};
</script>

<style scoped></style>
