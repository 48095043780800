<template>
  <v-expansion-panel>
    <v-expansion-panel-header id="secretsList" class="pl-3">
      <div>
        <v-tooltip bottom v-if="application.state == 'NO_VALID_SECRET'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="red">
              mdi-lock-alert-outline</v-icon
            >
          </template>
          <span>Your application needs at least one valid secret</span>
        </v-tooltip>
        <v-icon v-else> mdi-lock-check-outline </v-icon>
        <span class="ml-1">Secrets</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-row
        v-if="application.secrets.length != 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col cols="4" style="text-align: left">
          <strong>Secret Hint</strong>
        </v-col>
        <v-col cols="4" style="text-align: left">
          <strong>Valid from</strong>
        </v-col>
        <v-col cols="4" style="text-align: left">
          <strong>Valid to</strong>
        </v-col>
      </v-row>
      <v-row
        v-if="application.secrets.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col style="text-align: left"> No secrets available </v-col>
      </v-row>
      <v-row
        v-if="application.secrets.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-divider></v-divider>
      </v-row>
      <template v-for="(secret, index) in application.secrets">
        <v-list-item dense :key="index" :selectable="false">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-form-textbox-password</v-icon>
          </v-list-item-icon>
          <v-list-item-content style="text-align: left">
            <v-row>
              <v-col cols="4"> {{ secret.secretHint }} ... </v-col>
              <v-col cols="4" class="ml-2">
                {{ secret.secretValidFrom | formatDate }}
              </v-col>
              <v-col cols="3" class="ml-2">
                {{ secret.secretValidTo | formatDate }}
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              :id="'deleteSecretButton_' + index"
              @click="deleteSecret($event, secret)"
              >mdi-trash-can-outline</v-icon
            >
            <v-tooltip v-if="secret.expiryNotification" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :id="'notificationSecretButton_' + index"
                  v-bind="attrs"
                  v-on="on"
                  :loading="progessing"
                  @click="showConfirmDialog($event, secret, false)"
                  style="max-width: 24px; max-height: 24px"
                  class="no-focus"
                >
                  <v-icon>mdi-alarm</v-icon>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <span
                >Expiry notification is activated. Click to deactivate.</span
              >
            </v-tooltip>
            <v-tooltip v-if="!secret.expiryNotification" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :id="'notificationSecretButton_' + index"
                  v-bind="attrs"
                  v-on="on"
                  :loading="progessing"
                  @click="showConfirmDialog($event, secret, true)"
                  style="max-width: 24px; max-height: 24px"
                  class="no-focus"
                >
                  <v-icon>mdi-alarm-off</v-icon>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <span
                >Expiry notification is deactivated. Click to activate.</span
              >
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="index + 100"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-spacer></v-spacer>
        <div>Add Secret</div>
        <v-list-item-icon>
          <v-tooltip :disabled="!addSecretDisabled()" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon
                  id="addSecretButton"
                  :disabled="addSecretDisabled()"
                  @click="addSecret"
                  >mdi-lock-plus-outline</v-icon
                >
              </span>
            </template>
            <span v-html="getDisabledToolTip()"></span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
    <secret-overlay
      v-model="secretdialog"
      @close="closeSecretDialog"
    ></secret-overlay>
    <confirm-delete-overlay
      v-model="confirmDelete"
      @confirm="onRealDeleteSecret"
    >
    </confirm-delete-overlay>
    <expiry-notification-confirm
      ref="expiryComponent"
      v-model="confirmNotification"
      :mode="confirmNotificationMode"
    >
    </expiry-notification-confirm>
  </v-expansion-panel>
</template>

<script>
import moment from "moment";
import cass from "../api/cass";
import SecretOverlay from "./SecretOverlay.vue";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";
import ExpiryNotificationConfirm from "./ExpiryNotificationConfirm.vue";

export default {
  components: {
    SecretOverlay,
    ConfirmDeleteOverlay,
    ExpiryNotificationConfirm,
  },
  name: "Secrets",
  data() {
    return {
      progessing: false,
      confirmDelete: false,
      confirmedDeleteSecret: null,
      showSnackError: false,
      snackErrorMessage: "",
      confirmNotification: false,
      confirmNotificationMode: false,
      secretdialog: {
        secret: {},
        show: false,
      },
    };
  },
  props: {
    application: Object,
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("DD.MM.YYYY HH:mm");
    },
  },
  methods: {
    onRealDeleteSecret() {
      cass
        .deleteSecret(
          this.application.applicationId,
          this.confirmedDeleteSecret.secretId
        )
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.snackErrorMessage = err.response.data.message;
          this.showSnackError = true;
        });
    },
    addSecretDisabled() {
      if (this.application.permissionModel === "DELEGATED") {
        for (let i = 0; i < this.application.redirectURIs.length; i++) {
          if (this.application.redirectURIs[i].type === "WEB") {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    getDisabledToolTip() {
      return "For creating secrets in permission model delegated<br/>you need a redirect URI of type WEB";
    },
    retry() {
      this.$emit("retry");
      this.secretdialog.secret = {};
    },
    closeSecretDialog() {
      this.secretdialog.show = false;
    },
    addSecret() {
      cass
        .addSecret(this.application.applicationId)
        .then((response) => {
          console.log(response);
          this.secretdialog.show = true;
          this.secretdialog.secret = response.data;
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.snackErrorMessage = err.response.data.message;
          this.showSnackError = true;
        });
    },
    deleteSecret(evt, secret) {
      evt.stopPropagation();
      this.confirmedDeleteSecret = secret;
      this.confirmDelete = true;
    },
    showConfirmDialog(evt, secret, enable) {
      this.confirmNotification = true;
      this.confirmNotificationMode = enable;
      evt.stopPropagation();
      console.log(secret);
      this.$refs.expiryComponent.$off("confirm");
      if (enable) {
        this.$refs.expiryComponent.$on(["confirm"], () => {
          console.log("CONFIRMED Activation");
          this.activateNotifySecret(evt, secret);
        });
      } else {
        this.$refs.expiryComponent.$on(["confirm"], () => {
          console.log("CONFIRMED Deactivation");
          this.deactivateNotifySecret(evt, secret);
        });
      }
    },
    activateNotifySecret(evt, secret) {
      evt.stopPropagation();
      console.log(secret);
      this.progessing = true;
      cass
        .patchSecret(this.application.applicationId, secret.secretId, {
          expiryNotification: true,
        })
        .then((response) => {
          console.log(response);
          secret.expiryNotification = true;
          this.snackErrorMessage = "Activation successful!";
          this.showSnackError = true;
          this.progessing = false;
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data == "") {
            this.snackErrorMessage = err.response.statusText;
          } else {
            this.snackErrorMessage = err.response.data.message;
          }
          this.showSnackError = true;
          this.progessing = false;
        });
    },
    deactivateNotifySecret(evt, secret) {
      evt.stopPropagation();
      console.log(secret);
      this.progessing = true;
      cass
        .patchSecret(this.application.applicationId, secret.secretId, {
          expiryNotification: false,
        })
        .then((response) => {
          console.log(response);
          secret.expiryNotification = false;
          this.snackErrorMessage = "Deactivation successful!";
          this.showSnackError = true;
          this.progessing = false;
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data == "") {
            this.snackErrorMessage = err.response.statusText;
          } else {
            this.snackErrorMessage = err.response.data.message;
          }
          this.showSnackError = true;
          this.progessing = false;
        });
    },
  },
};
</script>

<style scoped>
.no-focus::before {
  display: none;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
