<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="6">
        <v-card :loading="loading">
          <template slot="progress">
            <v-progress-linear
              color="#006c27"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="text--secondary">
            Your Feedback is what we need!
            <v-spacer />
            <v-rating
              v-if="rating"
              :value="rating.ratingAvg"
              background-color="#006c27"
              color="amber"
              dense
              half-increments
              readonly
              size="24"
            ></v-rating>
            <div
              v-if="
                rating && rating.ratingCount !== 0 && rating.ratingAvg !== 0
              "
              class="text-subtitle-2 text--secondary ml-2"
            >
              {{ rating.ratingAvg }} ( {{ rating.ratingCount }} )
            </div>
          </v-card-title>
          <v-card-text v-if="success" class="text--primary my-3">
            <v-row>
              <v-col align="center">
                <v-icon color="#006c27" class="mb-6 mr-3" x-large
                  >mdi-party-popper</v-icon
                >
                <span class="text-h3">Thank you!</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="!success" class="text--primary my-3">
            <div>
              Our service can get better with your help!<br />Please give us
              feedback what´s great and what needs improvement. <br />
              Your feedback is sent and saved anonymously. <br />
            </div>
            <!--
            <div class="mt-3">
              This service uses an
              <a
                href="https://developer.schaeffler.com/product/rest_cmn_rating"
                target="_blank"
                class="link"
                >Schaeffler API</a
              >!
            </div>
            -->
            <v-textarea
              v-model="userFeedbackText"
              class="mt-6"
              outlined
              color="#006c27"
              label="Your Feedback"
              hint="Thanks for your feedback!"
              counter
              maxlength="300"
            ></v-textarea>
            <div class="text-h6" style="text-align: center;">
              Your rating
            </div>
            <v-rating
              align="center"
              v-model="userRating"
              color="yellow darken-3"
              background-color="#006c27"
              half-increments
              hover
              large
            ></v-rating>
            <v-row>
              <v-col align="end">
                <v-btn
                  :disabled="!submitEnbaled"
                  color="#006c27"
                  class="white--text"
                  @click="submitRating"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <error-overlay v-model="error" @retry="errorretryfunc"></error-overlay>
  </v-container>
</template>

<script>
import rating from "../api/rating";
import ErrorOverlay from "../components/ErrorOverlay.vue";
export default {
  name: "Feedback",

  components: { ErrorOverlay },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      errorretryfunc: this.loadData,
      error: null,
      loading: true,
      rating: null,
      success: false,
      userRating: null,
      userFeedbackText: "",
    };
  },
  filters: {},
  computed: {
    submitEnbaled: {
      get() {
        return this.userRating != null && this.userFeedbackText !== "";
      },
    },
  },
  created() {
    /*
    // For error testing
    this.error = {
      message: "no idea",
      requestId: "fklajflakj",
    };
    */
  },
  methods: {
    loadData() {
      this.error = null;
      this.errorretryfunc = this.loadData;
      this.loading = true;
      rating
        .getRatings("CASS")
        .then((response) => {
          console.log(response);
          this.rating = response.data;
        })
        .catch((error) => {
          this.error = rating.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    submitRating() {
      this.error = null;
      this.loading = true;
      this.errorretryfunc = this.submitRating;
      rating
        .submitRatings("CASS", this.userRating, this.userFeedbackText)
        .then((response) => {
          console.log(response);
          this.success = true;
          this.userFeedbackText = "";
          this.userRating = null;
          return rating.getRatings("CASS");
        })
        .then((response) => {
          console.log(response);
          this.rating = response.data;
        })
        .catch((error) => {
          this.error = rating.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}
</style>
