var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Upload CSR")]),
          _c("v-card-text", { staticClass: "pb-0" }, [
            _c(
              "div",
              { staticClass: "dropbox" },
              [
                _c("input", {
                  staticClass: "input-file",
                  attrs: {
                    type: "file",
                    name: _vm.uploadFieldName,
                    disabled: _vm.isSaving,
                    accept: "*",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.filesChange($event)
                    },
                  },
                }),
                _c(
                  "v-row",
                  { staticClass: "pt-7" },
                  [
                    _c(
                      "v-col",
                      { attrs: { align: "center" } },
                      [
                        _c("v-icon", { attrs: { "x-large": "" } }, [
                          _vm._v("mdi-cloud-upload-outline"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { align: "center" } }, [
                      _vm.isInitial || _vm.isFailed
                        ? _c("p", { staticClass: "subtitle-2" }, [
                            _vm._v(" Drag your CSR here"),
                            _c("br"),
                            _vm._v(" or click to browse "),
                          ])
                        : _vm._e(),
                      _vm.isSaving
                        ? _c("p", { staticClass: "subtitle-2" }, [
                            _vm._v("Uploading CSR ..."),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-card-text", [
            _vm.isSuccess
              ? _c(
                  "div",
                  [
                    _c("v-icon", { attrs: { color: "#006c27" } }, [
                      _vm._v("mdi-check-outline"),
                    ]),
                    _c("span", { staticClass: "subtitle-2" }, [
                      _vm._v(" Upload successful! "),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isFailed
              ? _c("div", [
                  _c("p", { staticClass: "red--text subtitle-2" }, [
                    _vm._v("Upload failed."),
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v("Error message:")]),
                    _vm._v(" " + _vm._s(_vm.uploadError)),
                  ]),
                  this.requestId
                    ? _c("div", [
                        _c("strong", [_vm._v("Request Id:")]),
                        _vm._v(" " + _vm._s(_vm.requestId) + " "),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27", light: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }