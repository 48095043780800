<template>
  <v-dialog v-model="show">
    <v-card @click="close">
      <v-card-text>
        <v-row>
          <v-col class="mt-4">
            <span class="text-h6 mr-14">
              {{ url }}
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="copy2clipboard"
                  v-bind="attrs"
                  v-on="on"
                  style="float: right"
                  class="mt-1"
                  color="#006c27"
                  >mdi-content-copy</v-icon
                >
              </template>
              <span>Copy to clipboard</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApiUrlOverlay",
  components: {},
  props: {
    value: Boolean,
    proxy: Object,
    environment: String,
  },
  data: () => ({
    url: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(show) {
      if (show) {
        if (this.value && this.proxy != null && this.environment != "") {
          this.url = "https://" + this.proxy.virtualHost + this.proxy.basePath;
        }
      } else {
        this.url = "";
      }
    },
  },
  methods: {
    copy2clipboard() {
      console.log("copy");
      navigator.clipboard.writeText(this.url);
      this.close();
    },
    close() {
      this.show = false;
      this.errormessage = "";
      this.requestId = "";
    },
  },
};
</script>

<style scoped></style>
