<template>
  <v-expansion-panel>
    <v-expansion-panel-header id="certificatesList" class="pl-3">
      <div>
        <v-tooltip bottom v-if="mtlsneeded">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="red">
              mdi-certificate-outline</v-icon
            >
          </template>
          <span>Your application needs at least one valid certificate</span>
        </v-tooltip>
        <v-icon v-else>mdi-certificate-outline </v-icon>
        <span class="ml-1">Certificates</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-row
        v-if="application.certificates.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col style="text-align: left"> No certificates available </v-col>
      </v-row>
      <v-row
        v-if="application.certificates.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-divider></v-divider>
      </v-row>
      <!--
      <v-row v-if="application.certificates.length != 0">
        <v-col cols="8">
          <v-text-field
            :value="application.certificates[0].csrdetail.commonName"
            label="Common Name (CN)"
            outlined
            dense
            readonly
            hide-details
            color="#006c27"
          >
            <v-icon
              style="margin-top: 2px;"
              dense
              slot="append"
              @click="copy2clipboard(application.applicationId)"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="application.certificates[0].csrdetail.organizationUnit"
            label="Organizational Unit (OU)"
            outlined
            dense
            readonly
            hide-details
            color="#006c27"
          >
            <v-icon
              style="margin-top: 2px;"
              dense
              slot="append"
              @click="copy2clipboard(application.applicationId)"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="application.certificates.length != 0">
        <v-col cols="8">
          <v-text-field
            :value="application.certificates[0].csrdetail.emailAddress"
            label="Email Address"
            outlined
            dense
            readonly
            hide-details
            color="#006c27"
          >
            <v-icon
              style="margin-top: 2px;"
              dense
              slot="append"
              @click="copy2clipboard(application.applicationId)"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="application.certificates[0].csrdetail.keyLength"
            label="Key Length"
            outlined
            dense
            readonly
            hide-details
            color="#006c27"
          >
            <v-icon
              style="margin-top: 2px;"
              dense
              slot="append"
              @click="copy2clipboard(application.applicationId)"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="application.certificates[0].csrdetail.keyType"
            label="Key Type"
            outlined
            dense
            readonly
            hide-details
            color="#006c27"
          >
            <v-icon
              style="margin-top: 2px;"
              dense
              slot="append"
              @click="copy2clipboard(application.applicationId)"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      -->

      <v-row
        v-if="application.certificates.length != 0"
        style="padding-left: 50px; padding-right: 104px; padding-bottom: 10px"
      >
        <v-col cols="7" style="text-align: left">
          <strong>Fingerprint/Status</strong>
        </v-col>
        <v-col cols="2" style="text-align: left">
          <strong>Valid from</strong>
        </v-col>
        <v-col cols="2" style="text-align: left">
          <strong>Valid to</strong>
        </v-col>
      </v-row>
      <template v-for="(certificate, index) in application.certificates">
        <v-list-item dense :key="index" :selectable="false">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>{{ getStatusIcon(certificate.state) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-row>
              <v-col cols="7" style="text-align: left">
                {{ getFingerprint(certificate) }}
              </v-col>
              <v-col cols="2" class="ml-4" style="text-align: left">
                {{ certificate.certNotBefore | formatDateShort }}
              </v-col>
              <v-col cols="2" class="ml-1" style="text-align: left">
                {{ certificate.certNotAfter | formatDateShort }}
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="getOrSetCSR($event, certificate)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ getCSRIcon(certificate.state) }}</v-icon
                >
              </template>
              <span>{{ getCSRIconTooltip(certificate.state) }} </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="getOrSetSignedCert($event, certificate)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="getSignedCertDisabled(certificate.state)"
                  >{{ getSignedCertIcon(certificate.state) }}</v-icon
                >
              </template>
              <p
                class="mb-0"
                v-html="getSignedCertIconTooltip(certificate.state)"
              ></p>
            </v-tooltip>

            <v-icon
              :id="'deleteCertificateButton_' + index"
              @click="deleteCertificate($event, certificate.certifcateId)"
              >mdi-trash-can-outline</v-icon
            >
          </v-list-item-icon>
          <v-tooltip v-if="certificate.expiryNotification" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :id="'notificationCertificateButton_' + index"
                v-bind="attrs"
                v-on="on"
                :disabled="!certificate.certNotBefore"
                :loading="progessing"
                @click="showConfirmDialog($event, certificate, false)"
                style="max-width: 24px; max-height: 24px"
                class="no-focus"
              >
                <v-icon>mdi-alarm</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>Expiry notification is activated. Click to deactivate.</span>
          </v-tooltip>
          <v-tooltip v-if="!certificate.expiryNotification" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :id="'notificationCertificateButton_' + index"
                v-bind="attrs"
                :disabled="!certificate.certNotBefore"
                v-on="on"
                :loading="progessing"
                @click="showConfirmDialog($event, certificate, true)"
                style="max-width: 24px; max-height: 24px"
                class="no-focus"
              >
                <v-icon>mdi-alarm-off</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>Expiry notification is deactivated. Click to activate.</span>
          </v-tooltip>
        </v-list-item>
        <v-divider :key="index + 100"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-spacer></v-spacer>
        <div>Add Certificate</div>
        <v-list-item-icon>
          <v-tooltip :disabled="!addCertDisabled()" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon
                  id="addCertificateButton"
                  :disabled="addCertDisabled()"
                  @click="addCertificate"
                  >mdi-plus-circle-outline</v-icon
                >
              </span>
            </template>
            <span v-html="getDisabledToolTip()"></span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <csr-upload-overlay
      v-model="csrupload"
      @close="closeCSRUpload"
    ></csr-upload-overlay>
    <signed-cert-upload-overlay
      v-model="signedcertupload"
      @close="closeSignedCert"
    ></signed-cert-upload-overlay>
    <certificate-create-overlay
      :appid="application.applicationId"
      v-model="createDialog"
      @close="closeCertificateCreate"
    ></certificate-create-overlay>
    <confirm-delete-overlay
      v-model="confirmDelete"
      @confirm="onRealDeleteCertificate"
    >
    </confirm-delete-overlay>
    <expiry-notification-confirm
      ref="expiryComponentCert"
      v-model="confirmNotification"
      :mode="confirmNotificationMode"
    >
    </expiry-notification-confirm>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
  </v-expansion-panel>
</template>

<script>
import moment from "moment";
import cass from "../api/cass";
import CertificateCreateOverlay from "./CertificateCreateOverlay.vue";
import CsrUploadOverlay from "./CsrUploadOverlay";
import SignedCertUploadOverlay from "./SignedCertUploadOverlay.vue";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";
import ExpiryNotificationConfirm from "./ExpiryNotificationConfirm.vue";

export default {
  components: {
    CsrUploadOverlay,
    SignedCertUploadOverlay,
    CertificateCreateOverlay,
    ConfirmDeleteOverlay,
    ExpiryNotificationConfirm,
  },
  name: "Certificates",

  data: () => ({
    progessing: false,
    confirmNotification: false,
    confirmNotificationMode: false,
    showSnackError: false,
    snackErrorMessage: "",
    confirmDelete: false,
    confirmedDeleteId: null,
    csrupload: {
      show: false,
      applicationId: "",
      certifcateId: "",
    },
    signedcertupload: {
      show: false,
      applicationId: "",
      certifcateId: "",
    },
    createDialog: false,
  }),
  props: {
    application: Object,
  },
  computed: {
    mtlsneeded() {
      if (this.mtlspresent && this.application.certificates.length == 0) {
        return true;
      }
      return false;
    },
    mtlspresent() {
      if (this.application == null) {
        return false;
      }
      if (
        this.application.subscriptions == null ||
        this.application.subscriptions == undefined
      ) {
        return false;
      }
      var mtls = false;
      this.application.subscriptions.forEach((product) => {
        product.apiProxies.forEach((proxy) => {
          if (proxy.virtualHost.includes("mtls")) {
            mtls = true;
          }
        });
      });
      return mtls;
    },
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("DD.MM.YYYY HH:mm");
    },
    formatDateShort: function (value) {
      if (value === undefined) {
        return "n/a";
      }
      return moment(String(value)).format("DD.MM.YYYY");
    },
  },
  methods: {
    onRealDeleteCertificate() {
      cass
        .deleteCertificate(
          this.application.applicationId,
          this.confirmedDeleteId
        )
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
    retry() {
      this.$emit("retry");
    },
    addCertDisabled() {
      if (this.application.certificates.length >= 2) {
        return true;
      }
      if (this.application.permissionModel == "DELEGATED") {
        return true;
      }
      if (!this.mtlspresent) {
        return true;
      }
      return false;
    },
    getDisabledToolTip() {
      if (this.application.certificates.length >= 2) {
        return "Only two certificates at the same<br />time are currently allowed.";
      }
      if (this.application.permissionModel == "DELEGATED") {
        return "Certificates can only be greated<br />in permission model APPLICATION.";
      }
      if (!this.mtlspresent) {
        return "You do not have an mTLS protected API subscribed yet.";
      }
      return "Hmmm, no idea why this button is off";
    },
    getStatusIcon(state) {
      if (state === "READY_TO_USE") {
        return "mdi-check-decagram-outline";
      }
      if (state === "WAITING_FOR_CSR") {
        return "mdi-file-upload-outline";
      }
      if (state === "WAITING_FOR_SIGNED_CERT") {
        return "mdi-book-clock-outline";
      }
    },
    getCSRIcon(state) {
      if (state === "WAITING_FOR_CSR") {
        return "mdi-file-upload-outline";
      } else {
        return "mdi-file-download-outline";
      }
    },
    getCSRIconTooltip(state) {
      if (state === "WAITING_FOR_CSR") {
        return "Please Upload your CSR";
      } else {
        return "Download your CSR";
      }
    },
    getSignedCertIcon(state) {
      if (state === "WAITING_FOR_SIGNED_CERT") {
        return "mdi-upload-lock-outline";
      } else {
        return "mdi-download-lock-outline";
      }
    },
    getSignedCertIconTooltip(state) {
      if (state === "WAITING_FOR_SIGNED_CERT") {
        return '<p class="mb-0">This is activated because you are admin.</p><p class="mb-0">Please upload signed cert</p>';
      } else {
        return "Download your signed certificate";
      }
    },
    getSignedCertDisabled(state) {
      if (state === "READY_TO_USE") {
        return false;
      }
      if (
        state === "WAITING_FOR_SIGNED_CERT" &&
        this.$userData.cass.isUserAdmin
      ) {
        return false;
      }

      return true;
    },
    getFingerprint(cert) {
      if (cert.state == "READY_TO_USE") {
        return cert.fingerprint;
      }
      if (cert.state === "WAITING_FOR_CSR") {
        return "Please generate and upload CSR";
      }
      if (cert.state === "WAITING_FOR_SIGNED_CERT") {
        return "Please wait for signed certificate";
      }
    },
    closeCSRUpload() {
      this.csrupload.show = false;
    },
    closeSignedCert() {
      this.signedcertupload.show = false;
    },
    closeCertificateCreate() {
      this.createDialog = false;
    },
    getOrSetCSR(event, cert) {
      event.srcElement.blur();
      if (cert.state === "WAITING_FOR_CSR") {
        this.csrupload.show = true;
        this.csrupload.applicationId = this.application.applicationId;
        this.csrupload.certificateId = cert.certifcateId;
      } else {
        cass
          .getCSR(this.application.applicationId, cert.certifcateId)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", cert.certifcateId + ".csr");
            document.body.appendChild(fileLink);

            fileLink.click();
          });
      }
    },
    getOrSetSignedCert(event, cert) {
      event.srcElement.blur();
      if (cert.state === "WAITING_FOR_SIGNED_CERT") {
        this.signedcertupload.show = true;
        this.signedcertupload.applicationId = this.application.applicationId;
        this.signedcertupload.certificateId = cert.certifcateId;
      } else {
        cass
          .getSignedCert(this.application.applicationId, cert.certifcateId)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", cert.certifcateId + ".pem");
            document.body.appendChild(fileLink);

            fileLink.click();
          });
      }
    },
    addCertificate() {
      this.createDialog = true;
    },
    deleteCertificate(evt, certid) {
      evt.stopPropagation();
      this.confirmedDeleteId = certid;
      this.confirmDelete = true;
    },
    showConfirmDialog(evt, cert, enable) {
      this.confirmNotification = true;
      this.confirmNotificationMode = enable;
      evt.stopPropagation();
      console.log(cert);
      this.$refs.expiryComponentCert.$off("confirm");
      if (enable) {
        this.$refs.expiryComponentCert.$on(["confirm"], () => {
          console.log("CONFIRMED Activation");
          this.activateNotifyCertificate(evt, cert);
        });
      } else {
        this.$refs.expiryComponentCert.$on(["confirm"], () => {
          console.log("CONFIRMED Deactivation");
          this.deactivateNotifyCertificate(evt, cert);
        });
      }
    },
    activateNotifyCertificate(evt, cert) {
      evt.stopPropagation();
      console.log(cert);
      this.progessing = true;
      cass
        .patchCertificate(this.application.applicationId, cert.certifcateId, {
          expiryNotification: true,
        })
        .then((response) => {
          console.log(response);
          cert.expiryNotification = true;
          this.snackErrorMessage = "Activation successful!";
          this.showSnackError = true;
          this.progessing = false;
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data == "") {
            this.snackErrorMessage = err.response.statusText;
          } else {
            this.snackErrorMessage = err.response.data.message;
          }
          this.showSnackError = true;
          this.progessing = false;
        });
    },
    deactivateNotifyCertificate(evt, cert) {
      evt.stopPropagation();
      console.log(cert);
      this.progessing = true;
      cass
        .patchCertificate(this.application.applicationId, cert.certifcateId, {
          expiryNotification: false,
        })
        .then((response) => {
          console.log(response);
          cert.expiryNotification = false;
          this.snackErrorMessage = "Deactivation successful!";
          this.showSnackError = true;
          this.progessing = false;
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data == "") {
            this.snackErrorMessage = err.response.statusText;
          } else {
            this.snackErrorMessage = err.response.data.message;
          }
          this.showSnackError = true;
          this.progessing = false;
        });
    },
  },
};
</script>

<style scoped>
.no-focus::before {
  display: none;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
