<template>
  <v-overlay :dark="false" :absolute="false" :value="show">
    <v-sheet color="rgb(33,33,33,0)" width="600">
      <v-card class="d-flex flex-column" width="600">
        <v-card-title>
          <div class="">Create New Application</div>
          <v-spacer></v-spacer>
          <v-icon id="closeCreateApplicationOverlay" @click="close()"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          You want to use an Schaeffler API from within your application? Great!
          🥳<br />
          Please register your application!
        </v-card-text>
        <v-card-text class="">
          <v-form
            ref="formCreate"
            v-model="createValid"
            v-on:submit.prevent="nop"
          >
            <v-row no-gutters>
              <v-col cols="9">
                <v-text-field
                  v-model="appNameCreate"
                  id="inputApplicationName"
                  label="Application Name"
                  placeholder="give your application a nice name"
                  outlined
                  :rules="[notEmpty, min, rules.validName]"
                  :error-messages="serverErrorCreate"
                  dense
                  maxlength="50"
                  counter
                  autocomplete="new-password"
                  color="#006c27"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  class="ml-6 white--text"
                  id="createButton"
                  color="#006c27"
                  style="display: inline-block"
                  :disabled="!createValid"
                  @click="createApplication()"
                >
                  Create
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9" id="selectedEnvironment">
                <v-select
                  v-model="selectedEnvironment"
                  :items="environments"
                  label="Environment"
                  outlined
                  class="mb-3"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#006c27"
                  hide-details
                  :rules="[notEmpty]"
                  dense
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  id="environmentHelpMeButtonCreateApplication"
                  @click="showEnvironmentHelp = true"
                  class="ml-6"
                  color="#006c27"
                  text
                  style="
                        display: inline-block;
                        text-transform: none;
                        letter-spacing: 0;
                        text-align: left;
                      "
                >
                  Help me!
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9" id="selectedPermissionModel" class="mb-4">
                <v-select
                  v-model="selectedPermissionModel"
                  :items="permissionModels"
                  label="Permission Model"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#006c27"
                  :rules="[notEmpty]"
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  id="permissionHelpMeButtonCreateApplication"
                  @click="showPermissionModelHelp = true"
                  class="ml-6"
                  color="#006c27"
                  text
                  style="
                        display: inline-block;
                        text-transform: none;
                        letter-spacing: 0;
                        text-align: left;
                      "
                >
                  Help me!
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-sheet>
    <v-overlay :dark="false" :absolute="true" :value="creating" opacity="0.1">
      <v-container fill-height fluid>
        <v-row>
          <v-col align="center" justify="center">
            <v-progress-circular
              align="center"
              :size="170"
              :width="13"
              color="#006c27"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <environment-dialog v-model="showEnvironmentHelp"></environment-dialog>
    <permission-dialog v-model="showPermissionModelHelp"></permission-dialog>
  </v-overlay>
</template>

<script>
import cass from "../api/cass";
import EnvironmentDialog from "./EnvironmentDialog.vue";
import PermissionDialog from "./PermissionDialog.vue";

export default {
  name: "ApplicationCreateOverlay",
  components: { EnvironmentDialog, PermissionDialog },
  props: {
    value: Boolean,
  },
  data: () => ({
    showEnvironmentHelp: false,
    showPermissionModelHelp: false,
    appNameCreate: "",
    selectedEnvironment: "",
    environments: null,
    selectedPermissionModel: "",
    permissionModels: ["DELEGATED", "APPLICATION"],
    createValid: false,
    serverErrorCreate: "",
    creating: false,
    nameValid: false,
    rules: {
      validName: (value) => {
        let pattern = /^(\w|\.|-)+$/;
        if (!pattern.test(value)) {
          return "given name contains invalid characters";
        }
        return true;
      },
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        // Here you would put something to happen when dialog opens up
        console.log("Application Create Dialog was opened!");
        this.environments = this.$userData.cass.environments;
        this.environments.forEach(function(part, index, theArray) {
          theArray[index] = part.toUpperCase();
        });
      } else {
        console.log("Application Create Dialog was closed!");
      }
    },
  },
  methods: {
    notEmpty(value) {
      if (this.serverErrorCreate != null) {
        this.serverErrorCreate = null;
      }
      if (this.serverErrorImport != null) {
        this.serverErrorImport = null;
      }
      if (value == null || value == undefined) {
        return "Required";
      }
      if (value.length == "") {
        return "Required";
      }
      return true;
    },
    nop() {},
    min(v) {
      return (v || "").length >= 5 || `Name must be at least 5 characters long`;
    },
    close() {
      this.show = false;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.reset();
      }
      if (this.$refs.formImport) {
        this.$refs.formImport.reset();
      }
    },
    async createApplication() {
      this.creating = true;
      cass
        .createApplication({
          environment: this.selectedEnvironment,
          permissionModel: this.selectedPermissionModel,
          displayName: this.appNameCreate,
        })
        .then(async (response) => {
          console.log(response);
          // Azure needs to time to settle the application on all service instances
          // So lets test until the permission is there
          var max_retries = 30;
          var retries = 1;
          while (retries < max_retries) {
            try {
              const rc = await cass.getPermissions(response.data.applicationId);
              //.getPermissions("notavailable");
              console.log(rc);
              console.log("Application is available");
              break;
            } catch (err) {
              retries++;
              console.log(err);
              console.log(
                "Application not available, still polling. current attempt: " +
                  retries
              );
              await this.Sleep(1000);
            }
          }

          if (retries == max_retries) {
            console.log("Azure did not return the newly created app");
            this.serverErrorCreate =
              "App created successfully! Microsoft Azure has currently slow performance. Your app will be visible shortly.";
            this.creating = false;
            return;
          }

          this.$router
            .push({
              name: "Application",
              params: { applicationid: response.data.applicationId },
            })
            .catch(() => {
              console.log("Something bad happend .. start over");
              window.location.reload();
            });
          this.close();
        })
        .catch((err) => {
          console.log(err);
          let casserror = cass.handleError(err);
          console.log(casserror);
          if (casserror.message) {
            this.serverErrorCreate = casserror.message;
          } else {
            this.serverErrorCreate = "network error occurred. please retry.";
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
};
</script>

<style>
.front,
.back {
  transform-style: unset !important;
  -webkit-transform-style: unset !important;
}
</style>
