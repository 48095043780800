import { render, staticRenderFns } from "./Permissions.vue?vue&type=template&id=dab75ef8&scoped=true"
import script from "./Permissions.vue?vue&type=script&lang=js"
export * from "./Permissions.vue?vue&type=script&lang=js"
import style0 from "./Permissions.vue?vue&type=style&index=0&id=dab75ef8&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dab75ef8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dab75ef8')) {
      api.createRecord('dab75ef8', component.options)
    } else {
      api.reload('dab75ef8', component.options)
    }
    module.hot.accept("./Permissions.vue?vue&type=template&id=dab75ef8&scoped=true", function () {
      api.rerender('dab75ef8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Permissions.vue"
export default component.exports