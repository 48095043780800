var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-8 mb-8",
              attrs: { loading: _vm.loading, width: 1200 },
            },
            [
              _c(
                "template",
                { slot: "progress" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "#006c27",
                      height: "10",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "grey--text text--darken-2" },
                [
                  _c("div", [_vm._v("Manage Users")]),
                  _c("v-spacer"),
                  _c(
                    "v-text-field",
                    {
                      staticStyle: { "padding-top": "0", "margin-top": "0" },
                      attrs: {
                        label: "Search",
                        "single-line": "",
                        color: "#006c27",
                        "hide-details": "",
                      },
                      on: { keyup: _vm.applyFilter },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b({}, "v-icon", attrs, false),
                                          on
                                        ),
                                        [_vm._v("mdi-magnify")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "You can wildcard search for email, firstname or lastname"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "text--primary my-3" },
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "elevation-1",
                      attrs: {
                        "sort-by": "email",
                        "custom-sort": _vm.customSort,
                        "must-sort": "",
                        headers: _vm.headers,
                        items: _vm.filteredUsers,
                        "hide-default-footer": "",
                        "disable-pagination": "",
                        "item-key": "email",
                      },
                      on: { "click:row": _vm.rowClicked },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: `item.roles`,
                            fn: function ({ item }) {
                              return [
                                item.roles == undefined
                                  ? _c("div", [_vm._v(" never logged in ")])
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(String(item.roles)) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: `item.environments`,
                            fn: function ({ item }) {
                              return [
                                _vm._l(
                                  item.environments,
                                  function (env, index) {
                                    return [
                                      env == "development"
                                        ? _c(
                                            "v-chip",
                                            {
                                              key: index,
                                              staticClass: "mr-1",
                                              attrs: {
                                                small: "",
                                                dark: "",
                                                color: _vm.getEnvironmentColor(
                                                  env.toUpperCase()
                                                ),
                                              },
                                            },
                                            [_vm._v(" D ")]
                                          )
                                        : _vm._e(),
                                      env == "quality"
                                        ? _c(
                                            "v-chip",
                                            {
                                              key: index,
                                              staticClass: "mr-1",
                                              attrs: {
                                                small: "",
                                                dark: "",
                                                color: _vm.getEnvironmentColor(
                                                  env.toUpperCase()
                                                ),
                                              },
                                            },
                                            [_vm._v(" Q ")]
                                          )
                                        : _vm._e(),
                                      env == "production"
                                        ? _c(
                                            "v-chip",
                                            {
                                              key: index,
                                              attrs: {
                                                small: "",
                                                dark: "",
                                                color: _vm.getEnvironmentColor(
                                                  env.toUpperCase()
                                                ),
                                              },
                                            },
                                            [_vm._v(" P ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _vm.loading
                          ? _c("div", [
                              _vm._v(" Loading Users, please wait ... "),
                            ])
                          : _c("div", [_vm._v(" No users found :( ")]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "#006c27", dark: "" },
                      on: { click: _vm.rescan },
                    },
                    [_vm._v("rescan")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("user-detail-overlay", {
        attrs: { user: _vm.selectedUser },
        on: { saved: _vm.userUpdated },
        model: {
          value: _vm.showUserDialog,
          callback: function ($$v) {
            _vm.showUserDialog = $$v
          },
          expression: "showUserDialog",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "2000", color: "#006c27" },
          model: {
            value: _vm.snackBar,
            callback: function ($$v) {
              _vm.snackBar = $$v
            },
            expression: "snackBar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackText) + " ")]
      ),
      _c("user-rescan-overlay", {
        on: { scanned: _vm.scanned },
        model: {
          value: _vm.showRescanDialog,
          callback: function ($$v) {
            _vm.showRescanDialog = $$v
          },
          expression: "showRescanDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }