var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("img", {
                staticClass: "mb-1",
                attrs: { src: _vm.$getImagePath("openapi-1.svg") },
              }),
              _c("div", [_vm._v(" Download Open API Specification ")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("div", [
                _vm._v(
                  " Please choose whether to download the Open API Specification in YAML or JSON "
                ),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c("v-col", { attrs: { align: "center" } }, [
                    _c("img", {
                      staticClass: "mb-1 mr-9",
                      attrs: {
                        width: "100",
                        height: "100",
                        src: _vm.$getImagePath("json.png"),
                      },
                      on: { click: _vm.downloadJSON },
                    }),
                    _c("img", {
                      staticClass: "mb-1",
                      attrs: {
                        width: "100",
                        height: "100",
                        src: _vm.$getImagePath("yaml.png"),
                      },
                      on: { click: _vm.downloadYAML },
                    }),
                  ]),
                ],
                1
              ),
              _vm.errormessage != ""
                ? _c(
                    "v-row",
                    { staticClass: "mt-4" },
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("div", [
                          _vm._v("Error message: " + _vm._s(_vm.errormessage)),
                        ]),
                        _c("div", [
                          _vm._v("Request Id: " + _vm._s(_vm.requestId)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }