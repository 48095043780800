var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { width: "100%", persistent: "", "max-width": "500" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "max-width": "500" } },
            [
              _c("v-card-title", { staticClass: "text--secondary" }, [
                _vm._v(" Permission Details "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text--primary pb-2" },
                [
                  _vm.value.permission?.proxyName
                    ? _c("v-text-field", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.value.permission.proxyName,
                          label: "Proxy Relation",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.value.permission.name,
                      label: "Permission Name",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c("v-textarea", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.value.permission.description,
                      label: "Permission Description",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      "auto-grow": true,
                      rows: "1",
                      color: "#006c27",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.formatString(_vm.value.permission.type),
                      label: "Permission Model",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.value.permission.tokenScope,
                      label: "Token Scope",
                      outlined: "",
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      color: "#006c27",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "#006c27" },
                      on: {
                        click: function ($event) {
                          return _vm.done()
                        },
                      },
                    },
                    [_vm._v(" Done ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }